import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../service/UserService';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../Globals'
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    this.get_allBoard();
  }
  boardList: any = [];
  get_allBoard() {
    this.ngxService.start();
    this.boardList = [];
    this._userService.get_allBoard().subscribe((data: any) => {
      this.boardList = data;
      this.boardList = this.boardList.filter(x => x.boardID == 6);
//console.log('this.boardList',this.boardList)
      this.fill_ins_pat_union_CompListDt('',6);

      
        this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
         this.ngxService.stop();
         alert(err);
      });
  }
  selected_userType:string="";
  isshowboard: boolean = false;
  showboarlist(event) {
    this.selected_userType = event.target.value;
    if (event.target.value == 'HO' || event.target.value == 'Inspector' || event.target.value == 'Chairman' || event.target.value == 'Secretary' || event.target.value == 'Staff') {
      this.isshowboard = false;
    }
    else {
      this.isshowboard = true;
    }
    this.fill_ins_pat_union_CompListDt('',this.selBrdID);
  }
  userData: any = [];
  user_authList:any=[];
//   send_nextPage(userid, boardid, usertype) {
//   //  alert(usertype)
    
//     this.userData = [];
//     if (usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Staff') {

//       let url = 'getLoginUserDt/' + userid + '/' + "''";
//        alert(url);
//       this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
        
//         this.userData = data

//         sessionStorage.setItem('mathaLog_Email', this.userData[0]['emailID']);
//         sessionStorage.setItem('mathaLog_UserTypeID', this.userData[0]['userTypeID']);
//         sessionStorage.setItem('mathaLog_UserMob', this.userData[0]['mobileNo']);
//         sessionStorage.setItem('mathaUserName', this.userData[0]['userName']);
//         sessionStorage.setItem('mathaLog_BoardID', this.userData[0]['boardID']);
//         sessionStorage.setItem('mathaLog_accessFor', this.userData[0]['accessFor']);
//         sessionStorage.setItem('mathafilterBoradID', this.userData[0]['boardID']);
//         sessionStorage.setItem('mathaLog_UserType', usertype);

// //Chairman,Secretary,Inspector show count dashboard boardwise as per Niket Sir 28Jun23
// //userType HO show count dashboard all board as per Niket Sir 28Jun23

//         if (this.userData.length == 1) {
//           //window.location.replace('dashboard/clerk-dashboard');
//          // this.router.navigate(['dashboard/clerk-dashboard'])
//           if (usertype == 'Chairman' && this.userData[0]['userTypeID'] == 2) {
//             // if (this.userData[0]['accessFor'] == 'HO'){
//             //   sessionStorage.setItem('mathaLog_BoardID', '0');
//             //   sessionStorage.setItem('mathafilterBoradID', '0');
//             // }
//             this.router.navigate(['/dashboard']);
//           }
//           else if (usertype == 'Secretary' && this.userData[0]['userTypeID'] == 6) {
//             // if (this.userData[0]['accessFor'] == 'HO'){
//             //   sessionStorage.setItem('mathaLog_BoardID', '0');
//             //   sessionStorage.setItem('mathafilterBoradID', '0');
//             // }

//               this.router.navigate(['/dashboard']);
              
//           }
//           else if (usertype == 'HO' && this.userData[0]['userTypeID'] == 7) {
//             if (this.userData[0]['accessFor'] == 'HO'){
//               sessionStorage.setItem('mathaLog_BoardID', '0');
//               sessionStorage.setItem('mathafilterBoradID', '0');
//             }

//               this.router.navigate(['/dashboard']);
              
//           }
//           else if (usertype == 'Staff') {
//             this.router.navigate(['/dashboard/clerk-dashboard']);
//           }
//         }
//         else {
//           sessionStorage.setItem('mathaLog_login', '0');
//           this.router.navigate(['/sellogin'])

//           this.globals.log_userName = this.userData[0]['userName'];
//           this.globals.is_login = true;
//         }

//       },
//         (err: HttpErrorResponse) => {
//           //   this.ngxService.stop();
//         });

     
//     }
//     else if (usertype == 'Insurance') {


//     }
    
//   }

  OnLogin(usertype, boardid, mobemail, psw) {
    //alert(usertype);
    if (usertype == '') {
      alert('Select Login To.');
    }
    else if (boardid == '') {
      alert('Select Board.');
    }
    else if (psw == '') {
      alert('Enter Password.')
    }
    else {
      if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector') {
        this._userService.staff_login(mobemail,psw,usertype,boardid);
      }
      else if (usertype == 'Insurance') {

       let filter_=[];
      filter_ = this.ins_compListDt.filter(x => x.emailID == mobemail && x.password == psw)

       if (Number(filter_.length) > 0){
        sessionStorage.setItem('mathaUserToken', '');
        sessionStorage.setItem('mathaLog_UserID', filter_[0]['insCompID']);
        sessionStorage.setItem('mathaLog_UserType', usertype);

        sessionStorage.setItem('mathaLog_Email', filter_[0]['emailID']);
        sessionStorage.setItem('mathaLog_UserTypeID', '0');
        sessionStorage.setItem('mathaLog_UserMob', filter_[0]['mobNo']);
        sessionStorage.setItem('mathaUserName', filter_[0]['compName']);
        sessionStorage.setItem('mathaLog_BoardID', this.sel_boardID.toString());
        sessionStorage.setItem('mathaLog_accessFor', '');
        sessionStorage.setItem('mathafilterBoradID', this.sel_boardID.toString());
        sessionStorage.setItem('mathaLog_UserType', usertype);

        this.globals.log_userName = filter_[0]['compName'];
          this.globals.is_login = true;
          this.router.navigate(['dashboard/inscomp-Dashboard']);

       }
       
       else{
         alert('Invalid UserID Or Password.')
       }
        
      }
      else if (usertype == 'Patpedi') {

        let filter_=[];
       filter_ = this.pat_compListDt.filter(x => x.emailID == mobemail && x.password == psw)
 
        if (Number(filter_.length) > 0){
         sessionStorage.setItem('mathaUserToken', '');
         sessionStorage.setItem('mathaLog_UserID', filter_[0]['patPediID']);
         sessionStorage.setItem('mathaLog_UserType', usertype);
 
         sessionStorage.setItem('mathaLog_Email', filter_[0]['emailID']);
         sessionStorage.setItem('mathaLog_UserTypeID', '0');
         sessionStorage.setItem('mathaLog_UserMob', filter_[0]['mobNo']);
         sessionStorage.setItem('mathaUserName', filter_[0]['patPediName']);
         sessionStorage.setItem('mathaLog_BoardID', this.sel_boardID.toString());
         sessionStorage.setItem('mathaLog_accessFor', '');
         sessionStorage.setItem('mathafilterBoradID', this.sel_boardID.toString());
         sessionStorage.setItem('mathaLog_UserType', usertype);
 
         this.globals.log_userName = filter_[0]['patPediName'];
           this.globals.is_login = true;
           this.router.navigate(['dashboard/patcomp-Dashboard']);
 
        }
        
        
        else{
          alert('Invalid UserID Or Password.')
        }
         
       }
       else if (usertype == 'Union') {

        let filter_ = [];
        filter_ = this.union_compListDt.filter(x => x.emailID == mobemail && x.password == psw)

        if (Number(filter_.length) > 0) {
          sessionStorage.setItem('mathaUserToken', '');
          sessionStorage.setItem('mathaLog_UserID', filter_[0]['unionID']);
          sessionStorage.setItem('mathaLog_UserType', usertype);

          sessionStorage.setItem('mathaLog_Email', filter_[0]['emailID']);
          sessionStorage.setItem('mathaLog_UserTypeID', '0');
          sessionStorage.setItem('mathaLog_UserMob', filter_[0]['mobNo']);
          sessionStorage.setItem('mathaUserName', filter_[0]['compName']);
          sessionStorage.setItem('mathaLog_BoardID', this.sel_boardID.toString());
          sessionStorage.setItem('mathaLog_accessFor', '');
          sessionStorage.setItem('mathafilterBoradID', this.sel_boardID.toString());
          sessionStorage.setItem('mathaLog_UserType', usertype);

          this.globals.log_userName = filter_[0]['compName'];
          this.globals.is_login = true;
          this.router.navigate(['dashboard/unioncomp-dashboard']);

        }


        else {
          alert('Invalid UserID Or Password.')
        }

      }
      else if (usertype == 'Est') {

        let filter_ = [];
        filter_ = this.est_ListDt.filter(x => x.emailID == mobemail && x.password == psw)

        if (Number(filter_.length) == 0) {
          filter_ = this.est_ListDt.filter(x => x.mobNo == mobemail && x.password == psw)
        }

        if (Number(filter_.length) > 0) {
          sessionStorage.setItem('mathaUserToken', '');
          sessionStorage.setItem('mathaLog_UserID', filter_[0]['estID']);
          sessionStorage.setItem('mathaLog_UserType', usertype);

          sessionStorage.setItem('mathaLog_Email', filter_[0]['emailID']);
          sessionStorage.setItem('mathaLog_UserTypeID', '0');
          sessionStorage.setItem('mathaLog_UserMob', filter_[0]['mobNo']);
          sessionStorage.setItem('mathaUserName', filter_[0]['estName']);
          sessionStorage.setItem('mathaLog_BoardID', this.sel_boardID.toString());
          sessionStorage.setItem('mathaLog_accessFor', '');
          sessionStorage.setItem('mathafilterBoradID', this.sel_boardID.toString());
          sessionStorage.setItem('mathaLog_UserType', usertype);

          this.globals.log_userName = filter_[0]['estName'];
          this.globals.is_login = true;
          this.router.navigate(['dashboard/est-dashboard']);

        }

        else {
          alert('Invalid UserID Or Password.')
        }

      }
      
      // this._userService.UserAuthentication(mobemail, psw).subscribe((data: any) => {
      //   if (data.UserID > 0) {
      //     sessionStorage.setItem('mathaUserToken', data.access_token);
      //     sessionStorage.setItem('mathaLog_UserID', data.UserID);
      //     sessionStorage.setItem('mathaLog_UserType', usertype);
      //     this.send_nextPage(data.UserID, boardid, usertype)
      //   }
      //   else {
      //     alert('Invalid UserID Or Password.')

      //   }


      // },
      //   (err: HttpErrorResponse) => {
      //     alert('error:Invalid Connection')
         
      //   });

        
      
    }

  }

  forgotpassword(usertype, boardid, mobemail, psw) {
    if (usertype == '') {
      alert('Select Login To.');
    }
    else if (boardid == '') {
      alert('Select Board.');
    }
    else {
      sessionStorage.setItem('mathaLog_UserType', usertype);
      sessionStorage.setItem('mathaLog_BoardID', boardid);
      sessionStorage.setItem('mathafilterBoradID', boardid);
      sessionStorage.setItem('mathaLog_boardName', '');
      //window.location.replace('forgotpassword');
      //this.router.navigate(['forgotpassword'])

      if (usertype == 'Staff' || usertype == 'Est') {
        this.router.navigate(['forgotpassword']);
      }

    }
  }

  //#region  insurance/patpedi/union comapny login
   ins_compListDt = [];
   pat_compListDt = [];
   union_compListDt = [];
   est_ListDt = [];
   sel_boardID = '';
   selBrdID:Number = 0;
    fill_ins_pat_union_CompListDt(event,brdID:Number){
      
      this.ins_compListDt = [];
      this.pat_compListDt=[];
      this.union_compListDt=[];
      this.est_ListDt = [];
     // let selBrdID:Number = 0;
      if (brdID > 0){
        this.selBrdID = brdID;
      }
      else{
        this.selBrdID = event.target.value;
      }
      
      if (this.selected_userType == 'Insurance') {
       
        if (Number(this.selBrdID) > 0){
          this.ngxService.start(); 
          let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.selBrdID) + '/inscomplist'
          this._userService.get_Data(url).subscribe((data) => {
            
            this.ngxService.stop();
            this.ins_compListDt = data;
            this.sel_boardID = this.selBrdID.toString();
            
          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            //alert(err);
            return;
          });
        }
      }
      else if (this.selected_userType == 'Patpedi') {
        if (Number(this.selBrdID) > 0){
          this.ngxService.start();

          let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.selBrdID) + '/patcomplist'
          this._userService.get_Data(url).subscribe((data) => {
            
            this.ngxService.stop();
            this.pat_compListDt = data;
            this.sel_boardID = this.selBrdID.toString();
            
          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            //alert(err);
            return;
          });
        }
      }
      else if (this.selected_userType == 'Union') {
        if (Number(this.selBrdID) > 0){
          this.ngxService.start();
          
          let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.selBrdID) + '/unionlist'
          this._userService.get_Data(url).subscribe((data) => {
            
            this.ngxService.stop();
            this.union_compListDt = data;
            this.sel_boardID = this.selBrdID.toString();
            
          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            //alert(err);
            return;
          });
        }
      }
      else if (this.selected_userType == 'Est') {

        if (Number(this.selBrdID) > 0) {
          this.ngxService.start();
          let url = 'api/' + this._userService.get_est_master_controlerName(this.selBrdID) + '/getestlist/0'
          this._userService.get_Data(url).subscribe((data) => {
  
            this.ngxService.stop();
            this.est_ListDt = data;
  
            this.sel_boardID = this.selBrdID.toString();
  
          },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              //alert(err);
              return;
            });
        }
      }
    }
  //#endregion
 

}
