//#region imports
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import { NgxPaginationModule } from 'ngx-pagination';
import { retry } from 'rxjs/operators';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion 

//#region companent
@Component({
  selector: 'app-rpt-labpayments',
  templateUrl: './rpt-labpayments.component.html',
  styleUrls: ['./rpt-labpayments.component.css'],
  providers: [NgbAccordionConfig]
})
//#endregion

export class RptLabpaymentsComponent implements OnInit {

  //#region  public variable
  search_rpt: string = '';
  selected_rptID: any = 0;
  selected_rptName: string = '';

  searchToli: string = '';

  filter_wageYr: Number;
  filter_wageMonth: Number;
  filter_toliID: Number = 0;

  lbl_date: string = '';

  Log_UserID;
  Log_BoardID;
  userID;
  CompanyID = 1;

  filter_rpt = [];
  rptname_list = [];

  toli_list: any = [];

  frm_Date = new Date().toISOString().substring(0, 10);
  to_Date = new Date().toISOString().substring(0, 10);

  iway_Date = new Date().toISOString().substring(0, 10);

  // frm_Date = new Date().toISOString().substring(0, 10);
  // to_Date = new Date().toISOString().substring(0, 10);

  // iway_Date = new Date().toISOString().substring(0, 10);

  //#endregion

  //#region constructor
  constructor(private calendar: NgbCalendar,
    config: NgbAccordionConfig,
    public http: Http,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  //#endregion

  //#region ngOnit
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    this.frm_Date = new Date(this._userService.get_month_Start_Date()).toISOString().substring(0, 10); 
    this.to_Date = new Date(this._userService.get_month_end_Date()).toISOString().substring(0, 10); 

    this.userID = this.Log_UserID;

    this.fill_FinYr();
    this.fill_rpt_TabNameList();

  }
  //#endregion

  //#region loading modal
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region fill fanacial year
  YrList = [];
  fill_FinYr() {
    this.YrList = [];
    this.ngxService.start();
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getFayear').subscribe(
      (data) => {
        this.ngxService.stop();
        this.show_loadingModal(false);
        this.YrList = data;

        let tddate = new Date();
        let mon = tddate.getMonth() + 1;
        if (this.filterFayear == 0) {
          if (mon < 4) {
            this.filterFayear = Number(tddate.getFullYear()) - 1;
          }
          else {
            this.filterFayear = tddate.getFullYear();
          }
        }

        this.fill_auto_Data_List();

      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.show_loadingModal(false);
        alert(err);
        return;
      });

  }
  //#endregion

  //#region set dropdown selection
  public filterFayear = 2024;
  public txt_year = 0;
  Set_Fayear(event) {
    this.filterFayear = event.target.value;
    this.fill_auto_Data_List();
  }

  
  public filterYr: number = 0;

  invalidYear='';
  invalidMonth = '';
  vlaidateMonth(event) {
    this.invalidMonth='invalid';
    let chkval = event.target.value;
   // 
     if (Number(chkval) < 1 || Number(chkval) > 12){
      this.invalidMonth = 'invalid';
    }
    else{
      if (chkval){
        this.invalidMonth='';
      }
    }
  }
  vlaidateYear(event) {
    this.invalidYear = 'invalid';
    let chkval = event.target.value;
    // 
    if (Number(chkval) < 1980 || Number(chkval) > 2099) {
      this.invalidYear = 'invalid';
    }
    else {
      if (chkval) {
        this.invalidYear = '';
      }
    }
  }

invalid_Iway_Date = '';
invalid_frm_Date = '';
invalid_to_Date = '';

  vlaidateDate(event,fldName) {
    if (fldName == 'iwaydate'){
      this.invalid_Iway_Date = 'invalid';
    }
    else if (fldName == 'frmdate'){
      this.invalid_frm_Date = 'invalid';
    }
    else if (fldName == 'todate'){
      this.invalid_to_Date = 'invalid';
    }

    
    let chkDt = new Date(event.target.value);
   // 
     if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
      if (fldName == 'iwaydate'){
        this.invalid_Iway_Date = 'invalid';
      }
      else if (fldName == 'frmdate'){
        this.invalid_frm_Date = 'invalid';
      }
      else if (fldName == 'todate'){
        this.invalid_to_Date = 'invalid';
      }
    }
    else{
      if (chkDt.getFullYear()){
        if (fldName == 'iwaydate'){
          this.invalid_Iway_Date = '';
        }
        else if (fldName == 'frmdate'){
          this.invalid_frm_Date = '';
        }
        else if (fldName == 'todate'){
          this.invalid_to_Date = '';
        }
      }
      else{

      }
      
    }
  }

  set_Wl_SelRec_DocID(event) {
    this.Sel_wlRec_DocID = event.target.value;
  }
  //#endregion

  //#region fill auto data 
  auto_Data_List: any;
  wlRecNo_List = [];
  Sel_wlRec_DocID = 0;
  est_List = [];
  fill_auto_Data_List() {
    this.auto_Data_List = [];
    this.wlRecNo_List = [];
    this.est_List = [];
    this.ngxService.start();
    this.Sel_wlRec_DocID = 0;
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrptautofilldata/' + this.filterFayear;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        this.show_loadingModal(false);
        // console.log(data);
        this.auto_Data_List = data;
        this.wlRecNo_List = data.wlRecNo;
        this.toli_list = data.estInfo;
        if (Number(this.wlRecNo_List.length) > 0) {
          this.Sel_wlRec_DocID = Number(this.wlRecNo_List[0]['valMem']);
        }

        this.set_searchRpt(this.selected_rptID, this.selected_rptName, this.rpt_TabName);

      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });

  }


  //#endregion

  //#region clear data
  show_frmtoDt = 'no';
  show_iwayDt = 'no';
  show_wagyrmonDt = 'no';
  show_searchToli = 'no';
  show_btngenratePdf = 'no';
  show_selpayDt = 'no';
  show_selrecNo = 'no';
  show_estDropDown = 'no';
  show_btnupdchqdt = 'no';
  show_pfreg = 'no';
  show_yrsel = 'no';
  show_rpttype ='no';

  show_activefdrdbbtn = 'no';
  show_fddatewise = 'no';
  clear_Data() {
    this.show_btngenratePdf = 'no';
    this.show_frmtoDt = 'no';
    this.show_searchToli = 'no';
    this.show_wagyrmonDt = 'no';
    this.show_selpayDt = 'no';
    this.show_selrecNo = 'no';
    this.show_estDropDown = 'no'
    this.show_iwayDt = 'no';
    this.show_btnupdchqdt = 'no';
    this.show_pfreg = 'no';
    this.show_yrsel = 'no';
    this.show_rpttype ='no';

    this.filter_toli_est_market_list = [];
    this.filter_toliID = 0;
    this.toliInfo = '';
    this.searchToli = '';
    this.filter_toliID = 0;

    this.show_activefdrdbbtn = 'no';
    this.show_fddatewise = 'no';
  }
  //#endregion

  //#region fill_rptTab_Name_List
  rpt_TabName_List: any;
  fill_rpt_TabNameList() {
    this.rptname_list = [];
    this.filter_rpt = [];
    this.rpt_TabName_List = [];
    this.clear_Data();
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpttabnamelist';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        this.show_loadingModal(false);
        //console.log(data);
        this.rpt_TabName_List = data.labPay;
        // this.rptname_list = data.labPay;
        //this.filter_rpt = data.labPay;
        // this.set_rptName_List('');
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  rpt_TabName: string = '';
  set_rptName_List(tabName: string) {
    this.clear_Data();
    this.rptname_list = [];
    this.filter_rpt = [];

    this.selected_rptID = 0;
    this.selected_rptName = '';

    this.filter_rpt = [];
    this.selected_rptID = '';
    this.selected_rptName = '';
    this.clear_Data();
    this.rpt_TabName = tabName;

    this.filter_rpt = this.rpt_TabName_List.filter((item) => {
      return ((item.tabName.toString().toLowerCase()) == tabName.toString().toLowerCase());
    });

    if (this.filter_rpt.length > 0) {
      this.selected_rptID = Number(this.filter_rpt[0]['valMem']);
      this.selected_rptName = this.filter_rpt[0]['disMem'];
      this.set_searchRpt(this.selected_rptID, this.selected_rptName, tabName)

    }
  }
  //#endregion

  //#region search rpt
  search_rptName() {
    this.filter_rpt = [];
    this.selected_rptID = '';
    this.selected_rptName = '';
    this.clear_Data();


    this.filter_rpt = this.rpt_TabName_List.filter((item) => {
      return ((item.disMem.toString().toLowerCase()).indexOf(this.search_rpt.toString().toLowerCase()) > -1);
    });
  }
  set_searchRpt(rptID, rptName, tabName) {
    this.clear_Data();
    this.show_frmtoDt = '';
    this.show_btngenratePdf = '';

    this.selected_rptID = rptID;
    this.selected_rptName = rptName;
    this.rpt_TabName = tabName;

    //console.log(this.auto_Data_List);

    if (this.rpt_TabName == 'Payment') {
      if (rptID == 1) {

        //Toliwise Payment Register
        this.show_selpayDt = 'yes';
        this.show_wagyrmonDt = 'yes';
        this.show_frmtoDt = 'no';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.toli_list = this.auto_Data_List.toliInfo;
      }
      else if (rptID == 5) {

        //Iway Register
        this.show_selpayDt = 'no';
        this.show_wagyrmonDt = 'no';
        this.show_frmtoDt = 'yes';
        this.show_searchToli = 'no';
        this.show_btngenratePdf = 'yes';
        this.show_iwayDt = 'yes';
        this.lbl_date = 'Iway Date';
      }
      else if (rptID == 7) {

        //Toliwise Pagar bill
        this.show_selpayDt = 'yes';
        this.show_wagyrmonDt = 'yes';
        this.show_frmtoDt = 'no';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.toli_list = this.auto_Data_List.toliInfo;
      }
    }
    else if (this.rpt_TabName == 'WlRec') {
      if (rptID == 1) {
        //Wages/Levy Receipt
        this.show_selrecNo = 'yes';
        this.show_btngenratePdf = 'yes';
      }
      else if (rptID == 2) {
        //Receipt Register
        this.show_selrecNo = 'no';
        this.show_frmtoDt = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_searchToli = 'yes';
        this.toli_list = this.auto_Data_List.toliInfo;
        
        this.toli_list.push({
          toliID: 0,
          toliNo: 0,
          mukName: 'All',
          filterVal: '0-All',
        });

      }
      else if (rptID == 3) {
        //Establishment Ledger
        this.show_selrecNo = 'no';
        this.show_frmtoDt = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_searchToli = 'yes';
        this.toli_list = this.auto_Data_List.estInfo;

        //console.log(this.toli_list)
      }
    }
    else if (this.rpt_TabName == 'PF') {
      if (rptID == 1) {
        this.show_frmtoDt = 'yes';
        this.show_btngenratePdf = 'yes';
      }
      else if (rptID == 2) {
        this.show_pfreg = 'yes';
        this.show_yrsel = 'yes';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.toli_list = this.auto_Data_List.labInfo;
      }
    }
    else if (this.rpt_TabName == 'Accounts') {
      if (rptID == 1) {

        //Bank Reconcilation

        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_iwayDt = 'yes';
        this.show_btnupdchqdt = 'yes';
        this.lbl_date = 'As On Date';
        this.toli_list = this.auto_Data_List.bankReocnInfo;
      }
      else if (rptID == 2) {

        //Ledger A/C

        this.show_frmtoDt = 'yes';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_btnupdchqdt = 'yes';
        this.lbl_date = 'As On Date';
        this.toli_list = this.auto_Data_List.acLgrInfo;

      }
      else if (rptID == 3) {

        //Trail Bal.
        this.txt_year = this.filterFayear;
        this.show_yrsel = 'yes';
        this.show_btngenratePdf = 'yes';
        this.lbl_date = 'As On Date';
        this.toli_list = [];

      }
      else if (rptID == 4) {

        //Trail Bal.
        this.txt_year = this.filterFayear;
        this.show_rpttype ='yes';
        this.show_yrsel = 'yes';
        this.show_btngenratePdf = 'yes';
        this.lbl_date = 'As On Date';
        this.toli_list = [];

      }
    }
    else if (this.rpt_TabName == 'FD') {
      if (rptID == 1) {

        //Active FD Report
        this.show_activefdrdbbtn = 'yes';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_iwayDt = 'yes';
        this.lbl_date = 'As On Date';
        this.toli_list = this.auto_Data_List.activeFDBank;
      }
      else  if (rptID == 2) {

        //Active FD Report
        this.show_frmtoDt = 'yes';
        this.show_fddatewise = 'yes';
        this.show_searchToli = 'yes';
        this.show_btngenratePdf = 'yes';
        this.show_iwayDt = 'no';
        this.lbl_date = 'As On Date';
        this.toli_list = this.auto_Data_List.activeFDBank;
      }
      else  if (rptID == 3) {

        //Maturity Date Wise FD Report
        this.show_frmtoDt = 'yes';
        this.show_btngenratePdf = 'yes';
        this.toli_list = [];
      }
      else  if (rptID == 4) {

        //Yearly FD Accured Interest Report
        this.txt_year = this.filterFayear;
        this.show_yrsel = 'yes';
        this.show_activefdrdbbtn = 'yes';
        this.show_searchToli = '';
        this.show_btngenratePdf = 'yes';
        this.show_iwayDt = 'no';
        this.lbl_date = 'As On Date';
        this.toli_list = [];
      }
    }

  }
  //#endregion

  //#region ToliList
  filter_toli_est_market_list: any = [];
  fill_toliList() {
    this.toli_list = [];
    this.filter_toli_est_market_list = [];
    this.toliInfo = '';
    this.searchToli = '';
    this.show_searchToli = 'no';
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/gettolilist/' + this.CompanyID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        this.toli_list = data;
        //
        this.show_searchToli = 'yes';
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  filter_toli_est_market() {

    this.filter_toliID = 0;
    this.toliInfo = '';
    this.filter_toli_est_market_list = [];
    // this.filter_toli_est_market_list = this.toli_list.filter((item) => {
    //   return ((item.toliNo.toString() + item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
    // });

    this.filter_toli_est_market_list = this.toli_list.filter((item) => {
      return ((item.filterVal.toString().toLowerCase()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
    });



  }
  toliInfo: string = '';
  set_searchToli(toliID: number, toliNo, toliName) {
    this.filter_toliID = 0;
    this.toliInfo = '';
    this.searchToli = '';
    this.filter_toliID = toliID;
    this.toliInfo = toliNo.toString() + ' - ' + toliName;
    this.filter_toli_est_market_list = [];

    if (this.rpt_TabName == 'Payment') {
      this.fill_filter_payDt(1);
    }
    
  }
  //#endregion

  //#region get RptData
  rptLabPaiddBankList: any = [];
  toli_unpaidList: any = [];
  TransBanklistID: Number = 0;
  get_Data(rptID) {
    rptID = this.selected_rptID;
    if (this.rpt_TabName == 'PF') {
      if (rptID == 1) {
        this.genrate_nonRefAppl_List_rpt();
      }
      else if (rptID == 2) {
        this.genrate_PFReg_rpt();
      }

    }
    else if (this.rpt_TabName == 'Payment') {
      if (rptID == 1) {
        this.genrate_Toliwise_Payment_Register_rpt();
      }
      else if (rptID == 5) {
        this.genrate_Iway_rpt();
      }
      else if (rptID == 7) {
        this.genrate_Toliwise_Pagarbill_rpt();
      }
    }
    else if (this.rpt_TabName == 'WlRec') {
      if (rptID == 1) {
        this.genrate_Wl_Rec_rpt();
      }
      else if (rptID == 2) {
        this.genrate_Wl_RecReg_rpt();
      }
      else if (rptID == 3) {
        this.genrate_EstLeadger_rpt();
      }

    }
    else if (this.rpt_TabName == 'Accounts') {
      if (rptID == 1) {
        this.genrate_BankReconcilation_rpt();
      }
      else if (rptID == 2) {
        this.genrate_ac_ledger_rpt();
      }
      else if (rptID == 3) {
        this.genrate_trail_Bal_rpt();
      }
      else if (rptID == 4) {
        this.schedule_BalSheet_rpt_pdf();
      }
      
    }
    else if (this.rpt_TabName == 'FD') {
      if (rptID == 1) {
        this.genrate_ActiveFD_rpt();
      }
      else if (rptID == 2) {
        this.genrate_FDDateWise_rpt();
      }
      else if (rptID == 3) {
        this.genrate_Maturity_DateWise_rpt();
      }
      else if (rptID == 4) {
        this.genrate_Yearly_AccInt_rpt();
      }
      
    }

    return;

  }
  //#endregion

  //#region Receipt Tab Reports

  //#region Wages/Levy Receipt
  genrate_Wl_Rec_rpt() {
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "rptName": 'WLRec',
      "FilterID": this.Sel_wlRec_DocID,
      "Fayear": this.filterFayear,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpt';
    this.show_rpt_post_Data(url, body);
    this.ngxService.stop();
  }
  //#endregion

  //#region Wages Levy RecReg
  genrate_Wl_RecReg_rpt() {
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "rptName": 'WLRec',
      "FilterID": Number(this.filter_toliID),
      "Fayear": this.filterFayear,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrecregrpt';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
  }
  //#endregion

  //#region Est Leadger
  genrate_EstLeadger_rpt() {
    if (Number(this.filter_toliID) == 0) {
      alert("Search And Select Establishment.");
      return;
    }
    // console.log('this.filter_toliID',this.filter_toliID);
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "rptName": 'EstLedger',
      "FilterID": this.filter_toliID,
      "Fayear": this.filterFayear,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpt';
    this.show_rpt_post_Data(url, body);
    this.ngxService.stop();
  }
  //#endregion

  //#endregion

  //#region  Payment Tab Reports

  //#region Toliwise Payment Register
  genrate_Toliwise_Payment_Register_rpt() {
    let filter: any = {
      "frmDt": this.filter_labPay_Date,
      "toDt": this.filter_labPay_Date,
      "rptName": 'ToliPayReg',
      "FilterID": this.filter_toliID,
      "Fayear": this.filterFayear,
      "Wageyr": this.filter_wageYr,
      "WageMon": this.filter_wageMonth,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/gettoliwisepayreg';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
  }
  //#endregion

  //#region genrate_Toliwise_Pagarbill_rpt
  genrate_Toliwise_Pagarbill_rpt(){
    let filter: any = {
      "frmDt": this.filter_labPay_Date,
      "toDt": this.filter_labPay_Date,
      "rptName": 'ToliPayReg',
      "FilterID": this.filter_toliID,
      "Fayear": this.filterFayear,
      "Wageyr": this.filter_wageYr,
      "WageMon": this.filter_wageMonth,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/gettoliwisepagarbill';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
  }
  //#endregion

  //#region genrate iway
  genrate_Iway_rpt() {
//return;
    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    //alert(userTypeID);

    let authFor = "Iway Post";

    let url_ = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url_).subscribe((data) => {

      //  console.log('retData',data);
      auth_List = data;
      //console.log('auth_List:', auth_List)
      let filterList = [];
      if (authFor != '') {

        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }

        if (Number(filterList.length) == 0) {
          filterList = [];
          authFor = "Iway Report View";
          if (actionType.toString().toLocaleLowerCase() == 'add') {
            filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
          }
        }

        // console.log('filterList',filterList);

        if (Number(filterList.length) > 0) {

          this.toli_unpaidList = [];
          let filter: any = {
            "frmDt": this.frm_Date,
            "toDt": this.to_Date,
            "payDt": this.iway_Date,
            "rptName": 'Iway',
            "FilterID": this.filter_toliID,
            "Fayear": this.filterFayear,
            "Wageyr": this.filter_wageYr,
            "WageMon": this.filter_wageMonth,
            "UserID": this.Log_UserID,
            "CompanyID": 1,
            "remark": authFor
          }

          let body = new FormData();
          body = filter;

          this.ngxService.start();

          let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/chkiwaycriteria';

          this._userService.post_data(url, body).subscribe(
            (data) => {
              //  console.log('chkiwaycriteria', data);

              //this.show_rpt_post_Data(url, body);

              if (data.retMsg != '') {
                alert(data.retMsg);
                this.toli_unpaidList = data.unpaidToliList;
                if (this.toli_unpaidList.length > 0) {
                  this.show_unpaidlist_modal();
                }

              }
              else {
                let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getiwayrpt';
                this.show_rpt_post_Data(url, body);
              }

            },
            (err: HttpErrorResponse) => {
              alert('Something went wrong.');

              //console.log('eror',err);

              return false;
            });
          //post_data



          this.ngxService.stop();

        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });


  }
  //#endregion

  //#endregion 

  //#region PF Tab Reports

  //#region nonRef application List
  genrate_nonRefAppl_List_rpt() {
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "rptName": 'NonRefApplList'
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpt';
    this.show_rpt_post_Data(url, body);
    this.ngxService.stop();
  }

  //#endregion

   //#region  PF Register
   sel_pfstatus = 'Active';
   Set_PFStatsus(event) {
     this.sel_pfstatus = event.target.value;
   }
 
   Set_PFFayear(event) {
     this.txt_year = event.target.value;
 
   }
   genrate_PFReg_rpt() {
     let yr = Number(this.txt_year);
     if (Number(this.filter_toliID) > 0) {
       if (Number(this.txt_year) == 0) {
         yr = this.filterFayear;
       }
       else {
         yr = Number(this.txt_year);
       }
     }
     else if (Number(this.filter_toliID) == 0 && Number(this.txt_year) == 0) {
       alert('Select Year');
       return;
     }
 
     if (this.sel_pfstatus == '') {
       alert("Select status")
       return;
     }
 
     // let date: Date;
     // date = new Date(this.wlmstdata[0]["docdt"]);
     // date.setDate(date.getDate() + 1);
     // this.recDate = new Date(date).toISOString().substring(0, 10);
 
     let filter: any = {
       "frmDt": this.frm_Date,
       "toDt": this.to_Date,
       "payDt": this.iway_Date,
       "rptName": this.sel_pfstatus,
       "FilterID": this.filter_toliID,
       "Fayear": yr,//this.txt_year,
       "Wageyr": this.filter_wageYr,
       "WageMon": this.filter_wageMonth,
       "UserID": this.Log_UserID,
       "CompanyID": 1,
       "remark": this.toliInfo
     }
 
     let body = new FormData();
     body = filter;
 
     //console.log(body);
 
     //this.ngxService.start();
     // this.show_loadingModal(true);
 
     let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getbankPFRegrpt';
     this.show_rpt_post_Data(url, body);
 
     // this.ngxService.stop();
     //this.show_loadingModal(false);
   }
   //#endregion
 
  //#endregion

  //#region A/C Tab Reports
  //#region Bank Reconcilation
  genrate_BankReconcilation_rpt() {
    if (this.filter_toliID == 0) {
      alert("Select bank a/c")
      return;
    }
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "payDt": this.iway_Date,
      "rptName": 'BankReconcilation',
      "FilterID": this.filter_toliID,
      "Fayear": this.filterFayear,
      "Wageyr": this.filter_wageYr,
      "WageMon": this.filter_wageMonth,
      "UserID": this.Log_UserID,
      "CompanyID": 1,
      "remark": this.toliInfo
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getbankReconcilationrpt';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
    this.show_loadingModal(false);
  }
  //#endregion

  //#region A/C Ledger
  genrate_ac_ledger_rpt() {
    //this.filter_toliID = 501;
    if (this.filter_toliID == 0) {
      alert("Select a/c")
      return;
    }
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "payDt": this.iway_Date,
      "rptName": this.toliInfo,
      "FilterID": this.filter_toliID,
      "Fayear": this.filterFayear,
      "Wageyr": this.filter_wageYr,
      "WageMon": this.filter_wageMonth,
      "UserID": this.Log_UserID,
      "CompanyID": 1,
      "remark": this.toliInfo
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getacledgerrpt';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
    this.show_loadingModal(false);
  }
  //#endregion

  //#region Trail Bal
  genrate_trail_Bal_rpt() {
    //this.filter_toliID = 501;
    if (Number(this.txt_year) == 0) {
      alert("Select Year.")
      return;
    }
    let filter: any = {
      "Fayear": Number(this.txt_year),
      "CompanyID": 1,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/gettrailbalrpt';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
    this.show_loadingModal(false);
  }
  //#endregion

//#region Schedule Bal. Sheet
rpt_type='brief'
Set_RptType(event){
  this.rpt_type = event.target.value;
}
schedule_BalSheet_rpt_pdf() {
  if (Number(this.txt_year) == 0) {
    alert("Select Year.")
    return;
  }
  else{
    let filter: any = {
      "rptName":this.rpt_type,
      "Fayear": this.txt_year,
      "UserID": this.Log_UserID,
      "CompanyID": 1,
      "remark": this.toliInfo
    }
  
    let body = new FormData();
    body = filter;
  
    this.ngxService.start();
    this.show_loadingModal(true);
  
    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getschbalsheetrpt';
    this.show_rpt_post_Data(url, body);
  
    this.ngxService.stop();
    this.show_loadingModal(false);

  }
  
}
//#endregion

  //#endregion
 
  // #region keyPress event only numbers
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // #endregion

  //#region  set Frm To Date
  set_frmDate(event) {
    this.frm_Date = event.target.value;

  }
  set_toDate(event) {
    this.to_Date = event.target.value;

  }
  set_iwayDate(event) {
    this.iway_Date = event.target.value;

  }
  //#endregion

  //#region Unauthorized payment list modal
  showModal: boolean;
  content: string;
  title: string;
  show_unpaidlist_modal() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = '';// "This is content!!"; // Dynamic Data
    this.title = "";    // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide_unpaidlist_modal() {
    this.showModal = false;
  }
  show_bankreconcModal: boolean;

  show_bankreconc_modal() {
    this.show_bankreconcModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide_bankreconc_modal() {
    this.show_bankreconcModal = false;
  }
  //#endregion

  //#region show_rpt_post_Data
  show_rpt_post_Data(url_: string, bodyString) {
    this.show_loadingModal(true);
    this._userService.get_post_DataFile(url_, bodyString).subscribe((data) => {
      //console.log('pdfRec', data);
      // alert(data.statusText)
      if (data.statusText == "OK") {
        this.show_loadingModal(false);
        let blob: any = new Blob([data.blob()], { type: 'application/pdf; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        return true;
      } else if (data.statusText == "No Data Found") {

        this.show_loadingModal(false);
        alert('No Data Found.');
        return false;

      }
      else if (data.statusText == "Error") {
        //console.log('rpt-error',data.statusText)
        this.show_loadingModal(false);
        alert('Something went wrong.');
        return false;

        //
      }
      else {
        //console.log('rpt-error',data.statusText);
        this.show_loadingModal(false);
        alert(data.statusText);
        return false;
      }

    },
      (err: HttpErrorResponse) => {
        //console.log('err ',err.statusText);
        this.show_loadingModal(false);
        if (err.statusText != 'No Data Found') {
          alert('Something went wrong.');
        }
        else {
          this.show_loadingModal(false);
          alert(err.statusText)
        }

        //console.log('eror',err);

        return false;
      });



  }
  //#endregion

  //#region filter payDt
  filter_PayDt_List = [];
  fill_filter_payDt(rptID) {
    this.filter_PayDt_List = [];
    this.filter_labPay_Date = '';
    if (this.rpt_TabName == 'Payment') {
      if (rptID == 1) {

        if (Number(this.filter_toliID) > 0 && Number(this.filter_wageYr) > 0 && Number(this.filter_wageMonth) && Number(this.filter_wageYr.toString().length) == 4 && Number(this.filter_wageMonth.toString().length) > 0) {
          let labPayDt: any;
          labPayDt = this.auto_Data_List.labPayDt;
          this.filter_PayDt_List = labPayDt.filter(x => x.toliId == Number(this.filter_toliID) && x.wageyr == Number(this.filter_wageYr) && x.wageMon == Number(this.filter_wageMonth));
          if (this.filter_PayDt_List.length > 0) {
            this.filter_labPay_Date = this.filter_PayDt_List[0]['payDt'];
          }

        };

      }
    }
  }
  filter_labPay_Date: any;
  set_sel_PayDt(event) {
    this.filter_labPay_Date = event.target.value;
  }
  //#endregion

  //#region show cheque clearing list to update
  set_chqClr_Date(event, rindex) {
    this.chq_ClearingList[rindex]['validDt'] = 'invalid';
    this.chqClr_Date = event.target.value;

    let chkDt = new Date(event.target.value);


    if (chkDt.getDay()) {
      this.chq_ClearingList[rindex]['validDt'] = '';
    }
    if (chkDt.getMonth()) {
      this.chq_ClearingList[rindex]['validDt'] = '';
    }
    if (chkDt.getFullYear()) {
      this.chq_ClearingList[rindex]['validDt'] = '';
    }

    if (chkDt.getFullYear()) {
      this.chq_ClearingList[rindex]['validDt'] = '';
    }


    // 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
      this.chq_ClearingList[rindex]['validDt'] = 'invalid';
      return;

    }


    if (Number(this.chq_ClearingList.length) > 0) {
      //this.chq_ClearingList[rindex]['newClrDate'] = chkDt;

      this.chq_ClearingList[rindex]['newClrDate'] = new Date(chkDt).toISOString().substring(0, 10);;
    }

  }

  chq_ClearingList: any = [];
  chqClr_Date = new Date().toISOString().substring(0, 10);

  show_cheque_clearing_List() {
    this.chq_ClearingList = [];
    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    //alert(userTypeID);

    let authFor = "BankReconcilation";

    let url_ = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url_).subscribe((data) => {

      //  console.log('retData',data);
      auth_List = data;
      //console.log('auth_List:', auth_List)
      let filterList = [];
      if (authFor != '') {

        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }



        if (Number(filterList.length) > 0) {

          this.toli_unpaidList = [];
          let filter: any = {
            "frmDt": this.frm_Date,
            "toDt": this.to_Date,
            "payDt": this.iway_Date,
            "rptName": 'BankReconcilation',
            "FilterID": this.filter_toliID,
            "Fayear": this.filterFayear,
            "Wageyr": this.filter_wageYr,
            "WageMon": this.filter_wageMonth,
            "UserID": this.Log_UserID,
            "CompanyID": 1,
            "remark": authFor
          }

          let body = new FormData();
          body = filter;

          this.ngxService.start();
          this.show_loadingModal(true);
          let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/chkchqclearingcriteria';

          this._userService.post_data(url, body).subscribe(
            (data) => {

              this.ngxService.stop();
              //console.log('chqclearinglist', data);
              if (data.retMsg != '') {

                this.show_loadingModal(false);
                alert(data.retMsg);
              }
              else {
                this.ngxService.start();
                this.show_loadingModal(true);
                let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getchqclearinglist';

                this._userService.post_data(url, body).subscribe(
                  (data) => {
                    //console.log('chqclearinglist', data);
                    this.ngxService.stop();
                    this.chq_ClearingList = data;
                    this.show_loadingModal(false);
                  },
                  (err: HttpErrorResponse) => {
                    this.ngxService.stop();
                    this.show_loadingModal(false);
                    alert('Something went wrong.');

                    //console.log('eror',err);

                    return false;
                  });
              }


            },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              this.show_loadingModal(false);
              alert('Something went wrong.');

              //console.log('eror',err);

              return false;
            });



        }
        else {
          this.ngxService.stop();
          this.show_loadingModal(false);
          alert('You have no authority.')
          return;
        }

      }
      else {
        this.ngxService.stop();
        this.show_loadingModal(false);
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.show_loadingModal(false);
        alert('You have no authority.')
        return;
      });



  }
  update_cheque_clearing_Date() {

    if (confirm('Are you sure to update.?')) {
      let filter: any = {
        "TransChqClearList": this.chq_ClearingList,

      }

      let body = new FormData();
      body = filter;

      //console.log(body);

      this.ngxService.start();
      this.show_loadingModal(true);
      let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/updatechqclrdate/' + this.filterFayear;

      this._userService.post_data(url, body).subscribe(
        (data) => {
          //  console.log('chqclearinglist', data);
          this.ngxService.stop();
          this.show_loadingModal(false);
          alert(data);
          if (data == "Saved SuccessFully.") {
            this.show_cheque_clearing_List();
          }

        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.ngxService.stop();
          alert('Something went wrong.');

          //console.log('eror',err);

          return false;
        });
    }

  }

  
  p: any;
  int_p: any;
  display_recPerPage: number = 4;
  //#endregion

  //#region test


  test_rpt_pdf() {
    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "payDt": this.iway_Date,
      "rptName": "brief",
      "FilterID": 25,
      "Fayear": this.filterFayear,
      "Wageyr": this.filter_wageYr,
      "WageMon": this.filter_wageMonth,
      "UserID": this.Log_UserID,
      "CompanyID": 1,
      "remark": this.toliInfo
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getschbalsheetrpt';
    this.show_rpt_post_Data(url, body);

    this.ngxService.stop();
    this.show_loadingModal(false);
  }

  //#endregion

  //#region FD Reports

 //#region  set radio btn value
 rdb = 'BankWise';
 set_active_FD_rdb(event,setVal)
 {
  this.toli_list = [];

   if (setVal == ''){
     this.rdb = setVal;
   }
   else{
     this.rdb = event.target.value;
   }

    this.filter_toliID = 0;
    this.toliInfo = '';
    this.searchToli = '';
    this.filter_toliID = 0;
    this.toliInfo = '';
    this.filter_toli_est_market_list = [];

   if (setVal== 'BankWise'){
    this.toli_list = this.auto_Data_List.activeFDBank;
   }
   else{
    this.toli_list = this.auto_Data_List.activeFDAc;
   }
   
 }
 govrdb = '';
 set_active_FD_Govrdb(event,setVal)
 {
   if (setVal == ''){
     this.govrdb = setVal;
   }
   else{
     this.govrdb = event.target.value;
   }
   
 }
 chk_fdDatewise = false;
 set_active_FD_datewise(event){
    this.chk_fdDatewise = event.currentTarget.checked;
  //alert(this.chk_fdDatewise);
  //ActiveFD
 }
 //#endregion

 //#region Active FD Report
 genrate_ActiveFD_rpt() {
  // if (this.filter_toliID == 0) {
  //   if (this.rdb == 'BankWise'){
  //     alert("Select bank")
  //   }
  // else{
  //   alert("Select a/c")
  // }
  //   return;
  // }


  let filter: any = {
    "frmDt": this.frm_Date,
    "toDt": this.to_Date,
    "payDt": this.iway_Date,
    "payType": this.rdb,
    "rptName": 'ActiveFDReport',
    "FilterID": this.filter_toliID,
    "Fayear": this.filterFayear,
    "Wageyr": this.filter_wageYr,
    "WageMon": this.filter_wageMonth,
    "UserID": this.Log_UserID,
    "CompanyID": 1,
    "remark": this.govrdb
  }

  let body = new FormData();
  body = filter;

  this.ngxService.start();
  this.show_loadingModal(true);

  let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getActiveFDReport';
  this.show_rpt_post_Data(url, body);

  this.ngxService.stop();
  this.show_loadingModal(false);
}
 //#endregion

 //#region Active FD Report
 genrate_FDDateWise_rpt() {
  
  let remark = ''
  if (this.chk_fdDatewise == true){
    remark = 'ActiveFD';
  }

  let filter: any = {
    "frmDt": this.frm_Date,
    "toDt": this.to_Date,
    "payDt": this.iway_Date,
    "payType": 'FDDateWise',
    "rptName": this.rdb,
    "FilterID": this.filter_toliID,
    "Fayear": this.filterFayear,
    "Wageyr": this.filter_wageYr,
    "WageMon": this.filter_wageMonth,
    "UserID": this.Log_UserID,
    "CompanyID": 1,
    "remark": remark
  }

  let body = new FormData();
  body = filter;

  this.ngxService.start();
  this.show_loadingModal(true);

  let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getFDDateWiseFDReport';
  this.show_rpt_post_Data(url, body);

  this.ngxService.stop();
  this.show_loadingModal(false);
}
 //#endregion

 //#region Maturity DateWise FD Report
 genrate_Maturity_DateWise_rpt() {
  
  let remark = ''
 

  let filter: any = {
    "frmDt": this.frm_Date,
    "toDt": this.to_Date,
    "payDt": this.iway_Date,
    "payType": 'MaturityDateWise',
    "rptName": this.rdb,
    "FilterID": 0,
    "Fayear": this.filterFayear,
    "Wageyr": this.filter_wageYr,
    "WageMon": this.filter_wageMonth,
    "UserID": this.Log_UserID,
    "CompanyID": 1,
    "remark": remark
  }

  let body = new FormData();
  body = filter;

  this.ngxService.start();
  this.show_loadingModal(true);

  let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getFDDateWiseFDReport';
  this.show_rpt_post_Data(url, body);

  this.ngxService.stop();
  this.show_loadingModal(false);
}
 //#endregion

 //#region Yearly FD Accured Interest Report
 genrate_Yearly_AccInt_rpt(){
  let remark = ''
 if (Number(this.txt_year) > 1980 && Number(this.txt_year) < 2099){

 }
 else{
   alert('Select valid year.');
   return;
 }

  let filter: any = {
    "frmDt": this.frm_Date,
    "toDt": this.to_Date,
    "payDt": this.iway_Date,
    "payType": 'MaturityDateWise',
    "rptName": this.rdb,
    "FilterID": 0,
    "Fayear": Number(this.txt_year),
    "Wageyr": this.filter_wageYr,
    "WageMon": this.filter_wageMonth,
    "UserID": this.Log_UserID,
    "CompanyID": 1,
    "remark": remark
  }

  let body = new FormData();
  body = filter;

  this.ngxService.start();
  this.show_loadingModal(true);

  let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getYearlyAccIntFDReport';
  this.show_rpt_post_Data(url, body);

  this.ngxService.stop();
  this.show_loadingModal(false);
 }
 //#endregion

//#endregion


}
