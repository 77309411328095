import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private _userService: UserService
    , public router: Router
    , public http: Http, ) { }
    Log_BoardID = 0;
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
  }

  userData:any = [];
  est_ListDt = [];
  union_ListDt =[];
  goto_OtpSereen(MobNo: string) {
   //alert(MobNo);
    if (MobNo.length != 10) {
      alert('Enter Valid Mobile No.')
    }
    else {
      sessionStorage.setItem('Mob_OTP', '');
      let IsValidNo = 'no';
    //alert(sessionStorage.getItem('mathaLog_UserType'));
    
    let usertype:string = sessionStorage.getItem('mathaLog_UserType');

    sessionStorage.setItem('Mob_OTP', '');
      
   // alert(sessionStorage.getItem('mathaLog_UserType'));

          IsValidNo = 'yes';
           let otpval:any = "********####";//Math.floor(Math.random() * 9000) + 1000;
           otpval = this._userService.getMobileotp(MobNo);
           
           sessionStorage.setItem('Mob_OTP', otpval.toString());
           let parameters = {
             mob: MobNo
           };

    if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector'){
     let url = 'getLoginUserDt/0/' + MobNo;
        this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
        if (data.length > 0) {
           
           this.userData = data;
           sessionStorage.setItem('userData', data);

           IsValidNo = 'yes';
           let otpval:any = "********####";//Math.floor(Math.random() * 9000) + 1000;
           otpval = this._userService.getMobileotp(MobNo);
           //alert(otpval);
           sessionStorage.setItem('Mob_OTP', otpval.toString());
           let parameters = {
             mob: MobNo
           };
           this.router.navigate(['/verifiedcode'], { queryParams: parameters });

         }
         else {
           alert('Enter Valid Registor Mobile No.')
         }
         },
       (err: HttpErrorResponse) => {
         // this.ngxService.stop();
         alert('Enter Valid Registor Mobile No.')
       }); 
   }
   else if (sessionStorage.getItem('mathaLog_UserType') == 'Est'){

    if (Number(this.Log_BoardID) > 0) {

      let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/getestlist/0'
      this._userService.get_Data(url).subscribe((data) => {
        this.est_ListDt = data;

        let filter_ = [];
        filter_ = this.est_ListDt.filter(x => x.emailID == MobNo)

        if (Number(filter_.length) == 0) {
          filter_ = this.est_ListDt.filter(x => x.mobNo == MobNo)
        }

        if (Number(filter_.length) == 1) {
          this.router.navigate(['/verifiedcode'], { queryParams: parameters });
        }
      },
        (err: HttpErrorResponse) => {

          //alert(err);
          return;
        });
    }
  }
  else if (sessionStorage.getItem('mathaLog_UserType') == 'Union'){

    if (Number(this.Log_BoardID) > 0) {

      let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/unionlist'
      this._userService.get_Data(url).subscribe((data) => {
        this.union_ListDt = data;

        let filter_ = [];
        filter_ = this.union_ListDt.filter(x => x.emailID == MobNo)

        if (Number(filter_.length) == 0) {
          filter_ = this.union_ListDt.filter(x => x.mobNo == MobNo)
        }

        if (Number(filter_.length) == 1) {
          this.router.navigate(['/verifiedcode'], { queryParams: parameters });
        }
      },
        (err: HttpErrorResponse) => {

          //alert(err);
          return;
        });
    }
  }
   }

  }

}
