<div  class="notranslate">
<div class="container dashboardmain">
    <div id="print-section" style="text-align: center;">
        <!-- <h3>नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक</h3>
        <p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p>
        <h4>पावती</h4> -->

<table class="table table-bordered" style="height: 400px;">
  <tbody>
    <tr >
      <td style="text-align: center;" colspan="2"><h3 style="font-weight: bold;">नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक</h3> 
      
       <p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p>
      <h3 style="font-weight: bold;">पावती</h3> 
      
      
    </tr>

    <!-- <tr >
      <td style="text-align: center;" colspan="2"><h3 style="font-weight: bold;"><p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p></h3> 
      
      <td></td>
      
    </tr> -->

    <!-- <tr >
      <td style="border-left: 1px solid white;text-align: center;" colspan="2"><p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p> </td>
      
    </tr> -->
    <tr >
      <td  style="text-align: left;border-bottom-color: white;border-right-color: white;">मालक नो.क्र. </td>
      <td  style="text-align: left;border-bottom-color: white;">टोळी क्र. </td>
    </tr>
    <tr >
      <td  style="text-align: left;border-right-color: white;border-top-color: white;">पावती क्र.</td>
      <td  style="text-align: left;">पावती दिनांक</td>
    </tr>
    <tr >
        <td style="text-align: left;" colspan="2">
            मे.श्री.- 
        
      </tr>
      <tr >
        <td style="text-align: left;" colspan="2">
            यांचेकडून माहे 
        </tr>
        <tr>
            <td style="text-align: left;">धनादेश क्र.</td>
            <td style="text-align: left;"></td>
        </tr>
        <tr>
            <td style="text-align: left;">मजुरी रक्कम</td>
            <td style="text-align: right;"></td>
        </tr>
        <tr>
            <td style="text-align: left;">लेव्ही रक्कम</td>
            <td style="text-align: right;"></td>
        </tr>
        <tr>
            <td style="text-align: left;">इतर रक्कम</td>
            <td style="text-align: right;"></td>
        </tr>
        <tr>
            <td style="text-align: left;font-weight: bold;">एकुण रक्कम</td>
            <td style="text-align: right;font-weight: bold;"></td>
        </tr>
        <tr>
            <td colspan="2" style="text-align: left;border-bottom-color: white;">जमा खाते </td>
            
        </tr>
        <tr>
            <td colspan="2" style="text-align: left;border-bottom-color: white;">जमा प्रकार</td>
            
        </tr>
        <tr>
            <!-- <td colspan="2" style="text-align: left;"> </td> -->
            <td colspan="2" style="text-align: right;border-top-color: white;">स्वीकारणाऱ्याची सही</td>
        </tr>
  </tbody>
</table>



        
        </div>
        <div style="text-align: center;margin-top: 5%;">
          <button type="button" class="btn btn-outline-primary btn-xs" styleSheetFile="assets/css/table.css"
            [useExistingCss]="true" printSectionId="print-section" ngxPrint>Create Pdf</button>
        </div>
</div>
</div>