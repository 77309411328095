//#region imports
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import { NgxPaginationModule } from 'ngx-pagination';
import { retry } from 'rxjs/operators';
import { runInThisContext } from 'vm';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion 


@Component({
  selector: 'app-est-dashboard',
  templateUrl: './est-dashboard.component.html',
  styleUrls: ['./est-dashboard.component.css']
})
export class EstDashboardComponent implements OnInit {

  //#region global variable
  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';
  active;
  disabled = true;

  onNavChange(changeEvent: NgbNavChangeEvent) {
    // if (changeEvent.nextId === 3) {
    //   changeEvent.preventDefault();
    // }
  }

  toggleDisabled() {
    // this.disabled = !this.disabled;
    // if (this.disabled) {
    //   this.active = 1;
    // }
  }
  //#endregion

  //#region constructor
  constructor(private calendar: NgbCalendar,
    config: NgbAccordionConfig,
    public http: Http,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) {
    config.closeOthers = true;
    config.type = 'info';
  }

  //#endregion

  // #region ngAfterViewChecked
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion

  //#region ngOnIt
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    this.frm_Date = new Date(this._userService.get_month_Start_Date()).toISOString().substring(0, 10);
    this.to_Date = new Date(this._userService.get_month_end_Date()).toISOString().substring(0, 10);

    this.frm_lgr_Date = new Date(this._userService.get_year_Start_Date()).toISOString().substring(0, 10);
    this.to_lgr_Date = new Date(this._userService.get_year_end_Date()).toISOString().substring(0, 10);

  }
  //#endregion

  //#region loading modal
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region  set Frm To Date
  frm_Date = new Date().toISOString().substring(0, 10);
  to_Date = new Date().toISOString().substring(0, 10);
  frm_lgr_Date = new Date().toISOString().substring(0, 10);
  to_lgr_Date = new Date().toISOString().substring(0, 10);
  set_Date(event, flag) {
    if (flag == 'frmdt') {
      this.frm_Date = event.target.value;
    }
    else if (flag == 'todt') {
      this.to_Date = event.target.value;
    }
    else if (flag == 'frmlgrdt') {
      this.frm_lgr_Date = event.target.value;
    }
    else if (flag == 'tolgrdt') {
      this.to_lgr_Date = event.target.value;
    }
  }


  //#endregion

  //#region clear filter control
  filter_RecNo: string = '';
  filter_docdt: string = '';
  filter_toliNo: string = '';
  filter_mukName: string = '';
  filter_wageyr: string = '';
  filter_wageMon: string = '';
  filter_wagesAmt: string = '';
  filter_levyAmt: string = '';
  filter_anamatAmt: string = '';
  filter_totAmt: string = '';

  filter_NoRec_toliNo: string = '';
  filter_NoRec_mukName: string = '';
  clear_filterVal() {
    this.filter_RecNo = '';
    this.filter_docdt = '';
    this.filter_toliNo = '';
    this.filter_mukName = '';
    this.filter_wageyr = '';
    this.filter_wageMon = '';
    this.filter_wagesAmt = '';
    this.filter_levyAmt = '';
    this.filter_anamatAmt = '';
    this.filter_totAmt = '';

    this.filter_NoRec_toliNo = '';
    this.filter_NoRec_mukName = '';
  }
  //#endregion

  //#region fill data

  get_Data(flag) {

    if (flag == 'wlrec') {
      this.rec_DtToli = [];
      this.rec_DoneToli = [];
      let filter: any = {
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
      }

      let body = new FormData();
      body = filter;

      let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/getestwiserec/' + this.Log_UserID;
      this.show_loadingModal(true);
      this._userService.post_data(url, body).subscribe((data) => {
        //console.log(data);
        this.rec_DtToli = data;
        this.rec_DoneToli = data;

        this.show_loadingModal(false);
        if (Number(this.rec_DoneToli.length) == 0) {
          alert('No data found.')
        }
      },
        (err: HttpErrorResponse) => {
          //console.log('err ',err.statusText);
          this.show_loadingModal(false);
          alert('Something went wrong.');

          //console.log('eror',err);

          return false;
        });

    }

  }
  //#endregion

  //#region filter wl rec
  filter_recDone(filterCol, filterval) {
    this.set_filterVal(filterCol, filterval);

    this.rec_DoneToli = this.rec_DtToli;
    let isFilterVal = '';
    // let filterData:any =[];
    // filterData = this.rec_DtToli;
    if (this.filter_RecNo.toString() != '') {

      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.receiptNO.toString()).indexOf(this.filter_RecNo.toString().toLowerCase()) > -1);
      //  });

      this.rec_DoneToli = this.rec_DtToli.filter(x => x.receiptNO.toString() == this.filter_RecNo);
    }
    if (this.filter_docdt.toString() != '') {
      isFilterVal = 'yes';
      this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
        return ((item.docdt.toString()).indexOf(this.filter_docdt.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_toliNo.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.toliNo.toString() == this.filter_toliNo);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.toliNo.toString()).indexOf(this.filter_toliNo.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_mukName.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
        return ((item.mukName.toString()).indexOf(this.filter_mukName.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_wageyr.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wageyr.toString() == this.filter_wageyr);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wageyr.toString()).indexOf(this.filter_wageyr.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_wageMon.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wageMon.toString() == this.filter_wageMon);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wageMon.toString()).indexOf(this.filter_wageMon.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_wagesAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wagesAmt.toString() == this.filter_wagesAmt);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wagesAmt.toString()).indexOf(this.filter_wagesAmt.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_levyAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.levyAmt.toString() == this.filter_levyAmt);

    }
    if (this.filter_anamatAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.anamatAmt.toString() == this.filter_anamatAmt);

    }
    if (this.filter_totAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.totAmt.toString() == this.filter_totAmt);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.totAmt.toString()).indexOf(this.filter_totAmt.toString().toLowerCase()) > -1);
      //  });
    }

  }
  set_filterVal(filterCol, filterval) {
    if (filterCol == 'receiptNO') {
      this.filter_RecNo = filterval.toString();
    }
    else if (filterCol == 'docdt') {
      this.filter_docdt = filterval.toString();
    }
    else if (filterCol == 'toliNo') {
      this.filter_toliNo = filterval.toString();
    }
    else if (filterCol == 'mukName') {
      this.filter_mukName = filterval.toString();
    }
    else if (filterCol == 'wageyr') {
      this.filter_wageyr = filterval.toString();
    }
    else if (filterCol == 'wageMon') {
      this.filter_wageMon = filterval.toString();
    }
    else if (filterCol == 'wagesAmt') {
      this.filter_wagesAmt = filterval.toString();
    }
    else if (filterCol == 'levyAmt') {
      this.filter_levyAmt = filterval.toString();
    }
    else if (filterCol == 'anamatAmt') {
      this.filter_anamatAmt = filterval.toString();
    }
    else if (filterCol == 'totAmt') {
      this.filter_totAmt = filterval.toString();
    }
  }
  //#endregion

  //#region get total
  rec_DtToli: any;
  rec_DoneToli: any;
  getTotal(data: any, fldname) {
    // console.log('totData:',data);
    if (data == null) {
      return '';
    }
    if (Number(data.length) > 0) {
      if (fldname == 'wagesAmt') {
        return data.reduce((sum, curr) => sum + curr.wagesAmt, 0);
      }
      else if (fldname == 'levyAmt') {
        return data.reduce((sum, curr) => sum + curr.levyAmt, 0);
      }
      else if (fldname == 'anamatAmt') {
        return data.reduce((sum, curr) => sum + curr.anamatAmt, 0);
      }
      //
      else if (fldname == 'totAmt') {
        return data.reduce((sum, curr) => sum + curr.totAmt, 0);
      }
      else if (fldname == 'netPay') {
        return data.reduce((sum, curr) => sum + curr.netPay, 0);
      }
      else if (fldname == 'recWageAmt') {
        return data.reduce((sum, curr) => sum + curr.recWageAmt, 0);
      }
      else if (fldname == 'recPaidAmt') {
        return data.reduce((sum, curr) => sum + curr.recPaidAmt, 0);
      }
    }



  }
  //#endregion

  //#region download pdf
  genrate_Wl_Rec_rpt(docID, fyr,estID) {

    let filter: any = {
      "frmDt": this.frm_Date,
      "toDt": this.to_Date,
      "rptName": 'EstWLRec',
      "FilterID": docID,
      "EstID": estID,
      "Fayear": fyr,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpt';
    this.show_rpt_post_Data(url, body);
    this.ngxService.stop();
  }

  //#region Est Leadger
  genrate_EstLeadger_rpt() {
    //return;
    // console.log('this.filter_toliID',this.filter_toliID);
    let filter: any = {
      "frmDt": this.frm_lgr_Date,
      "toDt": this.to_lgr_Date,
      "rptName": 'EstLedgerEst',
      "FilterID": this.Log_UserID,
      "Fayear": 0,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getrpt';
    this.show_rpt_post_Data(url, body);
    this.ngxService.stop();
  }
  //#endregion

  //#endregion

  //#region show_rpt_post_Data
  show_rpt_post_Data(url_: string, bodyString) {
    this.show_loadingModal(true);
    this._userService.get_post_DataFile(url_, bodyString).subscribe((data) => {
      //console.log('pdfRec', data);
      // alert(data.statusText)
      if (data.statusText == "OK") {
        this.show_loadingModal(false);
        let blob: any = new Blob([data.blob()], { type: 'application/pdf; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        return true;
      } else if (data.statusText == "No Data Found") {

        this.show_loadingModal(false);
        alert('No Data Found.');
        return false;

      }
      else if (data.statusText == "Error") {
        //console.log('rpt-error',data.statusText)
        this.show_loadingModal(false);
        alert('Something went wrong.');
        return false;

        //
      }
      else {
        //console.log('rpt-error',data.statusText);
        this.show_loadingModal(false);
        alert(data.statusText);
        return false;
      }

    },
      (err: HttpErrorResponse) => {
        //console.log('err ',err.statusText);
        this.show_loadingModal(false);
        if (err.statusText != 'No Data Found') {
          alert('Something went wrong.');
        }
        else {
          this.show_loadingModal(false);
          alert(err.statusText)
        }

        //console.log('eror',err);

        return false;
      });



  }
  //#endregion

}
