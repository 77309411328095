//#region imports
import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data, event } from 'jquery';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
//#endregion
@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
export class AddNotificationComponent implements OnInit {

  //#region public variable
  sub_Form: FormGroup;
  
  rdb = 'All';
  message_ID = 0;
  public UserType: string = '';
  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';
  //#endregion

  //#region  constructor
  constructor(config: NgbAccordionConfig,
    private _userService: UserService,
    private globals: Globals,
    private _http: Http,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder) {

      this.sub_Form = this.formBuilder.group({
        subject: ['', [Validators.required]],
        mesg: ['', [Validators.required]],
        txtlabName: ['', [Validators.required]],
      });

     }

  //#endregion

  //#region return error to control 
  get errorControl() {
    return this.sub_Form.controls;
  }
  //#endregion

   //#region go back
   go_back() {
    this.router.navigate(['dashboard/clerk-dashboard']);
   }
  //#endregion

  //#region ng OnIt
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }


   // alert( this.Log_BoardID)

    this.fill_labList();

  }
  //#endregion

  //#region  set radio btn value
set_rdb(event,setVal)
{
  this.show_searchCnt = 'no';
  if (setVal == ''){
    this.rdb = setVal;
  }
  else{
    this.rdb = event.target.value;
  }
  if (this.rdb != 'All'){
    this.show_searchCnt = 'yes';
  }
  
}
//#endregion

//#region fill lab lIst and filter

show_searchCnt = 'no';
searchLab: string = '';
filter_labID = 0;
lab_list:any=[];
filter_Lab_list: any = [];
fill_labList() {
  this.lab_list = [];
  this.filter_Lab_list = [];
  this.labInfo = '';
  this.searchLab = '';
  this.show_searchCnt = 'no';
  let url = 'api/AppNotification/getlablist/' + this.Log_BoardID;
  this._userService.get_Data(url).subscribe(
    (data) => {
      this.ngxService.stop();
      this.lab_list = data.labInfo;
    },
    (err: HttpErrorResponse) => {
      this.ngxService.stop();
      alert(err);
    });
}
filter_Lab() {

  this.filter_labID = 0;
  this.labInfo = '';
  this.filter_Lab_list = [];
  // this.filter_toli_est_market_list = this.toli_list.filter((item) => {
  //   return ((item.toliNo.toString() + item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
  // });

  this.filter_Lab_list = this.lab_list.filter((item) => {
    return ((item.filterVal.toString().toLowerCase()).indexOf(this.searchLab.toString().toLowerCase()) > -1);
  });

 // this.filter_Lab_list = this.lab_list.filter(x => x.toliNo == Number(toliNo));


}
labInfo: string = '';
set_searchLab(toliID: number, toliNo, toliName) {
  this.filter_labID = 0;
  this.labInfo = '';
  this.searchLab = '';
  this.filter_labID = toliID;
  this.labInfo = toliNo.toString() + ' - ' + toliName;

  this.add_lab_In_sendLabData(this.filter_labID,this.labInfo);

  this.filter_Lab_list = [];

}


//#endregion

//#region add labour in SendList
sendLabData_List:any =[];
add_lab_In_sendLabData(LabID,LabInfo){
    if (Number(this.sendLabData_List.length) > 0){

      for (let k = 0; k < this.sendLabData_List.length; k++) {
        if (Number(this.sendLabData_List[k]['LabourID']) == Number(LabID) && this.sendLabData_List[k]['AddLab'] == 'Yes'){
           return;
        }
        else if (Number(this.sendLabData_List[k]['LabourID']) == Number(LabID) && this.sendLabData_List[k]['AddLab'] == 'No'){
          this.sendLabData_List[k]['AddLab'] = 'Yes';
          return;
        }
      }

    }
   

  this.sendLabData_List.push({
    LabourID: LabID,
    LabInfo: LabInfo,
    AddLab:'Yes'
  });
  console.log(this.sendLabData_List)
}
remove_Lab_record(rindex){
  if (confirm('Are you sure to reomve.?')){
     this.sendLabData_List[rindex]['AddLab'] = 'No';
  }
 

  // for (let k = 0; k < this.sendLabData_List.length; k++) {
  //   if (Number(this.sendLabData_List[k]['LabourID']) == Number(labID)){
  //     this.sendLabData_List[k]['AddLab'] = 'No';
  //   }
  // }
}
//#endregion

//#region set browse file
public Save_formData = new FormData();
  imgSrc: any;
  Notific_fileName:any;
  set_file(event:any) {
     this.imgSrc= '';
     this.Notific_fileName = '';
      this.Save_formData = new FormData();
      this.Save_formData.append("", event.target.files[0], event.target.files[0].fileName);
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.imgSrc = event.target.result;
         
      }
      reader.readAsDataURL(event.target.files[0]);

      for (let file of event.target.files) {
        this.Notific_fileName =  file.name;
        // // Append to a list
        // this.files.push({
        //     name: file.name,
        //     type: file.type
        // });
    }

  }
//#endregion

//#region fill history
  msg_HistoryList:any = [];
  fill_history(){
    this.msg_HistoryList = [];

  }
  //#endregion

  //#region submit
  isSubmitted:any=false;
  submit(){
    this.isSubmitted = true;

    if (this.sub_Form.controls['subject'].value == '' || this.sub_Form.controls['subject'].value == null){
      alert('Enter subject.')
    }
    else  if (this.sub_Form.controls['mesg'].value == '' || this.sub_Form.controls['mesg'].value == null){
      alert('Enter message.')
    }
    else{
      if (confirm('Are you sure to submit.?')){

        let msg_Data: any = [{
          "MessageID":this.message_ID,
          "Subject":this.sub_Form.controls['subject'].value,
          "Message":this.sub_Form.controls['mesg'].value,
          "SendTo":this.rdb,
          "FileName":'',
          "UserID":this.Log_UserID,
          
        }]
    
        console.log(msg_Data);
    
        let url = this.globals.BaseURL + 'api/AppNotification/savenotificationmsg/' + this.Log_BoardID;
       // console.log(url);
    
        this.Save_formData.append("savemsg", JSON.stringify(msg_Data));
        this.Save_formData.append("labinfo", JSON.stringify(this.sendLabData_List));
    
        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {
    
            $("#fileProgress").hide();
    
           // console.log('retVal ', fileName);
    
            if (fileName == 'Saved SuccessFully.') {
              alert('Updated successfully.');
              
            }
            else {
              alert('Something went wrong internal server error.')
              return;
            }
    
          }
        });

        this.go_back();
        
      }
     }

  }
  //#endregion

//#region  test upload file
set_file_path(event:any) {
  this.imgSrc= '';
  this.Notific_fileName = '';
   this.Save_formData = new FormData();
   this.Save_formData.append("", event.target.files[0], event.target.files[0].fileName);
   var reader = new FileReader();
   reader.onload = (event:any) => {
       this.imgSrc = event.target.result;
      
   }
   reader.readAsDataURL(event.target.files[0]);

   for (let file of event.target.files) {
     this.Notific_fileName =  file.name;
     // // Append to a list
     // this.files.push({
     //     name: file.name,
     //     type: file.type
     // });
 }

}
//#endregion

}
