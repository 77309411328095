//#region imports
import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';
import { timeInterval } from 'rxjs/operators';

//#endregion

@Component({
  selector: 'app-upload-excel',
  templateUrl: './upload-excel.component.html',
  styleUrls: ['./upload-excel.component.css']
})
export class UploadExcelComponent implements OnInit {
//#region  global variables
title = 'htmltopdf';
@ViewChild('pdfTable') pdfTable: ElementRef;


  lastUploadMonth:string='';
  loading = '';
  uplodFile_for = '';
  
  active;
  disabled = true;
  activeTab = 'config-panel-bank';

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';

  public filterFayear: number = 2024;
  public fayear: number = 0;

  public uploadYr = 0;
  public uploadMon = 0;
  public currDateTime = new Date();
  payDate;
  //#endregion
  //#region constructor
  constructor(
    private http: HttpClient,
    config: NgbAccordionConfig
    , private _userService: UserService
    , private globals: Globals
    , public router: Router
    , private route: ActivatedRoute
    , private ngxService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) 
  { 
    this.uplodFile_for = this.route.snapshot.queryParamMap.get('flag');
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));

      config.closeOthers = true;
      config.type = 'info';
      if (this.uplodFile_for == 'bank'){
        this.activeTab = 'config-panel-bank';
      }
      else if(this.uplodFile_for == 'est'){
        this.activeTab = 'config-panel-est';
      }
  }

  //#endregion

  // #region ngAfterViewChecked
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion

//#region ngOnit
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));

    }
    this.payDate = new Date().toISOString().substring(0, 10);
    this.fill_toli_est_list();

  }
    
  //#endregion

  //#region fill toli/est list
    est_List:any= [];
    toli_list:any= [];
    fill_toli_est_list(){
     
      this.est_List = [];
      this.ngxService.start();
      
      this.show_loadingModal(true);
  
      let url = 'api/' + this._userService.get_upload_excel_controlerName(this.Log_BoardID) + '/gettoliestdata';
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.ngxService.stop();
          this.show_loadingModal(false);
          this.est_List = data.estInfo;         
          this.toli_list = data.toliInfo;
        
        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.ngxService.stop();
          alert(err);
        });
    }
  //#endregion

  //#region search record
searchToli: string = '';
filter_toli_List:any=[];
filter_toliID = 0;
filter_toli(){
  this.filter_toliID = 0;
  this.toliInfo = '';
  this.filter_toli_List = [];
  this.filter_toli_List = this.toli_list.filter((item) => {
    return ((item.filterVal.toString().toLowerCase()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
  });
}
toliInfo: string = '';
  set_searchToli(toliID: number, toliNo, toliName) {
    this.filter_toliID = 0;
    this.toliInfo = '';
    this.searchToli = '';
    this.filter_toliID = toliID;
    this.toliInfo = toliNo.toString() + ' - ' + toliName;
    this.filter_toli_List = [];

  }

searchEst: string = '';
estInfo: string = '';  
filter_Est_List:any=[];
filter_estID = 0;
filter_est(){
  this.Dt_RetVal = [];
  this.filter_estID = 0;
  this.estInfo = '';
  this.filter_Est_List = [];
  this.filter_Est_List = this.est_List.filter((item) => {
    return ((item.filterVal.toString().toLowerCase()).indexOf(this.searchEst.toString().toLowerCase()) > -1);
  });
}

  set_searchEst(estID: number, estNo, estName) {
    this.filter_estID = 0;
    this.estInfo = '';
    this.searchEst = '';
    this.filter_estID = estID;
    this.estInfo = estNo.toString() + ' - ' + estName;
    this.filter_Est_List = [];

  }
//#endregion

  //#region "set brwose file"
  Save_formData = new FormData();
  arrayBuffer: any;
  upload_fileName: File;
  sel_uploadfile(event) {
    this.upload_fileName = event.target.files[0];
    this.Dt_RetVal = [];
  }
  //#endregion

  //#region upload bank excel file
  uploadExcel_Data:any = [];
  public exlData_List : any = [];
  upload_bank_Excel(){
    this.uploadExcel_Data = [];
    this.exlData_List = [];
  
    if (this.upload_fileName == null) {
      alert('Select file.')
      return false;
    }
    else{
      if (confirm('Are you sure to upload file.?')){
        
        this.show_loadingModal(true)

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" , cellDates: true});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.uploadExcel_Data = XLSX.utils.sheet_to_json(worksheet, { 
           // raw: true
          
           // raw: false,
            // header: worksheet ? 0 : 1,
            // dateNF: "dd/mm/yyyy"
          }),console.error(this.show_loadingModal(false));
          
          

          if (Number(this.uploadExcel_Data.length) > 0) {
            this.show_loadingModal(true);

            for (let k = 0; k < this.uploadExcel_Data.length; k++) {

              let date: Date;
              date = new Date(this.uploadExcel_Data[k]['DATE']);
              date.setDate(date.getDate() + 1);
              let payDate = new Date(date).toISOString().substring(0, 10);

              this.exlData_List.push({
                UserID: this.Log_UserID,
                TransDt: this.uploadExcel_Data[k]['DATE'],
                UPINo: this.uploadExcel_Data[k]['UPIID'],
                Amt: this.uploadExcel_Data[k]['DEPOSITS'],
              });

          }
           
            if (Number(this.exlData_List.length) > 0) {

              this.show_loadingModal(true);
  
              this.loading = 'y';
    
              let url = 'api/' + this._userService.get_upload_excel_controlerName(this.Log_BoardID) + '/uploadbankexcel';
              let body = new FormData();

              let exlDt: any = {
                "BankUploadExcel": this.exlData_List,
              }

              body = exlDt;

              this._userService.post_data(url, body).subscribe(
                (data) => {

                  this.upload_fileName == null;
                  this.show_loadingModal(false);
                  console.log(data);
                  
                  this.Dt_RetVal = data;

                  this.loading = '';

                  // if (data == 'Data Upload SuccessFully.' ) {
                  //   this.show_loadingModal(false);
                   
                  //   alert('Data Upload SuccessFully.');

                  //   window.location.reload();
                  // }
                  // else{
                  //   this.show_loadingModal(false);
                  //   alert(data);
                    
                  // }


                });
              this.ngxService.stop();
             
            }
          }
        }
        fileReader.readAsArrayBuffer(this.upload_fileName);

        this.loading = '';

      }

    }
  }
  //#endregion

  //#region set data progress status
  progress_Value = '';
    set_data_upload_progress(DataLength){
      this.progress_Value = '';
      if (Number(DataLength )>0)
      {
        let step = 0;
        step = DataLength / 100;
        for (let k = 0; k < DataLength; k++) {
          this.progress_Value = 'Upload Value : ' + k.toString();
        }
      }
      
    }
  //#endregion

//#region  upload est excel file
  Dt_RetVal :any = [];
  upload_est_Excel(){
    this.uploadExcel_Data = [];
    this.Dt_RetVal = [];
    this.exlData_List = [];
    if (Number(this.filter_toliID) == 0){
      alert('Select toli.')
      return false;
    }
    else if (Number(this.filter_estID) == 0) {
      alert('Select establishment.')
      return false;
    }
    else if (Number(this.filterYr) == 0 || this.invalidYear != '') {
      alert('Enter valid wage year.')
      return false;
    }
    else if (Number(this.filterMonth) == 0 || this.invalidMonth != '') {
      alert('Enter valid wage month.')
      return false;
    }
    else if (this.upload_fileName == null) {
      alert('Select file.')
      return false;
    }

    else{
      if (confirm('Are you sure to upload file.?')){
        this.show_loadingModal(true);

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" , cellDates: true});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.uploadExcel_Data = XLSX.utils.sheet_to_json(worksheet, { 
           // raw: true
          
           // raw: false,
            // header: worksheet ? 0 : 1,
            // dateNF: "dd/mm/yyyy"
          })

          //console.log('this.uploadExcel_Data',this.uploadExcel_Data);

          if (Number(this.uploadExcel_Data.length) > 0) {
            for (let k = 0; k < this.uploadExcel_Data.length; k++) {

              let date: any;
              date = new Date().toISOString().substring(0, 10);
              //date = new Date(this.uploadExcel_Data[k]['Date']);
              //date.setDate(date.getDate() + 1);
              let payDate = new Date(date).toISOString().substring(0, 10);

              this.exlData_List.push({
                UserID: this.Log_UserID,
                TransDt: payDate, //this.uploadExcel_Data[k]['Date'],
                UPINo: this.uploadExcel_Data[k]['UTRNo'],
                Amt: this.uploadExcel_Data[k]['Amount'],
              }),console.error(this.show_loadingModal(false));

          }
          //  console.log('Exl',this.uploadExcel_Data);
          //  console.log('estExl',this.exlData_List);
         
            if (Number(this.exlData_List.length) > 0) {
              this.show_loadingModal(true);

              this.loading = 'y';
    
              let url = 'api/' + this._userService.get_upload_excel_controlerName(this.Log_BoardID) + '/uploadestexcel/' + this.filter_toliID + '/' + this.filter_estID + '/' + this.filterYr + '/' + this.filterMonth;
              let body = new FormData();

              let exlDt: any = {
                "BankUploadExcel": this.exlData_List,
              }

              body = exlDt;

              this._userService.post_data(url, body).subscribe(
                (data) => {
                  this.upload_fileName == null;
                  this.show_loadingModal(false);
                  console.log(data);
                  
                  this.Dt_RetVal = data;
                  this.loading = '';
                  // if (data == 'Data Upload SuccessFully.' ) {
                  //   this.show_loadingModal(false);
                   
                  //   alert('Data Upload SuccessFully.');

                  //   window.location.reload();
                  // }
                  // else{
                  //   this.show_loadingModal(false);
                  //   alert(data);
                    
                  // }
                },
                (err: HttpErrorResponse) => {
                  this.show_loadingModal(false);
                  this.loading = '';
                  this.ngxService.stop();
                  //alert(err);
                  return;
                });

              this.ngxService.stop();
             
            }
          }
        }
        fileReader.readAsArrayBuffer(this.upload_fileName);

        this.loading = '';

      }

    }
  }
//#endregion

//#region del_upload_est_Data
del_upload_est_Data(){
  if (Number(this.filter_toliID) == 0){
    alert('Select toli.')
    return false;
  }
  else if (Number(this.filter_estID) == 0) {
    alert('Select establishment.')
    return false;
  }
  else if (Number(this.filterYr) == 0 || this.invalidYear != '') {
    alert('Enter valid wage year.')
    return false;
  }
  else if (Number(this.filterMonth) == 0 || this.invalidMonth != '') {
    alert('Enter valid wage month.')
    return false;
  }
  else{
    if (confirm('Are you sure to delete.?')){
      this.loading = 'y';
    
      let url = 'api/' + this._userService.get_upload_excel_controlerName(this.Log_BoardID) + '/deluploadestexcel/' + this.filter_toliID + '/' + this.filter_estID + '/' + this.filterYr + '/' + this.filterMonth;
      this._userService.Delete_Enrty_ByID(url).subscribe(
        (data) => {
          if (data == 'Data Deleted SuccessFully.' ) {
            this.show_loadingModal(false);
           
            alert('Data Deleted SuccessFully.');

            window.location.reload();
          }
          else{
            this.show_loadingModal(false);
            alert(data);
            
          }
        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.loading = '';
          this.ngxService.stop();
          //alert(err);
          return;
        });

      this.ngxService.stop();
    }
  }
}
//#endregion

//#region upload_labPay_Excel
lab_Pay_Exl_RetVal:any= [];
filter_lab_Pay_Exl_RetVal:any=[];
upload_labPay_Excel(){
  this.uploadExcel_Data = [];
  this.exlData_List = [];
  this.lab_Pay_Exl_RetVal = [];
  this.filter_lab_Pay_Exl_RetVal = [];
  let isSave:boolean = true;

  let mon, yr;
  mon = new Date(this.payDate).getMonth() + 1;
  yr = new Date(this.payDate).getFullYear();

  if (mon < 4) {
    this.fayear = yr - 1;
  }
  else {
    this.fayear = yr;
  }
 
  let dd, mm, yy;
  dd = new Date(this.payDate).getDate();
  mm = new Date(this.payDate).getMonth() + 1;
  yy = new Date(this.payDate).getFullYear();

  let wgYrMonth_Date = new Date(Number(this.filterYr) + '-' + Number(this.filterMonth) + '-' + '01')
 
  let today_Date = new Date().toISOString().substring(0, 10);
           
  if (this.payDate < today_Date) {
    alert("Check payment date is smaller than today.")
    isSave = false;
    return;      
   }
 else if (this.fayear != this.filterFayear) {
    alert('Enter date selected finacial year.')
    isSave = false;
    return;
  }
 else if (new Date(this.payDate) < new Date(wgYrMonth_Date)) {
    alert('Enter valid date.Date below wage year month not allowed.')
    isSave = false;
    return;
  }
 else if (Number(this.filter_toliID) == 0){
    alert('Select toli.')
    return false;
  }
  else if (Number(this.filterYr) == 0 || this.invalidYear != '') {
    alert('Enter valid wage year.')
    return false;
  }
  else if (Number(this.filterMonth) == 0 || this.invalidMonth != '') {
    alert('Enter valid wage month.')
    return false;
  }
  else if (this.upload_fileName == null) {
    alert('Select file.')
    return false;
  }
  else{

    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/chkiwaypostdone/' + yr + '/' + mon
      this._userService.get_Data(url).subscribe((data) => {
     if (data != '') {
       this.ngxService.stop();
       alert(data);
       return false;
     }
     else{

      if (confirm('Are you sure to upload file.?')){
        this.show_loadingModal(true);
  
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" , cellDates: true});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.uploadExcel_Data = XLSX.utils.sheet_to_json(worksheet, { 
           // raw: true
          
           // raw: false,
            // header: worksheet ? 0 : 1,
            // dateNF: "dd/mm/yyyy"
          }),console.error(this.show_loadingModal(false));
  
          if (Number(this.uploadExcel_Data.length) > 0) {
            

            for (let k = 0; k < this.uploadExcel_Data.length; k++) {
                this.exlData_List.push({
                UserID: this.Log_UserID,
                PayDt: this.payDate, 
                RegNo: this.uploadExcel_Data[k]['RegNo'],
                Name: this.uploadExcel_Data[k]['Name'],
                TotDays: this.uploadExcel_Data[k]['TotDays'],
                GrossAmt: this.uploadExcel_Data[k]['GrossAmt'],
                Advance: this.uploadExcel_Data[k]['Advance'],
                WageYear: this.uploadExcel_Data[k]['WageYear'],
                WageMonth: this.uploadExcel_Data[k]['WageMonth'],
                Fayear : this.filterFayear
              });
  
          }
          //  console.log('Exl',this.uploadExcel_Data);
          //  console.log('estExl',this.exlData_List);
         
            if (Number(this.exlData_List.length) > 0) {

              this.show_loadingModal(true);
  
              this.loading = 'y';
    
              let url = 'api/' + this._userService.get_upload_excel_controlerName(this.Log_BoardID) + '/uploadlabpayexcel/' + this.filter_toliID + '/' + + this.filterYr + '/' + this.filterMonth;
              let body = new FormData();
  
              let exlDt: any = {
                "LabPayUploadExcel": this.exlData_List,
              }
  
              body = exlDt;
  
              this._userService.post_data(url, body).subscribe(
                (data) => {

                  this.show_loadingModal(false);
                  this.loading = '';

                  this.lab_Pay_Exl_RetVal  = data;
                  this.filter_lab_Pay_Exl_RetVal = this.lab_Pay_Exl_RetVal.filter(x => (x.retVal).toString() == '');
                  
                },
                (err: HttpErrorResponse) => {
                  this.show_loadingModal(false);
                  this.loading = '';
                  this.ngxService.stop();
                  //alert(err);
                  return;
                });
  
              this.ngxService.stop();
             
            }
          }
        }
        fileReader.readAsArrayBuffer(this.upload_fileName);
  
        this.loading = '';
  
      }


     }
    });

   

  }
}
//#endregion

//#region go_lab_Pay
go_to_labpay(payDt, toliID, print, wageyr, wagemon, payMode) {

  this.uploadExcel_Data = [];
  this.exlData_List = [];
  this.lab_Pay_Exl_RetVal = [];
  let isSave: boolean = true;

  let mon, yr;
  mon = new Date(this.payDate).getMonth() + 1;
  yr = new Date(this.payDate).getFullYear();

  if (mon < 4) {
    this.fayear = yr - 1;
  }
  else {
    this.fayear = yr;
  }

  let dd, mm, yy;
  dd = new Date(this.payDate).getDate();
  mm = new Date(this.payDate).getMonth() + 1;
  yy = new Date(this.payDate).getFullYear();

  let wgYrMonth_Date = new Date(Number(this.filterYr) + '-' + Number(this.filterMonth) + '-' + '01')

  let today_Date = new Date().toISOString().substring(0, 10);

  if (this.payDate < today_Date) {
    alert("Check payment date is smaller than today.")
    isSave = false;
    return;
  }
  else if (this.fayear != this.filterFayear) {
    alert('Enter date selected finacial year.')
    isSave = false;
    return;
  }
  else if (new Date(this.payDate) < new Date(wgYrMonth_Date)) {
    alert('Enter valid date.Date below wage year month not allowed.')
    isSave = false;
    return;
  }
  else if (Number(this.filter_toliID) == 0) {
    alert('Select toli.')
    return false;
  }
  else if (Number(this.filterYr) == 0 || this.invalidYear != '') {
    alert('Enter valid wage year.')
    return false;
  }
  else if (Number(this.filterMonth) == 0 || this.invalidMonth != '') {
    alert('Enter valid wage month.')
    return false;
  }
  else {

    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/chkiwaypostdone/' + yr + '/' + mon
    this._userService.get_Data(url).subscribe((data) => {
      if (data != '') {
        this.ngxService.stop();
        alert(data);
        return false;
      }
      else {

        var date_to_parse = new Date();
        var year = date_to_parse.getFullYear().toString();
        var month = (date_to_parse.getMonth() + 1).toString();
        var day = date_to_parse.getDate().toString();
      
        // alert(month);
      
        if (Number(wageyr) == 0) {
          if (Number(month) == 1) {
            wagemon = 12;
            wageyr = Number(year) - 1;
          }
          else {
            wagemon = Number(month) - 1;
            wageyr = Number(year);
          }
        }
        if (payDt == '') {
          payDt = new Date().toISOString().substring(0, 10);;
        }
        else {
          let date: Date;
          date = new Date(payDt);
          date.setDate(date.getDate());
          payDt = new Date(date).toISOString().substring(0, 10);
        }
      
        //alert(toliID);
        let parameters = {
          payDt: payDt,
          toliID: toliID,
          fayear: this.filterFayear,
          wageyr: wageyr,
          wagemon: wagemon,
          print: print,
          payMode: payMode
        };
      
      
        let actionType: string = 'na';
        actionType = 'add';
      
        let auth_List: any = [];
        let userTypeID: Number = -1;
        if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
          userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
        }
      
        let Log_BoardID: Number = -1;
        if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
          Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
        }
      
        let authFor = "Lab. Payment";
      
        let url = 'getUserAuthDt/' + Log_BoardID
        this._userService.get_Dashborad_Data(url).subscribe((data) => {
      
          // console.log('retData',data);
          auth_List = data;
          console.log('auth_List:', auth_List)
          if (authFor != '') {
            let filterList = [];
            if (actionType.toString().toLocaleLowerCase() == 'add') {
              filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
            }
            else if (actionType.toString().toLocaleLowerCase() == 'edit') {
              filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
            }
            else if (actionType.toString().toLocaleLowerCase() == 'delete') {
              filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
            }
            else {
              alert('You have no authority.')
              return;
            }
      
            if (Number(filterList.length) > 0) {
              //alert(Number(filterList.length));
      
              if (this.Log_BoardID == 6) {
                this.router.navigate(['payment/pimmatha/lab-payment-pimmatha'], { queryParams: parameters });
              }
      
            }
            else {
              alert('You have no authority.')
              return;
            }
      
          }
          else {
            alert('You have no authority.')
            return;
          }
        },
          (err: HttpErrorResponse) => {
            alert('You have no authority.')
            return;
          });

      }

    });

  }
}
//////////////



//#endregion


  //#region loading modal
    showloading_Modal: boolean;
    show_loadingModal(showhide: boolean) {
      this.showloading_Modal = showhide;
    }
//#endregion

//#region  set wageYr/Month
filterYr;
filterMonth;
invalidYear ='yes';
invalidMonth = 'yes';

validateYear(event) {
  this.Dt_RetVal = [];
  this.invalidYear='invalid';
  let chkval = event.target.value;
 // 
   if (Number(chkval) < 1980 || Number(chkval) > 2099){
    this.invalidYear = 'invalid';
  }
  else{
    if (chkval){
      this.invalidYear='';
    }
  }
}
validateMonth(event) {
  this.Dt_RetVal = [];
  this.invalidMonth='invalid';
  let chkval = event.target.value;
 // 
   if (Number(chkval) < 1 || Number(chkval) > 12){
    this.invalidMonth = 'invalid';
  }
  else{
    if (chkval){
      this.invalidMonth='';
    }
  }
}
//#endregion

//#region validate date
invalidDate='';
  vlaidateDate(event) {
    this.invalidDate='invalid';
    let chkDt = new Date(event.target.value);
   // 
     if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
      this.invalidDate = 'invalid';
    }
    else{
      if (chkDt.getFullYear()){
        this.invalidDate='';
      }
      else{

      }
      
    }
  }
  setDate(event) {
    // this.payDate = event.target.value;
  
    let chkDt = new Date(event.target.value);
 //
 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
    }
    else{
     this.payDate = new Date(chkDt).toISOString().substring(0, 10);;
     
    }
  
   }
//#endregion

//#region only number
onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}
onlyDecimalNumberKey(event) {
  let charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
    && (charCode < 48 || charCode > 57))
    return false;
  return true;
}
//#endregion

//#region download sample file
download_file_excelFormat(fltype){
  if (fltype == 'bank'){
    window.open(this.globals.BaseURL + "/FileFormatToDownload/PimMatha Bank UPI File Format.xlsx");
  }
  else if(fltype == 'est'){
    window.open(this.globals.BaseURL + "/FileFormatToDownload/PimMatha Est UPI File Format.xlsx");
  }
  else if (fltype == 'labpay'){
    window.open(this.globals.BaseURL + "/FileFormatToDownload/PimMatha LabPay File Format.xlsx");
  }
}
//#endregion

}
