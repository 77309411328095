// #region imports
import { Component, OnInit, ElementRef, ViewChild,ChangeDetectorRef  } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import "../../../../src/js/jquery-3.4.1.slim.min.js"
import "../../../../src/js/bootstrap.min.js"
import "../../../../src/js/wow.min.js"
import "../../../../src/js/popper.min.js"
import { truncate } from 'fs';
import { borderLeftStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';
import { AlertPromise } from 'selenium-webdriver';
import { makeParsedTranslation } from '@angular/localize/src/utils';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { exit } from 'process';

//import "../../../../src/js/script.js"
//import "../../../../src/js/datepicker.js"

// #endregion

// #region Component
@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-lab-payment-rmatha',
  templateUrl: './lab-payment-rmatha.component.html',
  styleUrls: ['./lab-payment-rmatha.component.css'],
  providers: [NgbAccordionConfig]
})

// #endregion

export class LabPaymentRmathaComponent implements OnInit {

  // #region public variable

  model: NgbDateStruct;
  date: { year: number, month: number };

  filterBoradID;
  filterMonth;
  filterYr;
  toliID;
  filterFayear;
  fayear;
  pf_lockYear = 0;
  rpt_print;
  dasbordfor;
  Log_UserID;
  Log_BoardID;
  userID;
  companyID = 1;
  payDate = new Date().toISOString().substring(0, 10);
  payPaidCnt_disabled = false;
  toliInfo: string = '';
  toliserchCnt = '';

  estInfo: string = '';
  estserchCnt = '';

  marInfo: string = '';
  marserchCnt = '';
  marketID;

  compInfo: string = '';

  payPaid = 'No';


  toli_List = [];
  est_List = [];
  filter_est = [];

  marcom_List = [];
  filter_marcom = [];


  payTypeList = [];
  setpayType: string = "Manual";

  set_active_Tabpay = "tab-pane fade show active";
  set_active_navTabpay = "nav-link active";
  // #endregion

  // #region constructor
  constructor(
    private calendar: NgbCalendar,
    config: NgbAccordionConfig,
    public http: Http,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) { }

  // #endregion
  
// #region ngAfterViewChecked
  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }
// #endregion
  // #region select functions 
  selectToday() {
    this.model = this.calendar.getToday();
  }
  set_selPayType(event) {
    this.setpayType = event.target.value;
    this.blank_grid();
  }
  set_selPayMode(event) {
    this.pay_mode = event.target.value;
    if (this.setpayType == 'Calculate') {
      this.cal_Matha();
    }
  }
  // #endregion

  // #region ngOnInit
  ngOnInit(): void {

    this.payTypeList = [{
      typeVal: 'Manual',
      paytype: 'Manual'
    },
    {
      typeVal: 'Calculate',
      paytype: 'Calculate'
    }
    ]

    this.frm_psw = this.formBuilder.group({
      txtpsw: ['', Validators.required],

    });

    this.toliID = Number(this.route.snapshot.queryParamMap.get('toliID'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.rpt_print = this.route.snapshot.queryParamMap.get('print').toString();


    // config.closeOthers = true;
    // config.type = 'info';

    this.filterBoradID = 0;
    this.filterMonth = 0;
    this.filterYr = 0;
    //this.filterFayear = 0;
    this.dasbordfor = 'Labour';

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    this.userID = this.Log_UserID;

    this.fill_AddMstData(this.toliID)

    this.filterMonth = Number(this.route.snapshot.queryParamMap.get('wagemon'));
    this.filterYr = Number(this.route.snapshot.queryParamMap.get('wageyr'));
    this.payDate = new Date(this.route.snapshot.queryParamMap.get('payDt')).toISOString().substring(0, 10);
    this.setpayType = this.route.snapshot.queryParamMap.get('payMode');
    
  }
  // #endregion  

  // #region clear data
  clear_Data() {
    this.showBtnDisabled = true;
    this.mode = '';
    this.estID = 0;
    this.filter_toli = [];
    this.toliInfo = '';
    this.estInfo = '';
    this.filter_est = [];
    this.filter_marcom = [];
    this.marInfo = 'Na';
    this.compInfo = '';
    this.marketID = 1;
    this.companyID = 1;
    this.blank_grid();
  }

  blank_grid() {
    this.selected_rindex = -1;
    this.insup_wgMst = [];
    this.recPaidDt = [];
    this.bind_wgMst = [];
    this.new_wgMst = [];

    this.rec_HW = 0; this.rec_HL = 0; this.paid_HW = 0; this.paid_HL = 0; this.rec_estAdv = 0; this.paid_estAdv = 0
    this.diff_HW = 0; this.difff_HL = 0; this.diff_tot = 0; this.diff_estAdv = 0;
    this.tot_rec_WL = 0; this.tot_paid_WL = 0; this.tot_diff_WL = 0;

    this.labwise_totPrsDay = 0;
    this.d1 = 0; this.d2 = 0; this.d3 = 0; this.d4 = 0; this.d5 = 0; this.d6 = 0; this.d7 = 0; this.d8 = 0; this.d9 = 0;
    this.d10 = 0; this.d11 = 0; this.d12 = 0; this.d13 = 0; this.d14 = 0; this.d15 = 0; this.d16 = 0; this.d17 = 0;
    this.d18 = 0; this.d19 = 0; this.d20 = 0; this.d21 = 0; this.d22 = 0; this.d23 = 0; this.d24 = 0; this.d25 = 0;
    this.d26 = 0; this.d27 = 0; this.d28 = 0; this.d29 = 0; this.d30 = 0; this.d31 = 0;
    this.tot_d = 0;
  }
  // #endregion

  // #region fill_AddMstData
  levyParaMst = [];
  fill_AddMstData(set_ToliID) {
    this.toliID = set_ToliID;
    //alert(set_ToliID);
    this.set_wageYr_Month();
    this.blank_grid();
    this.Without_Levy = 'No';
    this.estID = 0;

    this.toli_List = [];
    this.levyParaMst = [];
    this.filter_toli = [];
    this.toliInfo = '';
    this.estInfo = '';

    this.est_List = [];
    this.filter_est = [];

    this.marcom_List = [];
    this.filter_marcom = [];
    this.marInfo = 'Na';
    this.compInfo = '';
    this.marketID = 1;
    this.companyID = 1;

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/gettolilist/' + this.filterFayear;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.toli_List = data.labPayToliDt;
        this.levyParaMst = data.levyParaMst;

        if (Number(set_ToliID) > 0) {
          this.filter_toli = this.toli_List.filter(x => x.toliId == Number(set_ToliID) && x.wageyr == this.filterYr && x.wageMon == this.filterMonth);
          
          if (this.filter_toli.length > 0) {
            this.toliInfo = this.filter_toli[0]['toliNo'].toString() + ' ' + this.filter_toli[0]['mukName'];
            this.toliserchCnt = '';

            if (this.filter_toli[0]['levy'].toString().toLowerCase() == 'yes') {
              this.Without_Levy = 'No';
            }
            else {
              this.Without_Levy = 'Yes';
            }
            
            this.set_searchToli(set_ToliID,this.filter_toli[0]['toliNo'],this.filter_toli[0]['mukName'],this.filter_toli[0]['levy'],'no');


          }
          else {
            this.toliserchCnt = 'yes';
          }
        }

        this.set_LevyCode(0);

      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

  }
  LevyCode = 0;
  LabPFPer = 0;
  BrdPFPer = 0;
  A_BrdPFPer = 0;
  GrtPer = 0;
  A_GrtPer = 0;
  Levy_Per = 0;
  Without_Levy: string = 'No';
  set_LevyCode(levycode) {
    this.LevyCode = 0;
    this.LabPFPer = 0;
    this.BrdPFPer = 0;
    this.A_BrdPFPer = 0;
    this.GrtPer = 0;
    this.A_GrtPer = 0;
    this.Levy_Per = 0;

    let filter_lcode = []; this.levyParaMst.filter(x => x.levyCode == levycode);
    if (Number(levycode) > 0) {
      filter_lcode = this.levyParaMst.filter(x => x.levyCode == levycode);
    }
    else {
      filter_lcode = this.levyParaMst.filter(x => x.defualt.toString().toLowerCase() == 'true');
    }

    if (Number(filter_lcode.length) > 0) {
      this.LevyCode = Number(filter_lcode[0]['levyCode']);

      //Set para Per%
      for (let l = 0; l < filter_lcode.length; l++) {
        this.Levy_Per = Number(this.Levy_Per) + Number(filter_lcode[l]['lParaPer']);
        if (Number(filter_lcode[l]['lParaID']) == 1) {
          this.LabPFPer = Number(filter_lcode[l]['lParaPer']);
          this.BrdPFPer = Number(filter_lcode[l]['lParaPer']);
          this.A_BrdPFPer = Number(filter_lcode[l]['anamatPer']);
        }
        else if (Number(filter_lcode[l]['lParaID']) == 3) {
          this.GrtPer = Number(filter_lcode[l]['lParaPer']);
          this.A_GrtPer = Number(filter_lcode[l]['anamatPer']);

        }

      }
    }

  }

  setDate(event) {
    this.payDate = event.target.value;
    this.payPaidCnt_disabled = false;
    this.blank_grid();
  }
  set_wageYr_Month() {
    var date_to_parse = new Date(this.payDate);
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();

    if (Number(month) == 1) {
      this.filterMonth = 12;
      this.filterYr = Number(year) - 1;
    }
    else {
      this.filterMonth = Number(month) - 1;
      this.filterYr = Number(year);
    }
  }
  // #endregion

  // #region search bind data toli/est/market  
  searchToli;
  filter_toli;
  bind_toli_est_mar(toliID, estID, marketID) {
    this.toliID = 0;
    this.toliInfo = '';

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.searchToli = '';
    this.toliID = 0;
    this.toliInfo = '';
    this.toliserchCnt = '';


    let filter_tl = this.toli_List.filter(x => x.toliId == toliID);
    if (filter_tl.length > 0) {
      this.toliID = filter_tl[0]['toliId'];
      this.toliInfo = filter_tl[0]['mukName'];
    }

    let filter_est = this.est_List.filter(x => x.estID == estID);

    if (filter_est.length > 0) {
      this.estID = filter_est[0]['estID'];
      this.estInfo = filter_est[0]['filterval'];
      this.estserchCnt = '';
    }

    let filter_marcom = this.marcom_List.filter(x => x.marketID == marketID);
    if (filter_marcom.length > 0) {
      this.marketID = filter_marcom[0]['marketID'];
      this.marInfo = filter_marcom[0]['filterval'];
      this.marserchCnt = '';
      this.compInfo = filter_marcom[0]['companyname'];
      this.companyID = filter_marcom[0]['companyID'];
    }

  }
  showBtnDisabled = true;
  set_searchToli(toliID, toliNo, toliName, levy,biandpay) {
    this.toliID = 0;
    this.toliInfo = '';

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 1;
    this.marInfo = 'Na';

    this.searchToli = '';
    this.toliID = toliID;
    this.toliInfo = toliNo.toString() + ' ' + toliName;
    this.toliserchCnt = '';

    if (levy.toString().toLowerCase() == 'yes') {
      this.Without_Levy = 'No';
    }
    else {
      this.Without_Levy = 'Yes';
    }

    this.filter_toli = [];
    if (Number(toliID) > 0) {
      if (biandpay == 'yes'){
        this.bind_payDt();
        
      }
      this.showBtnDisabled = false;

    }

  }
  show_serchtoli_Cnt() {
    this.toliserchCnt = 'yes';
    this.filter_toli = this.toli_List;
  }
  serch_toli() {
    this.blank_grid();
    this.showBtnDisabled = true;
    this.filter_est = [];
    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.toliID = 0;
    this.toliInfo = '';

    this.filter_toli = this.toli_List.filter((item) => {
      return ((item.toliNo.toString() + item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1 && item.wageyr == this.filterYr && item.wageMon == this.filterMonth);
    });
  }

  searchEst = '';
  serch_est(toliID) {
    this.filter_marcom = [];

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    if (Number(toliID) > 0) {

      this.filter_est = this.est_List.filter(x => x.toliId == toliID);
      
      if (this.filter_est.length > 0) {
        if (this.filter_est.length == 1) {
          this.estID = this.filter_est[0]['estID'];
          this.estInfo = this.filter_est[0]['filterval'];
          this.estserchCnt = '';

          this.serch_mar(this.estID);
        }
        else {
          this.estserchCnt = 'yes';
        }

      }
      else {
        this.estserchCnt = '';
      }

    }
    else {
      this.filter_est = this.est_List.filter(x => x.toliId == this.toliID);

      this.filter_est = this.filter_est.filter((item) => {
        return ((item.filterval.toString()).indexOf(this.searchEst.toString().toLowerCase()) > -1);
      });
    }

  }
  estID;
  set_searchEst_Cnt(estID, estNo, estName) {
    this.filter_marcom = [];

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.searchEst = '';
    this.estID = estID;
    this.estInfo = estName;
    this.estserchCnt = '';

    this.serch_mar(this.estID);
  }
  show_serchest_Cnt() {

    if (this.toliID > 0) {
      //this.filter_est = this.est_List;
      if (this.filter_est.length > 1) {
        this.estserchCnt = 'yes';
      }

    }
    else {
      alert("Select Toli");
    }

  }

  /////filter market
  searchMar = '';
  serch_mar(estID) {

    if (Number(estID) > 0) {

      this.filter_marcom = this.marcom_List.filter(x => x.estID == estID);
      
      if (this.filter_marcom.length > 0) {
        if (this.filter_marcom.length == 1) {
          this.marketID = this.filter_marcom[0]['marketID'];
          this.marInfo = this.filter_marcom[0]['filterval'];
          this.marserchCnt = '';
          this.compInfo = this.filter_marcom[0]['companyname'];
          this.companyID = this.filter_marcom[0]['companyID'];
        }
        else {
          this.marserchCnt = 'yes';
        }

      }
      else {
        this.marserchCnt = '';
      }

    }
    else {

      this.filter_marcom = this.marcom_List.filter(x => x.estID == this.estID);

      this.filter_marcom = this.filter_marcom.filter((item) => {
        return ((item.filterval.toString()).indexOf(this.searchMar.toString().toLowerCase()) > -1);
      });

    }

  }

  set_searchMar_Cnt(marketID, marNo, marName, compID, CompName) {
    this.marketID = marketID;
    this.marInfo = marName;
    this.marserchCnt = '';
    this.compInfo = CompName;
    this.companyID = compID;
  }
  show_serchmar_Cnt() {

    if (this.estID > 0) {

      if (this.filter_marcom.length > 1) {
        this.marserchCnt = 'yes';
      }

    }
    else {
      alert("Select Establishment");
    }

  }
  // #endregion

  // #region keyPress event only numbers
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // #endregion

  // #region show data 
  public insup_wgMst: any = [];
  recPaidDt: any = [];
  bind_wgMst: any = [];
  new_wgMst: any = [];

  pfLoan_mst: any[];
  advLoan_mst: any[];
  rcd_labList: any[];
  patpediDed_List: any[];
  insDed_List: any[];
  unionDed_List: any[];


  AuthID: number = 0;
  TransBankListID: number = 0;
  isBankList_Auth = '';
  rec_HW: number = 0;
  rec_HL: number = 0;
  paid_HW: number = 0;
  paid_HL: number = 0;
  rec_estAdv: number = 0;
  paid_estAdv: number = 0;
  diff_HW: number = 0;
  difff_HL: number = 0;
  diff_tot: number = 0;
  diff_estAdv: number = 0;
  tot_rec_WL: number = 0;
  tot_paid_WL: number = 0;
  tot_diff_WL: number = 0;
  mode = '';
  is_year_Lock = 'yes';
  bind_matha_WL: any = [];
  isDisabled = false;
  str_payinfo:string="";
  
   show_data(finalize_enb: boolean) {
    this.set_active_Tabpay = "tab-pane fade show active";
    this.set_active_navTabpay = "nav-link active";
    this.str_payinfo="";
    this.mode = '';
    this.insup_wgMst = [];

    this.recPaidDt = [];
    this.bind_wgMst = [];
    this.new_wgMst = [];
    this.bind_matha_WL = [];

    this.pfLoan_mst = [];
    this.advLoan_mst = [];
    this.rcd_labList = [];

    this.rec_HW = 0;
    this.rec_HL = 0;
    this.paid_HW = 0;
    this.paid_HL = 0;
    this.rec_estAdv = 0;
    this.paid_estAdv = 0
    this.diff_HW = 0;
    this.difff_HL = 0;
    this.diff_tot = 0;
    this.diff_estAdv = 0;
    this.tot_rec_WL = 0;
    this.tot_paid_WL = 0;
    this.tot_diff_WL = 0;
    this.mode = ""

    this.isDisabled = false;

    let isvlaid: boolean = true;

    if (Number(this.filterYr.toString().length) != 4) {
      alert('Enter valid wage year');
      isvlaid = false;
      return;
    }

    if (Number(this.filterYr) <= 0) {
      alert('Enter valid wage year');
      isvlaid = false;
      return;
    }

    if (Number(this.filterMonth) > 0 && Number(this.filterMonth) < 13) {
    }
    else {
      alert('Enter valid wage month');
      isvlaid = false;
      return;
    }

    let mon, yr;
    mon = new Date(this.payDate).getMonth() + 1;
    yr = new Date(this.payDate).getFullYear();

    if (mon < 4) {
      this.fayear = yr - 1;
    }
    else {
      this.fayear = yr;
    }

    if (this.fayear != this.filterFayear) {
      alert('Enter date selected finacial year.')
      isvlaid = false;
      return;
    }

    let dd, mm, yy;
    dd = new Date(this.payDate).getDate();
    mm = new Date(this.payDate).getMonth() + 1;
    yy = new Date(this.payDate).getFullYear();

    let strPayDt = this.payDate;
    this.set_LevyCode(0);
    if (Number(this.LevyCode) > 0) {

    }
    else {
      alert('LevyCode not get.')
      isvlaid = false;
      return;
    }
    this.is_year_Lock = 'no';
    this.ngxService.start();
    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getpflockyear';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.pf_lockYear = data;
        if (Number(this.pf_lockYear) > 0) {
          if (Number(this.pf_lockYear) >= Number(this.fayear)) {
            this.is_year_Lock = 'yes';
            if (window.confirm(this.fayear + " This year is locked.Still do you want to show payment.?")) {

            }
            else {
              return;
            }
          }
        }

        let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getpaidDt/' + this.toliID + '/' + this.filterYr + '/' + this.filterMonth + '/' + this.filterFayear
          this._userService.get_Data(url).subscribe((data) => {
            this.ngxService.stop();
            if (Number(data.length) > 0) {
              if (this.setpayType != data[0]["flag"]) {
                isvlaid = false;
                alert('Check Paytype payment done with ' + data[0]["flag"] + ' mode.');
                return false;
              }
              else{
                this.bind_data(finalize_enb);
              }

              
            }
            else{
              this.bind_data(finalize_enb);
            }
            
          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            alert(err);
            return;
          });

          
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

    

  }
 bind_data(finalize_enb: boolean){
    let filter: any = {
      "payDt": this.payDate,
      "payType": this.setpayType,
      "Wageyr": this.filterYr,
      "WageMon": this.filterMonth,
      "ToliId": this.toliID,
      "Fayear": this.filterFayear,
      "CompanyID": 1,
    }

    

    let body = new FormData();
    body = filter;

    this.ngxService.start();

   let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getlabpaybind';
   
    this._userService.post_data(url, body).subscribe(
      (data) => {
        
        this.bind_wgMst = data.bindWagemaster;
        //this.bind_matha_WL = data.labPayBindWgeLevy;

        if (Number(this.bind_wgMst.length > 0)) {
          this.insup_wgMst = this.bind_wgMst;

          if (this.setpayType == 'Calculate') {
            this.matha_Mst = this.fill_Matha();

            let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/LabPayBindWgeLevy/' + this.insup_wgMst[0]['mathID'];
            this._userService.get_Data(url).subscribe(
              (data) => {

                this.bind_matha_WL = data;
                for (let l = 0; l < this.matha_Mst.length; l++) {
                  if (Number(this.matha_Mst[l]['day']) == 1) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage1']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy1']; }
                  else if (Number(this.matha_Mst[l]['day']) == 2) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage2']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy2']; }
                  else if (Number(this.matha_Mst[l]['day']) == 3) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage3']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy3']; }
                  else if (Number(this.matha_Mst[l]['day']) == 4) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage4']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy4']; }
                  else if (Number(this.matha_Mst[l]['day']) == 5) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage5']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy5']; }
                  else if (Number(this.matha_Mst[l]['day']) == 6) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage6']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy6']; }
                  else if (Number(this.matha_Mst[l]['day']) == 7) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage7']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy7']; }
                  else if (Number(this.matha_Mst[l]['day']) == 8) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage8']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy8']; }
                  else if (Number(this.matha_Mst[l]['day']) == 9) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage9']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy9']; }
                  else if (Number(this.matha_Mst[l]['day']) == 10) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage10']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy10']; }
                  else if (Number(this.matha_Mst[l]['day']) == 11) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage11']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy11']; }
                  else if (Number(this.matha_Mst[l]['day']) == 12) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage12']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy12']; }
                  else if (Number(this.matha_Mst[l]['day']) == 13) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage13']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy13']; }
                  else if (Number(this.matha_Mst[l]['day']) == 14) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage14']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy14']; }
                  else if (Number(this.matha_Mst[l]['day']) == 15) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage15']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy15']; }
                  else if (Number(this.matha_Mst[l]['day']) == 16) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage16']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy16']; }
                  else if (Number(this.matha_Mst[l]['day']) == 17) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage17']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy17']; }
                  else if (Number(this.matha_Mst[l]['day']) == 18) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage18']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy18']; }
                  else if (Number(this.matha_Mst[l]['day']) == 19) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage19']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy19']; }
                  else if (Number(this.matha_Mst[l]['day']) == 20) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage20']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy20']; }
                  else if (Number(this.matha_Mst[l]['day']) == 21) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage21']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy21']; }
                  else if (Number(this.matha_Mst[l]['day']) == 22) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage22']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy22']; }
                  else if (Number(this.matha_Mst[l]['day']) == 23) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage23']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy23']; }
                  else if (Number(this.matha_Mst[l]['day']) == 24) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage24']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy24']; }
                  else if (Number(this.matha_Mst[l]['day']) == 25) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage25']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy25']; }
                  else if (Number(this.matha_Mst[l]['day']) == 26) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage26']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy26']; }
                  else if (Number(this.matha_Mst[l]['day']) == 27) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage27']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy27']; }
                  else if (Number(this.matha_Mst[l]['day']) == 28) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage28']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy28']; }
                  else if (Number(this.matha_Mst[l]['day']) == 29) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage29']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy29']; }
                  else if (Number(this.matha_Mst[l]['day']) == 30) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage30']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy30']; }
                  else if (Number(this.matha_Mst[l]['day']) == 31) { this.matha_Mst[l]['wages'] = this.bind_matha_WL[0]['wage31']; this.matha_Mst[l]['levy'] = this.bind_matha_WL[0]['levy31']; }

                }
                
                this.cal_Presenty(false);
              })
          }


          this.set_LevyCode(this.insup_wgMst[0]['levyCode']);

          let chk_wageID = this.bind_wgMst.filter(x => x.wageID > 0);
          if (Number(chk_wageID.length) > 0) {
            let chkTranBank = this.bind_wgMst.filter(x => Number(x.transBankListID) > 0 || Number(x.authID) > 0);
            if (Number(chkTranBank.length) > 0) {
              this.isBankList_Auth = 'yes';
              if (Number(chkTranBank[0]['transBankListID']) > 0){
                this.str_payinfo="Note :- This Payment Paid Banklist Done.";
              }
              else{
                this.str_payinfo="Note :- This Payment Authorized.";
              }
              this.isDisabled = true;
              this.mode = "";
            }
            else {
              this.isBankList_Auth = 'no';
              if (finalize_enb == false) {
                if (window.confirm('Do you want to edit this payment.?')) {
                  this.mode = "Modify"
                  this.fill_AutoDed('no');
                }

                else {
                  //this.mode = "Delete";
                  this.mode = 'Finalize';
                  this.isDisabled = true;
                }
              }
              else {
                //alert(this.mode);
                this.mode = 'Finalize';
                this.isDisabled = true;

              }



            }



          }
          else {
            this.set_LevyCode(0);
            this.mode = "Add"
            let today_Date = new Date().toISOString().substring(0, 10);
            if (this.payDate < today_Date) {
              alert("Check pay date is smaller than today.")
              this.mode = ""
              this.blank_grid();
            }
            else {
              this.fill_AutoDed('yes');
            }

          }

        }
        else {
          this.mode = ""
          alert('No record found.')
          
        }

        if (this.is_year_Lock == 'yes') {
          this.mode = ""
        }

        

        let filterDate: any = {
          "payDt": this.payDate,
          "labType": "Hamal",
          "Mode": this.mode,
          "MarketID": 1,
          "Wageyr": this.filterYr,
          "WageMon": this.filterMonth,
          "ToliId": this.toliID
        }

        body = new FormData();
        body = filterDate;

        url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getlabpaydt';
        this._userService.post_data(url, body).subscribe(
          (data) => {
            this.recPaidDt = data.wlRecPaidDt;
            this.bind_recPay('rec');
          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            //alert(err);
            return;
          });

        this.fill_IT_ApplicableData();

        this.ngxService.stop();
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

  
 }
  // #endregion

  // #region fill auto deductions
  fill_AutoDed(newData) {
    this.pfLoan_mst = [];
    this.advLoan_mst = [];
    this.rcd_labList = [];
    this.patpediDed_List = [];
    this.insDed_List = [];
    this.unionDed_List = [];

    let filter: any = {
      "payDt": this.payDate,
      "payType": this.setpayType,
      "Wageyr": this.filterYr,
      "WageMon": this.filterMonth,
      "ToliId": this.toliID,
      "Fayear": this.filterFayear,
      "CompanyID": this.Log_BoardID,
    }

    let body = new FormData();
    body = filter;
    //PFLoan

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getautodedlist/' + newData + '/0';
    this._userService.post_data(url, body).subscribe(
      (data) => {
        this.pfLoan_mst = data.labPayPFLoanDedList;
        this.advLoan_mst = data.labPayAdvLoanDedList;
        this.rcd_labList = data.labPayRCDLabList;
        this.patpediDed_List = data.labPayPatPediDedList;
        this.insDed_List = data.labPayInsuranceDedList;
        this.unionDed_List = data.labPayUnionDedList;

        
        
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

    ///

    //PFLoan

    // url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getautodedlist/' + newData;
    //  this._userService.post_data(url, body).subscribe(
    //   (data) => {
    //     this.pfEdu_mst = data;
    
    // });

    ///
  }
  // #endregion

  // #region bind_payDt
  paidDate_list: any = [];
  bind_payDt() {
    this.ngxService.start()
    this.paidDate_list = [];
    this.payPaidCnt_disabled = false;
    this.payDate = new Date().toISOString().substring(0, 10);
    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getpaidDt/' + this.toliID + '/' + this.filterYr + '/' + this.filterMonth + '/' + this.filterFayear
    this._userService.get_Data(url).subscribe((data) => {
      
      this.paidDate_list = data;
      if (Number(this.paidDate_list.length) > 0) {
        let date: Date;
        date = new Date(this.paidDate_list[0]["payDt"]);
        date.setDate(date.getDate() + 1);
        this.payDate = new Date(date).toISOString().substring(0, 10);
        this.setpayType = this.paidDate_list[0]["flag"];
        this.payPaidCnt_disabled = true;

      }

      this.ngxService.stop();
    },
    (err: HttpErrorResponse) => {
      this.ngxService.stop();
      //alert(err);
      return;
    });

  }
  // #endregion

  // #region receipt details
  set_recpaid_checkStatus(i) {

    if (this.recPaidDt[i]['chkRec'] == true) {
      this.recPaidDt[i]['chkRec'] = false;
    }
    else {
      this.recPaidDt[i]['chkRec'] = true;
    }
    this.bind_recPay('rec');

  }
  bind_recPay(flag) {

    if (flag == 'rec') {
      this.rec_HW = 0;
      this.rec_HL = 0;
      this.rec_estAdv = 0;
      this.tot_rec_WL = 0;
      //  this.tot_paid_WL = 0; 
      for (let l = 0; l < this.recPaidDt.length; l++) {
        
        if (this.recPaidDt[l]['chkRec'] == true) {
          // alert('done')
          this.rec_HW += Number(this.recPaidDt[l]['wRecAmt']) + Number(this.recPaidDt[l]['lessAdvance']);
          this.rec_HL += Number(this.recPaidDt[l]['lRecAmt']);
          this.rec_estAdv += Number(this.recPaidDt[l]['lessAdvance']);

        }
        this.tot_rec_WL = Number(this.rec_HW) + Number(this.rec_HL);

        this.cal_WlDiff();

      }



    }
    else if (flag == 'pay') {
      this.paid_HW = 0;
      this.paid_HL = 0;
      this.paid_estAdv = 0
      this.tot_paid_WL = 0;
      this.paid_HW = Number(this.getTotal(this.insup_wgMst, 'actualGrossAmt'));
      this.paid_HL = Number(this.getTotal(this.insup_wgMst, 'levyAmt'));
      this.paid_estAdv = Number(this.getTotal(this.insup_wgMst, 'advAmt'));
      this.tot_paid_WL = Number(this.paid_HW) + Number(this.paid_HL);
      this.cal_WlDiff();
    }

  }
  // #endregion

  // #region mark present
  selLabName;
  seleLabRegNo;
  presenty_editIndex: Number = 0;
  set_presenty(rIndex: Number) {
    this.presenty_editIndex = rIndex;
    this.selLabName = this.insup_wgMst[Number(this.presenty_editIndex)]['labName'];
    this.seleLabRegNo = this.insup_wgMst[Number(this.presenty_editIndex)]['labRegNo'];
    this.daysInMonth = new Date(this.filterYr, this.filterMonth, 0).getDate();
    this.labwise_totPrsDay = this.insup_wgMst[Number(this.presenty_editIndex)]['totPrsDay'];
  }
  daysInMonth: number;
  mark_presenty() {
    
    this.daysInMonth = new Date(this.filterYr, this.filterMonth, 0).getDate();
    
    if (this.model.day <= this.daysInMonth) {
      if (this.insup_wgMst[Number(this.presenty_editIndex)]['day' + this.model.day + 'Math'] == "P") {
        this.insup_wgMst[Number(this.presenty_editIndex)]['day' + this.model.day + 'Math'] = "A";
      }
      else {
        this.insup_wgMst[Number(this.presenty_editIndex)]['day' + this.model.day + 'Math'] = "P";
      }
    }
    else {
      this.insup_wgMst[Number(this.presenty_editIndex)]['day' + this.model.day + 'Math'] = "A";
    }
    this.cal_Presenty(true);

  }


  fullmonth_prsAbc(prsAbc, selindex) {
    this.daysInMonth = new Date(this.filterYr, this.filterMonth, 0).getDate();
    
    if (Number(selindex) >= 0) {
      if (Number(this.selected_rindex) >= 0) {
        for (let d = 1; d < 32; d++) {
          
          this.insup_wgMst[Number(this.selected_rindex)]['day' + d + 'Math'] = prsAbc;
          if (Number(d) > Number(this.daysInMonth)) {
            this.insup_wgMst[Number(this.selected_rindex)]['day' + d + 'Math'] = "A";
          }

        }
        this.cal_Presenty(true);
      }
      else {
        alert("Select labour.")
      }
    }
    else {

      for (let l = 0; l < this.insup_wgMst.length; l++) {
        for (let d = 1; d < 32; d++) {
          this.insup_wgMst[l]['day' + d + 'Math'] = prsAbc;
          if (d > Number(this.daysInMonth)) {
            this.insup_wgMst[l]['day' + d + 'Math'] = "A";
          }
        }
      }
      this.cal_Presenty(true);
    }


  }
  selected_rindex: number = -1;
  setselrow_Color(i) {
    for (let l = 0; l < this.insup_wgMst.length; l++) {
      this.insup_wgMst[l]['selrowcolor'] = "white";
    }
    this.insup_wgMst[i]['selrowcolor'] = "#dcf9f6";
    this.selected_rindex = i;
  }
  set_holiday(strHoliday, selindex) {
    // let x = "10,12,15,16"
    if (strHoliday == '') {
      alert("Enter valid days.")
      return;
    }
    let holidayVal = strHoliday.split(',')
    

    if (Number(selindex) >= 0) {
      if (Number(this.selected_rindex) >= 0) {
      }
      else {
        alert("Select labour.")
      }
    }


    for (let item of holidayVal) {
      if (true) {
        if (Number(item) > 0 && Number(item) < 32) {
          this.mark_holiday(item, selindex);
        }
      }
    }

    // holidayVal.forEach(function (value) {
    
    //   this.mark_holiday(value,selindex);
    // }); 

  }

  mark_holiday(day, selindex) {
    if (Number(selindex) >= 0) {
      if (Number(this.selected_rindex) >= 0) {
        this.insup_wgMst[Number(this.selected_rindex)]['day' + Number(day) + 'Math'] = "A";
        this.cal_Presenty(true);
      }
    }
    else {

      for (let l = 0; l < this.insup_wgMst.length; l++) {
        this.insup_wgMst[l]['day' + Number(day) + 'Math'] = "A";
      }
      this.cal_Presenty(true);
    }
  }
  // #endregion

  // #region Cal Presenty
  d1: number = 0; d2: number = 0; d3: number = 0; d4: number = 0; d5: number = 0; d6: number = 0; d7: number = 0; d8: number = 0; d9: number = 0;
  d10: number = 0; d11: number = 0; d12: number = 0; d13: number = 0; d14: number = 0; d15: number = 0; d16: number = 0; d17: number = 0;
  d18: number = 0; d19: number = 0; d20: number = 0; d21: number = 0; d22: number = 0; d23: number = 0; d24: number = 0; d25: number = 0;
  d26: number = 0; d27: number = 0; d28: number = 0; d29: number = 0; d30: number = 0; d31: number = 0; tot_d: number = 0;

  h_prsDay: number = 0;
  labwise_totPrsDay: number = 0;
  cal_Presenty(iscal: boolean = true) {
    this.labwise_totPrsDay = 0;
    this.d1 = 0; this.d2 = 0; this.d3 = 0; this.d4 = 0; this.d5 = 0; this.d6 = 0; this.d7 = 0; this.d8 = 0; this.d9 = 0;
    this.d10 = 0; this.d11 = 0; this.d12 = 0; this.d13 = 0; this.d14 = 0; this.d15 = 0; this.d16 = 0; this.d17 = 0;
    this.d18 = 0; this.d19 = 0; this.d20 = 0; this.d21 = 0; this.d22 = 0; this.d23 = 0; this.d24 = 0; this.d25 = 0;
    this.d26 = 0; this.d27 = 0; this.d28 = 0; this.d29 = 0; this.d30 = 0; this.d31 = 0;
    this.tot_d = 0;
    for (let l = 0; l < this.insup_wgMst.length; l++) {
      this.h_prsDay = 0;

      for (let d = 1; d < 32; d++) {
        if (this.insup_wgMst[l]['day' + d + 'Math'] == "P") {
          this.h_prsDay = this.h_prsDay + 1;

          if (d == 1) { this.d1 = this.d1 + 1; }
          else if (d == 2) { this.d2 = this.d2 + 1; } else if (d == 3) { this.d3 = this.d3 + 1; } else if (d == 4) { this.d4 = this.d4 + 1; }
          else if (d == 5) { this.d5 = this.d5 + 1; } else if (d == 6) { this.d6 = this.d6 + 1; } else if (d == 7) { this.d7 = this.d7 + 1; }
          else if (d == 8) { this.d8 = this.d8 + 1; } else if (d == 9) { this.d9 = this.d9 + 1; } else if (d == 10) { this.d10 = this.d10 + 1; }
          else if (d == 11) { this.d11 = this.d11 + 1; } else if (d == 12) { this.d12 = this.d12 + 1; } else if (d == 13) { this.d13 = this.d13 + 1; }
          else if (d == 14) { this.d14 = this.d14 + 1; } else if (d == 15) { this.d15 = this.d15 + 1; } else if (d == 16) { this.d16 = this.d16 + 1; }
          else if (d == 17) { this.d17 = this.d17 + 1; } else if (d == 18) { this.d18 = this.d18 + 1; } else if (d == 19) { this.d19 = this.d19 + 1; }
          else if (d == 20) { this.d20 = this.d20 + 1; } else if (d == 21) { this.d21 = this.d21 + 1; } else if (d == 22) { this.d22 = this.d22 + 1; }
          else if (d == 23) { this.d23 = this.d23 + 1; } else if (d == 24) { this.d24 = this.d24 + 1; } else if (d == 25) { this.d25 = this.d25 + 1; }
          else if (d == 26) { this.d26 = this.d26 + 1; } else if (d == 27) { this.d27 = this.d27 + 1; } else if (d == 28) { this.d28 = this.d28 + 1; }
          else if (d == 29) { this.d29 = this.d29 + 1; } else if (d == 30) { this.d30 = this.d30 + 1; } else if (d == 31) { this.d31 = this.d31 + 1; }
        }

      }

      this.insup_wgMst[l]['totPrsDay'] = this.h_prsDay;
      this.insup_wgMst[l]['predays'] = this.h_prsDay;
    }
    this.labwise_totPrsDay = this.insup_wgMst[Number(this.presenty_editIndex)]['totPrsDay'];

    for (let l = 0; l < this.matha_Mst.length; l++) {
      if (Number(this.matha_Mst[l]['day']) == 1) { this.matha_Mst[l]['labcnt'] = this.d1; }
      else if (Number(this.matha_Mst[l]['day']) == 2) { this.matha_Mst[l]['labcnt'] = this.d2; }
      else if (Number(this.matha_Mst[l]['day']) == 3) { this.matha_Mst[l]['labcnt'] = this.d3; }
      else if (Number(this.matha_Mst[l]['day']) == 4) { this.matha_Mst[l]['labcnt'] = this.d4; }
      else if (Number(this.matha_Mst[l]['day']) == 5) { this.matha_Mst[l]['labcnt'] = this.d5; }
      else if (Number(this.matha_Mst[l]['day']) == 6) { this.matha_Mst[l]['labcnt'] = this.d6; }
      else if (Number(this.matha_Mst[l]['day']) == 7) { this.matha_Mst[l]['labcnt'] = this.d7; }
      else if (Number(this.matha_Mst[l]['day']) == 8) { this.matha_Mst[l]['labcnt'] = this.d8; }
      else if (Number(this.matha_Mst[l]['day']) == 9) { this.matha_Mst[l]['labcnt'] = this.d9; }
      else if (Number(this.matha_Mst[l]['day']) == 10) { this.matha_Mst[l]['labcnt'] = this.d10; }
      else if (Number(this.matha_Mst[l]['day']) == 11) { this.matha_Mst[l]['labcnt'] = this.d11; }
      else if (Number(this.matha_Mst[l]['day']) == 12) { this.matha_Mst[l]['labcnt'] = this.d12; }
      else if (Number(this.matha_Mst[l]['day']) == 13) { this.matha_Mst[l]['labcnt'] = this.d13; }
      else if (Number(this.matha_Mst[l]['day']) == 14) { this.matha_Mst[l]['labcnt'] = this.d14; }
      else if (Number(this.matha_Mst[l]['day']) == 15) { this.matha_Mst[l]['labcnt'] = this.d15; }
      else if (Number(this.matha_Mst[l]['day']) == 16) { this.matha_Mst[l]['labcnt'] = this.d16; }
      else if (Number(this.matha_Mst[l]['day']) == 17) { this.matha_Mst[l]['labcnt'] = this.d17; }
      else if (Number(this.matha_Mst[l]['day']) == 18) { this.matha_Mst[l]['labcnt'] = this.d18; }
      else if (Number(this.matha_Mst[l]['day']) == 19) { this.matha_Mst[l]['labcnt'] = this.d19; }
      else if (Number(this.matha_Mst[l]['day']) == 20) { this.matha_Mst[l]['labcnt'] = this.d20; }
      else if (Number(this.matha_Mst[l]['day']) == 21) { this.matha_Mst[l]['labcnt'] = this.d21; }
      else if (Number(this.matha_Mst[l]['day']) == 22) { this.matha_Mst[l]['labcnt'] = this.d22; }
      else if (Number(this.matha_Mst[l]['day']) == 23) { this.matha_Mst[l]['labcnt'] = this.d23; }
      else if (Number(this.matha_Mst[l]['day']) == 24) { this.matha_Mst[l]['labcnt'] = this.d24; }
      else if (Number(this.matha_Mst[l]['day']) == 25) { this.matha_Mst[l]['labcnt'] = this.d25; }
      else if (Number(this.matha_Mst[l]['day']) == 26) { this.matha_Mst[l]['labcnt'] = this.d26; }
      else if (Number(this.matha_Mst[l]['day']) == 27) { this.matha_Mst[l]['labcnt'] = this.d27; }
      else if (Number(this.matha_Mst[l]['day']) == 28) { this.matha_Mst[l]['labcnt'] = this.d28; }
      else if (Number(this.matha_Mst[l]['day']) == 29) { this.matha_Mst[l]['labcnt'] = this.d29; }
      else if (Number(this.matha_Mst[l]['day']) == 30) { this.matha_Mst[l]['labcnt'] = this.d30; }
      else if (Number(this.matha_Mst[l]['day']) == 31) { this.matha_Mst[l]['labcnt'] = this.d31; }

    }

    this.cal_Matha(iscal);

  }
  // #endregion

  // #region fill Matha
  matha_Mst;
  fill_Matha() {
    this.matha_Mst = [];
    this.daysInMonth = new Date(this.filterYr, this.filterMonth, 0).getDate();
    let wages: Number = 0;
    let levy: Number = 0;
    for (let d = 1; d < Number(this.daysInMonth) + 1; d++) {

      //wages = this.get_bind_Wl(data,d,'w');
      //levy = this.get_bind_Wl(data,d,'l');



      if (d == 1) {
        this.matha_Mst = [{
          day: d,
          strDate: d.toString() + '/' + this.filterMonth.toString() + '/' + this.filterYr.toString(),
          wages: wages,
          levy: levy,
          matha: 0,
          labcnt: 0,
          mathid: 0,
          wageid: 0,
          editcol: true
        },];
      }
      else {
        this.matha_Mst.push({
          day: d,
          strDate: d.toString() + '/' + this.filterMonth.toString() + '/' + this.filterYr.toString(),
          wages: wages,
          levy: levy,
          matha: 0,
          labcnt: 0,
          mathid: 0,
          wageid: 0,
          editcol: true
        });
      }


    }



    // this.cal_Presenty();

    
    return this.matha_Mst
  }
  get_bind_Wl(bindMatha: any = [], day_: number = 0, retVal: string = '') {
    
    if (Number(bindMatha.count) > 0) {
      if (retVal == "w") {
        //return Number(bindMatha[0]['wage'+day_.toString()]);
        return Number(bindMatha[0]['wage1']);
      }
      else {
        //return Number(bindMatha[0]['levy'+day_.toString()]);
        return Number(bindMatha[0]['levy1']);
      }

    }
    else {
      return 0;
    }


  }
  set_change_matha_Val(event, i) {
    this.matha_Mst[Number(i)]['wages'] = event.target.value;
    this.cal_Matha();
  }
  remove_levy_fromWages(event, i) {
    let act_WageAmt = 0;
    if ((event.keyCode == 13 || event.keyCode == 9)) {
      // let lper = Number(this.Levy_Per);
      // let lamt = Number((Number(event.target.value) * Number(lper)) / 100).toFixed(0);
      // act_WageAmt = Number(event.target.value);

    }
    else if (event.keyCode == 40) {
      act_WageAmt = 0;
      let lper = Number(this.Levy_Per) + 100;
      let lamt = Number((Number(event.target.value) / Number(lper)) * 100).toFixed(0);
      lamt = Number(Number(event.target.value) - Number(lamt)).toFixed(0);
      let wageAmt = Number(Number(event.target.value) - Number(lamt)).toFixed(0);
      act_WageAmt = Number(wageAmt);
      this.matha_Mst[Number(i)]['wages'] = Number(act_WageAmt);
      this.cal_Matha();

    }

  }
  adding_matha_Wages(event, i) {
    let strwages = event.target.value;
    let addingWages = strwages.split('+')
    
    let tot_Wages: number = 0;
    for (let item of addingWages) {
      if (true) {
        tot_Wages = Number(tot_Wages) + Number(item);
      }
    }
    this.matha_Mst[Number(i)]['wages'] = Number(tot_Wages);
    this.cal_Matha();

    
    // if ((event.keyCode == 13 || event.keyCode == 9)) {
    //   this.matha_Mst[Number(i)]['wages'] = Number(tot_Wages);
    //   this.cal_Matha();
    // }
    // else if (event.keyCode == 40) {
    //   let lper = Number(this.Levy_Per) + 100;
    //   let lamt = Number((Number(tot_Wages) / Number(lper)) * 100).toFixed(0);
    //   lamt = Number(Number(tot_Wages) - Number(lamt)).toFixed(0);
    //   let wageAmt = Number(Number(tot_Wages) - Number(lamt)).toFixed(0);
    //   tot_Wages = Number(wageAmt);
    //   this.matha_Mst[Number(i)]['wages'] = Number(tot_Wages);
    //   this.cal_Matha();

    // }
  }
  cal_labWages_from_Matha() {
    // alert(this.mode)

    for (let l = 0; l < this.insup_wgMst.length; l++) {

      this.insup_wgMst[l]['actualGrossAmt'] = 0;
      this.insup_wgMst[l]['preGrossAmt'] = 0;
      this.insup_wgMst[l]['diffAmt1'] = 0;
      this.insup_wgMst[l]['diffAmt2'] = 0;

      for (let day = 1; day < 32; day++) {
        if (this.insup_wgMst[Number(l)]['day' + day + 'Math'] == 'P') {
          this.insup_wgMst[l]['preGrossAmt'] = Number(Number(this.insup_wgMst[l]['preGrossAmt']) + Number(this.matha_Mst[Number(day) - 1]['matha']));
        }
      }
      this.cal_netPay(l);
      this.get_auto_deuduction(l);

    }


  }
  // #endregion

  // #region Cal Matha
  pay_mode = 'Manual';
  cal_Matha(isCal: boolean = true) {
    let TotHoliday = 0;
    let filterToAbcDay = this.insup_wgMst.filter(x => Number(x.totPrsDay) == 0);
    TotHoliday = Number(filterToAbcDay.length);

    let Tot_Prs_DayCnt, Tot_PrsCnt, Tot_Rec, Tot_Dis, Tot_Diff

    Tot_Prs_DayCnt = 0;
    Tot_PrsCnt = 0;
    Tot_Rec = 0;
    Tot_Dis = 0;
    Tot_Diff = 0;

    Tot_Rec = Number(this.rec_HW);

    for (let l = 0; l < this.matha_Mst.length; l++) {
      this.matha_Mst[l]['editcol'] = false;
      if (Number(this.matha_Mst[l]['labcnt']) == 0) {
        this.matha_Mst[l]['wages'] = 0;
        this.matha_Mst[l]['editcol'] = true;
      }
      if (this.pay_mode == 'Auto') {
        if (Number(this.insup_wgMst.length) - Number(TotHoliday) == Number(this.matha_Mst[l]['labcnt'])) {
          Tot_Prs_DayCnt = Number(Tot_Prs_DayCnt) + 1;
          this.matha_Mst[l]['editcol'] = true;
        }
        else {
          Tot_Dis = Number(Tot_Dis) + Number(this.matha_Mst[l]['wages']);

        }
      }
    }

    if (this.pay_mode == 'Auto') {
      Tot_Diff = Number(Tot_Rec) - Number(Tot_Dis);
    }
    else {
      Tot_Diff = 0;
    }

    
    for (let l = 0; l < this.matha_Mst.length; l++) {
      if (Number(this.insup_wgMst.length) - Number(TotHoliday) == Number(this.matha_Mst[l]['labcnt'])) {
        if (Number(Tot_Diff) > 0) {
          this.matha_Mst[l]['wages'] = Number(Number(Tot_Diff) / Number(Tot_Prs_DayCnt));
        }

      }

      if (Number(this.matha_Mst[l]['labcnt']) > 0) {
        this.matha_Mst[l]['matha'] = Number(Number(this.matha_Mst[l]['wages']) / Number(this.matha_Mst[l]['labcnt']));
        this.matha_Mst[l]['matha'] = parseInt(this.matha_Mst[l]['matha']); //****remove paise
      }
      else {
        this.matha_Mst[l]['matha'] = 0;
      }

      this.matha_Mst[l]['levy'] = Number((Number(this.matha_Mst[l]['wages']) * Number(this.Levy_Per)) / 100).toFixed(2);

    }
    //alert(isCal);
    if (isCal == true) {
      this.cal_labWages_from_Matha();
    }



  }
  // #endregion

  // #region getTotal
  getTotal(data: any = [], fldname) {
    if (Number(data.length) > 0) {

    }
    else {
      return 0;
    }

    if (fldname == 'predays') {
      return data.reduce((sum, curr) => sum + Number(curr.predays), 0);
    }
    else if (fldname == 'preGrossAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.preGrossAmt), 0);
    }
    else if (fldname == 'diffAmt1') {
      return data.reduce((sum, curr) => sum + Number(curr.diffAmt1), 0);
    }
    else if (fldname == 'diffAmt2') {
      return data.reduce((sum, curr) => sum + Number(curr.diffAmt2), 0);
    }
    else if (fldname == 'actualGrossAmt') {
      this.paid_HW = data.reduce((sum, curr) => sum + Number(curr.actualGrossAmt), 0);
      this.tot_paid_WL = Number(Number(this.paid_HW) + Number(this.paid_HL));
      this.cal_WlDiff();
      return this.paid_HW
    }
    else if (fldname == 'levyAmt') {
      this.paid_HL = Number(data.reduce((sum, curr) => sum + Number(curr.levyAmt), 0));
      this.tot_paid_WL = Number(Number(this.paid_HW) + Number(this.paid_HL));
      this.cal_WlDiff();
      return Number(this.paid_HL);
    }
    else if (fldname == 'pf') {
      return data.reduce((sum, curr) => sum + Number(curr.pf), 0);
    }
    else if (fldname == 'advAmt') {
      this.paid_estAdv = data.reduce((sum, curr) => sum + Number(curr.advAmt), 0);
      this.diff_estAdv = Number(Number(this.rec_estAdv) - Number(this.paid_estAdv));
      return this.paid_estAdv
    }
    else if (fldname == 'advLoanAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.advLoanAmt), 0);
    }
    else if (fldname == 'pfAdvCutting') {
      return data.reduce((sum, curr) => sum + Number(curr.pfAdvCutting), 0);
    }
    else if (fldname == 'pfAdvint') {
      return data.reduce((sum, curr) => sum + Number(curr.pfAdvint), 0);
    }
    else if (fldname == 'patpediAmt1') {
      return data.reduce((sum, curr) => sum + Number(curr.patpediAmt1), 0);
    }
    else if (fldname == 'insuranceAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.insuranceAmt), 0);
    }
    else if (fldname == 'unionAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.unionAmt), 0);
    }
    else if (fldname == 'itCutting') {
      return data.reduce((sum, curr) => sum + Number(curr.itCutting), 0);
    }
    else if (fldname == 'misc') {
      return data.reduce((sum, curr) => sum + Number(curr.misc), 0);
    }
    else if (fldname == 'deduction') {
      return data.reduce((sum, curr) => sum + Number(curr.deduction), 0);
    }
    else if (fldname == 'netPay') {
      return data.reduce((sum, curr) => sum + Number(curr.netPay), 0);
    }

    else if (fldname == 'wRecAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.wRecAmt), 0);
    }
    else if (fldname == 'lRecAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.lRecAmt), 0);
    }
    else if (fldname == 'wPaidAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.wPaidAmt), 0);
    }
    else if (fldname == 'lPaidAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.lPaidAmt), 0);
    }
    else if (fldname == 'wCurRecAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.wCurRecAmt), 0);
    }
    else if (fldname == 'lCurRecAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.lCurRecAmt), 0);
    }
    else if (fldname == 'wCurPaidAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.wCurPaidAmt), 0);
    }
    else if (fldname == 'lCurPaidAmt') {
      return data.reduce((sum, curr) => sum + Number(curr.lCurPaidAmt), 0);
    }
    else if (fldname == 'totPrsDay') {
      return data.reduce((sum, curr) => sum + Number(curr.totPrsDay), 0);
    }
    else if (fldname == 'labcnt') {
      return data.reduce((sum, curr) => sum + Number(curr.labcnt), 0);
    }
    else if (fldname == 'wages') {
      return data.reduce((sum, curr) => sum + Number(curr.wages), 0);
    }
    else if (fldname == 'levy') {
      return data.reduce((sum, curr) => sum + Number(curr.levy), 0);
    }

  }
  // #endregion

  // #region calculation
  cal_WlDiff() {
    this.diff_HW = Number(Number(this.rec_HW) - Number(this.paid_HW));
    this.diff_tot = Number(Number(this.tot_rec_WL) - Number(this.tot_paid_WL));
    this.difff_HL = Number(Number(this.rec_HL) - Number(this.paid_HL));
    this.diff_tot = Number(Number(this.tot_rec_WL) - Number(this.tot_paid_WL));
  }

  cal_netPay(i) {
    this.insup_wgMst[i]['actualGrossAmt'] = Number(Number(this.insup_wgMst[i]['preGrossAmt']) + Number(this.insup_wgMst[i]['diffAmt1']) + Number(this.insup_wgMst[i]['diffAmt2']));

    if (Number(this.insup_wgMst[i]['actualGrossAmt']) > 0 && Number(this.insup_wgMst[i]['wageID']) == 0) {
      if (this.insup_wgMst[i]['editgross'] == 'no') {
        this.insup_wgMst[i]['editgross'] == 'yes'
        // this.get_auto_deuduction(i);
      }
    }

    this.insup_wgMst[i]['pf'] = Number(Number((Number(this.insup_wgMst[i]['actualGrossAmt']) * Number(this.LabPFPer)) / 100).toFixed(0));

    if (this.Without_Levy == 'Yes') {
      this.insup_wgMst[i]['levyAmt'] = 0;
      this.insup_wgMst[i]['brdPF'] = Number(Number((Number(this.insup_wgMst[i]['actualGrossAmt']) * Number(this.A_BrdPFPer)) / 100).toFixed(0));
    }
    else {
      
      this.insup_wgMst[i]['levyAmt'] = Number(Number((Number(this.insup_wgMst[i]['actualGrossAmt']) * Number(this.Levy_Per)) / 100).toFixed(0));
      this.insup_wgMst[i]['brdPF'] = Number(Number((Number(this.insup_wgMst[i]['actualGrossAmt']) * Number(this.BrdPFPer)) / 100).toFixed(0));

    }


    let totDed: Number = Number(this.insup_wgMst[i]['pf']) + Number(this.insup_wgMst[i]['advAmt']) + Number(this.insup_wgMst[i]['advLoanAmt']) + Number(this.insup_wgMst[i]['pfAdvCutting']) + Number(this.insup_wgMst[i]['pfAdvint']) + Number(this.insup_wgMst[i]['patpediAmt1']) + Number(this.insup_wgMst[i]['insuranceAmt']) + Number(this.insup_wgMst[i]['unionAmt']) + Number(this.insup_wgMst[i]['itCutting']) + Number(this.insup_wgMst[i]['misc']);
    this.insup_wgMst[i]['deduction'] = Number(totDed);
    this.insup_wgMst[i]['netPay'] = Number(Number(this.insup_wgMst[i]['actualGrossAmt']) - Number(totDed));

    if (Number(this.insup_wgMst[i]['actualGrossAmt']) == 0 && Number(this.insup_wgMst[i]['netPay']) < 0) {
      this.set_Value_0(i);
    }




    this.bind_recPay('pay');

    

  }
  // #endregion

  // #region set 0 value
  set_Value_0(i) {
    if (Number(this.insup_wgMst[i]['wageID']) == 0) {
      this.insup_wgMst[i]['editgross'] == 'no'
    }

    this.insup_wgMst[Number(i)]['levyAmt'] = 0;
    this.insup_wgMst[Number(i)]['pf'] = 0;
    this.insup_wgMst[Number(i)]['advAmt'] = 0;
    this.insup_wgMst[Number(i)]['advLoanAmt'] = 0;
    this.insup_wgMst[Number(i)]['pfAdvCutting'] = 0;
    this.insup_wgMst[Number(i)]['pfAdvint'] = 0;
    this.insup_wgMst[Number(i)]['patpediAmt1'] = 0;
    this.insup_wgMst[Number(i)]['insuranceAmt'] = 0;
    this.insup_wgMst[Number(i)]['unionAmt'] = 0;
    this.insup_wgMst[Number(i)]['itCutting'] = 0;
    this.insup_wgMst[Number(i)]['misc'] = 0;

    this.insup_wgMst[Number(i)]['pfLoanID'] = 0;


    this.cal_netPay(i);

  }
  set_PrsDay_0_Value_0(i) {
    if (Number(this.insup_wgMst[i]['predays']) == 0) {
      this.insup_wgMst[Number(i)]['preGrossAmt'] = 0;
      this.insup_wgMst[Number(i)]['diffAmt1'] = 0;
      this.insup_wgMst[Number(i)]['diffAmt2'] = 0;
      this.insup_wgMst[Number(i)]['actualGrossAmt'] = 0;
    }

  }
  // #endregion

  // #region fill deductions
  deduction_editIndex: Number = 0;
  deduction_LabID: Number = -1;
  labPat_DedAmt: Number = 0;
  labPat_ActDedAmt: Number = 0;

  labPFAdv_DedAmt: Number = 0;
  filter_lab_PatDt: any = []
  set_patpediDed(rIndex: Number) {
    this.labPat_DedAmt = 0;
    this.labPat_ActDedAmt = 0;


    this.deduction_editIndex = rIndex;
    this.deduction_LabID = this.insup_wgMst[Number(this.deduction_editIndex)]['labourID'];
    this.selLabName = this.insup_wgMst[Number(this.deduction_editIndex)]['labName'];
    this.seleLabRegNo = this.insup_wgMst[Number(this.deduction_editIndex)]['labRegNo'];

    this.filter_lab_PatDt = [];
    this.filter_lab_PatDt = this.patpediDed_List.filter(x => Number(x.labourID) == Number(this.deduction_LabID));

    this.labPat_ActDedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.actDedAmt), 0);
    this.labPat_DedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);
  }

  set_PatPediAmt(event, index) {
    this.labPat_DedAmt = 0;
    this.labPat_ActDedAmt = 0;
    let dedAmt = Number(event.target.value);
    this.patpediDed_List[Number(index)]['dedAmt'] = Number(dedAmt);
    let labID = this.patpediDed_List[Number(index)]['labourID'];

    this.filter_lab_PatDt = [];
    this.filter_lab_PatDt = this.patpediDed_List.filter(x => Number(x.labourID) == Number(labID));

    this.labPat_ActDedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.actDedAmt), 0);
    this.labPat_DedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);

    this.insup_wgMst[Number(this.deduction_editIndex)]['patpediAmt1'] = this.labPat_DedAmt;

    this.cal_netPay(Number(this.deduction_editIndex));
  }

  //Insurance dudection details

  labIns_ActDedAmt: Number = 0;
  labIns_DedAmt: Number = 0;
  filter_lab_InsDt: any = []
  set_InsuranceDed(rIndex: Number) {
    this.labIns_DedAmt = 0;
    this.labIns_ActDedAmt = 0;

    this.deduction_editIndex = rIndex;
    this.deduction_LabID = this.insup_wgMst[Number(this.deduction_editIndex)]['labourID'];
    this.selLabName = this.insup_wgMst[Number(this.deduction_editIndex)]['labName'];
    this.seleLabRegNo = this.insup_wgMst[Number(this.deduction_editIndex)]['labRegNo'];

    this.filter_lab_InsDt = [];
    this.filter_lab_InsDt = this.insDed_List.filter(x => Number(x.labourID) == Number(this.deduction_LabID));

    this.labIns_ActDedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.actPolicyAmt), 0);
    this.labIns_DedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);
  }
  set_InsuranceAmt(event, index) {
    this.labIns_DedAmt = 0;
    this.labIns_ActDedAmt = 0;
    let dedAmt = Number(event.target.value);
    this.insDed_List[Number(index)]['dedAmt'] = Number(dedAmt);
    let labID = this.insDed_List[Number(index)]['labourID'];

    this.filter_lab_InsDt = [];
    this.filter_lab_InsDt = this.insDed_List.filter(x => Number(x.labourID) == Number(labID));

    this.labIns_ActDedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.actPolicyAmt), 0);
    this.labIns_DedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);

    this.insup_wgMst[Number(this.deduction_editIndex)]['insuranceAmt'] = this.labIns_DedAmt;

    this.get_ITAmt(Number(this.deduction_editIndex));

    this.cal_netPay(Number(this.deduction_editIndex));
  }
  // #endregion

  // #region cal ITAmt
  list_ITApplicableAmt: any = [];
  list_ITSectionLimit: any = [];
  list_ITSection: any = [];
  list_PayITSlab: any = [];
  fill_IT_ApplicableData() {
    this.list_ITApplicableAmt = [];
    this.list_ITSectionLimit = [];
    this.list_ITSection = [];
    this.list_PayITSlab = [];
    let filterDate: any = {
      "payDt": this.payDate,
    }

    let body = new FormData();
    body = filterDate;

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getitapplicabledata';
    this._userService.post_data(url, body).subscribe(
      (data) => {
        
        this.list_ITApplicableAmt = data.labPayITApplicableAmt;
        this.list_ITSectionLimit = data.labPayITSectionLimit;
        this.list_PayITSlab = data.labPayITSlab;
        this.list_ITSection = data.labPayITSection;
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  get_ITAmt(i) {
    let PayDt = this.payDate;
    let LabourID = this.insup_wgMst[i]['labourID'];
    let panLen = this.insup_wgMst[i]['panLen'];
    let ActGross = this.insup_wgMst[i]['actualGrossAmt'];
    let LabPF = this.insup_wgMst[i]['pf'];
    let PolicyAmt = this.insup_wgMst[i]['insuranceAmt'];
    let PT = this.insup_wgMst[i]['profTax'];

    let PrvLabPF = this.insup_wgMst[i]['prvPF'];
    let PrvPT = this.insup_wgMst[i]['prvProfTax'];
    let PrvITAmt = this.insup_wgMst[i]['prvITCutting'];
    let Sex = this.insup_wgMst[i]['sex'];
    let PrvEduCessAmt = this.insup_wgMst[i]['prvEduCessAmt'];
    let PrvActGross = this.insup_wgMst[i]['prvActualGrossAmt'];
    let PrvPolicyAmt = this.insup_wgMst[i]['prvInsuranceAmt'];

    let ITApllAmt = 0, Prv_ITCutiing = 0, Standerd_Ded = 0, TotDed80C = 0, LimitAmt = 0, LimitAmt80C = 0, TotEduCessCutting = 0, ProfTaxAmt = 0, Cur80CDed = 0, Prv_EduCessAmtCutting = 0;

    

    if (Number(ActGross) > 0) {
      LimitAmt80C = this.get_sectionLimtAmt('Section 80C');
      Standerd_Ded = this.get_sectionLimtAmt('Standard Deduction');

      let filter_ITApp: any[] = this.list_ITApplicableAmt.filter(x => Number(x.labourID) == Number(LabourID));

      if (filter_ITApp.length > 0) {
        TotDed80C = Number(filter_ITApp[0]['totDed80C']);
        if (Number(TotDed80C) > Number(LimitAmt80C)) {
          TotDed80C = Number(LimitAmt80C);
        }

        
        ProfTaxAmt = Number(filter_ITApp[0]['profTax']);
        ITApllAmt = Number(filter_ITApp[0]['actualGrossAmt']) - Number(TotDed80C) - Number(filter_ITApp[0]['invesmentAmt']);// - Number(ProfTaxAmt);

        if (Number(ITApllAmt) > Number(Standerd_Ded)) {
          ITApllAmt = Number(ITApllAmt) - Number(Standerd_Ded);
        }

        Prv_ITCutiing = Number(filter_ITApp[0]['itCutting']);
        Prv_EduCessAmtCutting = Number(filter_ITApp[0]['eduCessAmt']);
      }
      else {
        ITApllAmt = 0;
        Prv_ITCutiing = 0;
        Prv_EduCessAmtCutting = 0;
        PrvEduCessAmt = 0;
      }

      let totIT = Number(Number(ITApllAmt) + Number(PrvLabPF) + Number(PrvPT) + Number(PrvPolicyAmt));

      
      ITApllAmt = Number(Number(Number(ITApllAmt) + Number(PrvLabPF) + Number(PrvPT) + Number(PrvPolicyAmt)) - Number(PrvActGross));

      

      Prv_ITCutiing = Number(Prv_ITCutiing) - Number(PrvITAmt);
      Prv_EduCessAmtCutting = Number(Prv_EduCessAmtCutting) - Number(PrvEduCessAmt);

      Cur80CDed = Number(Number(LabPF) + Number(PT) + Number(PolicyAmt));


      if (Number(Cur80CDed) > Number(LimitAmt80C)) {
        Cur80CDed = Number(LimitAmt80C);
      }

      ITApllAmt = Number(Number(Number(ITApllAmt) + Number(ActGross)) - Number(Cur80CDed));

      let filter_ITslab: any = this.list_PayITSlab.filter(x => x.sex == Sex);
      if (filter_ITslab.length > 0) { } else {
        filter_ITslab = this.list_PayITSlab.filter(x => x.sex == 'Male');
      }

      let ITEduCuttingAmt = 0, TotITCuttingAmt = 0, EduCessPer = 0, EduCessAmt = 0, RebateAmt = 0;
      
      if (filter_ITslab.length > 0) {
        filter_ITslab = filter_ITslab.sort(function (a, b) {
          return a.frmAmt.rendered - b.frmAmt.rendered;
        });

        let SlabDiffAmt = 0, TotSlabDiffAmt = 0, TaxRate = 0, AddTaxRate = 0;

        if (Number(panLen) > 2) {
          AddTaxRate = 1;
        }
        else {
          AddTaxRate = 2;
        }

        for (let i = 0; i < filter_ITslab.length; i++) {

          if (Number(ITApllAmt) >= Number(filter_ITslab[i]["frmAmt"]) && Number(ITApllAmt) <= Number(filter_ITslab[i]["uptoAmt"])) {
            RebateAmt = Number(filter_ITslab[i]["rebateAmt"]);

            let s: number = i + 1;
            for (let n = 0; n < s; n++) {

              if (Number(EduCessPer) == 0) {
                EduCessPer = Number(filter_ITslab[n]["eduCessPer"]);
              }

              TaxRate = Number(filter_ITslab[n]["taxRate"]);
              TaxRate = Number(TaxRate) * Number(AddTaxRate);

              SlabDiffAmt = Number(filter_ITslab[n]["uptoAmt"]) - Number(filter_ITslab[n]["frmAmt"]);
              if (Number(n) == Number(i)) {
                let diffAmt = Number(Number(ITApllAmt) - Number(TotSlabDiffAmt));
                diffAmt = Number(Number(diffAmt) * Number(TaxRate));

                //ITEduCuttingAmt = Number(Number(Number((Number(ITApllAmt) - Number(TotSlabDiffAmt))) * Number(TaxRate)) / 100);
                ITEduCuttingAmt = Number(Number(diffAmt) / 100);

              }
              else {
                TotSlabDiffAmt = Number(TotSlabDiffAmt) + Number(SlabDiffAmt);
                ITEduCuttingAmt = Number(Number((Number(SlabDiffAmt) * Number(TaxRate))) / 100);

              }

              TotITCuttingAmt = Number(ITEduCuttingAmt) + Number(TotITCuttingAmt);

            }

          }

        }

      }

      TotITCuttingAmt = Number(TotITCuttingAmt) - Number(RebateAmt);
      EduCessAmt = Number(Number((Number(TotITCuttingAmt) * Number(EduCessPer))) / 100);

      TotITCuttingAmt = Number(TotITCuttingAmt) + Number(EduCessAmt);

      EduCessAmt = Number(EduCessAmt) - Number(Prv_EduCessAmtCutting);
      TotITCuttingAmt = Number(TotITCuttingAmt) - Number(Prv_ITCutiing);

      if (TotITCuttingAmt <= 0) {
        TotITCuttingAmt = 0;
        TotEduCessCutting = 0;
      }

      if (Number(EduCessAmt) < 0) {
        EduCessAmt = 0
      }

      let ActITAmt = 0, ActEduCess = 0, DiffAmt = 0, ModAmt = 0

      ActITAmt = Math.floor(TotITCuttingAmt);
      ActEduCess = Math.floor(EduCessAmt);
      ModAmt = Number(this.mod(Number(ActITAmt), 100));

      if (Number(ModAmt) > 0) {
        DiffAmt = 100 - ModAmt
      }
      else {
        DiffAmt = 0
      }

      ActITAmt = Number(ActITAmt) + Number(DiffAmt);
      ActEduCess = Number(ActEduCess) + Number(DiffAmt);

      if (Number(ActITAmt) > 0) {
        this.insup_wgMst[i]['itCutting'] = Number(Number(ActITAmt) + 100);
      }
      else {
        this.insup_wgMst[i]['itCutting'] = Number(ActITAmt);
      }

      this.insup_wgMst[i]['eduCessAmt'] = Number(ActEduCess);

    }
    else {
      this.insup_wgMst[i]['itCutting'] = 0;
      this.insup_wgMst[i]['eduCessAmt'] = 0;
    }

  }
  mod(n, m) {
    var remain = n % m;
    return Math.floor(remain >= 0 ? remain : remain + m);
  };

  get_sectionLimtAmt(sectionFlag: string) {
    let filter_section: any = this.list_ITSectionLimit.filter(x => x.sectionFlag == sectionFlag);
    if (filter_section.length > 0) {
      return Number(filter_section[0]['limitAmt']);
    }
    else {
      return 0;
    }
  }
  // #endregion

  // #region set Auto Diff
  auto_adjuest_diff(recAmt, diffAmt, diffFor) {
    let adjuestable_diffAmt = (Number(recAmt) * 0.075) / 100;
    
    if (Number(recAmt) > 0) {
      if (Number(diffAmt) != 0) {
        let remAmt = Number(diffAmt);
        let addLess = '';
        if (Number(remAmt) < 0) {
          remAmt = Number(remAmt) * -1;
          addLess = 'less';
        }
        else {
          addLess = 'add'
        }

        if (Number(remAmt) > Number(adjuestable_diffAmt)) {
          alert("Auto adjuest diff. amount is greater then 0.075% of recevied amount.")
          return;
        }

        if (diffFor == 'levy') {
          if (this.Without_Levy == 'Yes') {
            alert('This toli is no levy toli not able able to auto adjuest.')
            return false;
          }
        }

        for (let l = 0; l < this.insup_wgMst.length; l++) {
          if (Number(this.insup_wgMst[l]['preGrossAmt']) > 10) {
            if (Number(remAmt) > 0) {
              if (addLess == 'add') {
                if (diffFor == 'wages') {
                  this.insup_wgMst[l]['preGrossAmt'] = Number(this.insup_wgMst[l]['preGrossAmt']) + 1;
                  remAmt = Number(remAmt) - 1;
                  this.cal_netPay(l);
                  this.get_ITAmt(l);
                }
                else if (diffFor == 'levy') {
                  //alert(this.insup_wgMst[l]['levyAmt']);
                  this.insup_wgMst[l]['levyAmt'] = Number(this.insup_wgMst[l]['levyAmt']) + 1;
                  remAmt = Number(remAmt) - 1;
                  this.paid_HL = Number(this.paid_HL) + 1;
                }

              }
              else {
                if (diffFor == 'wages') {
                  this.insup_wgMst[l]['preGrossAmt'] = Number(this.insup_wgMst[l]['preGrossAmt']) - 1;
                  remAmt = Number(remAmt) - 1;
                  this.cal_netPay(l);
                  this.get_ITAmt(l);
                }
                else if (diffFor == 'levy') {
                  // alert(this.insup_wgMst[l]['levyAmt']);
                  this.insup_wgMst[l]['levyAmt'] = Number(this.insup_wgMst[l]['levyAmt']) - 1;
                  remAmt = Number(remAmt) - 1;
                  this.paid_HL = Number(this.paid_HL) - 1;

                }
                //this.cal_netPay(l);
                
                // this.bind_recPay('pay');
              }
            }
          }

        }
        if (addLess == 'less') {
          remAmt = Number(remAmt) * -1;

        }

        if (Number(remAmt) != 0) {
          if (diffFor == 'wages') {
            this.auto_adjuest_diff(this.rec_HW, remAmt, diffFor);
          }
          else if (diffFor == 'levy') {
            this.auto_adjuest_diff(this.rec_HL, remAmt, diffFor);
          }
        }

        this.bind_recPay('pay');
        this.cal_WlDiff();

      }
    }


  }
  // #endregion

  // #region get Auto deduction
  filter_Advloan: any = [];
  get_auto_deuduction(index) {
    //this.cal_netPay(index);

    if (Number(this.insup_wgMst[index]['actualGrossAmt']) > 0 && Number(this.insup_wgMst[index]['wageID']) == 0) {
      if (this.insup_wgMst[index]['editgross'] == 'no') {
        this.insup_wgMst[index]['editgross'] == 'yes'

        let labID = this.insup_wgMst[Number(index)]['labourID'];
        let PFMasterID = this.insup_wgMst[Number(index)]['pfMasterID'];

        //Get Patpedi deduction
        this.labPat_DedAmt = 0;
        this.labPat_ActDedAmt = 0;
        this.labPFAdv_DedAmt = 0;

        this.filter_lab_PatDt = [];
        this.filter_lab_PatDt = this.patpediDed_List.filter(x => Number(x.labourID) == Number(labID));

        this.labPat_ActDedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.actDedAmt), 0);
        this.labPat_DedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);

        this.insup_wgMst[Number(index)]['patpediAmt1'] = this.labPat_DedAmt;

        //Get Insurance deduction
        this.labIns_DedAmt = 0;
        this.labIns_ActDedAmt = 0;

        this.filter_lab_InsDt = [];
        this.filter_lab_InsDt = this.insDed_List.filter(x => Number(x.labourID) == Number(labID));

        this.labIns_ActDedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.actPolicyAmt), 0);
        this.labIns_DedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);

        this.insup_wgMst[Number(index)]['insuranceAmt'] = this.labIns_DedAmt;

        //Get PFLoan



        let filter_Pfloan = this.pfLoan_mst.filter(x => Number(x.pfMasterID) == Number(PFMasterID) && Number(x.balAmt) > 0);

        if (Number(filter_Pfloan.length) > 0) {
          //this.labPFAdv_DedAmt=Number(filter_Pfloan[0]['instAmt']);

          this.insup_wgMst[Number(index)]['pfLoanID'] = Number(filter_Pfloan[0]['pfLoanID']);
          this.insup_wgMst[Number(index)]['pfAdvCutting'] = Number(filter_Pfloan[0]['instAmt']);
          this.insup_wgMst[Number(index)]['pfAdvint'] = Number(filter_Pfloan[0]['intAmt']);
        }
        else {
          this.insup_wgMst[Number(index)]['pfLoanID'] = 0;
          this.insup_wgMst[Number(index)]['pfAdvCutting'] = 0;
          this.insup_wgMst[Number(index)]['pfAdvint'] = 0;
        }

        //AdvLoan

        this.filter_Advloan = this.advLoan_mst.filter(x => Number(x.pfMasterID) == Number(PFMasterID) && Number(x.balAmt) > 0);

        if (Number(this.filter_Advloan.length) > 0) {
          this.insup_wgMst[Number(index)]['advLoanID'] = Number(this.filter_Advloan[0]['advLoanID']);
          this.insup_wgMst[Number(index)]['advLoanAmt'] = Number(this.filter_Advloan[0]['instAmt']);

        }
        else {
          this.insup_wgMst[Number(index)]['advLoanID'] = 0;
          this.insup_wgMst[Number(index)]['advLoanAmt'] = 0;

        }

        //union

        let filter_union = this.unionDed_List.filter(x => Number(x.labourID) == Number(labID));

        if (Number(filter_union.length) > 0) {
          this.insup_wgMst[Number(index)]['unionID'] = Number(filter_union[0]['unionID']);
          this.insup_wgMst[Number(index)]['unionAmt'] = Number(filter_union[0]['unionAmt']);

        }
        else {
          this.insup_wgMst[Number(index)]['unionID'] = 0;
          this.insup_wgMst[Number(index)]['unionAmt'] = 0;

        }

      }
    }

    if (Number(this.insup_wgMst[index]['actualGrossAmt']) > 0) {
      //Get ITDeduction
      this.get_ITAmt(index);
    }


    this.cal_netPay(index);


  }
  // #endregion

  // #region check record save criteria
  check_record_save_Criteria() {
    if (Number(this.toliID) <= 0) {
      alert('ToliID not get.')
      return false;
    }
    if (Number(this.marketID) <= 0) {
      alert('MarketID not get.')
      return false;
    }
    if (Number(this.companyID) <= 0) {
      alert('MarketID not get.')
      return false;
    }
    if (Number(this.LevyCode) > 0) {
    }
    else {
      alert('LevyCode not get.')
      return false;
    }

    let mon, yr;
    mon = new Date(this.payDate).getMonth() + 1;
    yr = new Date(this.payDate).getFullYear();

    if (mon < 4) {
      this.fayear = yr - 1;
    }
    else {
      this.fayear = yr;
    }

    if (this.fayear != this.filterFayear) {
      alert('Enter date selected finacial year.')
      return false;
    }

    if (Number(this.filterYr.toString().length) != 4) {
      alert('Enter valid wage year');
      return false;

    }
    else if (Number(this.filterYr) <= 0) {
      alert('Enter valid wage year');
      return false;
    }

    if (Number(this.filterMonth) > 0 && Number(this.filterMonth) < 13) {
    }
    else {
      alert('Enter valid wage month');
      return false;
    }

    if (Number(this.diff_HW) < 0 || Number(this.diff_HW) > 0) {
      if (window.confirm('There Is Diffrance Between Received And Distributed Wages/Levy Still Do You Want To Save This Record.?')) {
      }
      else {
        return false;
      }

    }
    else if (Number(this.difff_HL) < 0 || Number(this.difff_HL) > 0) {
      if (window.confirm('There Is Diffrance Between Received And Distributed Wages/Levy Still Do You Want To Save This Record.?')) {
      }
      else {
        return false;
      }

    }

    if (Number(this.diff_estAdv) < 0 || Number(this.diff_estAdv) > 0) {
      if (window.confirm('There Is Diff. Between Rec.Advance And Payment Advance. Still Do You Want To Save This Record.?')) {
      }
      else {
        return false;
      }

    }

    

    for (let l = 0; l < this.insup_wgMst.length; l++) {

      let chkRcdLab: any = this.rcd_labList.filter(x => Number(x.pfMasterID) == Number(this.insup_wgMst[l]['pfMasterID']));
      if (Number(chkRcdLab.length) > 0) {
        alert("This Labour " + this.insup_wgMst[l]['labRegNo'] + " Resigned.");
        return false;
      }

      let chkData: boolean = true;

      chkData = this.check_netPay(l);

      

      if (chkData == false) {
        alert('Check NetPay');
        return false;
      }
      if (Number(this.insup_wgMst[l]['advLoanAmt']) > 0 && Number(this.insup_wgMst[l]['advLoanID']) == 0) {
        alert('Advance LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['pfAdvCutting']) > 0 && Number(this.insup_wgMst[l]['pfLoanID']) == 0) {
        alert('PF LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['pfAdvint']) > 0 && Number(this.insup_wgMst[l]['pfLoanID']) == 0) {
        alert('PF LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['unionAmt']) > 0 && Number(this.insup_wgMst[l]['unionID']) == 0) {
        alert('UnionID Not get.');
        return false;
      }

      if (Number(this.insup_wgMst[l]['patpediAmt1']) > 0) {
        this.filter_lab_PatDt = [];
        this.filter_lab_PatDt = this.patpediDed_List.filter(x => Number(x.labourID) == Number(this.insup_wgMst[l]['labourID']));
        this.labPat_DedAmt = this.filter_lab_PatDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);

        let DiffAmt = Number(this.insup_wgMst[Number(l)]['patpediAmt1']) - Number(this.labPat_DedAmt);
        if (Number(DiffAmt == 0)) {
        }
        else {
          alert('Check patpediwise deduction total amount.');
          return false;
        }
      }

      if (Number(this.insup_wgMst[l]['insuranceAmt']) > 0) {
        this.filter_lab_InsDt = [];
        this.filter_lab_InsDt = this.insDed_List.filter(x => Number(x.labourID) == Number(this.insup_wgMst[l]['labourID']));
        this.labIns_DedAmt = this.filter_lab_InsDt.reduce((sum, curr) => sum + Number(curr.dedAmt), 0);
        let DiffAmt = Number(this.insup_wgMst[Number(l)]['insuranceAmt']) - Number(this.labIns_DedAmt);

        if (Number(DiffAmt == 0)) {
        }
        else {
          alert('Check insurnace comapnywise deduction total amount.');
          return false;
        }
      }


      if (Number(this.insup_wgMst[l]['itCutting']) > 0) {
        let panLen = this.insup_wgMst[l]['panLen'];
        if (Number(panLen) > 2) {

        }
        else {
          if (window.confirm("This Labour " + this.insup_wgMst[l]['labRegNo'] + " PanCard No. IS Not Enter Still Do You Want To Save Record.?")) {
          }
          else {
            return false;
          }
        }
      }

    }

    return true;

  }
  check_netPay(i) {
    let totDed: Number = Number(this.insup_wgMst[i]['pf']) + Number(this.insup_wgMst[i]['advAmt']) + Number(this.insup_wgMst[i]['advLoanAmt']) + Number(this.insup_wgMst[i]['pfAdvCutting']) + Number(this.insup_wgMst[i]['pfAdvint']) + Number(this.insup_wgMst[i]['patpediAmt1']) + Number(this.insup_wgMst[i]['insuranceAmt']) + Number(this.insup_wgMst[i]['unionAmt']) + Number(this.insup_wgMst[i]['itCutting']) + Number(this.insup_wgMst[i]['misc']);
    let cal_NetPay = Number(this.insup_wgMst[i]['actualGrossAmt']) - Number(totDed);
    if (Number(this.insup_wgMst[i]['netPay']) < 0) {
      return false;
    }

    let diffAmt;
    diffAmt = Number(this.insup_wgMst[i]['netPay']) - Number(cal_NetPay);
    
    if (Number(diffAmt) < 0 || Number(diffAmt) > 0) {
      return false;
    }



    return true;

  }

  check_Patepedi_Ded_70Per(i) {
    return true;

    let DedLimitAmt;
    let DedPer = 70;
    let PatPediAmt = Number(this.insup_wgMst[i]['patpediAmt1']);
    if (Number(PatPediAmt) > 0) {
      DedLimitAmt = Number(Number((Number(this.insup_wgMst[i]['actualGrossAmt']) * DedPer)) / 100).toFixed(0);
      if (Number(PatPediAmt) > Number(DedLimitAmt)) {
        return false;
      }
    }
    else {
      return true;
    }
  }
  // #endregion

  // #region save record
  save_payment() {
    let IsValid: boolean = true;

    IsValid = this.check_record_save_Criteria();
    
    let TotGross, TotLevy;
    TotGross = Number(this.getTotal(this.insup_wgMst, 'actualGrossAmt'));
    TotLevy = Number(this.getTotal(this.insup_wgMst, 'levyAmt'));

    if (IsValid == true) {
      let filter: any = {
        "payDt": this.payDate,
        "payType": this.setpayType,
        "Wageyr": this.filterYr,
        "WageMon": this.filterMonth,
        "ToliId": this.toliID,
        "Fayear": this.filterFayear,
        "CompanyID": 1,
        "remark": "",
        "LevyCode": this.LevyCode,
        "MarketID": this.marketID,
        "UserID": this.userID,
        "TotPaidGross": Number(TotGross),
        "TotPaidLevy": Number(TotLevy)
      }

      let recpayDt: any = [];
      // recpayDt = this.recPaidDt;

      for (let l = 0; l < this.recPaidDt.length; l++) {

        recpayDt.push({
          "chkRec": this.recPaidDt[l]['chkRec'],
          "docID": this.recPaidDt[l]['docID'],
          "estName": this.recPaidDt[l]['estName'],
          "estRegNo": this.recPaidDt[l]['estRegNo'],
          "lCurPaidAmt": this.recPaidDt[l]['lCurPaidAmt'],
          "lCurRecAmt": this.recPaidDt[l]['lCurRecAmt'],
          "lPaidAmt": this.recPaidDt[l]['lPaidAmt'],
          "lRecAmt": this.recPaidDt[l]['lRecAmt'],
          "lRemAmt": this.recPaidDt[l]['lRemAmt'],
          "lessAdvance": this.recPaidDt[l]['lessAdvance'],
          "paidAdvance": this.recPaidDt[l]['paidAdvance'],
          "receiptNO": this.recPaidDt[l]['receiptNO'],
          "wCurPaidAmt": this.recPaidDt[l]['wCurPaidAmt'],
          "wCurRecAmt": this.recPaidDt[l]['wCurRecAmt'],
          "wPaidAmt": this.recPaidDt[l]['wPaidAmt'],
          "wRecAmt": this.recPaidDt[l]['wRecAmt'],
          "wRemAmt": this.recPaidDt[l]['wRemAmt']
        })

        // if (l == 0){
        //   recpayDt = [{
        //     "chkRec": this.recPaidDt[l]['chkRec'],
        //     "docID": this.recPaidDt[l]['docID'],
        //     "estName": this.recPaidDt[l]['estName'],
        //     "estRegNo": this.recPaidDt[l]['estRegNo'],
        //     "lCurPaidAmt": this.recPaidDt[l]['lCurPaidAmt'],
        //     "lCurRecAmt": this.recPaidDt[l]['lCurRecAmt'],
        //     "lPaidAmt": this.recPaidDt[l]['lPaidAmt'],
        //     "lRecAmt": this.recPaidDt[l]['lRecAmt'],
        //     "lRemAmt": this.recPaidDt[l]['lRemAmt'],
        //     "lessAdvance": this.recPaidDt[l]['lessAdvance'],
        //     "paidAdvance": this.recPaidDt[l]['paidAdvance'],
        //     "receiptNO": this.recPaidDt[l]['receiptNO'],
        //     "wCurPaidAmt": this.recPaidDt[l]['wCurPaidAmt'],
        //     "wCurRecAmt": this.recPaidDt[l]['wCurRecAmt'],
        //     "wPaidAmt": this.recPaidDt[l]['wPaidAmt'],
        //     "wRecAmt": this.recPaidDt[l]['wRecAmt'],
        //     "wRemAmt": this.recPaidDt[l]['wRemAmt']
        //   }]
        // }
        // else{
        //   recpayDt.push([{
        //     "chkRec": this.recPaidDt[l]['chkRec'],
        //     "docID": this.recPaidDt[l]['docID'],
        //     "estName": this.recPaidDt[l]['estName'],
        //     "estRegNo": this.recPaidDt[l]['estRegNo'],
        //     "lCurPaidAmt": this.recPaidDt[l]['lCurPaidAmt'],
        //     "lCurRecAmt": this.recPaidDt[l]['lCurRecAmt'],
        //     "lPaidAmt": this.recPaidDt[l]['lPaidAmt'],
        //     "lRecAmt": this.recPaidDt[l]['lRecAmt'],
        //     "lRemAmt": this.recPaidDt[l]['lRemAmt'],
        //     "lessAdvance": this.recPaidDt[l]['lessAdvance'],
        //     "paidAdvance": this.recPaidDt[l]['paidAdvance'],
        //     "receiptNO": this.recPaidDt[l]['receiptNO'],
        //     "wCurPaidAmt": this.recPaidDt[l]['wCurPaidAmt'],
        //     "wCurRecAmt": this.recPaidDt[l]['wCurRecAmt'],
        //     "wPaidAmt": this.recPaidDt[l]['wPaidAmt'],
        //     "wRecAmt": this.recPaidDt[l]['wRecAmt'],
        //     "wRemAmt": this.recPaidDt[l]['wRemAmt']
        //   }])
        // }
      }

      let saveData: any = {
        "BindWagemaster": this.insup_wgMst,
        "LabPayPatPediDedList": this.patpediDed_List,
        "LabPayInsuranceDedList": this.insDed_List,
        "WLRecPaidDt": recpayDt,
        "MathMaster": this.matha_Mst,
        "filterData": filter,

      }

      let body = new FormData();
      body = saveData;

      

      //  return;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/savelabpayment';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          alert(data);
          if (data == 'Record Saved.') {
           this.show_data(true);
          }

        },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });
    }

  }

  // #endregion

  // #region set value 
  set_ValInsUpData(event, i, fldname:string) {
  //  
    let fldVal:Number =  Number(event.target.value);
 //   
    //return;
    if (fldname == 'predays') {
      this.daysInMonth = new Date(this.filterYr, this.filterMonth, 0).getDate();
      if (Number(fldVal) > Number(this.daysInMonth)) {
        this.insup_wgMst[Number(i)]['predays'] = this.daysInMonth;
      }
      else {
        this.insup_wgMst[i]['predays'] = fldVal;
      }

      this.set_PrsDay_0_Value_0(i);

    }
    else if (fldname == 'preGrossAmt') {
      this.insup_wgMst[i]['preGrossAmt'] = fldVal;

    }
    else if (fldname == 'diffAmt1') {
      this.insup_wgMst[i]['diffAmt1'] = fldVal;
    }
    else if (fldname == 'diffAmt2') {
      this.insup_wgMst[i]['diffAmt2'] = fldVal;
    }
    else if (fldname == 'actualGrossAmt') {
      this.insup_wgMst[i]['actualGrossAmt'] = fldVal;
    }
    else if (fldname == 'levyAmt') {
     this.insup_wgMst[i]['levyAmt'] = fldVal;
     
    }
    else if (fldname == 'pf') {
      this.insup_wgMst[i]['pf'] = fldVal;
    }
    else if (fldname == 'advAmt') {
      this.insup_wgMst[i]['advAmt'] = fldVal;
    }
    else if (fldname == 'advLoanAmt') {
      this.insup_wgMst[i]['advLoanAmt'] = fldVal;
    }
    else if (fldname == 'pfAdvCutting') {
      // alert(i);
      this.insup_wgMst[i]['pfAdvCutting'] = fldVal;
      
    }
    else if (fldname == 'pfAdvint') {
      this.insup_wgMst[i]['pfAdvint'] = fldVal;
    }
    else if (fldname == 'patpediAmt1') {
      this.insup_wgMst[i]['patpediAmt1'] = fldVal;
    }
    else if (fldname == 'insuranceAmt') {
      this.insup_wgMst[i]['insuranceAmt'] = fldVal;
    }
    else if (fldname == 'unionAmt') {
      this.insup_wgMst[i]['unionAmt'] = fldVal;
    }
    else if (fldname == 'itCutting') {
      this.insup_wgMst[i]['itCutting'] = fldVal;
    }
    else if (fldname == 'misc') {
      this.insup_wgMst[i]['misc'] = fldVal;
    }
    else if (fldname == 'deduction') {
      this.insup_wgMst[i]['deduction'] = fldVal;
    }
    else if (fldname == 'netPay') {
      this.insup_wgMst[i]['netPay'] = fldVal;
    }

    this.cal_netPay(i);

  }
  // #endregion

  // #region set focus
  set_focus() {
    const element = document.querySelector("#inpt_preGrossAmt");
    element.scrollIntoView(true);
  }
  // #endregion

  // #region finalize record
  finalize_payment(check_criteria) {
    console.log('1')
    let IsValid: boolean = true;

    // this.show_pswModal(true);
    // return;
console.log('1')
    if (check_criteria == 'yes') {
      this.check_record_finalize_Criteria()
    }
    else{
      this.save_finlize_Payment();
      console.log('2')
    }
    

  }
save_finlize_Payment(){
  let saveData: any = {
    "BindWagemaster": this.insup_wgMst,
  }
  let body = new FormData();
  body = saveData;
  if (window.confirm('Are You Sure To Finalize Payment.?')) {
    this.ngxService.start();

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/finalizelabpayment';
    this._userService.post_data(url, body).subscribe(
      (data) => {
        this.ngxService.stop();
        alert(data);
        if (data == 'Payment Finalized SuccessFully.') {
          this.mode = "";
        }

      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
      });
  }
  else {
    return false;
  }
}
  check_record_finalize_Criteria() {


    if (Number(this.diff_estAdv) < 0 || Number(this.diff_estAdv) > 0) {
      alert('There Is Diff. Between Rec.Advance And Payment Advance.');
      return false

    }

    for (let l = 0; l < this.insup_wgMst.length; l++) {

      let chkData: boolean = true;

      chkData = this.check_netPay(l);
      if (chkData == false) {
        alert('Check NetPay');
        return false;
      }
      if (Number(this.insup_wgMst[l]['advLoanAmt']) > 0 && Number(this.insup_wgMst[l]['advLoanID']) == 0) {
        alert('Advance LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['pfAdvCutting']) > 0 && Number(this.insup_wgMst[l]['pfLoanID']) == 0) {
        alert('PF LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['pfAdvint']) > 0 && Number(this.insup_wgMst[l]['pfLoanID']) == 0) {
        alert('PF LoanID Not get.');
        return false;
      }
      if (Number(this.insup_wgMst[l]['unionAmt']) > 0 && Number(this.insup_wgMst[l]['unionID']) == 0) {
        alert('UnionID Not get.');
        return false;
      }


    }

    if (Number(this.diff_HW) < 0 || Number(this.diff_HW) > 0) {
      let dif;
      if (Number(this.diff_HW) < 0) {
        dif = Number(Number(this.diff_HW) * -1).toFixed(2);
      }
      else {
        dif = Number(this.diff_HW).toFixed(2);
      }

      if (Number(dif) > 1) {
        alert('There Is Diffrance Between Received And Distributed Wages > 1 Not Able To Finalize.')
        return false;
      }
      else {
        if (window.confirm('There Is Diffrance Between Received And Distributed Wages Still Do You Want To Finalize This Record.?')) {
          this.show_pswModal(true);
          return false;
        }
        else {
          return false;
        }
      }



    }
    else if (Number(this.difff_HL) < 0 || Number(this.difff_HL) > 0) {
      let dif;
      if (Number(this.difff_HL) < 0) {
        dif = Number(Number(this.difff_HL) * -1).toFixed(2);
      }
      else {
        dif = Number(this.difff_HL).toFixed(2);
      }
      if (Number(dif) > 1) {
        alert('There Is Diffrance Between Received And Distributed Levy > 1 Not Able To Finalize.')
        return false;
      }
      else {
        if (window.confirm('There Is Diffrance Between Received And Distributed Levy Still Do You Want To Finalize This Record.?')) {
          this.show_pswModal(true);
          return false;
        }
        else {
          return false;
        }
      }

    }

   this.save_finlize_Payment();

  }
  // #endregion

  //#region del labpayment

  del_labPayment() {
    if (window.confirm('Are You Sure To Delete Payment.?')) {
      let IsValid: boolean = true;

      IsValid = this.check_record_delete_Criteria();


      if (IsValid == true) {


        let saveData: any = {
          "BindWagemaster": this.insup_wgMst,
        }

        let body = new FormData();
        body = saveData;


        //  return;


        this.ngxService.start();

        let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/dellabpayment/' + this.filterFayear;
        this._userService.post_data(url, body).subscribe(
          (data) => {
            this.ngxService.stop();
            alert(data);
            if (data == 'Payment Deleted SuccessFully.') {
              this.mode = "";
              this.blank_grid();
            }

          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            alert(err);
          });

      }
    }
  }
  check_record_delete_Criteria() {
    for (let l = 0; l < this.insup_wgMst.length; l++) {
      let chkRcdLab: any = this.rcd_labList.filter(x => Number(x.pfMasterID) == Number(this.insup_wgMst[l]['pfMasterID']));
      if (Number(chkRcdLab.length) > 0) {
        alert("This Labour " + this.insup_wgMst[l]['labRegNo'] + " Resigned.");
        return false;
      }
    }
    return true;
  }
  //#endregion

  //#region add new lab

  new_labList: any = [];
  filter_new_labList: any = [];
  fill_addnewLab_List() {
    this.new_labList = [];
    this.filter_new_labList = [];
    this.clear_addNewLab_data();
    this.ngxService.start();

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getaddnewlab/' + this.toliID + '/' + this.companyID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        this.new_labList = data;
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
      });
  }
  fill_modnewLab_List() {
    let filter: any = {
      "payDt": this.payDate,
      "payType": this.setpayType,
      "Wageyr": this.filterYr,
      "WageMon": this.filterMonth,
      "ToliId": this.toliID,
      "Fayear": this.filterFayear,
      "CompanyID": 1,
    }

    let body = new FormData();
    body = filter;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getaddmodifynewlab';
    this._userService.post_data(url, body).subscribe(
      (data) => {
        this.ngxService.stop();
        
        if (Number(data.length) > 0) {
          for (let l = 0; l < data.length; l++) {
            this.add_newLab(data[l]['labourID'], data[l]['labRegNo'], data[l]['labName'], data[l]['sex'], data[l]['accNo'], data[l]['bankID'])
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
      });
  }
  clear_addNewLab_data() {
    this.add_newLabID = 0;
    this.add_newLabName = '';
    this.add_newLabRegNo = 0;
    this.add_newLabSex = '';
    this.add_newLabAccNo = '';
    this.add_newLabBankID = 0;
    this.add_newLabBankName = '';
  }
  serch_newLab() {
    this.clear_addNewLab_data();
    this.filter_new_labList = this.new_labList.filter((item) => {
      return ((item.labRegNo.toString() + item.labName.toString().toLowerCase()).indexOf(this.searchNewLab.toString().toLowerCase()) > -1);
    });
  }
  searchNewLab: string = '';
  searchNewLabBank: string = '';
  add_newLabID = 0;
  add_newLabName = '';
  add_newLabRegNo = 0;
  add_newLabSex = '';
  add_newLabAccNo = '';
  add_newLabBankID = 0;
  add_newLabBankName = '';

  newLab_bankList: any = [];
  filter_newLab_bankList: any = [];
  set_search_NewLab(labourID, labRegNo, labName, sex, accNo) {
    this.searchNewLab = '';
    this.add_newLabID = labourID;
    this.add_newLabRegNo = labRegNo;
    this.add_newLabName = labName;
    this.add_newLabSex = sex;
    this.add_newLabAccNo = '';
    this.add_newLabBankID = 0;
    this.add_newLabBankName = '';
    this.filter_new_labList = [];
    this.newLab_bankList = [];
    this.filter_newLab_bankList = [];

    //fill labour bank details

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getaddnewlabbankac/' + labourID + '/' + this.companyID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        this.newLab_bankList = data;
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
      });

  }
  serch_newLabBank() {
    this.add_newLabAccNo = '';
    this.add_newLabBankID = 0;
    this.add_newLabBankName = '';

    this.filter_newLab_bankList = this.newLab_bankList.filter((item) => {
      return ((item.bankName.toString().toLowerCase()).indexOf(this.searchNewLabBank.toString().toLowerCase()) > -1);
    });
  }
  set_search_NewLabBank(bankID, bankName, accNo) {
    this.searchNewLabBank = '';
    this.add_newLabBankID = bankID;
    this.add_newLabBankName = bankName;
    this.add_newLabAccNo = accNo;
    this.filter_newLab_bankList = [];

  }
  bind_addNew_Lab(labourID, labRegNo, labName, sex, accNo, bankID) {
    let isValid = true;

    let chkRecexist: any = this.insup_wgMst.filter(x => Number(x.labourID) == Number(labourID));
    if (Number(chkRecexist.length) > 0) {
      alert("This labour " + labRegNo + " allready exists.");
      isValid = false;
      return false;
    }

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getlabpaymonthdiff/' + labourID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        
        if (Number(data) > 6) {
          if (window.confirm('This Labour Not Taking Payment From ' + data + ' Months ! Still Do You Want To Save Record.?')) {
            this.add_newLab(labourID, labRegNo, labName, sex, accNo, bankID);

          }
          else {
            isValid = false;
            return false;
          }
        }
        else {
          this.add_newLab(labourID, labRegNo, labName, sex, accNo, bankID);
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
        isValid = false;
        return false;
      });
  }
  add_newLab(labourID, labRegNo, labName, sex, accNo, bankID) {

    let saveData: any = {
      "LabourID": labourID,
      "ToliID": this.toliID,
      "CompanyID": this.companyID,
      "UserID": this.userID,
      "BankID": bankID,
      "AccNo": accNo,
      "Status": 'Active'
    }

    let body = new FormData();
    body = saveData;

    this.ngxService.start();

    let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/savelabdeployment';
    this._userService.post_data(url, body).subscribe(
      (data) => {
        this.ngxService.stop();

        if (data == 'Record Saved SuccessFully.') {

          let filter: any = {
            "payDt": this.payDate,
            "payType": this.setpayType,
            "Wageyr": this.filterYr,
            "WageMon": this.filterMonth,
            "ToliId": this.toliID,
            "Fayear": this.filterFayear,
            "CompanyID": this.companyID,
          }

          let body = new FormData();
          body = filter;
          //PFLoan

          url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/getautodedlist/yes/' + labourID;
          this._userService.post_data(url, body).subscribe(
            (data) => {
              this.set_addNewLab_PfLoan(data.labPayPFLoanDedList);
              this.set_addNewLab_Patpedi(data.labPayPatPediDedList);
              this.set_addNewLab_Insurance(data.labPayInsuranceDedList);
              this.set_addNewLab_Union(data.labPayUnionDedList);

            },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              alert(err);
              return false;
            });

          this.insup_wgMst.push({
            accNo: accNo,
            actualGrossAmt: 0,
            advAmt: 0,
            advLoanAmt: 0,
            advLoanID: 0,
            authID: 0,
            bankID: bankID,
            brdPF: 0,
            companyID: this.companyID,
            day1Math: "A",
            day2Math: "A",
            day3Math: "A",
            day4Math: "A",
            day5Math: "A",
            day6Math: "A",
            day7Math: "A",
            day8Math: "A",
            day9Math: "A",
            day10Math: "A",
            day11Math: "A",
            day12Math: "A",
            day13Math: "A",
            day14Math: "A",
            day15Math: "A",
            day16Math: "A",
            day17Math: "A",
            day18Math: "A",
            day19Math: "A",
            day20Math: "A",
            day21Math: "A",
            day22Math: "A",
            day23Math: "A",
            day24Math: "A",
            day25Math: "A",
            day26Math: "A",
            day27Math: "A",
            day28Math: "A",
            day29Math: "A",
            day30Math: "A",
            day31Math: "A",
            deduction: 0,
            diffAmt1: 0,
            diffAmt2: 0,
            editgross: "no",
            eduCessAmt: 0,
            fayear: this.filterFayear,
            flag: this.setpayType,
            grossAmt: 0,
            insuranceAmt: 0,
            itCutting: 0,
            labName: labName,
            labRegNo: labRegNo,
            labourID: labourID,
            labourType: "Hamal",
            levyAmt: 0,
            levyCode: 0,
            mathDtID: 0,
            mathID: 0,
            misc: 0,
            netPay: 0,
            panLen: 0,
            patpediAmt1: 0,
            patpediAmt2: 0,
            patpediAmt3: 0,
            payDt: this.payDate,
            pf: 0,
            pfAdvCutting: 0,
            pfAdvint: 0,
            pfLoanID: 0,
            pfMasterID: labourID,
            preGrossAmt: 0,
            predays: 0,
            profTax: 0,
            prvActualGrossAmt: 0,
            prvAdvLoanAmt: 0,
            prvBrdPF: 0,
            prvEduCessAmt: 0,
            prvITCutting: 0,
            prvInsuranceAmt: 0,
            prvPF: 0,
            prvPFAdvCutting: 0,
            prvPFAdvint: 0,
            prvProfTax: 0,
            selrowcolor: "white",
            sex: sex,
            totPrsDay: 0,
            transBankListID: 0,
            unionAmt: 0,
            unionID: 0,
            wageID: 0,
            wageMon: this.filterMonth,
            wageYr: this.filterYr
          })

          this.modalService.dismissAll();
        }
        else {
          return false;
        }

      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        alert(err);
        return false;
      });

  }
  set_addNewLab_PfLoan(data: any = []) {
    
    if (Number(data.length) > 0) {

      for (let l = 0; l < data.length; l++) {
        //alert(Number(data[l]['pfLoanID']));
        let chkRecexist: any = this.pfLoan_mst.filter(x => Number(x.pfLoanID) == Number(data[l]['pfLoanID']));
        if (Number(chkRecexist.length) > 0) {

        }
        else {
          // if (data[l]['pfmaterID'] == labourID){
          this.pfLoan_mst.push({
            balAmt: data[l]['balAmt'],
            instAmt: data[l]['instAmt'],
            intAmt: data[l]['intAmt'],
            loanAmt: data[l]['loanAmt'],
            pfLoanID: data[l]['pfLoanID'],
            pfMasterID: data[l]['pfMasterID'],
            totInst: data[l]['totInst'],
          })
          // }
          
        }
      }

    }
  }
  set_addNewLab_Patpedi(data: any = []) {
    
    if (Number(data.length) > 0) {

      for (let l = 0; l < data.length; l++) {
        //alert(Number(data[l]['pfLoanID']));
        let chkRecexist: any = this.patpediDed_List.filter(x => Number(x.patPediLabdtID) == Number(data[l]['patPediLabdtID']));
        if (Number(chkRecexist.length) > 0) {

        }
        else {
          // if (data[l]['pfmaterID'] == labourID){
          this.patpediDed_List.push({
            actDedAmt: data[l]['actDedAmt'],
            dedAmt: data[l]['dedAmt'],
            labourID: data[l]['labourID'],
            patPediID: data[l]['patPediID'],
            patPediLabdtID: data[l]['patPediLabdtID'],
            patPediName: data[l]['patPediName'],
            patPedilabID: data[l]['patPedilabID']
          })
          // }
          
        }
      }

    }
  }
  set_addNewLab_Insurance(data: any = []) {
    
    if (Number(data.length) > 0) {

      for (let l = 0; l < data.length; l++) {
        //alert(Number(data[l]['pfLoanID']));
        let chkRecexist: any = this.insDed_List.filter(x => Number(x.insPoldtID) == Number(data[l]['insPoldtID']));
        if (Number(chkRecexist.length) > 0) {

        }
        else {
          // if (data[l]['pfmaterID'] == labourID){
          this.insDed_List.push({
            actPolicyAmt: data[l]['actPolicyAmt'],
            compName: data[l]['compName'],
            dedAmt: data[l]['dedAmt'],
            insCompID: data[l]['insCompID'],
            insPolMasID: data[l]['insPolMasID'],
            insPoldtID: data[l]['insPoldtID'],
            labourID: data[l]['labourID'],
            policyNo: data[l]['policyNo'],
          })
          // }
          
        }
      }

    }
  }
  set_addNewLab_Union(data: any = []) {
    
    if (Number(data.length) > 0) {

      for (let l = 0; l < data.length; l++) {
        //alert(Number(data[l]['pfLoanID']));
        let chkRecexist: any = this.unionDed_List.filter(x => Number(x.labourID) == Number(data[l]['labourID']));
        if (Number(chkRecexist.length) > 0) {

        }
        else {
          // if (data[l]['pfmaterID'] == labourID){
          this.unionDed_List.push({
            deductionMon: data[l]['deductionMon'],
            labourID: data[l]['labourID'],
            unionAmt: data[l]['unionAmt'],
            unionID: data[l]['unionID'],
          })
          // }
          
        }
      }

    }
  }
  //#endregion

  //#region update bank & AccNo
  update_bank_accNo() {
    if (window.confirm('Are you sure to update bank & accno.?')) {
      let filter: any = {
        "BindWagemaster": this.insup_wgMst,
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_controlerName(this.Log_BoardID) + '/updatelabbankaccno';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          if (data == 'Record Saved.') {
            alert('Bank & AccNo updated successfully.');
          }
          else {
            alert(data);
          }

        },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });

    }


  }
  //#endregion

  // #region show password authitication modal
  frm_psw: FormGroup;
  submitted = false;
  get f() { return this.frm_psw.controls; }
  onSubmit_pswauth() {
    if (this.frm_psw.controls['txtpsw'].value == 'kit') {
      this.finalize_payment('no');
      this.show_pswModal(false);
    }
    else {
      alert('Enter valid password.')
    }


  }
  showpsw_Modal: boolean;
  show_pswModal(showhide: boolean) {
    this.showpsw_Modal = showhide;
  }
  // #endregion



}
