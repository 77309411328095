
<div id="header">
  <!-- top-bar-section Start -->
  <div class="top-bar-section">
    <div class="container"> <a href="tel:9147397500" class="contact-no"><i class="fa fa-phone" aria-hidden="true"></i> Help Desk Number: <span class="number">0120-4888999</span></a>
      <div class="skip-main-content pull-right">
        <!-- <a href="#skip-content"><i class="fa fa-long-arrow-down" aria-hidden="true"></i> Skip to Main Content</a> -->
        <!-- <div class="text-format"><span>-A</span> <span>A</span> <span>+A</span></div> -->

        <div id="google_translate_element"></div>

        <div class="text-format" *ngIf = "globals.is_login" ><a routerLink="/" (click) ="LogOut()" ><i class="Logout" aria-hidden="true"></i>Logout</a> </div> 
      </div>
    </div>
  </div>
  <!-- top-bar-section End -->
  <!-- logo-pan Start -->
  <div class="container" >
    
    <div class="logo-pan" > <a routerLink="/" routerLinkActive="active" class="contact-no"><img src="assets/images/logo.jpg" alt="gst.gov" title="gst.gov" class="float-left img-fluid logo">{{boardName}}</a>
      <!-- <div class="login-section float-right"  routerLink="/dashboard" routerLinkActive="active"> <img src="assets/images/no-profile-img.png" alt="ANGELS" title="ANGELS"> John Smith<span>223kkf42k3</span> </div>  -->
      <div class="login-section float-right"  *ngIf = "!globals.is_login"  >   <a routerLink="/login"  style="font-size: medium"  > <i class="fa fa-home" aria-hidden="true"></i>Login</a> </div> 
      
      <div class="login-section float-right" *ngIf = "globals.is_login"  routerLink="/" routerLinkActive="active"> <img src="assets/images/no-profile-img.png" alt="{{globals.log_userName}}" title="{{globals.log_userName}}"><span style="text-align: center;">{{globals.log_userName}}
    </span> </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="clearfix"></div>
  <!-- logo-pan End -->
  <!-- Navbar Start -->
  <div class="navbarmain">
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-expand-md navbar-dark">
          <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
          <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav mr-auto">
            
              <!-- <li class="nav-item active"> <a class="nav-link" routerLink="/" routerLinkActive="active"><i class="fa fa-home" aria-hidden="true"></i> <span class="sr-only">(current)</span></a> </li> -->

              <li class="nav-item" *ngIf = "globals.is_login"  > <a class="nav-link" (click)="goto_dashboard()">Dashboard</a> </li>
             
              <li class="nav-item dropdown" ngbDropdown> <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>Services</a>
                <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item sub-sub" href="javascript:void(0)">Registration </a>

                  <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)">Establishment Registration</a>
                    <a class="dropdown-item" href="javascript:void(0)">Labour Registration</a>
                    <!-- <a class="dropdown-item" href="javascript:void(0)">Locate GST Practitioner (GSTP)</a>
                      <a class="dropdown-item" href="javascript:void(0)">Cause List</a> -->
                  </div>

                
                  <a class="dropdown-item sub-sub" data-toggle="dropdown" href="javascript:void(0)">User Services
                    <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)">Generate User for
                        Unregistered Applicant</a>
                      <a class="dropdown-item" href="javascript:void(0)">Holiday List</a>
                    </div>
                  </a>
                  
                  
                  <a *ngIf="globals.is_login == true" class="dropdown-item sub-sub" data-toggle="dropdown" href="javascript:void(0)">Admin
                    <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" (click)="goto_userAuthPage()">Set User
                        Authentication</a>
                    </div>
                  </a>


                   <!-- <a class="dropdown-item sub-sub" data-toggle="dropdown" href="javascript:void(0)">Refunds
                  <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)">Track Application Status</a></div>
                  </a> -->
                </div>
              </li>


              <li class="nav-item dropdown" ngbDropdown> <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>Mathadi Act</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  
                   <a class="dropdown-item sub-sub"  (click)="view_PdfFile('ACT_1969_2012 (2).pdf')">Mathadi Act
               
                  </a>
                  <a class="dropdown-item" (click)="view_PdfFile('ACT_Rules.pdf')" ngbDropdownItem>Act & Rules</a>
                </div>
              </li>

              <!-- <li class="nav-item" ><a  class="nav-link">Mathadi Act</a>
              
                <div class="dropdown-menu" ngbDropdownMenu>
                 
                   <a class="dropdown-item sub-sub" (click)="view_PdfFile('ACT_1969_2012 (2).pdf')">Mathadi Act
                   
                  </a>
                  <a class="dropdown-item" (click)="view_PdfFile('ACT_Rule.pdf')" ngbDropdownItem>Act & Rules</a>
                </div>

              </li> -->


              <li class="nav-item dropdown" ngbDropdown> <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>Downloads</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item sub-sub" href="javascript:void(0)">Circular
                  <!-- <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Scheme</a> <a class="dropdown-item" href="javascript:void(0)">Returns Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">Tran-1 Offline Tools</a> <a class="dropdown-item" href="javascript:void(0)">Tran-2 Offline Tools</a> <a class="dropdown-item" href="javascript:void(0)">GSTR3B Offline Utility</a> <a class="dropdown-item" href="javascript:void(0)">ITC01 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">ITC03 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">ITC04 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GST ARA 01 - Application for Advance Ruling</a> <a class="dropdown-item" href="javascript:void(0)">GSTR 4 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR 6 Offline Tool With Amendments</a> <a class="dropdown-item" href="javascript:void(0)">GSTR 11 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR7 Offline Utility</a> <a class="dropdown-item" href="javascript:void(0)">GSTR8 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR10 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR-9 Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR-9A Offline Tool</a> <a class="dropdown-item" href="javascript:void(0)">GSTR-9C Offline Tool</a> </div> -->
                  </a>
                   <a class="dropdown-item sub-sub" (click)="view_PdfFile('ActiveLabList.pdf')">Labour List
                        <!-- <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)">Establishment List</a>
                          <a class="dropdown-item" href="javascript:void(0)">New GST Return - Sahaj</a>
                          <a class="dropdown-item" href="javascript:void(0)">New GST Return - Sugam</a>
                        </div> -->
                  </a>
                  <a class="dropdown-item" (click)="view_PdfFile('ActiveEstList.pdf')" ngbDropdownItem>Establishment List</a>
                </div>
              </li>
              <li class="nav-item dropdown" ngbDropdown>
                 <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>About Us</a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Overview</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>List of Boards</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Organization Structure</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Working of Board</a>
                      </div>
              </li>
              <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>Help</a>
                    <div class="dropdown-menu" ngbDropdownMenu> <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>FAQ</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>User Manuals</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Videos</a>
                      </div>
              </li>
              <li class="nav-item"> <a class="nav-link">Area of Work</a> </li>
              <li class="nav-item"> <a class="nav-link">Contact Us</a> </li>
              
              <!-- <li class="nav-item active"> <a class="nav-link" routerLink="/" routerLinkActive="active">Dashboard</a> </li> -->
              
              <!-- <li class="nav-item dropdown new-return" ngbDropdown>
                <a class="nav-link dropdown-toggle submenu" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>New Return(Trial)</a>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>New Return Prototype</a>
                      <a class="dropdown-item" href="javascript:void(0)" ngbDropdownItem>Offline Tools for New Return (Trial)</a>
                    </div>
              </li> -->
               
              <li class="nav-item" *ngIf = "!globals.is_login"> <a routerLink="/login" class="nav-link login">Login</a> </li>
              <li class="nav-item" *ngIf = "globals.is_login"> <a routerLink="/" (click) ="LogOut()" class="nav-link login">Logout</a> </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Navbar End -->

  <a name="skip-content" id="skip-content"></a> </div>
