import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { UserService } from '../../service/UserService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Globals} from '../../Globals'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  mobNo:string='';
  OTP:string = '';
  psw:string = '';
  Log_BoardID = 0;
  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals) { 
      this.mobNo = this.route.snapshot.queryParamMap.get('mob');
    }

  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
  }
  update_newpsw(password,confirmpsw){
   // alert(password.toString().length);
   
    if (Number(this.mobNo.toString().length) != 10){
      alert("Enter valid mobile no.")
      return;
    }
    else if (password == ''){
      alert("Enter password.")
      return;
    }
    else if (Number(password.toString().length) < 4){
      alert("Enter minimum 4 characters.")
      return;
    }
    else if (password != confirmpsw){
      alert("Enter password and confirm password not match.")
      return;
    }
    else{
      let usertype:string = sessionStorage.getItem('mathaLog_UserType');

      if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector'){
        let url = 'setstaffnewpsw/' + this.mobNo + '/' + confirmpsw;
        //  alert(url);
        this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
          
          if (data == 'Done') {
            alert('New password updated successfully.')
            //this._userService.staff_login(this.mobNo,confirmpsw,'Staff',0);
            this.router.navigate(['/login']);
          }

        },
          (err: HttpErrorResponse) => {
            //   this.ngxService.stop();
          });

          
      }
      else if (sessionStorage.getItem('mathaLog_UserType') == 'Est') {
        let usertype = 'Est';
        let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/setnewpsw/' + this.mobNo + '/' + confirmpsw;
        //  alert(url);
        this._userService.get_Data(url).subscribe((data: any) => {

          if (Number(data.length) > 0) {
            if (data[0]['estID'] == -1) {
              alert('Mobile number not register.Register your mobile in Mathadi Board.');
            }
            else {
              alert('New password updated successfully.');

              sessionStorage.setItem('mathaUserToken', '');
              sessionStorage.setItem('mathaLog_UserID', data[0]['estID']);
              sessionStorage.setItem('mathaLog_UserType', usertype);

              sessionStorage.setItem('mathaLog_Email', data[0]['emailID']);
              sessionStorage.setItem('mathaLog_UserTypeID', '0');
              sessionStorage.setItem('mathaLog_UserMob', data[0]['mobNo']);
              sessionStorage.setItem('mathaUserName', data[0]['estName']);
              sessionStorage.setItem('mathaLog_BoardID', this.Log_BoardID.toString());
              sessionStorage.setItem('mathaLog_accessFor', '');
              sessionStorage.setItem('mathafilterBoradID', this.Log_BoardID.toString());
              sessionStorage.setItem('mathaLog_UserType', usertype);

              this.globals.log_userName = data[0]['estName'];
              this.globals.is_login = true;
              this.router.navigate(['dashboard/est-dashboard']);
            }


          }

        },
          (err: HttpErrorResponse) => {
            //   this.ngxService.stop();
          });
      }
      else if (sessionStorage.getItem('mathaLog_UserType') == 'Union') {
        let usertype = 'Union';
        let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/setnewpsw/' + this.mobNo + '/' + confirmpsw;
        //  alert(url);
        this._userService.get_Data(url).subscribe((data: any) => {

          if (Number(data.length) > 0) {
            if (data[0]['unionID'] == -1) {
              alert('Mobile number not register.Register your mobile in Mathadi Board.');
            }
            else {
              alert('New password updated successfully.');

              sessionStorage.setItem('mathaUserToken', '');
              sessionStorage.setItem('mathaLog_UserID', data[0]['unionID']);
              sessionStorage.setItem('mathaLog_UserType', usertype);

              sessionStorage.setItem('mathaLog_Email', data[0]['emailID']);
              sessionStorage.setItem('mathaLog_UserTypeID', '0');
              sessionStorage.setItem('mathaLog_UserMob', data[0]['mobNo']);
              sessionStorage.setItem('mathaUserName', data[0]['compName']);
              sessionStorage.setItem('mathaLog_BoardID', this.Log_BoardID.toString());
              sessionStorage.setItem('mathaLog_accessFor', '');
              sessionStorage.setItem('mathafilterBoradID', this.Log_BoardID.toString());
              sessionStorage.setItem('mathaLog_UserType', usertype);

              this.globals.log_userName = data[0]['compName'];
              this.globals.is_login = true;
              this.router.navigate(['dashboard/unioncomp-dashboard']);
            }


          }

        },
          (err: HttpErrorResponse) => {
            //   this.ngxService.stop();
          });
      }
      
      }
      

  }

}
