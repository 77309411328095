import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { UserService } from '../../service/UserService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Globals} from '../../Globals'

@Component({
  selector: 'app-verifiedcode',
  templateUrl: './verifiedcode.component.html',
  styleUrls: ['./verifiedcode.component.css']
})
export class VerifiedcodeComponent implements OnInit {
  mobNo:string='';
  OTP:string = '';
  psw:string = '';
  mobno_slice:string = '';
  maxTime: any=0;
  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals) { 
      this.mobNo = this.route.snapshot.queryParamMap.get('mob');
      if (this.mobNo.length == 10){
        this.mobno_slice = "xxxxxxx" + this.mobNo.slice(7, 10); 
      }
      else{
        this.mobno_slice = "xxxxxxx"; 
      }
      
      this.maxTime = 20;
      this.StartTimer();
    }

    ngOnInit(): void {
      if (sessionStorage.getItem('Mob_OTP') != null && sessionStorage.getItem('Mob_OTP') != ''){
         this.OTP = sessionStorage.getItem('Mob_OTP');
       }
      
       
     }
     verify_OTP(txtopt){
       if (txtopt == this.OTP){
         this.ngxService.start();
         let usertype:string = sessionStorage.getItem('mathaLog_UserType');
         if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector' || sessionStorage.getItem('mathaLog_UserType') == 'Est' || sessionStorage.getItem('mathaLog_UserType') == 'Union') {
          let parameters = {
            mob: this.mobNo
            
          };
          
          this.router.navigate(['/resetpassword'], { queryParams: parameters });
         }
         this.ngxService.stop();
       }
       else{
         alert('Invalid OTP')
       }
     }
   resend_OTP(){
    let otpval:any = "********####";//Math.floor(Math.random() * 9000) + 1000;
    otpval = this._userService.getMobileotp(this.mobNo);
    this.OTP = otpval;
    
    sessionStorage.setItem('Mob_OTP', otpval.toString());
    this.maxTime = 20;
   }
     timer:any=0;
  StartTimer(){
    this.timer = setTimeout(x => 
      {
          if(this.maxTime <= 0) { 

          }
          else{
           this.maxTime -= 1; 
          }
          
          if(this.maxTime>0){
           // this.hidevalue = false;
            this.StartTimer();
          }
          else{
             // this.hidevalue = true;
          }
       
      }, 1000);
      

  }
     
}
