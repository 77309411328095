<div  class="notranslate">

<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboard/clerk-dashboard">Clerk
                        Dashboard</a></li>
                <li class="breadcrumb-item " aria-current="page">Paid Bank List </li>
            </ol>
        </nav>
    </div>

    <ngb-tabset [destroyOnHide]="false">

        <ngb-tab>
            <ng-template ngbTabTitle>Paid Bank Authorization</ng-template>
            <ng-template ngbTabContent>
                <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                    <ngb-panel title="Pay Details" id="config-panel-one">
                      <ng-template ngbPanelContent>
                        <div class="row">
                         
                          <div class="col-md-3" style="text-align: left;">
                            <label for="Financial-Year">From Date </label>
                            <input type="date" class="form-control" />
                          </div>
                          <div class="col-md-3">
                            <label for="Financial-Year">To Date </label>
                            <input type="date" class="form-control" />
                         
                          </div>
                          <div class="col-md-3">
                           <br>
                           <button type="button" class="btn btn-primary" >Show</button>
                         
                          </div>
                        </div>
                        <br>
                        <a href="javascript:void(0)" style="color: blue;"  >Select All</a>
                        <br>
                
                        <div class="row">
                            <table class="table table-hover table-responsive-sm labour-table table-striped ">
                            <thead>
                                <tr>
                                    <th class="th-sm">select</th>
                                    <th class="th-sm">Gat No.</th>
                                    <th class="th-sm">Name</th>
                                    <th class="th-sm">Wage Year/Month</th>
                                    <th class="th-sm">Pay Date</th>
                                    <th class="th-sm">Rec.Wage</th>
                                    <th class="th-sm">Paid Wage</th>
                                    <th class="th-sm">Netpay</th>
                                    <th class="th-sm">IT Ded.</th>
                                    <th class="th-sm">PAN Card</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                               
                
                                <tr class="sub-tr">
                                    <td><input  type="checkbox" ></td>
                                    <td>1</td>
                                    <td style="text-align: left;">Test</td>
                                    <td>Dec-2021</td>
                                    <td>05/01/2022</td>
                                    <td>10000</td>
                                    <td>10000</td>
                                    <td>9000</td>
                                    <td>No</td>
                                    <td>No</td>
                                </tr>
                
                            </tbody>
                            <tfoot>
                                <tr Class="table-footer">
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: left;"></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>10000</td>
                                    <td>10000</td>
                                    <td>9000</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                        </div>
                
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3" style="text-align: right;">
                                    <label for="Financial-Year">Authorize Date </label>
                                </div>
                                <div class="col-md-3" style="text-align: right;">
                                    
                                     <input type="date" class="form-control" />
                                </div>
                                <div class="col-md-6" style="text-align: left;">
                                  
                                   <button type="button" class="btn btn-primary" >Submit</button>
                                </div>
                            </div>
                      
                            
                           
                        </div>
                
                       
                      </ng-template>
                    </ngb-panel>
                
                    <ngb-panel title="Labour Bank A/C Not Exist Details" id="config-panel-one">
                        <ng-template ngbPanelContent>
                          
                          <div class="row">
                              <table class="table table-hover table-responsive-sm labour-table table-striped ">
                              <thead>
                                  <tr>
                                      <th class="th-sm">Lab.RegNo.</th>
                                      <th class="th-sm">Lab. Name</th>
                                      <th class="th-sm">Wage Year/Month</th>
                                      <th class="th-sm">Pay Date</th>
                                      <th class="th-sm">Gat No.</th>
                                      <th class="th-sm">Gat Name</th>
                                     
                                      
                                  </tr>
                              </thead>
                              <tbody>
                                 
                  
                                  <tr class="sub-tr">
                                     
                                      <td>101</td>
                                      <td style="text-align: left;">Test Lab</td>
                                      <td>Dec-2021</td>
                                      <td>05/01/2022</td>
                                      <td>1</td>
                                      <td>Test</td>
                                   
                                  </tr>
                  
                              </tbody>
                            
                          </table>
                          </div>
                  
                        </ng-template>
                      </ngb-panel>
                
                  </ngb-accordion>
             </ng-template>
        </ngb-tab>

        <ngb-tab>
            <ng-template ngbTabTitle>Un-Paid Bank Authorization</ng-template>
            <ng-template ngbTabContent>
                <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                    <ngb-panel title="Pay Details" id="config-panel-one">
                      <ng-template ngbPanelContent>
                        <div class="text-center">
                           <div class="row">
                            <div class="col-md-12">
                                <button type="button" class="btn btn-primary" >Show</button>
                            </div>
                        </div> 
                        </div>
                        
                        <br>
                        <a href="javascript:void(0)" style="color: blue;"  >Select All</a>
                        <br>
                
                        <div class="row">
                            <table class="table table-hover table-responsive-sm labour-table table-striped ">
                                <thead>
                                    <tr>
                                        <th class="th-sm">Select</th>
                                        <th class="th-sm">Lab.RegNo.</th>
                                        <th class="th-sm">Lab. Name</th>
                                        <th class="th-sm">Wage Year/Month</th>
                                        <th class="th-sm">Pay Date</th>
                                        <th class="th-sm">Netpay</th>
                                        <th class="th-sm">Gat No.</th>
                                        <th class="th-sm">Gat Name</th>
                                        <th class="th-sm">Bank Name</th>
                                        <th class="th-sm">A/C No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                    
                                    <tr class="sub-tr">
                                        <td><input  type="checkbox" ></td>
                                        <td>101</td>
                                        <td style="text-align: left;">Test Lab</td>
                                        <td>Dec-2021</td>
                                        <td>05/01/2022</td>
                                        <td>5000</td>
                                        <td>1</td>
                                        <td>Test</td>
                                        <td>SBI</td>
                                        <td>0020152</td>
                                    </tr>
                    
                                </tbody>
                              
                            </table>
                        </div>
                
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3" style="text-align: right;">
                                    <label for="Financial-Year">Authorize Date </label>
                                </div>
                                <div class="col-md-3" style="text-align: right;">
                                    
                                     <input type="date" class="form-control" />
                                </div>
                                <div class="col-md-6" style="text-align: left;">
                                  
                                   <button type="button" class="btn btn-primary" >Submit</button>
                                </div>
                            </div>
                      
                            
                           
                        </div>
                
                       
                      </ng-template>
                    </ngb-panel>
                
                   
                
                  </ngb-accordion>
             </ng-template>
        </ngb-tab>

        <ngb-tab>
            <ng-template ngbTabTitle>Paid Bank Un-Authorization</ng-template>
            <ng-template ngbTabContent>
                <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                    <ngb-panel title="Pay Details" id="config-panel-one">
                      <ng-template ngbPanelContent>
                        <div class="row">
                         
                          <div class="col-md-3" style="text-align: left;">
                            <label for="Financial-Year">From Date </label>
                            <input type="date" class="form-control" />
                          </div>
                          <div class="col-md-3">
                            <label for="Financial-Year">To Date </label>
                            <input type="date" class="form-control" />
                         
                          </div>
                          <div class="col-md-3">
                           <br>
                           <button type="button" class="btn btn-primary" >Show</button>
                         
                          </div>
                        </div>
                        <br>
                        <a href="javascript:void(0)" style="color: blue;"  >Select All</a>
                        <br>
                
                        <div class="row">
                            <table class="table table-hover table-responsive-sm labour-table table-striped ">
                            <thead>
                                <tr>
                                    <th class="th-sm">select</th>
                                    <th class="th-sm">Gat No.</th>
                                    <th class="th-sm">Name</th>
                                    <th class="th-sm">Wage Year/Month</th>
                                    <th class="th-sm">Pay Date</th>
                                    <th class="th-sm">Rec.Wage</th>
                                    <th class="th-sm">Paid Wage</th>
                                    <th class="th-sm">Netpay</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                               
                
                                <tr class="sub-tr">
                                    <td><input  type="checkbox" ></td>
                                    <td>1</td>
                                    <td style="text-align: left;">Test</td>
                                    <td>Dec-2021</td>
                                    <td>05/01/2022</td>
                                    <td>10000</td>
                                    <td>10000</td>
                                    <td>9000</td>
                                   
                                </tr>
                
                            </tbody>
                            <tfoot>
                                <tr Class="table-footer">
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: left;"></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>10000</td>
                                    <td>10000</td>
                                    <td>9000</td>
                                    
                                </tr>
                            </tfoot>
                        </table>
                        </div>
                
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3" style="text-align: right;">
                                    <label for="Financial-Year">Un-Authorize Date </label>
                                </div>
                                <div class="col-md-3" style="text-align: right;">
                                    
                                     <input type="date" class="form-control" />
                                </div>
                                <div class="col-md-6" style="text-align: left;">
                                  
                                   <button type="button" class="btn btn-primary" >Submit</button>
                                </div>
                            </div>
                      
                            
                           
                        </div>
                
                       
                      </ng-template>
                    </ngb-panel>
                
                   
                
                  </ngb-accordion>
            </ng-template>
        </ngb-tab>

    </ngb-tabset>



</div>
</div>
