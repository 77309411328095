<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Union Office Dashboard</li>
                <!-- <li>{{tempdate}}</li> -->
            </ol>
        </nav>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
        <ngb-panel title="Register New Labour" id="config-panel-one">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" *ngIf="labserchCnt">
                            <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-lab" aria-describedby="search-lab"
                                    [(ngModel)]="searchLab" (input)="serch_lab()"
                                    placeholder="Enter text to search labour" autofocus>

                                <ul class="list-group list-group-flush anyClass">
                                    <li class="list-group-item nav-item" *ngFor="let rec of filter_lab" appHighlight
                                        [searchedWord]="searchLab" [content]="rec.labRegNo + ' - ' + rec.labName"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="set_searchLab_Cnt(rec.labourID,rec.labRegNo,rec.labName);"
                                        style="background-color: #dcf9f6;">
                                        {{rec.labRegNo}} - {{rec.labName}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="labserchCnt !='yes'">
                        <div style="color: blue;">Labour Info : {{labInfo}}
                            <a href="javascript:void(0)" *ngIf="filter_lab.length > 0"><i class="fa fa-edit"
                                    ngbTooltip="Edit" aria-hidden="true" (click)="show_serchlab_Cnt()"></i></a>
                        </div>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <input #fileInput type="file" style="display: inline-block;" (change)="set_uploadfile($event)"
                            (click)="$event.target.value=null;uploadFileName=''" placeholder="Upload file">
                    </div>

                </div>

                <div style="text-align: center;">
                    <button type="button" class="btn btn-info" (click)="save_addNewLab()">Submit</button>

                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel title="Register Labour List">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-6" style="margin-top: 18px;text-align: center;">
                        <button type="button" class="btn btn-primary" (click)="fill_Reg_Lab_List()">Show</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" *ngIf="regLab_List.length > 0" class="btn btn-info"
                            style="margin-top: 28px;" (click)="exportexcel_regLbaList()">Download Excel File</button>
                    </div>
                </div>

                <div class="row" *ngIf="regLab_List.length > 0">
                    <div class="table-responsive" style="height: 400px;" >
                        <table id="regLab-List-table" style="margin-left: 15px;" class="table table-bordered">
                            <thead class="thead-dark">
                                <tr>
                                    <td colspan="2" style="text-align: center;">
                                        <b>{{boardName}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align: center;">
                                        <b>Union Labour Register List</b>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Reg.No. </th>
                                    <th style="text-align: left;">Name </th>
                            </thead>

                            <tr *ngFor='let rec of regLab_List;let i = index' class="sub-tr border">
                                <td>{{rec.labRegNo}} </td>
                                <td style="text-align: left;">{{rec.labName}} </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel title="Approval Pending From Board">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-12" style="margin-top: 18px;text-align: center;">
                        <button type="button" class="btn btn-primary"
                            (click)="fill_approval_pendingList()">Show</button>
                    </div>
                </div>

                <div class="row" *ngIf="lab_approval_pendList.length > 0">
                    <h1 style="margin-left: 50px;margin-top: 20px; color: blue;">Union Approval Pending (To be approved
                        by board)</h1>
                    <table style="margin-left: 15px;" class="table table-hover table-responsive-xl labour-table">
                        <thead>
                            <!-- <th> </th> -->
                            <th>Reg.No. </th>
                            <th>Name </th>
                            <th>Status</th>
                            <th>Remark</th>
                            <th>Document</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </thead>
                        <tr *ngFor='let rec of lab_approval_pendList;let i = index' class="sub-tr border">

                            <td>{{rec.labRegNo}} </td>
                            <td *ngIf="rec.isEdit == false">{{rec.labName}} </td>

                            <td *ngIf="rec.isEdit == true" style="width: 380px;"><input type="text"
                                    (input)="search_approval_upload_labname($event,i)" class="form-control"
                                    value="{{rec.labName}}" />
                                <ul class="list-group list-group-flush anyClass"
                                    *ngIf="filter_approval_uploadLabName.length > 0 && selcted_approval_cntrIndex == i">
                                    <li class="list-group-item nav-item"
                                        *ngFor="let serchrec of filter_approval_uploadLabName" appHighlight
                                        [searchedWord]="serchrec.labRegNo + ' - ' + serchrec.labName"
                                        [content]="serchrec.labRegNo + ' - ' + serchrec.labName"
                                        [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                        (click)="set_approval_editlabNamel(serchrec.labName,serchrec.labRegNo,serchrec.labourID,i);"
                                        style="background-color: #dcf9f6;text-align: left;color: blue;">
                                        {{serchrec.labRegNo}} - {{serchrec.labName}}
                                    </li>
                                </ul>
                            </td>

                            <td>{{rec.status}}</td>
                            <td>{{rec.remark}}</td>
                            <td *ngIf="rec.isEdit == false"><i class="fa fa-file" ngbTooltip="view file"
                                    (click)="view_uploadFile(rec.docName)" aria-hidden="true" style="color:black;"> </i>
                            </td>
                            <td *ngIf="rec.isEdit == true"><input type="file" style="display: inline-block;"
                                    (change)="set_approval_updateData($event,'doc',i,rec.labourID,'','')"
                                    (click)="$event.target.value=null" placeholder="Upload file"> </td>
                            <td *ngIf="rec.isEdit == false"><i class="fa fa-edit" ngbTooltip="Edit file"
                                    aria-hidden="true" style="color: blue;"
                                    (click)="set_approval_pendList_editcontrol(i,true)"></i> </td>
                            <td><a *ngIf="rec.isEdit == true" style="color: blue;"
                                    (click)="update_approval_pending_List(i)">Update</a>
                            </td>
                            <td><i *ngIf="rec.isEdit == true" class="fas fa-times" ngbTooltip="Cancle edit"
                                    aria-hidden="true" style="color: red;"
                                    (click)="set_approval_pendList_editcontrol(i,false)"></i></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel title="Download Deduction List">
            <ng-template ngbPanelContent>
                <form class="search">
                    <div class="row">

                        <div class="col-md-3">
                            <label for="Financial-Year">Year</label>
                            <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
                                <option *ngFor='let yr of YrList' [value]="yr.dedYear"
                                    [selected]="yr.dedYear=== filterFayear">
                                    {{yr.dedYear}}</option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <label for="month-year">Month</label>
                            <select (change)="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
                                <option *ngFor='let mon of MonthList' [value]='mon.mon'
                                    [selected]="mon.mon=== filterMonth">
                                    {{mon.dispmon}}</option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                (click)="fill_unionDedList()">Show</button>

                        </div>
                        <div class="col-md-3">

                            <button type="button" *ngIf="union_DedList.length > 0" class="btn btn-info"
                                style="margin-top: 28px;" (click)="exportexcel()">Download Excel File</button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive" style="height: 400px;">
                    <table id="excel-table" class="table table-bordered" *ngIf="union_DedList.length > 0">

                        <thead class="thead-dark">
                            <tr>
                                <td colspan="5" style="text-align: center;">
                                    <b>{{boardName}}</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: center;">
                                    <b>Union Deduction List {{strMonthName}} / {{filterYr}}</b>
                                </td>
                            </tr>
                            <tr>
                                <th>Sr.No.</th>
                                <th>RegNo.</th>
                                <th>Name</th>
                                <th>Ded. Amt.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let rec of union_DedList;let i = index;'>

                                <td>{{i + 1}} </td>
                                <td>{{rec.labRegNo}}</td>
                                <td>{{rec.labName}}</td>
                                <td>{{rec.dedAmt}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr Class="table-footer">
                                <td></td>
                                <td></td>
                                <td><b>Total</b></td>
                                <td><b>{{getTotal(union_DedList,'dedAmt')}}</b></td>

                            </tr>
                        </tfoot>

                    </table>
                </div>
           </ng-template>
        </ngb-panel>
    </ngb-accordion>

</div>