import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/Globals';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { data } from 'jquery';

@Component({
  selector: 'app-labour-dashboard',
  templateUrl: './labour-dashboard.component.html',
  styleUrls: ['./labour-dashboard.component.css']
})
export class LabourDashboardComponent implements OnInit {
  expandContent = true;
  public dasbordfor: string = '';
  public YrList: any = [];
  public MonthList: any = [];
  public boardlist: any = [];
  public labourData: any = [];
  public brdwise_labData: any = [];
  public branchwise_labData: any = [];
  public filter_newApll_labData: any = [];
  public monthl_labData: any = [];
  public filterFayear;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;
  public UserType: string = '';
  public Applstatus:string = '';
  public Log_UserID: number = 0;
  constructor(
    config: NgbAccordionConfig,
    private _userService: UserService,
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.filterBoradID = Number(this.route.snapshot.queryParamMap.get('BoradID'));
    this.filterMonth = Number(this.route.snapshot.queryParamMap.get('month'));
    this.filterYr = Number(this.route.snapshot.queryParamMap.get('year'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.dasbordfor = this.route.snapshot.queryParamMap.get('dasbordfor');

    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }
  }
  go_dashboard() {
    //   let parameters = { 
    //     BoradID : this.filterBoradID,
    //     month: this.filterMonth,
    //     year: this.filterYr,
    //     fayear: this.filterFayear,

    //  };
    //this.router.navigate(['/dashboard'], { queryParams : parameters });

    sessionStorage.setItem('mathaLog_BoardID', this.filterBoradID.toString())
    sessionStorage.setItem('mathafilterMonth', this.filterMonth.toString())
    sessionStorage.setItem('mathafilterYr', this.filterYr.toString())
    sessionStorage.setItem('mathafilterFayear', this.filterFayear.toString())


  }
  ngOnInit(): void {
    if (this.UserType == 'Inspector'){
      this.Applstatus = 'Pending'
    }
    else if (this.UserType == 'Secretary'){
      this.Applstatus = 'Inspector Approve'
    }
    else if (this.UserType == 'Chairman'){
      this.Applstatus = 'Secretary Approve'
    }
    else if (this.UserType == 'HO'){
      this.Applstatus = 'NA'
    }

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }

    console.log('this.Applstatus',this.Applstatus)

    this.fill_FinYr();
    this.Get_AllBoard();

  }
  fill_Data() {
    // this.clear_Data();
    if (this.dasbordfor == 'Labour' || this.dasbordfor == 'LabourAppl') {

      this.get_LabourDetails();
    }
    else if (this.dasbordfor == 'Establishment' || this.dasbordfor == 'EstAppl') {
      this.get_EstDetails();
    }
    else if (this.dasbordfor == 'NonRefAppl') {
      this.show_approve_btn = 'no';
      this.get_NonRef_Lab_Details();
    }
    else if (this.dasbordfor == 'WagesLevy') {
      this.get_WLDetails();
    }
    else if (this.dasbordfor == 'FD') {
      this.get_FDDetails()
    }


  }

  fill_FinYr() {
    this.YrList = [];
    //this.filterFayear = 0;
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getFayear').subscribe(
      (data) => {
        this.YrList = data;
        // this.filterFayear = Number(this.YrList[data.length - 1]['fayear']);

        //this.fill_Month(this.YrList[data.length - 1]['fayear']);

        if (this.filterFayear > 0) {
          this.fill_Month(this.filterFayear);
        }
        else {
          this.fill_Month(this.YrList[data.length - 1]['fayear']);
        }
        this.show_loadingModal(false);
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
    //console.log(this.filterFayear);
    // for (let i = 1970; i < 2051; i++) {
    //   this.YrList.push(i);
    // }
  }
  Set_Fayear(event) {
    this.fill_Month(event.target.value);
    this.filterFayear = event.target.value;
    this.clear_Data();
    this.fill_Data();
  }
  Set_FiterYrMon(event) {
    this.filterMonth = Number(event.target.value);
    //alert(this.filterMonth)
    if (Number(this.filterMonth) < 4) {
      this.filterYr = this.filterFayear + 1
    }
    else {
      this.filterYr = this.filterFayear
    }
    if (this.filterMonth == 0) {
      this.filterYr = 0;
    }
    this.clear_Data();
    this.fill_Data();
  }
  Set_FilterBoardID(event) {
    this.filterBoradID = Number(event.target.value);
    this.clear_Data();
    this.fill_Data();
  }
  Get_AllBoard() {
    // this.filterBoradID = 0;
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getallboardname').subscribe(
      (data) => {
        this.show_loadingModal(false);
        this.boardlist = data;
        // this.filterBoradID = 0;//Number(this.boardlist[0]['boardID']);

        //only NskMatha Working
        this.boardlist = this.boardlist.filter(x => x.boardID == 6);

        this.fill_Data();
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
    //this.clear_Data();
  }

  fill_Month(Yr) {
    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "Apr-" + Yr },
      { mon: 5, yr: Yr, dispmon: "May-" + Yr },
      { mon: 6, yr: Yr, dispmon: "Jun-" + Yr },
      { mon: 7, yr: Yr, dispmon: "Jul-" + Yr },
      { mon: 8, yr: Yr, dispmon: "Aug-" + Yr },
      { mon: 9, yr: Yr, dispmon: "Sep-" + Yr },
      { mon: 10, yr: Yr, dispmon: "Oct-" + Yr },
      { mon: 11, yr: Yr, dispmon: "Nov-" + Yr },
      { mon: 12, yr: Yr, dispmon: "Dec-" + Yr },
      { mon: 1, yr: Yr + 1, dispmon: "Jan-" + (Number(Yr) + 1) },
      { mon: 2, yr: Yr + 1, dispmon: "Feb-" + (Number(Yr) + 1) },
      { mon: 3, yr: Yr + 1, dispmon: "Mar-" + (Number(Yr) + 1) },
    ];

    // this.clear_Data();
  }
  lab_opnActiveCnt: any = [];
  lab_newApllList: any = [];
  public get_LabourDetails() {
    this.show_loadingModal(true);

    this.lab_opnActiveCnt = [];
    this.labourData = [];
    this.brdwise_labData = [];
    this.lab_newApllList = [];

    let url: string = 'get_MonthWiseLabMonthly/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
    // console.log(url);
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        console.log(data);
        this.labourData = data.clsLabourMonthly;
        this.lab_newApllList = data.lab_Master;
        this.filter_newApll_labData = data.lab_Master;
        this.show_loadingModal(false);

        /////////////Boardwise Lab Deatil
        this.show_loadingModal(true);

        url = 'get_BrdWiseLabMonthly/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID+ '/' + this.Applstatus;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {
            this.show_loadingModal(false);
            this.brdwise_labData = data;
            console.log('brdwise_labData' ,this.brdwise_labData )
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });


      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

  }
  private get_monthname(month) {
    if (month == 4) {
      return 'Apr'
    }
    else if (month == 5) {
      return 'May'
    }
    else if (month == 6) {
      return 'Jun'
    }
    else if (month == 7) {
      return 'Jul'
    }
    else if (month == 8) {
      return 'Aug'
    }
    else if (month == 9) {
      return 'Sep'
    }
    else if (month == 10) {
      return 'Oct'
    }
    else if (month == 11) {
      return 'Nov'
    }
    else if (month == 12) {
      return 'Dec'
    }
    else if (month == 1) {
      return 'Jan'
    }
    else if (month == 2) {
      return 'Feb'
    }
    else if (month == 3) {
      return 'Mar'
    }
    else {
      return ''
    }
  }

  estData: any = [];
  brdwise_estData: any = [];
  branchwise_estData: any = [];
  est_newApllList: any = [];
  filter_newApll_estData: any = [];
  public get_EstDetails() {

    this.show_loadingModal(true);
    this.estData = [];
    this.branchwise_estData = [];
    this.brdwise_estData = [];
    this.est_newApllList = [];
    this.filter_newApll_estData=[];

    this.wlData = [];
    this.brdwise_wlData = [];
    this.branchwise_wlData = [];

    let url: string = 'get_MonthWiseEstMonthlycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID+ '/' + this.Applstatus;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        // this.estData = data;
        // this.wlData = data;

        console.log('estApllDt',data)

        this.estData = data.clsEstCollection;
        this.wlData = data.clsEstCollection;

        this.est_newApllList = data.est_Master;
        this.filter_newApll_estData = data.est_Master;

        console.log('estApllDt',this.filter_newApll_estData)

        this.show_loadingModal(false);

        this.show_loadingModal(true);
        url = 'get_BrdWiseEstMonthlycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {
            this.brdwise_estData = data;
            this.brdwise_wlData = data;
            this.show_loadingModal(false);
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

  }
  wlData: any = [];
  brdwise_wlData: any = [];
  branchwise_wlData: any = [];
  public get_WLDetails() {
    this.get_EstDetails();

    // this.show_loadingModal(true);
    // this.estData = [];
    // this.brdwise_wlData = [];
    // this.branchwise_wlData = [];
    // let url: string = 'get_MonthWiseMonthlyWagesLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.wlData = data;
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

    // url = 'get_BrdWiseMonthlyWagesLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.brdwise_wlData = data;
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

    // url = 'getBranchWiseWageLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.show_loadingModal(false);
    //     this.branchwise_wlData = data;

    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

  }

  fdData: any = [];
  brdwise_fdData: any = [];
  baranchwise_fdData: any = [];
  public get_FDDetails() {
    this.show_loadingModal(true);
    this.fdData = [];
    this.brdwise_fdData = [];
    this.baranchwise_fdData = [];
    let url: string = 'get_MonthFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {

        this.fdData = data;

        url = 'get_BrdFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {

            this.brdwise_fdData = data;
            // this.baranchwise_fdData = data;
            this.show_loadingModal(false);
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });


      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

    // console.log('fddata url :', url);
    // console.log('fddata :', this.fdData);




    // url = 'get_MonthFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.baranchwise_fdData = data;
    // });
  }
  clear_Data() {
    this.labourData = [];
    this.brdwise_labData = [];
    this.branchwise_labData = [];
    this.estData = [];
    this.branchwise_estData = [];
    this.brdwise_estData = [];
    this.estData = [];
    this.brdwise_wlData = [];
    this.branchwise_wlData = [];
    this.fdData = [];
    this.brdwise_fdData = [];
    this.baranchwise_fdData = [];
  }

  findDetails(data) {
    if (this.filterBoradID == 0) {
      if (this.dasbordfor == 'Labour') {
        return this.brdwise_labData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'Establishment') {
        return this.brdwise_estData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'WagesLevy') {
        return this.brdwise_wlData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'FD') {
        return this.brdwise_fdData.filter(x => x.month === data.month);
      }
    }
    else {
      if (this.dasbordfor == 'Labour') {
        return this.branchwise_labData.filter(x => x.month === data.month);
      }
      if (this.dasbordfor == 'NewApplLabour') {
        return this.filter_newApll_labData.filter(x => x.month === data.month);
      }
      //
      else if (this.dasbordfor == 'Establishment') {
        return this.branchwise_estData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'WagesLevy') {
        return this.branchwise_wlData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'FD') {
        return this.baranchwise_fdData.filter(x => x.month === data.month);
      }
    }

  }

  getTotal(data, fldname) {
    if (fldname == 'newResigstration') {
      return data.reduce((sum, curr) => sum + curr.newResigstration, 0);
    }
    else if (fldname == 'newApplication') {
      return data.reduce((sum, curr) => sum + curr.newApplication, 0);
    }
    else if (fldname == 'resigned') {
      return data.reduce((sum, curr) => sum + curr.resigned, 0);
    }
    else if (fldname == 'death') {
      return data.reduce((sum, curr) => sum + curr.death, 0);
    }
    else if (fldname == 'cancellation') {
      return data.reduce((sum, curr) => sum + curr.cancellation, 0);
    }
    else if (fldname == 'estnewRegistration') {
      return data.reduce((sum, curr) => sum + curr.newRegistration, 0);
    }
    else if (fldname == 'preCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.preCollectionAmt, 0);
    }
    else if (fldname == 'penaltyCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.penaltyCollectionAmt, 0);
    }
    else if (fldname == 'pendingCollection') {
      return data.reduce((sum, curr) => sum + curr.pendingCollection, 0);
    }
    else if (fldname == 'wagesCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.wagesCollectionAmt, 0);
    }
    else if (fldname == 'levyCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.levyCollectionAmt, 0);
    }
    else if (fldname == 'newFDAmt') {
      return data.reduce((sum, curr) => sum + curr.newFDAmt, 0);
    }
    else if (fldname == 'renewalFDAmt') {
      return data.reduce((sum, curr) => sum + curr.renewalFDAmt, 0);
    }
    else if (fldname == 'withdrwalFDAmt') {
      return data.reduce((sum, curr) => sum + curr.withdrwalFDAmt, 0);
    }
    else {
      return 0;
    }
  }
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  show_newLabApplDt(mobno){
    let parameters = {
      labID: 0,
      mob: mobno,
      boardID:this.filterBoradID
     };

     if (this.filterBoradID == 6) {
      this.router.navigate(['reg-lab-pimmatha'], { queryParams: parameters });
      
    }
    else{
      alert('काम प्रगतीपथावर आहे.लवकरच कार्यरत होईल.');
    }
  }
  show_newEstApplDt(mobno){
    let parameters = {
      estID: 0,
      mob: mobno,
      boardID:this.filterBoradID
     };

   if (this.filterBoradID == 6) {
      this.router.navigate(['reg-est-pimmatha'], { queryParams: parameters });
      
    }
    else{
      alert('काम प्रगतीपथावर आहे.लवकरच कार्यरत होईल.');
    }
  }


//#region non Ref Adv
public get_NonRef_Lab_Details() {
  this.nonRef_AuthSelunSel = "Select All";
  this.show_approve_btn = 'no';
  this.show_loadingModal(true);
  this.nonReflab_approval_pendList = [];
  this.All_nonReflab_approvalDoc_List = [];

  let url: string = 'get_MonthWiseLabMonthly/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
  // console.log(url);
  this._userService.get_Dashborad_Data(url).subscribe(
    (data) => {
      //console.log('nonRef_monthlydata', data);
      this.nonReflab_approval_pendList = data.pfAdvanceAppl;
      this.All_nonReflab_approvalDoc_List = data.pfLoanDocList;

     // console.log('nonReflab_approval_pendList',this.nonReflab_approval_pendList);
      if (Number(this.nonReflab_approval_pendList.length) > 0){
        this.show_approve_btn = 'yes';
      }
   

      this.show_loadingModal(false);

    },
    (err: HttpErrorResponse) => {
      this.show_loadingModal(false);
      alert('something went wrong');
      return;
    });

}
nonReflab_approval_pendList:any=[];
All_nonReflab_approval_pendList: any = [];
All_nonReflab_approvalDoc_List: any = [];
filter_nonReflab_approvalDoc_List: any = [];
show_approve_btn:any = 'no';
update_new_nonRef_Accept() {
  this.show_approve_btn = 'no';
  let update_Rej_Nonref = [];
  let isSave:any = true;
  if (this.nonReflab_approval_pendList.length > 0) {

    let Status:string= ''
    if (this.UserType == 'Secretary') {
      Status = 'Secretary Approve';
    }
    else if (this.UserType == 'Chairman') {
      Status = 'Chairman Approve'
    }


    if (this.nonReflab_approval_pendList.length > 0) {
      if (confirm("Are you sure to submit.?")) {
        let flag: string = "";
      
        let setVal = false;
        for (let k = 0; k < this.nonReflab_approval_pendList.length; k++) {
         
          this.nonReflab_approval_pendList[k]['status'] = Status;
          this.nonReflab_approval_pendList[k]['userID'] = this.Log_UserID;
            
          if (this.nonReflab_approval_pendList[k]['checkStatus'] == true) {
            setVal = true;
          }
        }

       

        if (setVal == false) {
          alert('No record selected to authorized.');
          isSave = false;
          this.show_approve_btn = 'yes';
          return;
        }
        else {
          
          this.show_approve_btn = 'no';

          if (isSave == true) {

            let save_Data: any = {
              "PFAdvanceAppl": this.nonReflab_approval_pendList,
            }
      
            let body = new FormData();
            body = save_Data;
      
            let url = 'api/' + this._userService.get_nonRef_controlerName(this.filterBoradID) + '/updatenonrefauthall';
            this._userService.post_data(url, body).subscribe(
              (data) => {
                console.log(data);
                if (data == 'Saved SuccessFully.') {

                  // for (let k = 0; k < this.nonReflab_approval_pendList.length; k++) {
         
                  //   // this.nonReflab_approval_pendList[k]['status'] = "Approve";
                  //   // this.nonReflab_approval_pendList[k]['userID'] = this.Log_UserID;
                  //   // this.nonReflab_approval_pendList[k]['status'] = Status;
          
                  //   if (this.nonReflab_approval_pendList[k]['checkStatus'] == true) {
                  //     this.nonReflab_approval_pendList[k]['status'] = "Approve";
                  //     this.nonReflab_approval_pendList[k]['remark'] = '';
                  //   }
                  // }
                 
                  alert('Record saved successfully.');
                  update_Rej_Nonref = [];
                  this.get_NonRef_Lab_Details();
      
                }
                else {
                  alert(data);
                  this.show_approve_btn = 'yes';
                }
              });
            
          }

        }
      }
      else{
        this.show_approve_btn = 'yes';
      }
    }
    else {
      this.show_approve_btn = 'yes';
      // this.ngxService.stop();
      // this.loading = '';
      this.show_loadingModal(false);
      alert('No record to authorized.')
    }
    
}
else{
  this.show_loadingModal(false);
  alert('No record to authorized.')
}
}
Str_nonRef_Rej_remark;

update_nonRef_Reject() {
  if (this.Str_nonRef_Rej_remark == '' || this.Str_nonRef_Rej_remark == null) {
    alert('Enter remark.')
    return false;
  }
  else {
    let update_Rej_Nonref = [];
    if (window.confirm('Are sure you to update.?')) {

      let save_Data: any = {
        "PFAdvApplID": this.rej_pfAdvApplID,
        "Status": 'Reject',
        "Remark": this.Str_nonRef_Rej_remark,
        "UserID": this.Log_UserID
      }

      let body = new FormData();
      body = save_Data;

      let url = 'api/' + this._userService.get_nonRef_controlerName(this.filterBoradID) + '/updatenonrefauthreject';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          console.log(data);
          if (data == 'Saved SuccessFully.') {
            this.nonReflab_approval_pendList[this.rej_nonRefRindex]['status'] = "Reject";
            this.nonReflab_approval_pendList[this.rej_nonRefRindex]['remark'] = this.Str_nonRef_Rej_remark;
            this.Str_nonRef_Rej_remark = "";
            this.hide_modal_nonRefRej();
            alert('Record saved successfully.');
            update_Rej_Nonref = [];
            this.get_NonRef_Lab_Details();
          }
          else {
            alert(data);
          }
        });



    }

  }
}
showModal_nonRefRej: boolean;

// title: string;
show_modal_nonRefRej(pfAdvApplID, i) {
  this.Str_nonRef_Rej_remark = '';
  this.rej_nonRefRindex = i;
  this.rej_pfAdvApplID = pfAdvApplID;
  this.showModal_nonRefRej = true; // Show-Hide Modal Check

}
rej_nonRefRindex;
rej_pfAdvApplID;

//Bootstrap Modal Close event
hide_modal_nonRefRej() {
  this.rej_nonRefRindex = -1;
  this.rej_pfAdvApplID = 0;
  this.showModal_nonRefRej = false;
  this.Str_nonRef_Rej_remark = '';
}

showModal_nonRefDocModal: boolean;

// title: string;
show_modal_nonRef_Doc(pfAdvApplID) {
  this.showModal_nonRefDocModal = false;
  this.filter_nonReflab_approvalDoc_List = [];
  this.filter_nonReflab_approvalDoc_List = this.All_nonReflab_approvalDoc_List.filter(x => x.pfAdvApplID == pfAdvApplID)
  if (Number(this.filter_nonReflab_approvalDoc_List.length) > 1) {
    this.showModal_nonRefDocModal = true;
  }
  else if (Number(this.filter_nonReflab_approvalDoc_List.length) == 1) {
    this.view_uploadFile(this.filter_nonReflab_approvalDoc_List[0]['docName'], 'nonref');
  }
  else {
    alert('No document attach.')
  }
  // Show-Hide Modal Check

}

//Bootstrap Modal Close event
hide_modal_nonRef_Doc() {
  this.showModal_nonRefDocModal = false;
  this.filter_nonReflab_approvalDoc_List = [];
}
view_uploadFile(fileName, filefor): any {
  if (filefor == "nonref") {
    window.open(this.globals.BaseURL + "/" + this._userService.get_nonRef_DocFolderName(this.filterBoradID) + "/" + fileName);
  }
}

//#region NonRef LabPF Case Rpt
fill_rpt_nonRef_LabPFcase(PFAdvApplID,PFMasterID,LabourID) {
  this.show_loadingModal(true);
  let url_: string = '';
  url_ = 'api/' + this._userService.get_rpt_controlerName(this.filterBoradID) + '/getnonrefapplpfcase/' + PFAdvApplID + '/' + PFMasterID + '/' + LabourID;
  //alert(url_);
  this._userService.show_rpt(url_);
  this.show_loadingModal(false);
  return;

}
//#endregion

//#region set select/unselect all nonRefAppl 
  nonRef_AuthSelunSel: string = "Select All"
  set_selAll_nonRef_Auth(event: any, i) {
    if (i == -1) {
      let setVal = false;
      if (this.nonRef_AuthSelunSel == "Select All") {
        this.nonRef_AuthSelunSel = "Un-Select All";
        setVal = true;
      }
      else {
        this.nonRef_AuthSelunSel = "Select All";
        setVal = false;
      }

      if (this.nonReflab_approval_pendList.length > 0) {
        for (let k = 0; k < this.nonReflab_approval_pendList.length; k++) {
          this.nonReflab_approval_pendList[k]['checkStatus'] = setVal;
        }
      }
    }
    else {
      this.nonReflab_approval_pendList[i]['checkStatus'] = event.currentTarget.checked;
    }

  }
//#endregion


  //#endregion

}
