<div  class="notranslate">
<div class="container dashboardmain">
  <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Head Office Dashboard</li>
      </ol>
    </nav>
  </div>

  <form class="search">
    <div class="row">

      <div class="col-md-3">
        <label for="Financial-Year">Financial Year</label>
        <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
          <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== filterFayear">
            {{yr.displayYear}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="month-year">Month - Year</label>
        <select (change)="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
          <option [value]='0'>All</option>
          <option *ngFor='let mon of MonthList' [value]='mon.mon' [selected]="mon.mon=== filterMonth">{{mon.dispmon}}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="board-name" class="board-name-label">Board Name</label>
        <select (change)="Set_FilterBoardID($event)" class="form-control select-t board-name" id="board-name">
          <option *ngIf="Log_BoardID == 0" [value]='0'>All</option>
          <option *ngFor='let brd of boardlist' [value]='brd.boardID' [selected]="brd.boardID=== filterBoradID">
            {{brd.boardName}}</option>
        </select>
        <div class="search-bt" (click)="fill_Data();"><i class="fa fa-refresh" aria-hidden="true"></i></div>
      </div>


    </div>
  </form>

  <!-- waiting modal -->

  <div class="container">

    <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
      <div class="modal-dialog" role="document">
        <div class="modal-content">

          <!-- Modal body -->
          <div class="modal-body">

            <div style="text-align: center;">

              Please wait ...
              <div class="spinner-grow text-success"></div>
              <div class="spinner-grow text-info"></div>
              <div class="spinner-grow text-warning"></div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- waiting modal -->

  <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
    <ngb-panel title="Labour" id="config-panel-one">
      <ng-template ngbPanelContent>
        <div class="row">

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('LabourAppl')">
              <div class="pan"><img src="assets/images/icons/new-registration.png" alt="New Application"
                  title="New Application" class="float-left"> New Application Pending</div>
              <span>{{newApplication}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Labour')">
              <div class="pan"><img src="assets/images/icons/new-registration.png" alt="New Registration"
                  title="New Registration" class="float-left"> New Registration</div>
              <span>{{newResigstration}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Labour')">
              <div class="pan"><img src="assets/images/icons/resigned.png" alt="Resigned" title="Resigned"
                  class="float-left"> Resigned</div>
              <span>{{resigned}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3" *ngIf="this.UserType == 'Secretary' || this.UserType == 'Chairman'">
            <div class="main-pan" (click)="goto_Dtdashboard('NonRefAppl')">
              <div class="pan"><img src="assets/images/icons/new-registration.png" alt="New Application"
                  title="New Application" class="float-left"> Non Ref.Advance Application Pending</div>
              <span>{{newNonRefApplication}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Labour')">
              <div class="pan"><img src="assets/images/icons/death.png" alt="Death" title="Death" class="float-left">
                Death</div>
              <span>{{death}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Labour')">
              <div class="pan"><img src="assets/images/icons/cancelled.png" alt="Cancelled" title="Cancelled"
                  class="float-left"> Cancelled</div>
              <span>{{cancellation}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <!-- Establishment -->

    <ngb-panel title="Establishment">
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('EstAppl')">
              <div class="pan"><img src="assets/images/icons/new-registration.png" alt="New Application"
                  title="New Application" class="float-left"> New Application Pending</div>
              <span>{{estnewApplication}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Establishment')">
              <div class="pan"><img src="assets/images/icons/new-registration.png" alt="New Registration"
                  title="New Registration" class="float-left"> New Registration</div>
              <span>{{estnewResigstration}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Establishment')">
              <div class="pan"><img src="assets/images/icons/collection-amount.png" alt="Collection Amount"
                  title="Collection Amount" class="float-left"> Collection Amount</div>
              <span>{{preCollectionAmt}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Establishment')">
              <div class="pan"><img src="assets/images/icons/penalty-collection.png" alt="Collection Amount"
                  title="Collection Amount" class="float-left"> Penalty Collection</div>
              <span>{{penaltyCollectionAmt}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Establishment')">
              <div class="pan"><img src="assets/images/icons/no-collection-reason.png" alt="Active Count"
                  title="Active Count" class="float-left"> Active Count </div>
              <span>{{estActiveCnt}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('Establishment')">
              <div class="pan"><img src="assets/images/icons/no-collection-reason.png" alt="Collection Amount"
                  title="Collection Amount" class="float-left"> No Collection </div>
              <span>{{pendingCollection}}</span>
            </div>
          </div>

         

        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel title="Wages / Levy">
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('WagesLevy')">
              <div class="pan"><img src="assets/images/icons/wages-collection.png" alt="Wages Collection"
                  title="Wages Collection" class="float-left"> Wages Collection</div>
              <span>{{wagesCollectionAmt}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('WagesLevy')">
              <div class="pan"><img src="assets/images/icons/wages-collection.png" alt="Levy Collection"
                  title="Levy Collection" class="float-left"> Levy Collection</div>
              <span>{{levyCollectionAmt}}</span>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('WagesLevy')">
              <div class="pan"><img src="assets/images/icons/levy-collection.png" alt="Levy Collection"
                  title="Levy Collection" class="float-left"> Total Collection</div>
              <span>{{wagesCollectionAmt + levyCollectionAmt}}</span>
            </div>
          </div>

        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel title="Fix Deposits">
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('FD')">
              <div class="pan"><img src="assets/images/icons/new-F-D-S.png" alt="New F.D.’S" title="New F.D.’S"
                  class="float-left"> New F.D.’S</div>
              <span>{{newFDAmt}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('FD')">
              <div class="pan"><img src="assets/images/icons/renewal-F-D-S.png" alt="Renewal F.D.’S"
                  title="Renewal F.D.’S" class="float-left"> Renewal F.D.’S</div>
              <span>{{renewalFDAmt}}</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan" (click)="goto_Dtdashboard('FD')">
              <div class="pan"><img src="assets/images/icons/withdrawal-F-D-S.png" alt="Withdrawal F.D.’S"
                  title="Withdrawal F.D.’S" class="float-left"> Withdrawal F.D.’S</div>
              <span>{{withdrwalFDAmt}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <!-- <ngb-panel title="Audit">
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/audit-completed.png" alt="Audit Completed"
                  title="Audit Completed" class="float-left">Audit Completed</div>
              <span>2018</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/presented-to-government.png" alt="Presented To Government"
                  title="Presented To
              Government" class="float-left">Presented To Government</div>
              <span>2019</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/audit-pending.png" alt="Audit Pending"
                  title="Audit Pending" class="float-left"> Audit Pending</div>
              <span>2020</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    
    <ngb-panel title="Court Case & Letters & Inpector Visit">
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/court-case-pending.png" alt="Court Case Pending"
                  title="Court Case Pending" class="float-left">Court Case Pending</div>
              <span>01</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/letters-pending.png" alt="Letters Pending"
                  title="Letters Pending" class="float-left">Letters Pending</div>
              <span>01</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan">
              <div class="pan"><img src="assets/images/icons/inspector-visits.png" alt="Inspector Visits"
                  title="Inspector Visits" class="float-left">Inspector Visits</div>
              <span>02</span>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="main-pan sub-pan">
              <div class="pan"><img src="assets/images/icons/Inspector-registration-report.png"
                  alt="Inspector Registration Report" title="Inspector Registration Report"
                  class="float-left">Inspector<br>
                Registration Report</div>
              <span>
                <div class="row">
                  <div class="col-sm-5"><span class="small-text">Labour</span>01</div>
                  <div class="col-sm-7"><span class="small-text">Establishment</span>01</div>
                </div>

              </span>

            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel> -->
  </ngb-accordion>

</div>
</div>