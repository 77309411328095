import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './patcomp-dashboard.component.html',
  styleUrls: ['./patcomp-dashboard.component.css']
})
export class PatcompDashboardComponent implements OnInit {
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;


  active;
  disabled = true;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';

  public uploadYr = 0;
  public uploadMon = 0;
  public currDateTime = new Date();
  constructor(private http: HttpClient,
    config: NgbAccordionConfig
    , private _userService: UserService
    , private globals: Globals
    , public router: Router
    , private route: ActivatedRoute
    , private ngxService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer) {
    config.closeOthers = true;
    config.type = 'info';
  }

  // #region ngAfterViewChecked
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion
  public curDate: any = [];

  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    // this.curDate = this._userService.get_currentDateFromWebServer();
    //let m = curDate.getMonth();
    //alert(curDate);

    //

    // alert(this.Log_UserID);

    this.get_lastfile_uploadMonth();
    this.fill_allLab();
    this.set_uploadFile_Yr_Month();
    this.fill_dedYear();
  }
  //#region get_lastupload_month
  lastUploadMonth = '';
  get_lastfile_uploadMonth() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.lastUploadMonth = '';
    let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/getlastuploadmonth/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.lastUploadMonth = data;
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region All LabourList
  all_LabList = [];
  fill_allLab() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.all_LabList = [];
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getalllablist';
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.all_LabList = data;
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region fill dedList month/year
  public YrList: any = [];
  public MonthList: any = [];
  public filterFayear = 0;

  fill_dedYear() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.YrList = [];
    let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/getdedyear/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.YrList = data;
      this.filterFayear = data[0]['dedYear'];
      this.filterYr = this.filterFayear
      this.fill_Month(this.filterFayear);
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  fill_Month(Yr) {

    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "April" },
      { mon: 5, yr: Yr, dispmon: "May" },
      { mon: 6, yr: Yr, dispmon: "June" },
      { mon: 7, yr: Yr, dispmon: "July" },
      { mon: 8, yr: Yr, dispmon: "August" },
      { mon: 9, yr: Yr, dispmon: "September" },
      { mon: 10, yr: Yr, dispmon: "October" },
      { mon: 11, yr: Yr, dispmon: "November" },
      { mon: 12, yr: Yr, dispmon: "December" },
      { mon: 1, yr: Yr, dispmon: "January" },
      { mon: 2, yr: Yr, dispmon: "February" },
      { mon: 3, yr: Yr, dispmon: "March" },
    ];

    this.filterMonth = 4;
  }
  get_monthName(intMonth) {
    if (intMonth == 1) {
      return "January";
    }
    else if (intMonth == 2) {
      return "February";
    }
    else if (intMonth == 3) {
      return "March";
    }
    else if (intMonth == 4) {
      return "April";
    }
    else if (intMonth == 5) {
      return "May";
    }
    else if (intMonth == 6) {
      return "June";
    }
    else if (intMonth == 7) {
      return "July";
    }
    else if (intMonth == 8) {
      return "August";
    }
    else if (intMonth == 9) {
      return "September";
    }
    else if (intMonth == 10) {
      return "October";
    }
    else if (intMonth == 11) {
      return "November";
    }
    else if (intMonth == 12) {
      return "December";
    }
    else {
      return "";
    }

  }
  Set_Fayear(event) {
    this.pat_DedList = [];
    this.filterFayear = event.target.value;
    this.filterYr = event.target.value;
    this.fill_Month(event.target.value);
  }
  public filterMonth = 0;
  public filterYr = 0;
  Set_FiterYrMon(event) {
    this.pat_DedList = [];
    this.filterMonth = Number(event.target.value);
    this.filterYr = this.filterFayear;
  }
  //#endregion

  //#region set Year/Month
  set_uploadFile_Yr_Month() {
    var date_to_parse = new Date();
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getcurrendatefromweb';
    this._userService.get_Data(url).subscribe((data) => {
      this.uploadMon = Number(data.currMonth);
      this.uploadYr = Number(data.currYr);
      this.show_loadingModal(false);
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.uploadMon = Number(month);
        this.uploadYr = Number(year);
        this.show_loadingModal(false);
        return;
      });;

  }
  //#endregion

  //#region read Excel
  arrayBuffer: any;
  upload_fileName: File;
  sel_uploadfile(event) {
    this.Save_formData = new FormData();
    this.upload_fileName = event.target.files[0];
  }

  uploadExcel_Data = [];
  new_patpediLabList = [];
  excle_LabList = [];

  //#endregion
  //#region get policyNumber list from db
  noLabGet_fromDb_List = [];
  patpediReglab_List = [];
  fill_patpedi_fromDb() {
    this.Save_formData = new FormData();

    if (Number(this.uploadYr.toString().length) != 4) {
      alert('Enter valid year.')
      return false;
    }
    else if (Number(this.uploadMon) <= 0 && Number(this.uploadMon) > 12) {
      alert('Enter valid month.')
      return false;
    }
    else if (this.upload_fileName == null) {
      alert('Select file.')
      return false;
    }
    else {
      this.patpediReglab_List = [];
      this.new_patpediLabList = [];
      this.excle_LabList = [];
      this.uploadExcel_Data = [];
      this.noLabGet_fromDb_List = [];

      //this.ngxService.start();

      this.loading = 'y'

      let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/patpediReglablist/' + this.Log_UserID
      this._userService.get_Data(url).subscribe((data) => {
        this.show_loadingModal(true);

        this.patpediReglab_List = data;

        //Get Data From Excel

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];

          if (worksheet['A1']['h'] != 'patpediid'){
            this.show_loadingModal(false)
            alert('Set file A1 column header patpediid')
            return '';
          }
          else if (worksheet['B1']['h'] != 'regno'){
            this.show_loadingModal(false)
            alert('Set file B1 column header regno')
            return '';
          }
          else if (worksheet['C1']['h'] != 'name'){
            this.show_loadingModal(false)
            alert('Set file C1 column header name')
            return '';
          }
          else if (worksheet['D1']['h'] != 'dedamt'){
            this.show_loadingModal(false)
            alert('Set file D1 column header dedamt')
            return '';
          }
          else if (worksheet['E1']['h'] != 'current year'){
            this.show_loadingModal(false)
            alert('Set file E1 column header current year')
            return '';
          }
          else if (worksheet['F1']['h'] != 'current month'){
            this.show_loadingModal(false)
            alert('Set file F1 column header current month')
            return '';
          }

          this.uploadExcel_Data = XLSX.utils.sheet_to_json(worksheet, { raw: true })
          //
          //console.log('this.uploadExcel_Data ',this.uploadExcel_Data );

          // let filter_MonYr = this.uploadExcel_Data.filter(x => x.DedYear != this.uploadYr);
          // if (filter_MonYr.length > 0){
          //   this.loading = '';
          //    alert("Please check upload file year and selected file DedYear not match.");
          //   return false;
          // }
          // else{
          //   filter_MonYr = [];
          //   filter_MonYr = this.uploadExcel_Data.filter(x => x.DedMonth != this.uploadMon);
          //  if (filter_MonYr.length > 0){
          //   this.loading = '';
          //   alert("Please check upload file month and selected file DedMonth not match.");
          //   return false;
          //  }

          // }

          // 
          if (Number(this.uploadExcel_Data.length) > 0) {
            for (let k = 0; k < this.uploadExcel_Data.length; k++) {

              if (Number(this.uploadExcel_Data[k]['current year']) != Number(this.uploadYr)) {
                this.loading = '';
                this.show_loadingModal(false);
                alert("Please check upload file year and selected file DedYear not match.");
                return false;
              }

              if (Number(this.uploadExcel_Data[k]['current month']) != Number(this.uploadMon)) {
                this.loading = '';
                this.show_loadingModal(false);
                alert("Please check upload file month and selected file DedMonth not match.");
                return false;
              }
              if (Number(this.uploadExcel_Data[k]['patpediid']) != Number(this.Log_UserID)) {
                this.loading = '';
                this.show_loadingModal(false);
                alert("Please check upload file patepdhiid and login ID not match.");
                return false;
              }

              let labourID, labName, labRegNo, exl_labName, exl_labRegNo;
              let isEdit = true;
              labourID = 0;
              labName = ''; //this.uploadExcel_Data[k]['LabName'];
              labRegNo = '';// this.uploadExcel_Data[k]['RegNo'];

              exl_labName = this.uploadExcel_Data[k]['name'];
              exl_labRegNo = this.uploadExcel_Data[k]['regno'];

              let filter_ = [];
              filter_ = this.patpediReglab_List.filter(x => x.labRegNo == this.uploadExcel_Data[k]['regno'])

              if (Number(this.uploadExcel_Data[k]['regno']) > 0) {
                if (Number(filter_.length) == 0) {
                  let filter_lab = [];
                  if (Number(this.uploadExcel_Data[k]['regno']) > 0) {

                    filter_lab = this.all_LabList.filter(x => x.labRegNo == this.uploadExcel_Data[k]['regno'])
                    if (Number(filter_lab.length) == 1) {
                      labourID = filter_lab[0]['labourID'];
                      labName = filter_lab[0]['labName'];
                      labRegNo = filter_lab[0]['labRegNo'];

                      exl_labName = filter_lab[0]['labName'];
                      exl_labRegNo = filter_lab[0]['labRegNo'];

                      isEdit = false;
                    }
                    else if (Number(filter_lab.length) > 1) {
                      for (let l = 0; l < filter_lab.length; l++) {
                        if (Number(this.noLabGet_fromDb_List.length) == 0) {
                          this.noLabGet_fromDb_List = [{
                            "RegNo": filter_lab[l]['labRegNo'],
                            "LabName": filter_lab[l]['labName'],
                            "Flag": 'duplicate reg.No. in board data.'
                          }]
                        }
                        else {
                          this.noLabGet_fromDb_List.push({
                            "RegNo": filter_lab[l]['labRegNo'],
                            "LabName": filter_lab[l]['labName'],
                            "Flag": 'duplicate reg.No. in board data.'
                          })
                        }
                      }

                    }
                    else {
                      if (Number(this.noLabGet_fromDb_List.length) == 0) {
                        this.noLabGet_fromDb_List = [{
                          "RegNo": this.uploadExcel_Data[k]['regno'],
                          "LabName": this.uploadExcel_Data[k]['name'],
                          "Flag": 'Lab RegNo. not get from borad data.'
                        }]
                      }
                      else {
                        this.noLabGet_fromDb_List.push({
                          "RegNo": this.uploadExcel_Data[k]['regno'],
                          "LabName": this.uploadExcel_Data[k]['name'],
                          "Flag": 'Lab RegNo. not get from borad data.'
                        })
                      }
                    }

                  }


                  isEdit = false;


                  if (Number(this.new_patpediLabList.length) == 0) {
                    this.new_patpediLabList = [{
                      "PatPediID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "DedAmt": this.uploadExcel_Data[k]['dedamt'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": isEdit,
                      "isNew": true,
                    },]
                  }
                  else {
                    this.new_patpediLabList.push({
                      "PatPediID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "DedAmt": this.uploadExcel_Data[k]['dedamt'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": isEdit,
                      "isNew": true,
                    })
                  }
                }
                else {

                  let filter_lab = [];
                  if (Number(this.uploadExcel_Data[k]['regno']) > 0) {
                    filter_lab = this.all_LabList.filter(x => x.labRegNo == this.uploadExcel_Data[k]['regno'])

                    if (Number(filter_lab.length) == 1) {
                      labourID = filter_lab[0]['labourID'];
                      labName = filter_lab[0]['labName'];
                      labRegNo = filter_lab[0]['labRegNo'];

                      exl_labName = filter_lab[0]['labName'];
                      exl_labRegNo = filter_lab[0]['labRegNo'];

                      isEdit = false;
                    }
                    else if (Number(filter_lab.length) > 1) {
                      for (let l = 0; l < filter_lab.length; l++) {
                        if (Number(this.noLabGet_fromDb_List.length) == 0) {
                          this.noLabGet_fromDb_List = [{
                            "RegNo": filter_lab[l]['labRegNo'],
                            "LabName": filter_lab[l]['labName'],
                            "Flag": 'duplicate reg.No. in board data.'
                          }]
                        }
                        else {
                          this.noLabGet_fromDb_List.push({
                            "RegNo": filter_lab[l]['labRegNo'],
                            "LabName": filter_lab[l]['labName'],
                            "Flag": 'duplicate reg.No. in board data.'
                          })
                        }
                      }

                    }
                    else {
                      if (Number(this.noLabGet_fromDb_List.length) == 0) {
                        this.noLabGet_fromDb_List = [{
                          "RegNo": exl_labName,
                          "LabName": exl_labRegNo,
                          "Flag": 'Lab RegNo. not get from borad data.'
                        }]
                      }
                      else {
                        this.noLabGet_fromDb_List.push({
                          "RegNo": exl_labName,
                          "LabName": exl_labRegNo,
                          "Flag": 'Lab RegNo. not get from borad data.'
                        })
                      }
                    }

                  }


                  if (Number(this.excle_LabList.length) == 0) {
                    this.excle_LabList = [{
                      "PatPediID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": exl_labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": exl_labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "DedAmt": this.uploadExcel_Data[k]['dedamt'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": false,
                      "isNew": false,
                    },]
                  }
                  else {
                    this.excle_LabList.push({
                      "PatPediID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": exl_labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": exl_labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "DedAmt": this.uploadExcel_Data[k]['dedamt'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": false,
                      "isNew": false,
                    })
                  }
                }

                //check duplicate regNo record in excel

                let filter_dup = this.uploadExcel_Data.filter(x => x.RegNo == this.uploadExcel_Data[k]['regno'])


                if (Number(filter_dup.length) > 1) {
                  let filter_ifexists = this.noLabGet_fromDb_List.filter(x => x.RegNo == this.uploadExcel_Data[k]['regno'])
                  if (Number(filter_ifexists.length == 0)) {
                    for (let l = 0; l < filter_dup.length; l++) {
                      if (Number(this.noLabGet_fromDb_List.length) == 0) {
                        this.noLabGet_fromDb_List = [{
                          "RegNo": filter_dup[l]['RegNo'],
                          "LabName": filter_dup[l]['LabName'],
                          "Flag": 'duplicate reg.No. in board data.'
                        }]
                      }
                      else {
                        this.noLabGet_fromDb_List.push({
                          "RegNo": filter_dup[l]['RegNo'],
                          "LabName": filter_dup[l]['LabName'],
                          "Flag": 'duplicate reg.No. in board data.'
                        })
                      }
                    }
                  }
                }


                this.show_loadingModal(false);

              }
              else {
                this.show_loadingModal(false);
                this.loading = '';
                alert('Check this labour RgeNo. not get ' + this.uploadExcel_Data[k]['name']);
                return false;
              }

            }



            if (Number(this.new_patpediLabList.length) == 0) {
              if (Number(this.excle_LabList.length) > 0) {
                this.show_loadingModal(false);
                this.upload_excel_file();
              }
              else {
                this.show_loadingModal(false);
                this.loading = '';
                alert('Data not get.')
              }
            }
            else {
              this.show_loadingModal(false);
              this.loading = '';
              alert('Upload faild.New labour found in excle file.')
            }


          }
        }
        fileReader.readAsArrayBuffer(this.upload_fileName);

        this.loading = '';

        //this.ngxService.stop();

      },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.loading = '';
          this.ngxService.stop();
          //alert(err);
          return;
        });
    }

  }
  cancle_update_AddNewLab() {
    this.Save_formData = new FormData();
    this.new_patpediLabList = [];
    this.excle_LabList = [];
    this.noLabGet_fromDb_List = [];
  }
  //#endregion

  //#region Add new lablist in InsPolicyMaster
  add_NewLab_InsPolicy() {
    let isValid = true;
    if (window.confirm('Are sure you to update new labour list.?')) {

      if (Number(this.new_patpediLabList.length) > 0) {
        for (let k = 0; k < this.new_patpediLabList.length; k++) {
          if (Number(this.new_patpediLabList[k]['LabourID']) <= 0) {
            alert('Policy number ' + this.new_patpediLabList[k]['PolicyNo'] + ' selected labour invalid.')
            this.new_patpediLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {

        let saveData: any = {
          "InsPolicyAuthMaster": this.new_patpediLabList,
          "ExlInsPolicyDt": this.excle_LabList,
        }
        this.show_loadingModal(true);
        this.ngxService.start();
        let body = new FormData();
        body = saveData;
        let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/saveinspolicyauth';
        //alert(url);
        this._userService.post_data(url, body).subscribe(
          (data) => {
            this.show_loadingModal(false);
            if (data == 'Saved SuccessFully.') {

              alert('Record saved successfully.');
              this.new_patpediLabList = [];
              this.excle_LabList = [];
              this.get_lastfile_uploadMonth();
            }
            else {
              this.show_loadingModal(false);
              alert(data);
            }
          });
        this.ngxService.stop();
      }

    }

  }
  //#endregion

  //#region set addnew lab
  set_addNewLab_Check(event: any, rindex) {
    this.new_patpediLabList[rindex]['isSave'] = event.currentTarget.checked;
  }
  //#endregion

  //#region only numbers
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  //#endregion

  //#region filter upload labName and set
  updateLabName;
  set_upload_editcontrol(i) {
    this.new_patpediLabList[i]['isEdit'] = true;
  }
  set_upload_editlabNamel(labName, labRegNo, labourID, i) {
    this.new_patpediLabList[i]['LabName'] = labName;
    this.new_patpediLabList[i]['RegNo'] = labRegNo;
    this.new_patpediLabList[i]['LabourID'] = labourID;
    this.new_patpediLabList[i]['isEdit'] = false;
    this.filter_uploadLabName = [];
  }
  set_approval_editlabNamel(labName, labRegNo, labourID, i) {
    this.lab_approval_pendList[i]['labName'] = labName;
    this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    this.lab_approval_pendList[i]['labourID'] = labourID;
    //this.lab_approval_pendList[i]['isEdit'] = false;
    this.filter_approval_uploadLabName = [];
  }
  //#endregion

  //#region Search filter labour by name or Regno
  filter_uploadLabName = [];
  searchuploadLabName;
  selcted_cntrIndex = -1;
  search_upload_labname(event, i) {
    this.selcted_cntrIndex = i;
    this.filter_uploadLabName = [];
    this.filter_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  filter_approval_uploadLabName = [];
  sear_approval_chuploadLabName;
  selcted_approval_cntrIndex = -1;
  search_approval_upload_labname(event, i) {
    this.selcted_approval_cntrIndex = i;
    this.filter_approval_uploadLabName = [];
    this.filter_approval_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  //#endregion

  //#region approval pending list\
  lab_approval_pendList = [];
  fill_approval_pendingList() {
    this.lab_approval_pendList = [];
    this.ngxService.start();
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.lab_approval_pendList = data;
      if (this.lab_approval_pendList.length == 0) {
        this.show_loadingModal(false);
        alert('No record found.');
      }
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  set_approval_pendList_editcontrol(i, isEdit: boolean) {
    this.Save_formData = new FormData();
    for (let k = 0; k < this.lab_approval_pendList.length; k++) {
      this.lab_approval_pendList[k]['isEdit'] = false;
    }
    this.lab_approval_pendList[i]['isEdit'] = isEdit;

  }
  //#endregion

  //#region test

 
  url: any; //Angular 11, for stricter type
  msg = "";

  //selectFile(event) { //Angular 8
  uploadImg: any;
  selectFile(event: any) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    this.uploadImg = event.target.files[0];

    if (mimeType.match(/image\/*/) == null) {
      this.msg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.url = reader.result;
    }

    const formData: FormData = new FormData();
    formData.append('Image', event.target.files[0]);
    formData.append('ComponentId', '1');

  }

  //#endregion

  //#region upload image
  upload_image(event: any) {
    const formData: FormData = new FormData();
    //    formData.append('Image', event.target.files[0]);
    formData.append('Image', this.uploadImg);

    formData.append('ComponentId', '1');
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/UploadImage';

    this._userService.post_data(url, formData).subscribe(
      (data) => {

        if (data == 'Saved SuccessFully.') {

          alert('Record saved successfully.');
          this.patpediReglab_List = [];
          this.new_patpediLabList = [];
          this.excle_LabList = [];
          this.uploadExcel_Data = [];

        }
        else {
          alert(data);
        }
      });

    // return this.http.post('/api/dashboard/UploadImage', formData);

  }

  ///////////////////////
  imgPatha: any = "assets/mem_images/user.jpg";
  public isImageWithinLimit(event) {
    //let max_limit = 2097152;//2 mb
    let max_limit = 102400;//100 kb
    let file_size = event.target.files[0].size;
    return (file_size < max_limit);
  }
  public isChoosenFileValid = true;
  public filePosArray: Array<any> = [];
  public fileToUpload: any = [];
  public onChoosenFileTextChanged(event) {
    let text = event.target.value;
    if (text.length > 0) {
      if (this.isImageWithinLimit(event)) {
        this.isChoosenFileValid = true;

        let pos = this.filePosArray.length;
        this.addPositionToList(pos);
        this.fileToUpload[pos] = event.target.files[0];

        // this.imgPatha=event.target.result;

        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imgPatha = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);


      }
      else {
        event.target.value = '';
        alert('Maximum size is 100 Kb');
      }

    }
  }
  uploadedImage: Blob;
  public addPositionToList(pos) {
    if (this.filePosArray.indexOf(pos) == -1) {
      this.filePosArray.push(pos);
    }
    if (this.fileToUpload == null || this.fileToUpload.length == 0) {
      this.fileToUpload = [];
    }

  }

  loading: string = '';
  remve_addImg = '';
  upload_excel_file() {
    //return false;
    let isValid = true;
    if (window.confirm('Are sure you to update new labour list.?')) {

      if (Number(this.new_patpediLabList.length) > 0) {
        for (let k = 0; k < this.new_patpediLabList.length; k++) {
          if (Number(this.new_patpediLabList[k]['LabourID']) <= 0) {
            alert(this.new_patpediLabList[k]['LabName'] + ' selected labour invalid.')
            //this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }
          if (this.new_patpediLabList[k]['imageFile'] == null || this.new_patpediLabList[k]['imageFile'] == '') {
            alert(this.new_patpediLabList[k]['LabName'] + ' select document.')
            //this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }

        }
      }

      if (Number(this.excle_LabList.length) > 0) {
        for (let k = 0; k < this.excle_LabList.length; k++) {
          if (Number(this.excle_LabList[k]['LabourID']) <= 0) {
            alert(this.excle_LabList[k]['LabName'] + ' selected labour invalid.')
            //this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {
        this.loading = 'y';

        let url = this.globals.BaseURL + 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/savepatpediauth';
        let isSaved: boolean = false;
        this.Save_formData.append("PatAuthMaster", JSON.stringify(this.new_patpediLabList));
        this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_LabList));
        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {
            $("#fileProgress").hide();


            if (fileName == 'Saved SuccessFully.') {

              alert('File uploaded successfully.')
              window.location.reload();

            }
            else {
              this.loading = '';
              alert('Something went wromg internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

        this.Save_formData = new FormData();
        this.patpediReglab_List = [];
        this.new_patpediLabList = [];
        this.excle_LabList = [];
        this.uploadExcel_Data = [];

      }
    }

  }

  public Save_formData = new FormData();
  set_uploadfile(event, i, RegNo) {
    this.new_patpediLabList[i]['imageFile'] = 'yes';
    this.Save_formData.append(RegNo, event.target.files[0], event.target.files[0].fileName);
  }

  set_approval_updateData(event, colName, i, labID, labName, labRegNo) {
    if (colName == 'labname') {
      this.lab_approval_pendList[i]['labourID'] = labID;
      this.lab_approval_pendList[i]['labName'] = labName;
      this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    }

    else if (colName == 'dedAmt') {
      this.lab_approval_pendList[i]['dedAmt'] = event.target.value;
    }
    else if (colName == 'doc') {
      this.Save_formData = new FormData();
      this.Save_formData.append(labID, event.target.files[0], event.target.files[0].fileName);
    }
  }
  update_approval_policyList = [];
  save_editIndex = -1;
  update_approval_pending_List(rindex: number) {
    this.update_approval_policyList = [];
    this.save_editIndex = rindex;

    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;
      this.update_approval_policyList = [{
        "PatpediAuthID": this.lab_approval_pendList[rindex]['patpediAuthID'],
        "PatPediID": this.Log_UserID,
        "LabourID": this.lab_approval_pendList[rindex]['labourID'],
        "RegNo": this.lab_approval_pendList[rindex]['labRegNo'],
        "LabName": this.lab_approval_pendList[rindex]['labName'],
        "exlLabName": this.lab_approval_pendList[rindex]['labName'],
        "DedAmt": this.lab_approval_pendList[rindex]['dedAmt'],
        "DedMonth": this.lab_approval_pendList[rindex]['dedMonth'],
        "DedYear": this.lab_approval_pendList[rindex]['dedYear'],
        "bgcolor": 'None',
        "imageFile": '',
        "isSave": true,
        "isEdit": false,
        "isNew": true,
        "Status": 'Pending',
      },]

      //

      if (Number(this.update_approval_policyList.length) > 0) {
        for (let k = 0; k < this.update_approval_policyList.length; k++) {
          if (Number(this.update_approval_policyList[k]['LabourID']) <= 0) {
            alert(this.update_approval_policyList[k]['labName'] + ' selected labour invalid.')

            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {

        let url = this.globals.BaseURL + 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/savepatpediauth';

        this.Save_formData.append("PatAuthMaster", JSON.stringify(this.update_approval_policyList));
        // this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_policyLabList));

        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {

            $("#fileProgress").hide();

            if (fileName == 'Saved SuccessFully.') {
              //  this.Save_formData = new FormData(); 
              alert('Updated successfully.')
              //this.lab_approval_pendList[this.save_editIndex]['isEdit'] = false;
            }
            else {
              alert('Something went wromg internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

      }
    }

    this.set_approval_pendList_editcontrol(this.save_editIndex, false);

  }

  //#endregion

  //#region view/download file

  view_uploadFile(fileName): any {

    window.open(this.globals.BaseURL + "/" + this._userService.get_patCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);

  }
  download_file_excelFormat() {

    //window.open(this.globals.BaseURL + "/FileFormatToDownload/Patpedi File Format.xlsx");
    window.open(this.globals.BaseURL + "/FileFormatToDownload/PimMathaPatpedi File Format.xlsx");

  }

  //#endregion

  //#region create excele downlod file

  pat_DedList = [];
  boardName = '';
  strMonthName = '';
  fill_patpediDedList() {
    this.ngxService.start();
    this.pat_DedList = [];
    this.strMonthName = '';
    let strFlag = "Pat";
    //////
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/checkdedpaid/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr + '/' + strFlag;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      // this.lic_DedList = data;
      if (data == 'yes') {
        url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/getpatdedlist/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr;
        this._userService.get_Data(url).subscribe((data) => {
          this.ngxService.stop();
          this.pat_DedList = data;
          if (data.length > 0) {
            this.boardName = data[0]['boardName'];
            this.strMonthName = this.get_monthName(this.filterMonth);
          }
        },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            this.ngxService.stop();
            return;
          });

      }
      else {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert('Deduction list not authorized from board.');
      }
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });

  }

  exportexcel(): void {
    let fileName = 'Patpedhi Deduction List ' + this.filterYr + '-' + this.filterMonth + '.xlsx'
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
  //#endregion

  //#region get Total
  getTotal(data, fldname) {
    if (fldname == 'dedAmt') {
      return data.reduce((sum, curr) => sum + curr.dedAmt, 0);
    }
  }
  //#endregion
  showModal_patpediRej: boolean;
  rej_patpediAuthID = 0;
  rej_patpediRindex = -1;
  Str_patpedirejremark = '';
  show_modal_patpediRej(patpediAuthID, i) {
    this.rej_patpediRindex = i;
    this.rej_patpediAuthID = patpediAuthID;
    this.showModal_patpediRej = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide_modal_patpediRej() {
    this.rej_patpediRindex = -1;
    this.rej_patpediAuthID = 0;
    this.showModal_patpediRej = false;
    this.Str_patpedirejremark = '';
  }

  //#region loading modal
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion
}
