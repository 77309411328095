import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';



@Component({
  selector: 'app-unioncomp-dashboard',
  templateUrl: './unioncomp-dashboard.component.html',
  styleUrls: ['./unioncomp-dashboard.component.css']
})
export class UnioncompDashboardComponent implements OnInit {
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;


  active;
  disabled = true;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';

  public uploadYr = 0;
  public uploadMon = 0;
  public currDateTime = new Date();
  constructor(private http: HttpClient,
    config: NgbAccordionConfig
    , private _userService: UserService
    , private globals: Globals
    , public router: Router
    , private route: ActivatedRoute
    , private ngxService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer) {
    config.closeOthers = true;
    config.type = 'info';
  }
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion
  public curDate: any = [];
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));

    }
    this.fill_allLab();
    this.fill_dedYear();
  }

  //#region All LabourList
  all_LabList = [];
  fill_allLab() {
    this.ngxService.start();
    this.all_LabList = [];
    let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getalllablist/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      this.all_LabList = data;
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region search lab record

  labInfo: string = '';
  labserchCnt = 'yes';
  searchLab = '';
  filter_lab = [];
  serch_lab() {
    this.filter_lab = [];
    this.filter_lab = this.all_LabList.filter((item) => {
      return ((item.filterval.toString()).indexOf(this.searchLab.toString().toLowerCase()) > -1);
    });
  }
  public labID = 0;
  set_searchLab_Cnt(labID, regNo, labName) {
    this.labInfo = ''
    this.labID = 0;

    this.searchLab = '';
    this.labID = labID;
    this.labInfo = regNo + ' - ' + labName;
    this.labserchCnt = '';
  }
  show_serchlab_Cnt() {
    this.labserchCnt = 'yes';
  }
  //#endregion
  public uploadFileName = '';
  public Save_formData = new FormData();
  set_uploadfile(event) {
    this.Save_formData = new FormData();
    this.Save_formData.append(this.labID.toString(), event.target.files[0], event.target.files[0].fileName);
    this.uploadFileName = this.labID.toString();
  }

  //#region  save Data
  save_addNewLab() {

    if (this.labID <= 0 || this.labID.toString() == '' || this.labID == null) {
      alert('Select valid labour.');
      return;
    }
    else if (this.uploadFileName == null || this.uploadFileName == '') {
      alert('Select valid document.');
      return;
    }
    else {

      if (window.confirm('Are sure you to update.?')) {
        let save_newLab_union = [];
        save_newLab_union = [{
          "UnionAuthID": 0,
          "UnionID": this.Log_UserID,
          "LabourID": this.labID,
          "DocName": '',
          "AuthUserID": 0,
          "Remark": '',
          "isSave": true,
          "isEdit": false,
          "isNew": true,
          "Status": 'Pending',
        },]

        let url = this.globals.BaseURL + 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/saveunionauth';

        this.Save_formData.append("UnionAuthMaster", JSON.stringify(save_newLab_union));

        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {

            $("#fileProgress").hide();

            if (fileName == 'Saved SuccessFully.') {

              alert('Updated successfully.')
            }
            else {
              alert('Something went wrong internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

        this.Save_formData = new FormData();
        this.fileInput.nativeElement.value = "";
        this.filter_lab = [];
        this.labserchCnt = 'yes';
      }
    }
  }
  //#endregion

  //#region fill reg union labList
  public regLab_List = [];
  fill_Reg_Lab_List() {
    this.boardName = '';
    this.ngxService.start();
    this.regLab_List = [];
    let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getreglablist/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      this.regLab_List = data;
      if (data.length > 0) {
        this.boardName = data[0]['boardName'];
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region Search filter labour by name or Regno
  filter_uploadLabName = [];
  searchuploadLabName;
  selcted_cntrIndex = -1;
  search_upload_labname(event, i) {
    this.selcted_cntrIndex = i;
    this.filter_uploadLabName = [];
    this.filter_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  filter_approval_uploadLabName = [];
  sear_approval_chuploadLabName;
  selcted_approval_cntrIndex = -1;
  search_approval_upload_labname(event, i) {
    this.selcted_approval_cntrIndex = i;
    this.filter_approval_uploadLabName = [];
    this.filter_approval_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  set_approval_editlabNamel(labName, labRegNo, labourID, i) {
    this.lab_approval_pendList[i]['labName'] = labName;
    this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    this.lab_approval_pendList[i]['labourID'] = labourID;
    //this.lab_approval_pendList[i]['isEdit'] = false;
    this.filter_approval_uploadLabName = [];
  }
  set_approval_updateData(event, colName, i, labID, labName, labRegNo) {
    if (colName == 'labname') {
      this.lab_approval_pendList[i]['labourID'] = labID;
      this.lab_approval_pendList[i]['labName'] = labName;
      this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    }
    else if (colName == 'doc') {
      this.Save_formData = new FormData();
      this.Save_formData.append(labID, event.target.files[0], event.target.files[0].fileName);
    }
  }
  //#endregion


  //#region approval pending list\
  lab_approval_pendList = [];
  fill_approval_pendingList() {
    this.lab_approval_pendList = [];
    this.Save_formData = new FormData();
    this.ngxService.start();
    let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      this.lab_approval_pendList = data;
      if (this.lab_approval_pendList.length == 0) {
        alert('No record found.');
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  set_approval_pendList_editcontrol(i, isEdit: boolean) {
    this.Save_formData = new FormData();
    for (let k = 0; k < this.lab_approval_pendList.length; k++) {
      this.lab_approval_pendList[k]['isEdit'] = false;
    }
    this.lab_approval_pendList[i]['isEdit'] = isEdit;

  }
  //#endregion

  //#region view/download file

  view_uploadFile(fileName): any {

    window.open(this.globals.BaseURL + "/" + this._userService.get_unionCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);

  }

  //#endregion

  //#region update approval pending list
  update_approval_unionList = [];
  save_editIndex = -1;
  update_approval_pending_List(rindex: number) {
    this.update_approval_unionList = [];
    this.save_editIndex = rindex;

    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;
      this.update_approval_unionList = [{
        "UnionAuthID": this.lab_approval_pendList[rindex]['unionAuthID'],
        "UnionID": this.Log_UserID,
        "LabourID": this.lab_approval_pendList[rindex]['labourID'],
        "bgcolor": 'None',
        "imageFile": '',
        "isSave": true,
        "isEdit": false,
        "isNew": true,
        "Status": 'Pending',
      },]

      //

      if (Number(this.update_approval_unionList.length) > 0) {
        for (let k = 0; k < this.update_approval_unionList.length; k++) {
          if (Number(this.update_approval_unionList[k]['LabourID']) <= 0) {
            alert(this.update_approval_unionList[k]['labName'] + ' selected labour invalid.')

            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {

        let url = this.globals.BaseURL + 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/saveunionauth';

        this.Save_formData.append("UnionAuthMaster", JSON.stringify(this.update_approval_unionList));

        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {

            $("#fileProgress").hide();

            if (fileName == 'Saved SuccessFully.') {
              alert('Updated successfully.')
            }
            else {
              alert('Something went wrong internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

      }
    }

    this.set_approval_pendList_editcontrol(this.save_editIndex, false);

  }
  //#endregion

  //#region fill dedList month/year
  public YrList: any = [];
  public MonthList: any = [];
  public filterFayear = 0;

  fill_dedYear() {
    this.ngxService.start();
    this.YrList = [];
    let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getdedyear/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      this.YrList = data;
      this.filterFayear = data[0]['dedYear'];
      this.filterYr = this.filterFayear
      this.fill_Month(this.filterFayear);
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  fill_Month(Yr) {

    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "April" },
      { mon: 5, yr: Yr, dispmon: "May" },
      { mon: 6, yr: Yr, dispmon: "June" },
      { mon: 7, yr: Yr, dispmon: "July" },
      { mon: 8, yr: Yr, dispmon: "August" },
      { mon: 9, yr: Yr, dispmon: "September" },
      { mon: 10, yr: Yr, dispmon: "October" },
      { mon: 11, yr: Yr, dispmon: "November" },
      { mon: 12, yr: Yr, dispmon: "December" },
      { mon: 1, yr: Yr, dispmon: "January" },
      { mon: 2, yr: Yr, dispmon: "February" },
      { mon: 3, yr: Yr, dispmon: "March" },
    ];

    this.filterMonth = 4;
  }
  get_monthName(intMonth) {
    if (intMonth == 1) {
      return "January";
    }
    else if (intMonth == 2) {
      return "February";
    }
    else if (intMonth == 3) {
      return "March";
    }
    else if (intMonth == 4) {
      return "April";
    }
    else if (intMonth == 5) {
      return "May";
    }
    else if (intMonth == 6) {
      return "June";
    }
    else if (intMonth == 7) {
      return "July";
    }
    else if (intMonth == 8) {
      return "August";
    }
    else if (intMonth == 9) {
      return "September";
    }
    else if (intMonth == 10) {
      return "October";
    }
    else if (intMonth == 11) {
      return "November";
    }
    else if (intMonth == 12) {
      return "December";
    }
    else {
      return "";
    }

  }
  Set_Fayear(event) {
    this.union_DedList = [];
    this.filterFayear = event.target.value;
    this.filterYr = event.target.value;
    this.fill_Month(event.target.value);


  }
  public filterMonth = 0;
  public filterYr = 0;
  Set_FiterYrMon(event) {
    this.union_DedList = [];
    this.filterMonth = Number(event.target.value);
    this.filterYr = this.filterFayear;


  }
  //#endregion

  //#region fill deduction list

  union_DedList = [];
  boardName = '';
  strMonthName = '';
  fill_unionDedList() {
    this.ngxService.start();
    this.union_DedList = [];
    this.strMonthName = '';
    let strFlag = "Union";
    //////

    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/checkdedpaid/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr + '/' + strFlag;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      // this.lic_DedList = data;
      if (data == 'yes') {
        url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getuniondedlist/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr;
        this._userService.get_Data(url).subscribe((data) => {

          this.ngxService.stop();
          this.union_DedList = data;
          if (data.length > 0) {
            this.boardName = data[0]['boardName'];
            this.strMonthName = this.get_monthName(this.filterMonth);
          }
        },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            return;
          });

      }
      else {
        this.ngxService.stop();
        alert('Deduction list not authorized from board.');
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

  }

  //#endregion

  //#region get Total
  getTotal(data, fldname) {
    if (fldname == 'dedAmt') {
      return data.reduce((sum, curr) => sum + curr.dedAmt, 0);
    }
  }
  //#endregion

  //#region  export deduction list to excle
  exportexcel(): void {

    let fileName = 'Union Deduction List ' + this.filterYr + '-' + this.filterMonth + '.xlsx'
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
  exportexcel_regLbaList(): void {

    let fileName = 'Union Labour Register List.xlsx'
    /* table id is passed over here */
    let element = document.getElementById('regLab-List-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
  //#endregion

}
