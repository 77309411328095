<div  class="notranslate">
<div class="container dashboardmain">
  <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
        <li class="breadcrumb-item"> <a href="javascript:void(0)" routerLink="../">Head Office Dashboard</a></li>
        <!-- (click)="go_dashboard()" -->
        <li class="breadcrumb-item active" aria-current="page">{{dasbordfor}}</li>
      </ol>
    </nav>
  </div>
  <form class="search">
    <div class="row">
      <div class="col-md-3">
        <label for="Financial-Year">Financial Year</label>
        <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
          <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== filterFayear">
            {{yr.displayYear}} </option>
          <!-- [selected]="yr.fayear=== filterFayear" -->
        </select>
      </div>
      <div class="col-md-3">
        <label for="month-year">Month - Year</label>
        <select (change)="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
          <option [value]='0'>All</option>
          <option *ngFor='let mon of MonthList' [value]='mon.mon' [selected]="mon.mon=== filterMonth">{{mon.dispmon}}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="board-name" class="board-name-label">Board Name</label>
        <select (change)="Set_FilterBoardID($event)" class="form-control select-t board-name" id="board-name">
          <option *ngIf="UserType == 'HO'" [value]='0'>All</option>
          <option *ngFor='let brd of boardlist' [value]='brd.boardID' [selected]="brd.boardID=== filterBoradID">
            {{brd.boardName}}</option>
        </select>
        <div class="search-bt" (click)="fill_Data();"><i class="fa fa-refresh" aria-hidden="true"></i></div>
      </div>
    </div>
  </form>

  <!-- waiting modal -->

  <div class="container">

    <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
      <div class="modal-dialog" role="document">
        <div class="modal-content">

          <!-- Modal body -->
          <div class="modal-body">

            <div style="text-align: center;">

              Please wait ...
              <div class="spinner-grow text-success"></div>
              <div class="spinner-grow text-info"></div>
              <div class="spinner-grow text-warning"></div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- waiting modal -->


  <table *ngIf="dasbordfor == 'Labour'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Months Report </th>
      <th>New Application Pending </th>

      <th> Opening </th>

      <th>New Registration </th>
      <th>Resigned</th>
      <th>Death</th>
      <th>Cancelled</th>
      <th> Closing </th>
      <!-- <th>Month Closing</th> -->
    </thead>
    <tbody>

      <ng-container *ngFor="let data of labourData">
        <tr class="sub-tr">
          <td (click)="data.expanded = !data.expanded">
            <div *ngIf="filterBoradID == 0" [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div>
            {{data.monthYearName}}
          </td>
          <!-- <td (click)="data.expanded = !data.expanded">
       <div *ngIf="filterBoradID > 0 && data.newApplication > 0 && UserType != 'HO'"  [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div>
       {{data.newApplication}}  
      <span><i *ngIf="data.newApplication > 0 && UserType != 'HO'"
      class="fas fa-edit" ngbTooltip="View List" aria-hidden="true"
      style="color: blue;"
      (click)="show_newLabApplDt()" ></i></span>
     </td>  -->
          <td>{{data.newApplication}}</td>
          <td>{{data.opnBal}}</td>
          <td>{{data.newResigstration}}</td>
          <td>{{data.resigned}}</td>
          <td>{{data.death}}</td>
          <td>{{data.cancellation}}</td>
          <td>{{data.clsBal}}</td>
        </tr>
        <ng-container *ngIf="data.expanded && filterBoradID == 0">
          <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
            <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
            <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
            <td> {{data.newApplication}} </td>
            <td>{{data.opnBal}}</td>
            <td> {{data.newResigstration}} </td>
            <td> {{data.resigned}} </td>
            <td> {{data.death}} </td>
            <td> {{data.cancellation}} </td>
            <td>{{data.clsBal}}</td>
            <!-- <td> {{data.opnBal + data.newResigstration - data.resigned - data.death - data.cancellation}} </td>      -->
          </tr>
        </ng-container>


      </ng-container>
    </tbody>
    <tfoot>
      <tr Class="table-footer">
        <td>Total</td>
        <td>{{getTotal(labourData,'newApplication')}}</td>
        <td></td>
        <td>{{getTotal(labourData,'newResigstration')}}</td>
        <td>{{getTotal(labourData,'resigned')}}</td>
        <td>{{getTotal(labourData,'death')}}</td>
        <td>{{getTotal(labourData,'cancellation')}}</td>
        <td></td>
        <!-- <td>-</td> -->
      </tr>
    </tfoot>
  </table>

  <table *ngIf="dasbordfor == 'LabourAppl'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Application Date </th>
      <th>Name </th>
      <th>Address </th>
      <th>Mobile Number</th>
      <th>View Application</th>
    </thead>
    <tbody>

      <ng-container *ngFor="let data of filter_newApll_labData">
        <tr class="sub-tr">
          <td>{{data.labRegdt | date: 'dd/MM/yyyy'}}</td>
          <td>{{data.labName}}</td>
          <td>{{data.resiAdd1}}</td>
          <td>{{data.mobNo}}</td>
          <td><i class="fas fa-eye" ngbTooltip="View List" aria-hidden="true" style="color: blue;"
              (click)="show_newLabApplDt(data.mobNo)"></i></td>
        </tr>

      </ng-container>
    </tbody>

  </table>

  <table *ngIf="dasbordfor == 'NonRefAppl'" class="table table-hover table-responsive-xl labour-table">
    <thead>

      <th class="th-sm" style="text-align: center;"><input type="checkbox" (change)="set_selAll_nonRef_Auth($event,-1)"
          *ngIf="nonReflab_approval_pendList.length > 0">
        &nbsp;<span>{{nonRef_AuthSelunSel}}</span></th>
      <th style="text-align: center;">Application Date</th>
      <th style="text-align: center;">Reg.No. </th>
      <th style="text-align: center;">Name </th>
      <th style="text-align: center;">Application Loan Amt.</th>
      <th style="text-align: center;">Reason</th>
      <th style="text-align: center;">Status</th>
      <th style="text-align: center;">Remark</th>
      <th>View Case</th>
      <th style="text-align: center;">Document</th>
      <!-- <th></th> -->
      <th></th>

    </thead>
    <tbody>

      <ng-container *ngFor="let rec of nonReflab_approval_pendList;let i = index">
        <tr class="sub-tr">
          <!-- <td></td> -->
          <td><input type="checkbox" (change)="set_selAll_nonRef_Auth($event,i)" [checked]="rec.checkStatus"></td>
          <td>{{rec.applDt | date: 'dd/MM/yyyy'}} </td>
          <td>{{rec.labRegNo}} </td>
          <td>{{rec.labName}} </td>
          <td>{{rec.advAmt}} </td>
          <td>{{rec.rdesc}} </td>
          <td>{{rec.status}}</td>
          <td>{{rec.remark}}</td>
          <td><i class="fa fa-eye" ngbTooltip="view file" aria-hidden="true"
              (click)="fill_rpt_nonRef_LabPFcase(rec.pfAdvApplID,rec.pfMasterID,rec.labourID)" style="color:blue;"> </i>
          </td>

          <td><i class="fa fa-file" ngbTooltip="view file" aria-hidden="true"
              (click)="show_modal_nonRef_Doc(rec.pfAdvApplID)" style="color:black;"> </i></td>

          <!-- <td > <a
            style="color: blue;text-decoration: underline;"
            (click)="update_new_nonRef_Accept(rec.pfAdvApplID,i)">Accept</a> </td> -->
          <td><a style="color: blue;text-decoration: underline;"
              (click)="show_modal_nonRefRej(rec.pfAdvApplID,i)">Reject</a></td>
        </tr>

      </ng-container>
    </tbody>



  </table>

  <div class="text-center" *ngIf="nonReflab_approval_pendList.length > 0 && show_approve_btn == 'yes'">
    <button  type="button" class="btn btn-primary" (click)="update_new_nonRef_Accept()"
    >Approve</button>
  </div>

  <table *ngIf="dasbordfor == 'EstAppl'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Application Date </th>
      <th>Name </th>
      <th>Address </th>
      <th>Mobile Number</th>
      <th>View Application</th>
    </thead>
    <tbody>

      <ng-container *ngFor="let data of filter_newApll_estData">
        <tr class="sub-tr">
          <td>{{data.estRegdt | date: 'dd/MM/yyyy'}}</td>
          <td>{{data.estName}}</td>
          <td>{{data.estAdd1}}</td>
          <td>{{data.mobNo}}</td>
          <td><i class="fas fa-eye" ngbTooltip="View List" aria-hidden="true" style="color: blue;"
              (click)="show_newEstApplDt(data.mobNo)"></i></td>
        </tr>

      </ng-container>
    </tbody>

  </table>

  <table *ngIf="dasbordfor == 'Establishment'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Months Report </th>
      <!--  <th>Opening Registration </th> -->
      <th>New Registration </th>
      <th>Collection Amount </th>
      <th>Penalty Collection</th>
      <th>No Collection</th>

    </thead>
    <tbody>

      <ng-container *ngFor="let data of estData">
        <tr (click)="data.expanded = !data.expanded" class="sub-tr">
          <td>
            <!-- <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> -->
             {{data.monthYearName}}
          </td>
          <!-- <td> {{data.opnRegistrationBal}} </td> -->
          <td> {{data.newRegistration}} </td>
          <td> {{data.preCollectionAmt}} </td>
          <td> {{data.penaltyCollectionAmt}} </td>
          <td> {{data.pendingCollection}} </td>

        </tr>
        <ng-container *ngIf="data.expanded">
          <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
            <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
            <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
            <!-- <td> {{data.opnRegistrationBal}} </td> -->
            <td> {{data.newRegistration}} </td>
            <td> {{data.preCollectionAmt}} </td>
            <td> {{data.penaltyCollectionAmt}} </td>
            <td> {{data.pendingCollection}} </td>

          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr Class="table-footer">
        <td>Total</td>
        <!-- <td>-</td> -->

        <td>{{getTotal(estData,'estnewRegistration')}}</td>
        <td>{{getTotal(estData,'preCollectionAmt')}}</td>
        <td>{{getTotal(estData,'penaltyCollectionAmt')}}</td>
        <td>-</td>
        <!-- {{getTotal(estData,'pendingCollection')}} -->

      </tr>
    </tfoot>
  </table>

  <table *ngIf="dasbordfor == 'WagesLevy'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Months Report </th>
      <th>Wages Collection </th>
      <th>Levy Collection </th>
      <th>Total Collection </th>
    </thead>
    <tbody>

      <ng-container *ngFor="let data of wlData">
        <tr (click)="data.expanded = !data.expanded" class="sub-tr">
          <td>
            <!-- <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div>  -->
            {{data.monthYearName}}
          </td>
          <td> {{data.wagesCollectionAmt}} </td>
          <td> {{data.levyCollectionAmt}} </td>
          <td> {{data.wagesCollectionAmt + data.levyCollectionAmt}} </td>

        </tr>
        <ng-container *ngIf="data.expanded">
          <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
            <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
            <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
            <td> {{data.wagesCollectionAmt}} </td>
            <td> {{data.levyCollectionAmt}} </td>
            <td> {{data.wagesCollectionAmt + data.levyCollectionAmt}} </td>

          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr Class="table-footer">
        <td>Total</td>
        <td>{{getTotal(wlData,'wagesCollectionAmt')}}</td>
        <td>{{getTotal(wlData,'levyCollectionAmt')}}</td>
        <td>{{getTotal(wlData,'wagesCollectionAmt') + getTotal(wlData,'levyCollectionAmt')}}</td>
      </tr>
    </tfoot>
  </table>

  <table *ngIf="dasbordfor == 'FD'" class="table table-hover table-responsive-xl labour-table">
    <thead>
      <th>Months Report </th>
      <th>New F.D.’S </th>
      <th>Renewal F.D.’S </th>
      <th>Withdrawal F.D.’S </th>
    </thead>
    <tbody>

      <ng-container *ngFor="let data of fdData">
        <tr (click)="data.expanded = !data.expanded" class="sub-tr">
          <td>
            <!-- <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> -->
             {{data.monthYearName}}
          </td>
          <td> {{data.newFDAmt}} </td>
          <td> {{data.renewalFDAmt}} </td>
          <td> {{data.withdrwalFDAmt}} </td>


        </tr>
        <ng-container *ngIf="data.expanded">
          <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
            <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
            <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
            <td> {{data.newFDAmt}} </td>
            <td> {{data.renewalFDAmt}} </td>
            <td> {{data.withdrwalFDAmt}} </td>


          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr Class="table-footer">
        <td>Total</td>
        <td>{{getTotal(fdData,'newFDAmt')}}</td>
        <td>{{getTotal(fdData,'renewalFDAmt')}}</td>
        <td>{{getTotal(fdData,'withdrwalFDAmt')}}</td>
      </tr>
    </tfoot>
  </table>

  <!-- Modals start -->

  <!-- Nonref Adv DocList -->
  <div class="container">

    <div class="modal" id="nonRefDocModal" [style.display]="showModal_nonRefDocModal ? 'block' : 'none'">
      <div class="modal-dialog modal-xl" role="dialog">
        <div class="modal-content">

          <!-- Modal Header -->
          <div class="modal-header" style="background-color: #1A5276;color: white;">
            <h4 class="modal-title">Document Details</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_nonRef_Doc()"
              style="color: white;">&times;</button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <div class="table-responsive" style="height: 400px;">
                  <table class="table table-hover table-responsive-xl labour-table">
                    <thead>
                      <th style="text-align: center;">Document Name</th>
                      <th>View</th>
                    </thead>

                    <tr *ngFor='let rec of filter_nonReflab_approvalDoc_List;let i = index' class="sub-tr border">
                      <td>{{rec.docExample}} </td>
                      <td><i class="fa fa-eye" ngbTooltip="view file" aria-hidden="true"
                          (click)="view_uploadFile(rec.docName,'nonref')" style="color:blue;"> </i></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Non RefAdv Application Reject -->
  <div class="container">
    <div class="modal" id="myModal" [style.display]="showModal_nonRefRej ? 'block' : 'none'">
      <div class="modal-dialog modal-xl" role="dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header" style="background-color: #1A5276;color: white;">
            <h4 class="modal-title">Reject Non Ref.Adv. Application</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_nonRefRej()"
              style="color: white;">&times;</button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <label for="remark">Remark </label>
                <input type="text" id="txtnonrefrejremark" [(ngModel)]="Str_nonRef_Rej_remark"
                  value="{{Str_nonRef_Rej_remark}}" class="form-control" placeholder="Enter remark" />
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="update_nonRef_Reject()">Update</button>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
</div>