<div  class="notranslate">
<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li *ngIf="Log_accessFor != 'Auth'" class="breadcrumb-item"><a href="javascript:void(0)"
                        routerLink="/">Home</a></li>
                <li *ngIf="Log_accessFor == 'Auth'" class="breadcrumb-item"><a href="javascript:void(0)"
                        (click)="go_back()">Dashboard</a></li>
                <li class="breadcrumb-item " aria-current="page">कामगार नोंदणी अर्ज </li>
            </ol>
        </nav>

    </div>

    <!-- waiting modal -->

    <div class="container">

        <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <!-- Modal body -->
                    <div class="modal-body">

                        <div style="text-align: center;">

                            Please wait ...
                            <div class="spinner-grow text-success"></div>
                            <div class="spinner-grow text-info"></div>
                            <div class="spinner-grow text-warning"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- waiting modal -->

    <!-- server response error -->
   
    <div *ngIf="appl_Type == 'error'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
        <h2 style="font-weight: bold;color: red; text-align: center;">
            <i class='fab fa-github' style='font-size:36px;color: red;'></i> Something went wrong.
        </h2>
    </div>

    <!-- server response error -->

    <section id="company-dashboard" style="margin-top: 5px;" *ngIf="appl_Type != 'error'">
        <div class="container">

            <div class="row">

                <div *ngIf="appl_Type == 'labMst'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9" class="blink">
                    <i class='fas fa-arrow-right' style="color: red;"></i>
                </div>

                <div *ngIf="appl_Type == 'labMst'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                    <h2 style="font-weight: bold;color: #1b998b;">मंडळाकडून नोंदणी यशस्वीरीत्या अधिकृत झाली असुन.अर्जात
                        कुठलाही बदल करता येणार नाही.<a style="font-size: small;"> अधिक माहितीसाठी मंडळात संपर्क
                            साधा.</a></h2>
                </div>

                <div *ngIf="appl_Type == 'labApplication' && Log_accessFor == '' && Appl_Status == 'Reject'"
                    class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                    <h2 style="font-weight: bold;color: red;">नोंदणी रद्द केली आहे.</h2>
                    <a style="font-size: medium;color: blue;"> {{rejRemark}}</a>

                </div>

                <div *ngIf="appl_Type == 'labApplication' && Log_accessFor == '' && Appl_Status == 'Pending'"
                    class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                    <h2 style="font-weight: bold;color: blue;">नोंदणी तपासणीसाठी सादर.</h2>
                </div>

                <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <ul class="header-menus">
                        <li>
                            <ul class="social">
                                <li (click)="set_lang('mar')" class="button_lang">मराठी</li>
                                <li (click)="set_lang('en')" class="button_lang current_lang">En</li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- navigate Button -->

            <div class="row">
                <div class="col-sm-12" style="text-align: center;">
                    <button [ngClass]="step == 1 ? 'button button5 buttonActive' : 'button button5'"
                        (click)="set_step(1)">1</button>
                    &nbsp;<i class='fas fa-arrow-right ' style="color: #04AA6D;font-size:15px">&nbsp;</i><button
                        [ngClass]="step == 2 ? 'button button5 buttonActive' : 'button button5'" (click)="set_step(2)"
                        [disabled]="step2_disable">2</button>
                    &nbsp;<i class='fas fa-arrow-right' style="color: #04AA6D;font-size:15px"></i>&nbsp;<button
                        [ngClass]="step == 3 ? 'button button5 buttonActive' : 'button button5'" (click)="set_step(3)"
                        [disabled]="step3_disable">3</button>
                    &nbsp;<i class='fas fa-arrow-right' style="color: #04AA6D;font-size:15px"></i>&nbsp;<button
                        [ngClass]="step == 4 ? 'button button5 buttonActive' : 'button button5'" (click)="set_step(4)"
                        [disabled]="step4_disable">4</button>
                </div>
            </div>

            <!-- navigate Button -->

            <!-- step--1 Start -->

            <div class="row" *ngIf="step == 1">

                <form [formGroup]="step1_Form">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="content-panel">
                            <div class="row">

                                <div class="form-group col-sm-12">

                                    <label *ngIf="lang == 'en'">Labour Photo <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">कामगार फोटो <strong>*</strong></label>

                                    <div class="file-upload">
                                        <div class="image-upload-wrap">
                                            <!-- <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" /> -->

                                            <input class="file-upload-input" type="file" accept="image/*"
                                                (change)="set_file($event)" placeholder="Upload file">

                                            <div *ngIf="imgName == ''" class="drag-text">
                                                <h3 *ngIf="lang == 'en'">Select add Image</h3>
                                                <h3 *ngIf="lang == 'mar'">प्रतिमा जोडा निवडा</h3>
                                            </div>

                                            <div *ngIf="imgName !=''" class="drag-text">
                                                <img id="imageprev" [src]="imgSrc" alt="" />
                                            </div>
                                        </div>
                                        <div class="file-upload-content">
                                            <img class="file-upload-image" src="#" alt="your image" />

                                            <div class="image-title-wrap">
                                                <button type="button" onclick="removeUpload()" class="remove-image"
                                                    *ngIf="lang == 'en'"><i class="bi bi-x"></i> Remove <span
                                                        class="image-title">Uploaded Image</span></button>

                                                <button type="button" onclick="removeUpload()" class="remove-image"
                                                    *ngIf="lang == 'mar'"><i class="bi bi-x"></i> काढा <span
                                                        class="image-title">अपलोड फोट</span></button>
                                            </div>
                                        </div>
                                        <!-- <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )" *ngIf="lang == 'en'">Upload Labour Photo</button>
                              <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )" *ngIf="lang == 'mar'">कामगार फोटो अपलोड करा</button> -->
                                    </div>

                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Labour Name English <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">कामगाराचे नाव इंग्रजी <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="eng_name" placeholder=""
                                        required>
                                    <span class="error ion-padding" style="color: red;"
                                        *ngIf="isSubmitted_step_1 && errorControl.eng_name.errors?.required">
                                        {{err_eng_name}}
                                    </span>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Labour Name Marathi <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">कामगाराचे नाव मराठी <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="mar_name" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Mother Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">आईचे नाव <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="mother_name"
                                        placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Address <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पत्ता <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="address" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label class="check-input" *ngIf="lang == 'en'">
                                        <input type="checkbox" (change)="set_asAbove_address()" class="checkbox">
                                        <span class="checkmark"></span>
                                        As Above
                                    </label>
                                    <label class="check-input" *ngIf="lang == 'mar'">
                                        <input type="checkbox" (change)="set_asAbove_address()" class="checkbox">
                                        <span class="checkmark"></span>
                                        वरील प्रमाणे
                                    </label>
                                </div>

                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Permanent Address <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">कायमचा पत्ता <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="peraddress" placeholder="">
                                </div>

                                <div class="form-group col-sm-4">
                                    <label *ngIf="lang == 'en'">Gender <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">लिंग <strong>*</strong></label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="gender" id="gender1"
                                            [checked]="gender == 'Male'" (change)="set_gender($event,'')" value="Male">
                                        <label class="form-check-label" for="exampleRadios1" *ngIf="lang == 'en'">
                                            Male
                                        </label>
                                        <label class="form-check-label" for="exampleRadios1" *ngIf="lang == 'mar'">
                                            पुरुष
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="gender" id="gender2"
                                            [checked]="gender == 'Female'" (change)="set_gender($event,'')"
                                            value="Female">
                                        <label class="form-check-label" for="exampleRadios2" *ngIf="lang == 'en'">
                                            Female
                                        </label>
                                        <label class="form-check-label" for="exampleRadios2" *ngIf="lang == 'mar'">
                                            महिला
                                        </label>
                                    </div>
                                </div>

                                <!--Marital Status-->
                                <div class="form-group col-sm-5">
                                    <label *ngIf="lang == 'en'">Marital Status <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">वैवाहिक स्थिती <strong>*</strong></label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="married_status"
                                            id="married_status1" [checked]="marStatus == 'Married'"
                                            (change)="set_marStatus($event.target.value)" value="Married">
                                        <label class="form-check-label" for="married_status1" *ngIf="lang == 'en'">
                                            Married
                                        </label>
                                        <label class="form-check-label" for="married_status1" *ngIf="lang == 'mar'">
                                            विवाहित
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="married_status"
                                            id="married_status2" [checked]="marStatus == 'UnMarried'"
                                            (change)="set_marStatus($event.target.value)" value="UnMarried">
                                        <label class="form-check-label" for="married_status2" *ngIf="lang == 'en'">
                                            Unmarried
                                        </label>
                                        <label class="form-check-label" for="married_status2" *ngIf="lang == 'mar'">
                                            अविवाहित
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="content-panel" style="display: none;">
                            <div class="row">
                                <h4 class="col-sm-12" *ngIf="lang == 'en'">OTP Verification</h4>
                                <h4 class="col-sm-12" *ngIf="lang == 'mar'">ओ.टी.पी पडताळणी</h4>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Mobile Number <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">मोबाईल नंबर <strong>*</strong></label>
                                    <input type="text" (keypress)="onlyNumberKey($event)" class="form-control"
                                        formControlName="mobno" maxlength="10">
                                </div>
                                <div class="form-group col-sm-12" *ngIf="lang == 'en'"><a class="btn btn-dark btn-sm"
                                        style="color: white;" (click)="genrateOTP()">Get OTP</a></div>
                                <div class="form-group col-sm-12" *ngIf="lang == 'mar'"><a class="btn btn-dark btn-sm"
                                        style="color: white;" (click)="genrateOTP()">ओ.टी.पी मिळवा</a></div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Enter OTP <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">ओ.टी.पी प्रविष्ट करा <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="otp">
                                </div>
                            </div>
                        </div>

                        <div class="content-panel">
                            <div class="row">

                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Date of Birth <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">जन्मतारीख <strong>*</strong></label>
                                    <div class="input-group mb-3">
                                        <input type="date" class="form-control" (input)="vlaidateDate($event,'labdob')"
                                            (change)="setDate($event)" value="{{dob}}" />
                                        <span *ngIf="invalidDate!=''" style="font-size: small;color:red">Invalid
                                            Date</span>
                                    </div>

                                </div>

                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Age <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">वय <strong>*</strong></label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" value="{{age}}" readonly="true" />

                                    </div>

                                </div>

                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Cast <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">जात <strong>*</strong></label>
                                    <select class="form-control" id="selcast"
                                        (change)="filter_subCastList($event.target.value)">
                                        <!-- <option selected>Choose...</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option> -->

                                        <option *ngFor='let rec of cast_list;let i = index' value="{{rec.castID}}"
                                            [selected]="rec.castID===castID">{{rec.castName}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Sub-cast <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पोटजाती <strong>*</strong></label>
                                    <select class="form-control" id="selsubcast"
                                        (change)="set_subCastID($event.target.value)">
                                        <!-- <option selected>Choose...</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option> -->

                                        <option *ngFor='let rec of filter_subcast_list;let i = index'
                                            value="{{rec.subCastID}}" [selected]="rec.subCastID===subCastID">
                                            {{rec.subCastName}}</option>
                                    </select>


                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Work Type <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">कामाचा प्रकार <strong>*</strong></label>
                                    <select class="form-control" id="selworktype"
                                        [selectedIndex]="workerType_selectedIndex"
                                        (change)="set_workerType($event.target.value)">
                                        <option value="Hamal">Hamal</option>
                                        <option value="Bharai">Bharai</option>
                                        <option value="Tolai">Tolai</option>
                                        <option value="Varai">Varai</option>
                                        <option value="Bardana">Bardana</option>

                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">IFSC Code <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">आयएफएससी कोड <strong>*</strong></label>
                                    <div class="input-group">
                                        <!-- <span class="input-group-text"><i class="bi bi-search"></i></span> -->
                                        <input type="text" style="text-transform: uppercase;" class="form-control"
                                            formControlName="ifsccode"
                                            (change)="get_bankDt_fromIFSSc($event.target.value)">
                                    </div>
                                    <div class="form-group mt-4 mb-0">
                                        <label *ngIf="lang == 'en'">A/c. No <strong>*</strong></label>
                                        <label *ngIf="lang == 'mar'">खाते क्रमांक <strong>*</strong></label>
                                        <input type="text" class="form-control" formControlName="accno">
                                    </div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Bank Info <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">बँकेची माहिती <strong>*</strong></label>
                                    <label>Bank Name : {{bankName}}</label>
                                    <label>Branch Name : {{branchName}}</label>
                                    <label>Address : {{bankaddress}}</label>
                                    <!-- <textarea class="form-control" formControlName="bankinfo" rows="5" >  
                          </textarea> -->
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Aadhar Number <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">आधार क्रमांक <strong>*</strong></label>
                                    <input type="text" (keypress)="onlyNumberKey($event)" class="form-control"
                                        formControlName="aadhar" maxlength="12">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">PAN Number <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पॅन क्रमांक <strong>*</strong></label>
                                    <input type="text" style="text-transform: uppercase;" class="form-control"
                                        formControlName="panno" maxlength="10">
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Division Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">विभागाचे नाव <strong>*</strong></label>
                                    <select class="form-control" id="seldiv" (change)="set_divID($event.target.value)">
                                        <option *ngFor='let rec of comp_List;let i = index' value="{{rec.companyID}}"
                                            [selected]="rec.companyID===companyID">{{rec.companyname}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Sub-Division Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">उपविभागाचे नाव <strong>*</strong></label>
                                    <select class="form-control" id="selsubdiv"
                                        (change)="set_marketID($event.target.value)">
                                        <option *ngFor='let rec of filter_market_list;let i = index'
                                            value="{{rec.marketID}}" [selected]="rec.marketID===marketID">
                                            {{rec.marketName}}</option>
                                    </select>
                                </div>
                                <div *ngIf="Log_accessFor == '' && (Appl_Status == 'Pending' || Appl_Status == 'Reject' || Appl_Status == '')"
                                    class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_1()" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_1()" *ngIf="lang == 'mar'">पुढे</a>
                                </div>

                                <div *ngIf="Log_accessFor == 'Auth'" class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(2);set_step(2)" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(2);set_step(2)" *ngIf="lang == 'mar'">पुढे</a>
                                </div>



                            </div>
                        </div>
                    </div>
                </form>



            </div>

            <!-- step--2 Start -->

            <div class="row" *ngIf="step == 2">

                <form [formGroup]="step2_Form">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="content-panel">
                            <div class="row">

                                <h4 class="col-sm-12" *ngIf="lang == 'en'">Labour Other Details</h4>
                                <h4 class="col-sm-12" *ngIf="lang == 'mar'">कामगार इतर तपशील</h4>

                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Toli Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">टोळीचे नाव <strong>*</strong></label>
                                    <select class="form-control" id="seltoli"
                                        (change)="set_toliID($event.target.value)">
                                        <option *ngFor='let rec of toli_List;let i = index' value="{{rec.toliID}}"
                                            [selected]="rec.toliID===toliID">{{rec.mukName}}</option>
                                    </select>

                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Union Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">युनियनचे नाव <strong>*</strong></label>
                                    <select class="form-control" id="selunion"
                                        (change)="set_unionID($event.target.value)">
                                        <option *ngFor='let rec of union_List;let i = index' value="{{rec.unionID}}"
                                            [selected]="rec.unionID===unionID">{{rec.compName}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Police Verification <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पोलीस पडताळणी <strong>*</strong></label>
                                    <select class="form-control" id="selpoliceverf"
                                        [selectedIndex]="policeverf_selectedIndex"
                                        (change)="set_policeverf($event.target.value)">
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 mb-3">
                                    <hr />
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Relative/Friends Name 1 <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">नातेवाईक/मित्रांचे नाव 1 <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="rel_name1" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Address <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पत्ता <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="address1" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Mobile Number <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">मोबाईल नंबर <strong>*</strong></label>
                                    <input type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                                        formControlName="mobno1" maxlength="10" placeholder="">
                                </div>
                                <div class="col-sm-12 mb-3">
                                    <hr />
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Relative/Friends Name 2</label>
                                    <label *ngIf="lang == 'mar'">नातेवाईक/मित्रांचे नाव 2</label>
                                    <input type="text" class="form-control" formControlName="rel_name2" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Address</label>
                                    <label *ngIf="lang == 'mar'">पत्ता</label>
                                    <input type="text" class="form-control" formControlName="address2" placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Mobile Number</label>
                                    <label *ngIf="lang == 'mar'">मोबाईल नंबर </label>
                                    <input type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                                        formControlName="mobno2" maxlength="10" placeholder="">
                                </div>

                                <div *ngIf="Log_accessFor == '' && (Appl_Status == 'Pending' || Appl_Status == 'Reject'  || Appl_Status == '')"
                                    class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_2()" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_2()" *ngIf="lang == 'mar'">पुढे</a>

                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(1)" *ngIf="lang == 'en'">Prev</a>
                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(1)" *ngIf="lang == 'mar'">मागील</a>
                                </div>

                                <div *ngIf="Log_accessFor == 'Auth'" class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(3);set_step(3)" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(3);set_step(3)" *ngIf="lang == 'mar'">पुढे</a>

                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(1)" *ngIf="lang == 'en'">Prev</a>
                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(1)" *ngIf="lang == 'mar'">मागील</a>
                                </div>

                            </div>
                        </div>

                    </div>
                </form>

            </div>

            <!-- step--3 Start -->

            <div class="row" *ngIf="step == 3">

                <form [formGroup]="step3_Form">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="content-panel">
                            <div class="row">

                                <h4 class="col-sm-12" *ngIf="lang == 'en'">Labour Nominee/Guarantor Details</h4>
                                <h4 class="col-sm-12" *ngIf="lang == 'mar'">कामगाराचे वारस \ साक्षीदारचे तपशील</h4>
                                <h5 class="col-sm-12" *ngIf="lang == 'en'">Nominee Details</h5>
                                <h5 class="col-sm-12" *ngIf="lang == 'mar'">वारसदाराचे तपशील</h5>



                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Name <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">नाव <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="NomName" placeholder="">
                                    <input type="text" class="form-control" formControlName="nomeditIndex"
                                        hidden="true">
                                </div>
                                <div class="form-group col-sm-4">
                                    <label *ngIf="lang == 'en'">Date of Birth <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">जन्म दिनांक <strong>*</strong></label>
                                    <input type="date" class="form-control" formControlName="NomDOB"
                                        (input)="vlaidateDate($event,'nomdob')"
                                        (change)="setnom_DOB_Date($event,'nomdob')" value="{{nomdob}}" />
                                    <span *ngIf="invalid_NomDob_Date!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>
                                <div class="form-group col-sm-2">
                                    <label *ngIf="lang == 'en'">Age <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">वय <strong>*</strong></label>
                                    <input type="text" class="form-control" value="{{nomAge}}" readonly="true"
                                        placeholder="">
                                </div>

                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Address <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">पत्ता <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="NomAdd" placeholder="">
                                </div>

                                <div class="form-group col-sm-6">
                                    <label *ngIf="lang == 'en'">Relation <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">नाते <strong>*</strong></label>
                                    <select class="form-control" id="selrel" [selectedIndex]="selrel_selectedIndex"
                                        (change)="set_nomRelation($event.target.value)">
                                        <option value="Wife">Wife</option>
                                        <option value="Husbund">Husbund</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Father">Father</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Son">Son</option>
                                        <option value="Doughter">Doughter</option>
                                        <option value="GrandFather">GrandFather</option>
                                        <option value="GrandMother">GrandMother</option>

                                    </select>
                                </div>
                                <div class="form-group col-sm-4">
                                    <label *ngIf="lang == 'en'">Relation Type<strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">वारसदराचे प्रकार <strong>*</strong></label>
                                    <select class="form-control" id="selnomtype"
                                        [selectedIndex]="selnomtype_selectedIndex"
                                        (change)="set_nomType($event.target.value)">
                                        <option value="Major">Major</option>
                                        <option value="Minor">Minor</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-2">
                                    <label *ngIf="lang == 'en'">Percentage <strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">टक्केवारी <strong>*</strong></label>
                                    <input type="text" class="form-control" formControlName="NomineePercent"
                                        (keypress)="onlyNumberKey($event)" placeholder="">
                                </div>

                                <div class="form-group col-sm-4">
                                    <label *ngIf="lang == 'en'">Date<strong>*</strong></label>
                                    <label *ngIf="lang == 'mar'">दिनांक <strong>*</strong></label>
                                    <input type="date" class="form-control" formControlName="NomFrmDt"
                                        (input)="vlaidateDate($event,'nomfrmdt')"
                                        (change)="setnom_DOB_Date($event,'nomfrmdt')" value="{{nomfrmdt}}" />
                                    <span *ngIf="invalid_NomFrm_Date!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>
                                <div class="form-group col-sm-4" style="margin-top: 35px;">
                                    <input class="btn btn-info btn-sm mr-2" type="button" (click)="add_nominee()"
                                        value="+ Add">

                                </div>


                                <div class="col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mb-4">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th><span *ngIf="lang == 'en'">Relation Type</span><span
                                                            *ngIf="lang == 'mar'">वारसदराचे प्रकार</span></th>
                                                    <th><span *ngIf="lang == 'en'">Name</span><span
                                                            *ngIf="lang == 'mar'">नाव</span></th>
                                                    <th><span *ngIf="lang == 'en'">Relation</span><span
                                                            *ngIf="lang == 'mar'">नाते</span></th>
                                                    <th><span *ngIf="lang == 'en'">Date of Birth</span><span
                                                            *ngIf="lang == 'mar'">जन्म दिनांक</span></th>
                                                    <th><span *ngIf="lang == 'en'">Age</span><span
                                                            *ngIf="lang == 'mar'">वय</span></th>
                                                    <th><span *ngIf="lang == 'en'">Address</span><span
                                                            *ngIf="lang == 'mar'">पत्ता</span></th>
                                                    <th><span *ngIf="lang == 'en'">Date</span><span
                                                            *ngIf="lang == 'mar'">दिनांक</span></th>
                                                    <th><span *ngIf="lang == 'en'">Percentage</span><span
                                                            *ngIf="lang == 'mar'">टक्केवारी</span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>


                                                <tr *ngFor='let rec of nom_list;let i = index'>

                                                    <td *ngIf="rec.Name != ''">{{rec.NomType}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.Name}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.Relation}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.DOB | date :'dd/MM/yyyy'}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.Age}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.NomAdd}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.FrmDt | date :'dd/MM/yyyy'}}</td>
                                                    <td *ngIf="rec.Name != ''">{{rec.NomineePercent}}</td>
                                                    <td *ngIf="rec.Name != ''" class="d-flex">
                                                        <input class="btn btn-info btn-sm mr-2" type="button"
                                                            (click)="set_editNominee(i)" value="+ Edit"
                                                            *ngIf="lang == 'en'">
                                                        <input class="btn btn-danger btn-sm" type="button"
                                                            (click)="del_nominee(i)" value="Delete"
                                                            *ngIf="lang == 'en'">

                                                        <input class="btn btn-info btn-sm mr-2" type="button"
                                                            (click)="set_editNominee(i)" value="+ Edit"
                                                            *ngIf="lang == 'mar'">
                                                        <input class="btn btn-danger btn-sm" type="button"
                                                            (click)="del_nominee(i)" value="Delete"
                                                            *ngIf="lang == 'mar'">
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-sm-12 mb-3">
                                    <hr />
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Guarantor Name 1 </label>
                                    <label *ngIf="lang == 'mar'">साक्षीदाराचे नाव 1 </label>
                                    <input type="text" class="form-control" formControlName="NomWitName1"
                                        placeholder="">
                                </div>
                                <div class="form-group col-sm-12">
                                    <label *ngIf="lang == 'en'">Guarantor Name 2 </label>
                                    <label *ngIf="lang == 'mar'">साक्षीदाराचे नाव 2 </label>
                                    <input type="text" class="form-control" formControlName="NomWitName2"
                                        placeholder="">
                                </div>
                                <div class="form-group col-sm-4" style="display: none;">
                                    <label *ngIf="lang == 'en'">Remark</label>
                                    <label *ngIf="lang == 'mar'">शेरा</label>
                                    <input type="text" class="form-control" formControlName="NomRemark" placeholder="">
                                </div>

                                <div *ngIf="Log_accessFor == '' && (Appl_Status == 'Pending' || Appl_Status == 'Reject' || Appl_Status == '')"
                                    class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_3()" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="submit_step_3()" *ngIf="lang == 'mar'">पुढे</a>

                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(2)" *ngIf="lang == 'en'">Prev</a>
                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(2)" *ngIf="lang == 'mar'">मागील</a>
                                </div>

                                <div *ngIf="Log_accessFor == 'Auth'" class="col-sm-12 text-right">
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(4);set_step(4)" *ngIf="lang == 'en'">Next</a>
                                    <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step_disbl_enb(4);set_step(4)" *ngIf="lang == 'mar'">पुढे</a>

                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(2)" *ngIf="lang == 'en'">Prev</a>
                                    <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                        (click)="set_step(2)" *ngIf="lang == 'mar'">मागील</a>
                                </div>

                            </div>
                        </div>

                    </div>
                </form>



            </div>

            <!-- step--4 Start -->

            <div class="row" *ngIf="step == 4">

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="content-panel">
                        <div class="row">

                            <h4 class="col-sm-12" *ngIf="lang == 'en'">Document Details</h4>
                            <h4 class="col-sm-12" *ngIf="lang == 'mar'">कागदपत्रे तपशील</h4>
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered mb-4">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th><span *ngIf="lang == 'en'">Doc Type</span><span
                                                        *ngIf="lang == 'mar'">कागदपत्रे प्रकार</span></th>
                                                <th><span *ngIf="lang == 'en'">Doc Name</span><span
                                                        *ngIf="lang == 'mar'">कागदपत्रे नाव</span></th>
                                                <th><span *ngIf="lang == 'en'">Yes/No</span><span
                                                        *ngIf="lang == 'mar'">हो \ नाही</span></th>
                                                <th><span *ngIf="lang == 'en'">Upload Doc.</span><span
                                                        *ngIf="lang == 'mar'">अपलोड कागदपत्रे</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>



                                            <tr *ngFor='let rec of lab_DocList;let i = index'>
                                                <td>{{rec.DocType}}</td>
                                                <td>{{rec.DocName}}</td>
                                                <td>

                                                    <input *ngIf="rec.DocID > 0" type="checkbox" class="checkbox"
                                                        (change)="set_doc_Sel(i)" [checked]="rec.isSel">
                                                </td>
                                                <td>
                                                    <div *ngIf="rec.DocID > 0" class="custom-file">
                                                        <!-- <input type="file" class="custom-file-input" id="customFile" (change)="set_Sel_docFile($event,i)" (click)="$event.target.value=null"> -->
                                                        <input type="file" (change)="set_Sel_docFile($event,i)"
                                                            (click)="$event.target.value=null"
                                                            placeholder="Upload file">
                                                        <!-- <label class="custom-file-label" for="customFile" >Choose file</label> -->


                                                        <i *ngIf="rec.FlName != ''" class="fa fa-file"
                                                            ngbTooltip="view file" (click)="view_uploadFile(rec.FlName)"
                                                            aria-hidden="true" style="color:black;"> </i>

                                                    </div>
                                                </td>
                                            </tr>



                                        </tbody>
                                    </table>

                                </div>


                            </div>

                            <div *ngIf="Log_accessFor == 'Auth'" class="form-group col-sm-12">
                                <label *ngIf="lang == 'en'"> <strong>* If Application Reject Enter
                                        Remark</strong></label>
                                <label *ngIf="lang == 'mar'"><strong>* नोंदणी मंजुरी रद्द असेल तर तपशील आवश्यक
                                        आहे</strong></label>
                                <input type="text" class="form-control" [value]="rejRemark"
                                    (change)="set_rejRemark($event)" placeholder="">
                            </div>


                            <div *ngIf="Log_accessFor == '' && (Appl_Status == 'Pending' || Appl_Status == 'Reject'  || Appl_Status == '')"
                                class="col-sm-12 text-right">
                                <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                    (click)="submit_step_4()" *ngIf="lang == 'en'">Submit</a>
                                <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;"
                                    (click)="submit_step_4()" *ngIf="lang == 'mar'">सबमिट</a>

                                <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                    (click)="set_step(3)" *ngIf="lang == 'en'">Prev</a>
                                <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                    (click)="set_step(3)" *ngIf="lang == 'mar'">मागे </a>
                            </div>


                            <div *ngIf="Log_accessFor == 'Auth'" class="col-sm-12 text-right">

                                <!-- <a class="btn-fullfil btn-comman mr-3 float-right" style="color: white;"
                                *ngIf="lang == 'en'">Reject</a> -->

                                <button type="button" *ngIf="lang == 'en'" (click)="authorize_appl('reject')"
                                    class="btn btn-outline-danger btn-xs">Reject</button>&nbsp;&nbsp;

                                <!-- <a class="btn-fullfil btn-comman mr-3 float-right" style="color: white;"
                                *ngIf="lang == 'mar'">मंजुरी रद्द  </a> -->

                                <button type="button" *ngIf="lang == 'mar'" (click)="authorize_appl('reject')"
                                    class="btn btn-outline-danger btn-xs">मंजुरी रद्द</button>

                                <!-- <a class="btn-fullfil btn-comman mr-3 float-right" style="color: white;"
                                     *ngIf="lang == 'en'">Accept</a>
                                <a class="btn-fullfil btn-comman mr-3 float-right" style="color: white;"
                                     *ngIf="lang == 'mar'">मंजुरी आहे </a> -->

                                <button type="button" *ngIf="lang == 'en'" (click)="authorize_appl('accept')"
                                    class="btn btn-outline-primary btn-xs">Accept</button> &nbsp;&nbsp;
                                <button type="button" *ngIf="lang == 'mar'" (click)="authorize_appl('accept')"
                                    class="btn btn-outline-primary btn-xs">मंजुरी आहे</button>

                                <!-- <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                    (click)="set_step(3)" *ngIf="lang == 'en'">Prev</a>
                                <a class="btn-dark btn-comman mr-2 float-right" style="color: white;"
                                    (click)="set_step(3)" *ngIf="lang == 'mar'">मागे </a> -->
                            </div>

                        </div>
                    </div>

                </div>



            </div>

        </div>

    </section>

</div>
</div>