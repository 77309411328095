<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Establishments Office Dashboard</li>
                <!-- <li>{{tempdate}}</li> -->
            </ol>
        </nav>
    </div>

    <!-- waiting modal -->

    <div class="container">

        <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <!-- Modal body -->
                    <div class="modal-body">

                        <div style="text-align: center;">

                            Please wait ...
                            <div class="spinner-grow text-success"></div>
                            <div class="spinner-grow text-info"></div>
                            <div class="spinner-grow text-warning"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- waiting modal -->

    <body>

        <section id="company-dashboard">
            <div class="content-panel">
                <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                    <ngb-panel title="Reports" id="config-panel-one">
                        <ng-template ngbPanelContent>
                            <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active"
                                class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Wages/Levy Receipt</a>
                                    <ng-template ngbNavContent>

                                        <!-- From And To Date -->
                                        <div class="row">
                                            <div class="col-md-5">
                                                <label for="Financial-Year">From Date </label>
                                                <input type="date" class="form-control"
                                                    (change)="set_Date($event,'frmdt')" value="{{frm_Date}}" />
                                            </div>
                                            <div class="col-md-5" style="background-color: yello">
                                                <label for="Financial-Year">To Date </label>
                                                <input type="date" class="form-control"
                                                    (change)="set_Date($event,'todt')" value="{{to_Date}}" />
                                            </div>
                                            <div class="col-md-2" style="text-align: left;margin-top: 25px;">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="get_Data('wlrec')">Show</button>
                                            </div>
                                        </div>

                                        <div class="table-responsive" style="height: 400px;overflow: auto">
                                            <table class="table table-hover table-responsive-sm labour-table">
                                                <thead>
                                                    <tr>
                                                        <!-- <th>Sr.No</th> -->
                                                        <th>Receipt No</th>
                                                        <th>Date</th>
                                                        <th>Toli No.</th>
                                                        <th>Name</th>
                                                        <th style="text-align: right;">Wage Year</th>
                                                        <th style="text-align: right;">Wage Month</th>
                                                        <th style="text-align: right;">Amount</th>
                                                        <!-- <th style="text-align: right;">Levy Amt.</th>
                                                        <th style="text-align: right;">Anamat Amt.</th>
                                                        <th style="text-align: right;">Tot. Amt.</th> -->
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container>
                                                        <tr class="sub-tr">
                                                            <!-- <td scope="row"><i class="fa fa-search" aria-hidden="true"></i></td> -->
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_RecNo"
                                                                    (ngModelChange)="filter_recDone('receiptNO',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_docdt"
                                                                    (ngModelChange)="filter_recDone('docdt',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_toliNo"
                                                                    (ngModelChange)="filter_recDone('toliNo',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_mukName"
                                                                    (ngModelChange)="filter_recDone('mukName',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_wageyr"
                                                                    (ngModelChange)="filter_recDone('wageyr',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_wageMon"
                                                                    (ngModelChange)="filter_recDone('wageMon',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_wagesAmt"
                                                                    (ngModelChange)="filter_recDone('wagesAmt',$event)" />
                                                            </td>
                                                            <!-- <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_levyAmt"
                                                                    (ngModelChange)="filter_recDone('levyAmt',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_levyAmt"
                                                                    (ngModelChange)="filter_recDone('anamatAmt',$event)" />
                                                            </td>
                                                            <td style="text-align: left;"><input class="form-control"
                                                                    [ngModel]="filter_totAmt"
                                                                    (ngModelChange)="filter_recDone('totAmt',$event)" />
                                                            </td> -->

                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                       

                                                        <tr class="sub-tr" style="font-weight:lighter;color: black;"
                                                            *ngFor='let rec of rec_DoneToli;let i = index'>

                                                            <!-- <td scope="row">{{i + 1}}</td> -->
                                                            <td style="text-align: left;">{{rec.receiptNO}}</td>
                                                            <td style="text-align: left;">{{rec.docdt}}</td>
                                                            <td style="text-align: left;">{{rec.toliNo}}</td>
                                                            <td style="text-align: left;width: 450px;">{{rec.mukName}}</td>
                                                            <td style="text-align: left;">{{rec.wageyr}}</td>
                                                            <td style="text-align: left;">{{rec.wageMon}}</td>
                                                            <td style="text-align: right;">{{rec.wagesAmt}}</td>
                                                            <!-- <td style="text-align: right;">{{rec.levyAmt}}</td>
                                                            <td style="text-align: right;">{{rec.anamatAmt}}</td>
                                                            <td style="text-align: right;">{{rec.totAmt}}</td> -->

                                                            <td><a href="javascript:void(0)">
                                                                <i class="fa fa-print" (click)="genrate_Wl_Rec_rpt(rec.docID,rec.fayear,rec.estID)"
                                                                        ngbTooltip="Print" aria-hidden="true"></i></a>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tfoot>
                                                    <tr Class="table-footer">
                                                        <td> </td>
                                                        <td> </td>
                                                        <td> </td>
                                                        <td> </td>
                                                        <td> </td>
                                                        <td>Total </td>
                                                        <td style="text-align: right;">
                                                            {{getTotal(rec_DoneToli,'wagesAmt')}}
                                                        </td>
                                                        <!-- <td style="text-align: right;">
                                                            {{getTotal(rec_DoneToli,'levyAmt')}}
                                                        </td>
                                                        <td style="text-align: right;">
                                                            {{getTotal(rec_DoneToli,'anamatAmt')}}
                                                        </td>
                                                        <td style="text-align: right;">
                                                            {{getTotal(rec_DoneToli,'totAmt')}}</td> -->
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>

                                            </table>
                                        </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>Ledger</a>
                                    <ng-template ngbNavContent>

                                        <!-- From And To Date -->
                                        <div class="row">
                                            <div class="col-md-5">
                                                <label for="Financial-Year">From Date </label>
                                                <input type="date" class="form-control"
                                                    (change)="set_Date($event,'frmlgrdt')" value="{{frm_lgr_Date}}" />
                                            </div>
                                            <div class="col-md-5" style="background-color: yello">
                                                <label for="Financial-Year">To Date </label>
                                                <input type="date" class="form-control"
                                                    (change)="set_Date($event,'tolgrdt')" value="{{to_lgr_Date}}" />
                                            </div>
                                            <div class="col-md-2" style="text-align: right;margin-top: 25px;">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="genrate_EstLeadger_rpt()">Genrate PDf</button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>

                            <div [ngbNavOutlet]="nav" class="mt-2"></div>

                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel title="Payment Pending">
                        <ng-template ngbPanelContent>

                        </ng-template>
                    </ngb-panel> -->


                </ngb-accordion>
            </div>
        </section>
    </body>
</div>