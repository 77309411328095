<div  class="notranslate">

<div class="container dashboardmain">

    <!-- <p>Simple datepicker</p>

    <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"></ngb-datepicker>
    
    <hr/>
    
    <button class="btn btn-sm btn-outline-primary me-2" (click)="selectToday()">Select Today</button>
    <button class="btn btn-sm btn-outline-primary me-2" (click)="dp.navigateTo()">To current month</button>
    <button class="btn btn-sm btn-outline-primary me-2" (click)="dp.navigateTo({year: 2013, month: 2})">To Feb 2013</button>
    
    <hr/>
    
    <pre>Month: {{ date.month }}.{{ date.year }}</pre>
    <pre>Model: {{ model | json }}</pre> -->

    
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboard/clerk-dashboard">Clerk
                        Dashboard</a></li>
                <li class="breadcrumb-item " aria-current="page">Payment (Regular Labour Payments) </li>
            </ol>
        </nav>
    </div>

   

    <body>
      
  
        <section id="company-dashboard">
          <div class="container">
              <div class="row">
  
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="content-panel">
                          <div class="row">
  
                              <h4 class="col-sm-12">Payment Information</h4>
                              
                              <div class="form-group col-md-2">
                                  <label>Wages Year <strong>*</strong></label>
                                  <input type="text" class="form-control" name="txtwageYr" id="txtwageYr" (input)="clear_Data()" [(ngModel)]="filterYr" value="{{filterYr}}" placeholder="" (keypress)="onlyNumberKey($event)" maxlength="4" [disabled]='payPaidCnt_disabled'>
                              </div>
                              <div class="form-group col-md-2">
                                  <label>Wages Month <strong>*</strong></label>
                                  <input type="text" class="form-control" name="txtwageMon" id="txtwageMon" (input)="clear_Data()" [(ngModel)]="filterMonth" value="{{filterMonth}}" placeholder="" (keypress)="onlyNumberKey($event)" maxlength="2" [disabled]='payPaidCnt_disabled'>
                              </div>
                              <div class="form-group col-md-3">
                                  <label>Date <strong>*</strong></label>
                                  <input type="date" class="form-control" (change)="setDate($event)" value="{{payDate}}"  />
                                  <!-- <input type="date" class="form-control" (change)="setDate($event)" value="{{recDate}}" [disabled]='recPaidCnt_disabled'/> -->
                                  <!-- <select class="form-select" name="" id="">
                                      <option value="">-- Select Date --</option>
                                      <option value="">1</option>
                                      <option value="">2</option>
                                  </select> -->
                              </div>
                              <div class="form-group col-md-2">
                                  <label>Pay Type <strong>*</strong></label>
                                  <select class="form-select" (change)=set_selPayType($event)  name="selpaytype" id="selpaytype" #selpaytype [disabled]='payPaidCnt_disabled'>
                                      <!-- <option value="">-- Select Pay Type --</option> -->
                                      <!-- <option [value]="Manual" [selected]="bind_payType">Manual</option>
                                      <option [value]="Calculate" [selected]="bind_payType">Calculate</option> -->

                                      <option *ngFor="let rec of payTypeList" [value]="rec.typeVal" [selected]="rec.typeVal ===setpayType">{{rec.paytype}}</option>
                                  </select>
                              </div>
                              <div class="form-group col-md-2">
                                  <label>Lab Type <strong>*</strong></label>
                                  <select class="form-select" name="sellabtype" id="sellabtype" [disabled]='payPaidCnt_disabled'>
                                      <!-- <option value="">-- Select Lab Type --</option> -->
                                      <option value="Hamal">Hamal</option>
                                      <option value="Badali">Badali</option>
                                  </select>
                              </div>
                             
                          </div>
                          <div class="row">
                              <div class="form-group col-md-6">
                                  <div class="input-group">
                                      <span class="input-group-text"><i class="bi bi-search"></i></span>
                                      <input type="text" class="form-control" [(ngModel)]="searchToli" (input)="serch_toli()" placeholder="Search gat by number or name">
                                      
                                  </div>
                                  <div>
                                    <ul class="list-group list-group-flush anyClass" style="margin-left: 40px;">
                                      <li class="list-group-item nav-item" *ngFor="let rec of filter_toli" appHighlight
                                        [searchedWord]="searchToli" [content]="rec.toliNo - rec.mukName" [classToApply]="'font-weight-bold'"
                                        [setTitle]="'true'" (click)="set_searchToli(rec.toliId,rec.toliNo,rec.mukName,rec.levy,'yes');"
                                        style="background-color: #dcf9f6;">
                                        {{rec.toliNo}} - {{rec.mukName}}
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div class="form-group col-md-4">
                                  <div class="input-group" style="color: blue;">
                                    {{toliInfo}}
                                      <!-- <span class="input-group-text"><i class="bi bi-search"></i></span>
                                      <input type="text" class="form-control" placeholder="Search Division"> -->
                                  </div>
                              </div>
                              <div class="col-sm-2 text-left">
                                <button type="button" class="btn-fullfil btn-comman mr-2" *ngIf="!showBtnDisabled" (click) = "show_data(false)">Show</button>
                             </div>
                          </div>
                          <div class="row">
                            <div *ngIf="isBankList_Auth == 'yes'" style="text-align: center;color: red;">
                                <h2 style="color: red;">{{str_payinfo}}</h2>
                              </div>
                          </div>
                      </div>
                      <div class="content-panel">
                          <div class="row">
                              <h4 class="col-sm-12">Receipt Information <span><button type="button" class="btn-bordered btn-comman" data-toggle="modal" data-target="#show_receipt_pay">Show Receipt Pay</button></span></h4>
                              <div class="col-sm-12">
                                  <div class="table-responsive mb-4">
                                      <table class="table table-bordered">
                                          <thead>
                                              <tr>
                                                  <th></th>
                                                  <th>Wages</th>
                                                  <th>Levy</th>
                                                  <th>Total</th>
                                                  <th>Est. Advance Amount</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr>
                                                  <th>Receipt Wages</th>
                                                  <td>{{rec_HW}}</td>
                                                  <td>{{rec_HL}}</td>
                                                  <td style="color: blue;font-weight: bold;">{{tot_rec_WL}}</td>
                                                  <td>{{rec_estAdv}}</td>
                                              </tr>
                                              <tr>
                                                  <th>District Wages</th>
                                                  <td>{{paid_HW}}</td>
                                                  <td>{{paid_HL}}</td>
                                                  <td style="color: blue;font-weight: bold;">{{tot_paid_WL}}</td>
                                                  <td>{{paid_estAdv}}</td>
                                              </tr>
                                              <tr style="font-weight: bold;">
                                                  <th style="color: blue;font-weight: bold;">Difference</th>
                                                  <td [style.color]="diff_HW == 0 ? 'blue': 'red'">{{diff_HW}}</td>
                                                  <td [style.color]="difff_HL == 0 ? 'blue': 'red'">{{difff_HL}}</td>
                                                  <td [style.color]="diff_tot == 0 ? 'blue': 'red'">{{diff_tot}}</td>
                                                  <td [style.color]="diff_estAdv == 0 ? 'blue': 'red'">{{diff_estAdv}}</td>

                                                 
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
  
                              </div>
                              <div class="col-sm-6 text-left" *ngIf="mode == 'Add' || mode == 'Modify'"> 
                                <button type="button" class="btn-dark btn-comman mr-2" data-toggle="modal" data-target="#add-new-lab" (click)="fill_addnewLab_List()">+ Add New Lab</button>
                                <button type="button" *ngIf="mode == 'Modify'" class="btn-dark btn-comman" (click)="fill_modnewLab_List()">Modify With New Lab</button>
                                   <!-- <button type="button" class="btn-fullfil btn-comman mr-2">Show</button> -->
                                  

                                  
                               </div>

                               
                               <div class="col-sm-6 text-right" *ngIf="(mode == 'Add' || mode == 'Modify')" > 
                                <button type="button" *ngIf="(mode == 'Add' || mode == 'Modify') && setpayType == 'Calculate'"  class="btn-bordered btn-comman mr-2" (click)="this.auto_adjuest_diff(rec_HW,diff_HW,'wages')">Auto Adjuest Wages</button>
                                <button type="button" *ngIf="(mode == 'Add' || mode == 'Modify') " class="btn-bordered btn-comman" (click)="this.auto_adjuest_diff(rec_HL,difff_HL,'levy')">Auto Adjuest Levy</button>
                            </div>
                              

                              
                             
                          </div>
                      </div>
  
                      <div class="content-panel">
                         
                          <div class="row">
                            <!-- <h4  class="col-sm-3">Payment Information</h4> -->
                            <!-- *ngIf="setpayType == 'Calculate'"  -->
                            <div class="form-group col-md-2" hidden> 
                               
                                <select class="form-select" name="selpaymode" id="selpaymode" (change)=set_selPayMode($event)>
                                    <!-- <option value="">-- Select Lab Type --</option> -->
                                    <option value="Manual">Manual</option>
                                    <option value="Auto">Auto</option>
                                </select>
                            </div>

                            <div class="col-sm-12 text-right">
                                <button type="button" *ngIf="mode == 'Modify'"class="btn-fullfil btn-comman mr-2"  (click) = "fill_AutoDed('yes')">Show New Data</button>
                                <button type="button" *ngIf="mode == 'Add' || mode == 'Modify'" class="btn-dark btn-comman mr-2" (click)="save_payment()">Submit</button>
                                <button type="button"  *ngIf="mode == 'Finalize'" class="btn-fullfil btn-comman mr-2" (click)="finalize_payment('yes')" >Finalize</button>
                                <button type="button"  *ngIf="mode == 'Finalize'" class="btn-danger btn-comman" (click)="del_labPayment()">Delete</button>
                                
                              </div>

                              <!-- <div class="col-sm-12 text-right">
                                <a *ngIf="mode == 'Add' || mode == 'Modify'" class="btn-dark btn-comman mr-2"  (click)="get_auto_deuduction(2)">Fill Deduction</a>
                                   
                                
                              </div> -->

                            
                             <!-- <div class="col-sm-6 text-right" *ngIf="mode == 'Add' || mode == 'Modify'"> 
                              
                             </div> -->

                                 <div class="col-sm-12">
                                  <nav>
                                      <ul class="nav nav-tabs" id="nav-tab" >
                                          <li><a href="#" class="nav-link" id="" data-toggle="tab" data-target="#presenty" > Presenty</a></li>
                                          <li><a href="#" class="nav-link" id="" data-toggle="tab" data-target="#matha" >Matha</a></li>
                                          <li><a href="#" class="nav-link active" id="" data-toggle="tab" data-target="#payment" >Payment</a></li>
                                      </ul>
                                  </nav>
                                  <div class="tab-content"  id="nav-tabContent" >
                                      <!-- <div [class]="setpayType == 'Calculate' ? 'tab-pane fade show active': 'tab-pane fade'" id="presenty" *ngIf="setpayType == 'Calculate'"> -->
                                        <div class="tab-pane fade" id="presenty" >
                                          <div class="table-responsive" style="height: 400px;" *ngIf="setpayType == 'Calculate'">
                                              <table class="table table-bordered mb-4" >
                                                  <thead class="thead-dark">
                                                      <tr>
                                                          <th>Sr. No.</th>
                                                          <th>RegNo</th>
                                                          <th>Name</th>
                                                          <th>1</th>
                                                          <th>2</th>
                                                          <th>3</th>
                                                          <th>4</th>
                                                          <th>5</th>
                                                          <th>6</th>
                                                          <th>7</th>
                                                          <th>8</th>
                                                          <th>9</th>
                                                          <th>10</th>
                                                          <th>11</th>
                                                          <th>12</th>
                                                          <th>13</th>
                                                          <th>14</th>
                                                          <th>15</th>
                                                          <th>16</th>
                                                          <th>17</th>
                                                          <th>18</th>
                                                          <th>19</th>
                                                          <th>20</th>
                                                          <th>21</th>
                                                          <th>22</th>
                                                          <th>23</th>
                                                          <th>24</th>
                                                          <th>25</th>
                                                          <th>26</th>
                                                          <th>27</th>
                                                          <th>28</th>
                                                          <th>29</th>
                                                          <th>30</th>
                                                          <th>31</th>
                                                          <th>Tot.</th>
                                                          <th></th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr *ngFor='let rec of insup_wgMst;let i = index'[style.background-color]="rec.selrowcolor" (click)="setselrow_Color(i)">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{rec.labRegNo}}</td>
                                                        <td>{{rec.labName}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day1Math == 'A' ? 'red': 'blue'">{{rec.day1Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day2Math == 'A' ? 'red': 'blue'">{{rec.day2Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day3Math == 'A' ? 'red': 'blue'">{{rec.day3Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day4Math == 'A' ? 'red': 'blue'">{{rec.day4Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day5Math == 'A' ? 'red': 'blue'">{{rec.day5Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day6Math == 'A' ? 'red': 'blue'">{{rec.day6Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day7Math == 'A' ? 'red': 'blue'">{{rec.day7Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day8Math == 'A' ? 'red': 'blue'">{{rec.day8Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day9Math == 'A' ? 'red': 'blue'">{{rec.day9Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day10Math == 'A' ? 'red': 'blue'">{{rec.day10Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day11Math == 'A' ? 'red': 'blue'">{{rec.day11Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day12Math == 'A' ? 'red': 'blue'">{{rec.day12Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day13Math == 'A' ? 'red': 'blue'">{{rec.day13Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day14Math == 'A' ? 'red': 'blue'">{{rec.day14Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day15Math == 'A' ? 'red': 'blue'">{{rec.day15Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day16Math == 'A' ? 'red': 'blue'">{{rec.day16Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day17Math == 'A' ? 'red': 'blue'">{{rec.day17Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day18Math == 'A' ? 'red': 'blue'">{{rec.day18Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day19Math == 'A' ? 'red': 'blue'">{{rec.day19Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day20Math == 'A' ? 'red': 'blue'">{{rec.day20Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day21Math == 'A' ? 'red': 'blue'">{{rec.day21Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day22Math == 'A' ? 'red': 'blue'">{{rec.day22Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day23Math == 'A' ? 'red': 'blue'">{{rec.day23Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day24Math == 'A' ? 'red': 'blue'">{{rec.day24Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day25Math == 'A' ? 'red': 'blue'">{{rec.day25Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day26Math == 'A' ? 'red': 'blue'">{{rec.day26Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day27Math == 'A' ? 'red': 'blue'">{{rec.day27Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day28Math == 'A' ? 'red': 'blue'">{{rec.day28Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day29Math == 'A' ? 'red': 'blue'">{{rec.day29Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day30Math == 'A' ? 'red': 'blue'">{{rec.day30Math}}</td>
                                                          <td style="font-weight: bold;" [style.color]="rec.day31Math == 'A' ? 'red': 'blue'">{{rec.day31Math}}</td>
                                                          <td style="font-weight: bold;">{{rec.totPrsDay}}</td>
                                                          <td><button type="button" class="btn btn-dark" data-toggle="modal" data-target="#presenty-calendar" (click)="set_presenty(i);dp.navigateTo({year: filterYr, month: filterMonth})" ><i class="bi bi-pencil-fill"></i></button></td>
                                                      </tr>
                                                    
  
                                                  </tbody>
                                                  <tfoot>
                                                    <tr style="font-weight: bold;color: blue;">
                                                        <td></td>
                                                        <td></td>
                                                        <td>Total :</td>
                                                          <td [style.color]="d1 == 0 ? 'red': 'blue'">{{d1}}</td>
                                                          <td [style.color]="d2 == 0 ? 'red': 'blue'">{{d2}}</td>
                                                          <td [style.color]="d3 == 0 ? 'red': 'blue'">{{d3}}</td>
                                                          <td [style.color]="d4 == 0 ? 'red': 'blue'">{{d4}}</td>
                                                          <td [style.color]="d5 == 0 ? 'red': 'blue'">{{d5}}</td>
                                                          <td [style.color]="d6 == 0 ? 'red': 'blue'">{{d6}}</td>
                                                          <td [style.color]="d7 == 0 ? 'red': 'blue'">{{d7}}</td>
                                                          <td [style.color]="d8 == 0 ? 'red': 'blue'">{{d8}}</td>
                                                          <td [style.color]="d9 == 0 ? 'red': 'blue'">{{d9}}</td>
                                                          <td [style.color]="d10 == 0 ? 'red': 'blue'">{{d10}}</td>
                                                          <td [style.color]="d11 == 0 ? 'red': 'blue'">{{d11}}</td>
                                                          <td [style.color]="d12 == 0 ? 'red': 'blue'">{{d12}}</td>
                                                          <td [style.color]="d13 == 0 ? 'red': 'blue'">{{d13}}</td>
                                                          <td [style.color]="d14 == 0 ? 'red': 'blue'">{{d14}}</td>
                                                          <td [style.color]="d15 == 0 ? 'red': 'blue'">{{d15}}</td>
                                                          <td [style.color]="d16 == 0 ? 'red': 'blue'">{{d16}}</td>
                                                          <td [style.color]="d17 == 0 ? 'red': 'blue'">{{d17}}</td>
                                                          <td [style.color]="d18 == 0 ? 'red': 'blue'">{{d18}}</td>
                                                          <td [style.color]="d19 == 0 ? 'red': 'blue'">{{d19}}</td>
                                                          <td [style.color]="d20 == 0 ? 'red': 'blue'">{{d20}}</td>
                                                          <td [style.color]="d21 == 0 ? 'red': 'blue'">{{d21}}</td>
                                                          <td [style.color]="d22 == 0 ? 'red': 'blue'">{{d22}}</td>
                                                          <td [style.color]="d23 == 0 ? 'red': 'blue'">{{d23}}</td>
                                                          <td [style.color]="d24 == 0 ? 'red': 'blue'">{{d24}}</td>
                                                          <td [style.color]="d25 == 0 ? 'red': 'blue'">{{d25}}</td>
                                                          <td [style.color]="d26 == 0 ? 'red': 'blue'">{{d26}}</td>
                                                          <td [style.color]="d27 == 0 ? 'red': 'blue'">{{d27}}</td>
                                                          <td [style.color]="d28 == 0 ? 'red': 'blue'">{{d28}}</td>
                                                          <td [style.color]="d29 == 0 ? 'red': 'blue'">{{d29}}</td>
                                                          <td [style.color]="d30 == 0 ? 'red': 'blue'">{{d30}}</td>
                                                          <td [style.color]="d31 == 0 ? 'red': 'blue'">{{d31}}</td>
                                                          <td>{{getTotal(insup_wgMst,'totPrsDay')}}</td>
                                                          <td></td>
                                                      </tr>
                                                  </tfoot>
                                              </table>

                                          </div>

                                          <div class="content-panel" *ngIf="setpayType == 'Calculate'">
                                            <div class="row" >
                                                <h4 class="col-sm-12">Mark Presenty / Abcenty</h4>
                                                <div class="col-sm-4 mb-3"><a class="btn-bordered btn-comman"  (click)="fullmonth_prsAbc('P',1)">Full Month Presenty</a></div>
                                                <div class="col-sm-4 mb-3"><a class="btn-bordered btn-comman" (click)="fullmonth_prsAbc('A',1)">Full Month Abcenty</a></div>
                                                <div class="col-sm-4 mb-3"><a class="btn-bordered btn-comman"  (click)="fullmonth_prsAbc('P',-1)">Total Lab Present</a></div>
                                                <div class="form-group col-sm-5 mt-3">
                                                    <label>Holidays</label>
                                                    <input type="text" class="form-control" #txtholiday id="txtholiday" name="txtholiday" placeholder="Enter holidays (ex. 5,6,7,9...)">
                                                </div>
                                                <div class="form-group col-sm-1 pr-0 pl-0 mt-4" ><a class="btn-bordered btn-comman" style="margin-top: 18px;" (click)="set_holiday(txtholiday.value,1)" >Apply</a></div>
                                                <div class="form-group col-sm-3 mt-4"><a class="btn-bordered btn-comman" style="margin-top: 18px;margin-left: 5px;" (click)="set_holiday(txtholiday.value,-1)">Total Lab Apply</a></div>
                    
                                            </div>
                                        </div>

                                      </div>
                                      <div class="tab-pane fade" id="matha" >
                                          <div class="table-responsive" style="height: 400px;" *ngIf="setpayType == 'Calculate'" [ngClass]="{'disableDiv': isDisabled}">
                                              <table class="table table-bordered mb-4" >
                                                  <thead class="thead-dark">
                                                      <tr>
                                                          <th>Date</th>
                                                          <th>Lab Count</th>
                                                          <th>Wages</th>
                                                          <th>Wages +</th>
                                                          <th>Levy</th>
                                                          <th>Matha</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr *ngFor='let rec of matha_Mst;let i = index' [style.background-color]="rec.editcol == true ? '#f0f5f5': 'white'" >
                                                        <td>{{rec.strDate}}</td>
                                                        <td>{{rec.labcnt}}</td>
                                                        <td><input class="form-control form-control-sm"  type="text" [disabled]="rec.editcol" value="{{rec.wages}}" (keydown)="remove_levy_fromWages($event,i)" (keypress)="onlyDecimalNumberKey($event)"  (input)="set_change_matha_Val($event,i)" placeholder=""></td>
                                                        <td><input class="form-control form-control-sm"  type="text" [disabled]="rec.editcol" (input)="adding_matha_Wages($event,i)" placeholder=""></td>
                                                        <td>{{rec.levy}}</td>
                                                        <td>{{rec.matha}}</td>
                                                    </tr>

                                                      <!-- <tr>
                                                          <td>01/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>02/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>03/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>04/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>05/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>06/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>07/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>08/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>09/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>10/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>11/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>12/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>13/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>14/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>15/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>16/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>17/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>18/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>19/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>20/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>21/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>22/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>23/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>24/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>25/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>26/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>27/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>28/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>29/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>30/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr>
                                                      <tr>
                                                          <td>31/01/2022</td>
                                                          <td></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td><input class="form-control form-control-sm" type="text" placeholder=""></td>
                                                          <td></td>
                                                          <td></td>
                                                      </tr> -->
  
  
                                                      <tr style="font-weight: bold;color: blue;">
                                                          <th>Total</th>
                                                          <td>{{getTotal(matha_Mst,'labcnt')}}</td>
                                                          <td>{{getTotal(matha_Mst,'wages')}}</td>
                                                          <td></td>
                                                          <td>{{getTotal(matha_Mst,'levy')}}</td>
                                                          <td></td>
  
                                                      </tr>
  
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      <div class="tab-pane fade show active" id="payment" >
                                          <div class="table-responsive" style="height: 400px;">
                                              <table class="table table-bordered mb-4">
                                                  <thead class="thead-dark">
                                                      <tr>
                                                          <th>Sr. No.</th>
                                                          <th>Reg.No.</th>
                                                          <th>Name</th>
                                                          <th>Present Day</th>
                                                          <th>Prev. wages</th>
                                                          <th>Wages Diff. 1</th>
                                                          <th>Wages Diff. 2</th>
                                                          <th>Gross Amt.</th>
                                                          <th>Levy</th>
                                                          <th>PF</th>
                                                          <th>Est. Advance</th>
                                                          <th>Advance</th>
                                                          <th>PF Advance</th>
                                                          <th>PF Advance Int.</th>
                                                          <th>Patpedi Deduction</th>
                                                          <th>Insurance Deduction</th>
                                                          <th>Union</th>
                                                          <th>IT</th>
                                                          <th>Mise</th>
                                                          <th>Deduction</th>
                                                          <th>Net Pay</th>
  
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr *ngFor='let rec of insup_wgMst;let i = index' [style.background-color]="rec.netPay >= 0 ? rec.selrowcolor : 'red'" (click)="setselrow_Color(i)">
                                                          <td>{{i + 1}}</td>
                                                          <td>{{rec.labRegNo}}</td>
                                                          <td>{{rec.labName}}</td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_predays" value="{{rec.predays}}" (input)="set_ValInsUpData($event,i,'predays')"  maxlength="2" placeholder="" [disabled]="setpayType == 'Calculate' ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_preGrossAmt" value="{{rec.preGrossAmt}}" (input)="set_ValInsUpData($event,i,'preGrossAmt')" (change)="get_auto_deuduction(i)"  placeholder="" [disabled]="setpayType == 'Calculate' || rec.predays == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_diffAmt1" value="{{rec.diffAmt1}}" (input)="set_ValInsUpData($event,i,'diffAmt1')" (change)="get_auto_deuduction(i)" placeholder="" [disabled]="setpayType == 'Calculate' || rec.predays == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_diffAmt2" value="{{rec.diffAmt2}}" (input)="set_ValInsUpData($event,i,'diffAmt2')" (change)="get_auto_deuduction(i)" placeholder="" [disabled]="setpayType == 'Calculate' || rec.predays == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_actualGrossAmt" value="{{rec.actualGrossAmt}}"  placeholder="" disabled></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_levyamt" value ="{{rec.levyAmt}}" (input)="set_ValInsUpData($event,i,'levyAmt')" placeholder="" disabled></td>
                                                          <!-- [disabled]="setpayType == 'Calculate' || rec.actualGrossAmt == 0 ? true: false" -->
                                                          
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_pf" value="{{rec.pf}}" placeholder="" disabled></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_advAmt" value="{{rec.advAmt}}" (input)="set_ValInsUpData($event,i,'advAmt')"  placeholder="" [disabled]="rec.actualGrossAmt == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_advLoanAmt" value="{{rec.advLoanAmt}}" (input)="set_ValInsUpData($event,i,'advLoanAmt')" placeholder="" [disabled]="rec.actualGrossAmt == 0 || rec.advLoanID == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_pfAdvCutting" value="{{rec.pfAdvCutting}}" (input)="set_ValInsUpData($event,i,'pfAdvCutting')" placeholder="" [disabled]="rec.actualGrossAmt == 0 || rec.pfLoanID == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" id="inpt_pfAdvint" value="{{rec.pfAdvint}}" (input)="set_ValInsUpData($event,i,'pfAdvint')" placeholder="" [disabled]="rec.actualGrossAmt == 0 || rec.pfLoanID == 0 ? true: false"></td>
                                                          <td >
                                                              {{rec.patpediAmt1}}
                                                              <a class="btn-link text-info" href="#" data-toggle="modal" data-target="#patpedi" (click)="set_patpediDed(i)" *ngIf="rec.actualGrossAmt > 0"><i class="bi bi-pencil-fill"></i></a>
                                                          </td>
                                                          <td >
                                                              {{rec.insuranceAmt}}
                                                              <a class="btn-link text-info" href="#" data-toggle="modal" data-target="#insurance" (click)="set_InsuranceDed(i)" *ngIf="rec.actualGrossAmt > 0"><i class="bi bi-pencil-fill"></i></a>
                                                          </td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" value="{{rec.unionAmt}}" (input)="set_ValInsUpData($event,i,'unionAmt')"  placeholder="" [disabled]="rec.actualGrossAmt == 0 || rec.unionID == 0 ? true: false"></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" value="{{rec.itCutting}}" placeholder=""  disabled></td>
                                                          <td><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" value="{{rec.misc}}" (input)="set_ValInsUpData($event,i,'misc')"  placeholder="" [disabled]="rec.actualGrossAmt == 0 ? true: false"></td>
                                                          <td>{{rec.deduction}}</td>
                                                          <td>{{rec.netPay}}</td>
  
                                                      </tr>
                                                    
                                                      <tr style="color: blue;font-weight: bold;">
                                                          <!-- <th>Total </th>
                                                          <td colspan="19">34353</td> -->

                                                          <td></td>
                                                          <td></td>
                                                          <td>Total</td>
                                                          <td>{{getTotal(insup_wgMst,'predays')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'preGrossAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'diffAmt1')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'diffAmt2')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'actualGrossAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'levyAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'pf')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'advAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'advLoanAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'pfAdvCutting')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'pfAdvint')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'patpediAmt1')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'insuranceAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'unionAmt')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'itCutting')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'misc')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'deduction')}}</td>
                                                          <td>{{getTotal(insup_wgMst,'netPay')}}</td>
  
                                                      </tr>
  
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                      <div class="content-panel" *ngIf="mode == 'Finalize'">
                         <div class="col-sm-12 mb-4 mt-2" ><a class="btn-bordered btn-comman" (click)="update_bank_accNo()">Update Bank A/C No.</a></div>
                       </div>
                  </div>
                 
              </div>
          </div>
      </section>

        <!-- Add New Lab Modal -->
    <div class="modal fade" id="add-new-lab" tabindex="-1">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Add New Labour</h5>
                  <button type="button" class="btn-close" data-dismiss="modal"><i class="bi bi-x"></i></button>
              </div>
              <div class="modal-body">
                  <div class="form-group">
                      <div class="input-group">
                          <span class="input-group-text"><i class="bi bi-search"></i></span>
                          <input type="text" class="form-control" [(ngModel)]="searchNewLab" (input)="serch_newLab()" placeholder="Search by Registration No. & Name">
                      </div>
                      <div>
                        <ul class="list-group list-group-flush anyClass" style="margin-left: 40px;">
                          <li class="list-group-item nav-item" *ngFor="let rec of filter_new_labList" appHighlight
                            [searchedWord]="searchNewLab" [content]="rec.labRegNo - rec.labName" [classToApply]="'font-weight-bold'"
                            [setTitle]="'true'" (click)="set_search_NewLab(rec.labourID,rec.labRegNo,rec.labName,rec.sex,rec.accNo);"
                            style="background-color: #dcf9f6;">
                            {{rec.labRegNo}} - {{rec.labName}}
                          </li>
                        </ul>
                      </div>
                  </div>
                  <table class="table">
                      <thead>
                          <tr>
                              <th>Registration No.</th>
                              <th>Name</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td style="color: blue;">{{add_newLabRegNo}}</td>
                              <td style="color: blue;">{{add_newLabName}}</td>
                          </tr>
                          
                      </tbody>
                  </table>
                  <hr>
                  <div class="form-group">
                      <div class="input-group">
                          <span class="input-group-text"><i class="bi bi-search"></i></span>
                          <input type="text" class="form-control" [(ngModel)]="searchNewLabBank" (input)="serch_newLabBank()" placeholder="Search Bank Name">
                      </div>
                      <div>
                        <ul class="list-group list-group-flush anyClass" style="margin-left: 40px;">
                          <li class="list-group-item nav-item" *ngFor="let rec of filter_newLab_bankList" appHighlight
                            [searchedWord]="searchNewLabBank" [content]="rec.bankName" [classToApply]="'font-weight-bold'"
                            [setTitle]="'true'" (click)="set_search_NewLabBank(rec.bankID,rec.bankName,rec.accNo);"
                            style="background-color: #dcf9f6;">
                            {{rec.bankName}}
                          </li>
                        </ul>
                      </div>
                  </div>
                  <table class="table">
                      <thead>
                          <tr>
                              <th>Bank Name</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td style="color: blue;">{{add_newLabBankName}}</td>
                          </tr>
                          
                      </tbody>
                  </table>
                  <hr>
                  <div class="form-group">
                      <label class="form-label">Bank A/C No.</label>
                      <div class="input-group">
                          <input type="text" style="color: blue;font-weight: bold;" class="form-control" value="{{add_newLabAccNo}}" placeholder="Enter Bank Account Number">
                      </div>
                  </div>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn-fullfil btn-comman" (click)="bind_addNew_Lab(add_newLabID,add_newLabRegNo,add_newLabName,add_newLabSex,add_newLabAccNo,add_newLabBankID)">Save</button>
              </div>
          </div>
      </div>
  </div>

  <!-- Add New Lab Modal -->
  <div class="modal fade" id="presenty-calendar" tabindex="-1">
      <div class="modal-dialog  modal-xl">
          <div class="modal-content">
              <div class="modal-body">
                  <button type="button" class="btn-close" data-dismiss="modal"><i class="bi bi-x"></i></button>
                  <div class="app">
                    <h5 style="color: black;font-weight: bold;">Lab.Info : {{seleLabRegNo}} - {{selLabName}}</h5>
                      <div class="app__main">
                          <div class="calendar">
                              <div id="calendar" style="text-align: center;">
                                <ngb-datepicker #dp  [(ngModel)]="model" (navigate)="date = $event.next" (click)="mark_presenty();"  ></ngb-datepicker>
                               

                                
                      <table class="table table-bordered mb-4">
                        <thead class="thead-dark">
                            <tr>
                                <th>Day In Month</th>
                                <th>Present Days</th>
                                <th>Abcent Day</th>
                            </tr>
                           
                        </thead>
                        <tbody>
                            <tr>
                                <td style="color: black;font-weight: bold;">{{daysInMonth}}</td>
                                <td style="color: blue;font-weight: bold;">{{labwise_totPrsDay}}</td>
                                <td style="color: red;font-weight: bold;">{{daysInMonth - labwise_totPrsDay}}</td>
                            </tr>
                        </tbody>
                      </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr>
                  <div class="table-responsive">
                      
                      <table class="table table-bordered mb-4">
                          <thead class="thead-dark">
                              <tr>
                                <!-- <th>Reg.No.</th>
                                <th>Name</th> -->
                                  <th>1</th>
                                  <th>2</th>
                                  <th>3</th>
                                  <th>4</th>
                                  <th>5</th>
                                  <th>6</th>
                                  <th>7</th>
                                  <th>8</th>
                                  <th>9</th>
                                  <th>10</th>
                                  <th>11</th>
                                  <th>12</th>
                                  <th>13</th>
                                  <th>14</th>
                                  <th>15</th>
                                  <th>16</th>
                                  <th>17</th>
                                  <th>18</th>
                                  <th>19</th>
                                  <th>20</th>
                                  <th>21</th>
                                  <th>22</th>
                                  <th>23</th>
                                  <th>24</th>
                                  <th>25</th>
                                  <th>26</th>
                                  <th>27</th>
                                  <th>28</th>
                                  <th>29</th>
                                  <th>30</th>
                                  <th>31</th>
                                  <th>Tot.</th>
                              </tr>
                          </thead>
                          <tbody>
                            <!-- {{insup_wgMst['presenty_editIndex']['labRegNo']}} -->
                                <tr *ngFor='let rec of insup_wgMst;let i = index' >
                                  
                                    <!-- <td *ngIf="i == presenty_editIndex">{{rec.labRegNo}}</td>
                                    <td *ngIf="i == presenty_editIndex">{{rec.labName}}</td> -->
                                      <td *ngIf="i == presenty_editIndex" style="font-weight: bold;" [style.color]="rec.day1Math == 'A' ? 'red': 'blue'">{{rec.day1Math}}</td>
                                      <td *ngIf="i == presenty_editIndex" style="font-weight: bold;" [style.color]="rec.day2Math == 'A' ? 'red': 'blue'">{{rec.day2Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day3Math == 'A' ? 'red': 'blue'">{{rec.day3Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day4Math == 'A' ? 'red': 'blue'">{{rec.day4Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day5Math == 'A' ? 'red': 'blue'">{{rec.day5Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day6Math == 'A' ? 'red': 'blue'">{{rec.day6Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day7Math == 'A' ? 'red': 'blue'">{{rec.day7Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day8Math == 'A' ? 'red': 'blue'">{{rec.day8Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day9Math == 'A' ? 'red': 'blue'">{{rec.day9Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day10Math == 'A' ? 'red': 'blue'">{{rec.day10Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day11Math == 'A' ? 'red': 'blue'">{{rec.day11Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day12Math == 'A' ? 'red': 'blue'">{{rec.day12Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day13Math == 'A' ? 'red': 'blue'">{{rec.day13Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day14Math == 'A' ? 'red': 'blue'">{{rec.day14Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day15Math == 'A' ? 'red': 'blue'">{{rec.day15Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day16Math == 'A' ? 'red': 'blue'">{{rec.day16Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day17Math == 'A' ? 'red': 'blue'">{{rec.day17Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day18Math == 'A' ? 'red': 'blue'">{{rec.day18Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day19Math == 'A' ? 'red': 'blue'">{{rec.day19Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day20Math == 'A' ? 'red': 'blue'">{{rec.day20Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day21Math == 'A' ? 'red': 'blue'">{{rec.day21Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day22Math == 'A' ? 'red': 'blue'">{{rec.day22Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day23Math == 'A' ? 'red': 'blue'">{{rec.day23Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day24Math == 'A' ? 'red': 'blue'">{{rec.day24Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day25Math == 'A' ? 'red': 'blue'">{{rec.day25Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day26Math == 'A' ? 'red': 'blue'">{{rec.day26Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day27Math == 'A' ? 'red': 'blue'">{{rec.day27Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day28Math == 'A' ? 'red': 'blue'">{{rec.day28Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day29Math == 'A' ? 'red': 'blue'">{{rec.day29Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day30Math == 'A' ? 'red': 'blue'">{{rec.day30Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;" [style.color]="rec.day31Math == 'A' ? 'red': 'blue'">{{rec.day31Math}}</td>
                                      <td  *ngIf="i == presenty_editIndex"style="font-weight: bold;">{{rec.totPrsDay}}</td>
                                   
                                  </tr>
                             
                          </tbody>
                      </table>
                  </div>

              </div>

          </div>
      </div>
  </div>

  <!-- Patpedi Deduction Modal -->
  <div class="modal fade" id="patpedi" tabindex="-1">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">{{seleLabRegNo}} - {{selLabName}} Patpedi Deduction</h5>
                  <button type="button" class="btn-close" data-dismiss="modal"><i class="bi bi-x"></i></button>
              </div>
              <div class="modal-body">
                  <table class="table table-bordered">
                      <thead>
                          <tr>
                              
                              <th>Name</th>
                              <th>Act. Ded.</th>
                              <th>Ded. Amount</th>
                          </tr>
                      </thead>
                      <tbody>
                        <!-- *ngIf="rec.labourID == deduction_LabID" -->
                          <tr *ngFor='let rec of patpediDed_List;let i = index' >
                                <td *ngIf="rec.labourID == deduction_LabID">{{rec.patPediName}}</td>
                                <td *ngIf="rec.labourID == deduction_LabID">{{rec.actDedAmt}}</td>
                                <td *ngIf="rec.labourID == deduction_LabID"><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" value="{{rec.dedAmt}}" (input)="set_PatPediAmt($event,i)" placeholder=""></td>
                           </tr>
                         
                          <tr style="color: blue;font-weight: bold;">
                              <th>Total</th>
                              <td>{{labPat_ActDedAmt}}</td>
                              <td>{{labPat_DedAmt}}</td>
                          </tr>
                      </tbody>
                  </table>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn-fullfil btn-comman" data-dismiss="modal">Save</button>
              </div>
          </div>
      </div>
  </div>


  <!-- Patpedi Deduction Modal -->
  <div class="modal fade" id="insurance" tabindex="-1">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">{{seleLabRegNo}} - {{selLabName}} Insurance Deduction</h5>
                  <button type="button" class="btn-close" data-dismiss="modal"><i class="bi bi-x"></i></button>
              </div>
              <div class="modal-body">
                  <table class="table table-bordered">
                      <thead>
                          <tr>
                              
                              <th>Name</th>
                              <th>Policy No.</th>
                              <th>Act. Ded.</th>
                              <th>Ded. Amount</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor='let rec of insDed_List;let i = index'>
                              <td *ngIf="rec.labourID == deduction_LabID">{{rec.compName}}</td>
                              <td *ngIf="rec.labourID == deduction_LabID">{{rec.policyNo}}</td>
                              <td *ngIf="rec.labourID == deduction_LabID">{{rec.actPolicyAmt}}</td>
                              <td *ngIf="rec.labourID == deduction_LabID"><input class="form-control form-control-sm" type="text" (keypress)="onlyDecimalNumberKey($event)" (input)="set_InsuranceAmt($event,i)" value="{{rec.dedAmt}}" placeholder=""></td>
                          </tr>
                          
                          <tr style="color: blue;font-weight: bold;">
                              <td></td>
                              <th>Total</th>
                              <td>{{labIns_ActDedAmt}}</td>
                              <td>{{labIns_DedAmt}}</td>
                          </tr>
                      </tbody>
                  </table>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn-fullfil btn-comman" data-dismiss="modal">Save</button>
              </div>
          </div>
      </div>
  </div>


  <!-- Show Receipt Pay Modal -->
  <div class="modal fade" id="show_receipt_pay" tabindex="-1">
      <div class="modal-dialog modal-xl">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Receipt Details</h5>
                  <button type="button" class="btn-close" data-dismiss="modal"><i class="bi bi-x"></i></button>
              </div>
              <div class="modal-body">
                  <div class="table-responsive mb-4">
                      <table class="table table-bordered">
                          <thead class="thead-dark">
                              <tr>
                                  <th>Apply</th>
                                  <th>Receipt No.</th>
                                  <th>Wages Rec</th>
                                  <th>Levy Rec</th>
                                  <th>Wages Paid</th>
                                  <th>Levy Paid</th>
                                  <th>Wages Current Rec. amount</th>
                                  <th>Levy Rec Current amount</th>
                                  <th>Wages Current Paid</th>
                                  <th>Levy Current Paid</th>
                                  <th>Wages Ramaining</th>
                                  <th>Levy Ramaining</th>
                                  <th>Est. Registartion No.</th>
                                  <th>Name</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor='let rec of recPaidDt;let i = index'> 
                                  <td>
                                      <label class="check-input">
                                          <input type="checkbox" class="checkbox" (change)="set_recpaid_checkStatus(i)" [checked]="rec.chkRec" *ngIf="rec.wPaidAmt == 0">
                                          <span class="checkmark"></span>
                                      </label>
                                  </td>
                                  <td>{{rec.receiptNO}}</td>
                                  <td>{{rec.wRecAmt}}</td>
                                  <td>{{rec.lRecAmt}}</td>
                                  <td>{{rec.wPaidAmt}}</td>
                                  <td>{{rec.lPaidAmt}}</td>
                                  <td>{{rec.wCurRecAmt}}</td>
                                  <td>{{rec.lCurRecAmt}}</td>
                                  <td>{{rec.wCurPaidAmt}}</td>
                                  <td>{{rec.lCurPaidAmt}}</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>{{rec.estRegNo}}</td>
                                  <td>{{rec.name}}</td>

                              </tr>
                              <!-- <tr>
                                  <td>
                                      <label class="check-input">
                                          <input type="checkbox" class="checkbox">
                                          <span class="checkmark"></span>
                                      </label>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                              </tr> -->
                              <tr style="color: blue;font-weight: bold;">
                                <td>                               
                                </td>
                                <td>Total</td>
                                <td>{{getTotal(recPaidDt,'wRecAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'lRecAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'wPaidAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'lPaidAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'wCurRecAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'lCurRecAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'wCurPaidAmt')}}</td>
                                <td>{{getTotal(recPaidDt,'lCurPaidAmt')}} </td>
                                <td>0</td>
                                <td>0</td>
                                <td></td>
                                <td></td>

                              </tr>
                          </tbody>
                      </table>
                  </div>

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn-fullfil btn-comman" data-dismiss="modal">Apply</button>
              </div>

          </div>
      </div>
  </div>

   <!-- Payment Finalize Modal -->
   <div class="container"  >

    <div class="modal" id="pswModal"  [style.display]="showpsw_Modal ? 'block' : 'none'">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
  
          <!-- Modal Header -->
          <div class="modal-header" style="background-color: #1A5276;color: white;">
            <h4 class="modal-title">Authentication Details</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="show_pswModal(false)"
              style="color: white;">&times;</button>
          </div>
  
          <!-- Modal body -->
          <div class="modal-body">
            <form [formGroup]="frm_psw" (ngSubmit)="onSubmit_pswauth()">
              <div class="form-group">
                <label for="bank">Enter password:</label>
                <input type="password" class="form-control" placeholder="Enter password" id="txtpsw" formControlName="txtpsw"
                  [ngClass]="{ 'is-invalid': submitted && f.txtpsw.errors }" required>
                <div *ngIf="submitted && f.txtpsw.errors" class="invalid-feedback">
                  <div *ngIf="f.txtpsw.errors.required">Password is required</div>
                </div>
              </div>
  
              
  
              <div class="form-group">
                <button class="btn btn-primary">Submit</button>
              </div>
            </form>
  
  
  
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Main JS-->
  
  <!-- <script src="../../../js/bootstrap.min.js"></script>
  <script src="../../../js/bootstrap.min.js"></script>
  <script src="../../../js/popper.min.js"></script>
  <script src="../../../js/wow.min.js"></script>
  <script src="../../../js/script.js"></script> -->

  </body>

  

</div>    
</div>

