
<div class="gst-gov-style">
<app-header></app-header>
<!-- <div style="text-align: center;" class="blink">BLINK</div> -->

<app-slider *ngIf="router.url == '/'"></app-slider>

<div class="container" *ngIf="router.url == '/'">
  <!-- <div class="lodge-self-service" style="text-align: center; margin-bottom: 10px;"><a (click)="goto_Page('lab')" style="color: red;">कामगार नोंदणी अर्ज </a>
    / <a (click)="goto_Page('est')" style="color: red;">अस्थापना नोंदणी अर्ज </a>
   </div> -->
<div class="row">
 
<div class="col-md-12 col-lg-8">
<app-popularhelptopics></app-popularhelptopics>
<app-importantdates></app-importantdates>
</div>
<div class="col-md-12 col-lg-4">
<div class="rightside">
<app-news-updates-events></app-news-updates-events>
<h2 class="defult-title"><span>Mathadi on </span> <span >Youtube</span></h2>
<div style="text-align: center;">
  <img src="assets/images/youtubeicon.png" class="img-fluid"  alt="Mathadi on Youtube">
</div>

<div class="lodge-self-service"><a href="javascript:void(0)">Lodge your Grievance using self-
service Help Desk Portal</a></div>
<!-- <h2 class="defult-title"><span>Taxpayers (Normal/TDS/TCS)</span></h2>
<div class="find-gst-practitioner">
	Find a GST<br>Practitioner
<div class="clearfix"></div>
<a href="javascript:void(0)" class="register_now">Register Now</a>
</div>
<h2 class="defult-title mar-t-30"><span>gst-practitioners</span></h2>
<div class="gst-practitioners">
	Find a<br>Taxpayer
<div class="clearfix"></div>
<a href="javascript:void(0)" class="register_now">Register Now</a>
</div> -->
</div>
</div>
</div>
</div>



<router-outlet></router-outlet>

<app-footer></app-footer>
<ngx-ui-loader></ngx-ui-loader>



