import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagenotfoundcomponentComponent } from './pagenotfoundcomponent/pagenotfoundcomponent.component';
import { LabourDashboardComponent } from './dashboard/labour-dashboard/labour-dashboard.component';
import { ClerkDahsboardComponent } from './dashboard/clerk-dahsboard/clerk-dahsboard.component';
import { LoginComponent } from './login/login.component';
import { SelloginComponent } from './login/sellogin/sellogin.component';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { VerifiedcodeComponent } from './login/verifiedcode/verifiedcode.component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { WlReceiptRmathaComponent } from './receipt/wl-receipt-rmatha/wl-receipt-rmatha.component';
import { LabPaymentRmathaComponent } from './payment/lab-payment-rmatha/lab-payment-rmatha.component';
import { LabPaidbankListRmathaComponent } from './paidbankList/lab-paidbank-list-rmatha/lab-paidbank-list-rmatha.component';
// import { RportHomeComponent } from './rport-home/rport-home.component';
import { RptLabpaymentsComponent } from './reports/rpt-labpayments/rpt-labpayments.component';
import { InscompDashboardComponent } from './dashboard/inscomp-Dashboard/inscomp-dashboard/inscomp-dashboard.component';
import { PatcompDashboardComponent } from './dashboard/patcomp-Dashboard/patcomp-dashboard/patcomp-dashboard.component';
import { UnioncompDashboardComponent } from './dashboard/unioncomp-dashboard/unioncomp-dashboard.component';
import { WlReceiptPimmathaComponent } from './receipt/wl-receipt-pimmatha/wl-receipt-pimmatha.component';
import { LabPaymentpimmathaComponent } from './payment/pimmatha/lab-payment-pimmatha/lab-payment-pimmatha.component';
import { SetUserauthComponent } from './admin/set-userauth/set-userauth.component';
import { WlRecrptComponent } from './Reports/wl-recrpt/wl-recrpt.component';
//import { RegLabPimmathaComponent } from './registration/reg-lab-pimmatha/reg-lab-pimmatha.component';
import { RegLabPimmathaComponent } from './registration/reg-lab-pimmatha/reg-lab-pimmatha.component';
import { RegEstNskmathaComponent } from './registration/reg-est-nskmatha/reg-est-nskmatha.component';

import { LabLoginComponent } from './login/lab-login/lab-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DownloadListComponent } from './download-list/download-list.component';
import { UploadExcelComponent } from './uploadExcel/upload-excel/upload-excel.component';
import { AddNotificationComponent } from './notification/add-notification/add-notification.component';
import { EstDashboardComponent } from './dashboard/est-dashboard/est-dashboard.component';

const routes: Routes = [
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'dashboard/labour-dashboard', component: LabourDashboardComponent}, 
  {path: 'dashboard/clerk-dashboard', component: ClerkDahsboardComponent}, 
  {path: 'dashboard/inscomp-Dashboard', component: InscompDashboardComponent}, 
  {path: 'dashboard/patcomp-Dashboard', component: PatcompDashboardComponent},
  {path: 'dashboard/unioncomp-dashboard', component: UnioncompDashboardComponent},
  {path: 'login', component: LoginComponent}, 
  {path: 'lablogin', component: LabLoginComponent}, 
  {path: 'sellogin', component: SelloginComponent},
  {path: 'forgotpassword', component: ForgotpasswordComponent}, 
  {path: 'verifiedcode', component: VerifiedcodeComponent},  
  {path: 'resetpassword', component: ResetpasswordComponent},
  {path: 'receipt/wl-receipt-rmatha', component: WlReceiptRmathaComponent}, 
  {path: 'receipt/wl-receipt-pimmatha', component: WlReceiptPimmathaComponent},
  {path: 'payment/lab-payment-rmatha', component: LabPaymentRmathaComponent },
  {path: 'payment/pimmatha/lab-payment-pimmatha', component: LabPaymentpimmathaComponent },
  {path: 'paidbankList/lab-paidbank-list-rmatha', component: LabPaidbankListRmathaComponent },
  {path: 'admin/set-userauth', component: SetUserauthComponent },
  {path: 'wl-rec-rpt', component: WlRecrptComponent },
  {path: 'reg-lab-pimmatha', component: RegLabPimmathaComponent },
  {path: 'reg-est-nskmatha', component: RegEstNskmathaComponent },
  {path: 'privacy-policy', component: PrivacyPolicyComponent },
  {path: 'download-list', component: DownloadListComponent },
  {path: 'upload-excel', component: UploadExcelComponent },
  {path: 'add-notification', component: AddNotificationComponent },
  {path: 'dashboard/est-dashboard', component: EstDashboardComponent},

  // {path: 'NskMatha/nskmclerk-dahsboard', component: NskmclerkDahsboardComponent},

  // {path: 'rpthome', component: RportHomeComponent}, 
  {path: 'rptlabpay', component: RptLabpaymentsComponent}, 
  {path: '**', component: PagenotfoundcomponentComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes),RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
