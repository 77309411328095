<div class="notranslate">
  <div class="container dashboardmain">



    <!-- waiting modal -->

    <div class="container">

      <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
          <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">

              <div style="text-align: center;">

                Please wait ...
                <div class="spinner-grow text-success"></div>
                <div class="spinner-grow text-info"></div>
                <div class="spinner-grow text-warning"></div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- waiting modal -->

    <body>
      <section id="company-dashboard">

        <!-- <app-reports></app-reports> -->

        <section id="company-dashboard">
          <div class="container">


            <div class="row">

              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center;">
                <div class="content-panel">

                  <button type="button" class="button button4" (click)="set_rptName_List('')">Registration</button>
                  <button type="button" class="button button4" (click)="set_rptName_List('WlRec')">Receipts</button>
                  <button type="button" class="button button4" (click)="set_rptName_List('Payment')">Payments</button>
                  <button type="button" class="button button4" (click)="set_rptName_List('Accounts')">Accounts</button>
                  <button type="button" class="button button4" (click)="set_rptName_List('FD')">FD</button>
                  <button type="button" class="button button4" (click)="set_rptName_List('PF')">PF</button>


                </div>

              </div>

            </div>

            <form class="search">

              <div class="row">
                <div class="col-md-3"></div>

                <div class="col-md-2" style="text-align: right;">
                  <label for="Financial-Year" style="margin-top: 15px;">Financial Year</label>
                </div>
                <div class="col-md-3">

                  <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
                    <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== filterFayear">
                      {{yr.displayYear}}</option>
                  </select>
                </div>

              </div>
            </form>

          </div>
        </section>


        <div class="content-panel">

          <div class="row">

            <!-- Report Tab Name -->

            <div class="col-md-4 col-lg-4">
              <div class="form-group">
                <div class="form-group has-search">
                  <i class="fa fa-search form-control-feedback"></i>
                  <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                    [(ngModel)]="search_rpt" (input)="search_rptName()" placeholder="Enter text to search report"
                    autofocus>

                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item nav-item" *ngFor="let rec of filter_rpt" appHighlight
                        [searchedWord]="search_rpt" [content]="rec.disMem" [classToApply]="'font-weight-bold'"
                        [setTitle]="'true'" (click)="set_searchRpt(rec.valMem,rec.disMem,rec.tabName);">

                      </li>
                    </ul>

                  </div>
                </div>

              </div>
            </div>

            <!-- Report Filter Control Tab Wise -->

            <div class="col-md-8 col-lg-8">

              <!-- Rpoert Header -->
              <div class="row">
                <div class="col-md-12" style="text-align: center;">
                  <h4>{{selected_rptName}}</h4>
                </div>

              </div>

              <!-- Active FD Report Bank/Ac wise Radio Button -->
              <div class="row" *ngIf="show_activefdrdbbtn == 'yes' || show_fddatewise == 'yes'">
                <div class="col-md-4">
                  <input class="form-check-input" type="radio" name="BankWise" id="BankWise1"
                    [checked]="rdb == 'BankWise'" (change)="set_active_FD_rdb($event,'BankWise')" value="BankWise">
                  <label class="form-check-label" for="exampleRadios1">
                    Bank Wise FD Report
                  </label>
                </div>

                <div class="col-md-4">
                  <input class="form-check-input" type="radio" name="BankWise" id="BankWise2"
                    [checked]="rdb == 'AcWise'" (change)="set_active_FD_rdb($event,'AcWise')" value="AcWise">
                  <label class="form-check-label" for="exampleRadios2">
                    Account Wise FD Report
                  </label>
                </div>

                <div class="col-md-3" style="margin-top: 31px;" *ngIf="show_fddatewise == 'yes'">
                  <input class="form-check-input" type="checkbox" (change)="set_active_FD_datewise($event)">
                  <label class="form-check-label" for="exampleRadios4">
                    Active FD
                  </label>
                </div>
              </div>

              <br>

              <!-- Text Filter -->

              <div class="row" style="margin-top: 5px;" *ngIf="show_searchToli == 'yes'">
                <div class="col-md-12">
                  <div class="form-group has-search">
                    <i class="fa fa-search form-control-feedback"></i>
                    <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                      [(ngModel)]="searchToli" (input)="filter_toli_est_market()" placeholder="Search by number or name"
                      autofocus>

                    <div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item nav-item" *ngFor="let rec of filter_toli_est_market_list"
                          appHighlight [searchedWord]="searchToli" [content]="rec.toliNo - rec.mukName"
                          [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                          (click)="set_searchToli(rec.toliID,rec.toliNo,rec.mukName);"
                          style="text-align: start;font-size: medium;font-weight: bold;">
                          {{rec.toliNo}} - {{rec.mukName}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-8">
                  <div class="input-group" style="color: blue;">
                    {{toliInfo}}

                  </div>
                </div>



              </div>

              <!-- Wage Yr/Month Filter -->

              <div class="row" *ngIf="show_wagyrmonDt == 'yes'">
                <div class="col-md-6">
                  <label for="Financial-Year">Wage Year </label>
                  <input type="text" class="form-control" name="txtwageYr" id="txtwageYr" [(ngModel)]="filter_wageYr"
                    value="{{filter_wageYr}}" placeholder="" (keypress)="onlyNumberKey($event)"
                    (input)="vlaidateYear($event)" maxlength="4">
                  <span *ngIf="invalidYear!=''" style="font-size: small;color:red">Invalid Year</span>
                </div>
                <div class="col-md-6" style="background-color: yello">
                  <label for="Financial-Year">Wage Month </label>
                  <input type="text" class="form-control" name="txtwageMon" id="txtwageMon"
                    [(ngModel)]="filter_wageMonth" value="{{filter_wageMonth}}" placeholder=""
                    (keypress)="onlyNumberKey($event)" (input)="vlaidateMonth($event)" (input)="fill_filter_payDt(1)"
                    maxlength="2">
                  <span *ngIf="invalidMonth!=''" style="font-size: small;color:red">Invalid Month</span>
                </div>
              </div>



              <!-- Iway Date -->
              <div class="row" *ngIf="show_iwayDt == 'yes'">
                <div class="col-md-6">
                  <label for="Financial-Year">{{lbl_date}}</label>
                  <input type="date" class="form-control" (input)="vlaidateDate($event,'iwaydate')"
                    (change)="set_iwayDate($event)" value="{{iway_Date}}" />
                  <span *ngIf="invalid_Iway_Date!=''" style="font-size: small;color:red">Invalid Date</span>
                </div>
                <div class="col-md-6" *ngIf="show_btnupdchqdt == 'yes'" style="text-align: right;margin-top: 18px;">


                  <button type="button" class="button button4" (click)="show_bankreconc_modal();">Go To Update Cheque
                    Clearing Date</button>
                </div>

                <div class="col-md-3" style="margin-top: 31px;" *ngIf="show_activefdrdbbtn == 'yes'">
                  <input class="form-check-input" type="radio" name="GovWise" id="GovWise1"
                    [checked]="govrdb == 'GovWise'" (change)="set_active_FD_Govrdb($event,'GovWise')" value="GovWise">
                  <label class="form-check-label" for="exampleRadios3">
                    Govt. Use Only
                  </label>
                </div>

                <div class="col-md-3" style="margin-top: 31px;" *ngIf="show_activefdrdbbtn == 'yes'">
                  <input class="form-check-input" type="radio" name="GovWise" id="GovWise2"
                    [checked]="govrdb == 'RptAWise'" (change)="set_active_FD_Govrdb($event,'RptAWise')"
                    value="RptAWise">
                  <label class="form-check-label" for="exampleRadios4">
                    प्रपत्रक - अ
                  </label>
                </div>

              </div>




              <!-- From And To Date -->
              <div class="row" *ngIf="show_frmtoDt == 'yes'">
                <div class="col-md-6">
                  <label for="Financial-Year">From Date </label>
                  <input type="date" class="form-control" (input)="vlaidateDate($event,'frmdate')"
                    (change)="set_frmDate($event)" value="{{frm_Date}}" />
                  <span *ngIf="invalid_frm_Date!=''" style="font-size: small;color:red">Invalid Date</span>
                </div>
                <div class="col-md-6" style="background-color: yello">
                  <label for="Financial-Year">To Date </label>
                  <input type="date" class="form-control" (input)="vlaidateDate($event,'todate')"
                    (change)="set_toDate($event)" value="{{to_Date}}" />
                  <span *ngIf="invalid_to_Date!=''" style="font-size: small;color:red">Invalid Date</span>
                </div>
              </div>


              <br>

              <!-- Select Rec.No. -->
              <div class="row" *ngIf="show_selrecNo == 'yes'">
                <div class="col-md-3">
                  <label style="margin-top: 15px;">Select Receipt No.</label>

                </div>
                <div class="col-md-6">

                  <select (change)="set_Wl_SelRec_DocID($event)" class="form-control select-t" id="Financial-Year">
                    <option *ngFor='let rec of wlRecNo_List' [value]="rec.valMem"
                      [selected]="rec.valMem=== Sel_wlRec_DocID">
                      {{rec.disMem}}</option>
                  </select>
                </div>

              </div>



              <!-- Select Pay Date -->
              <div class="row" *ngIf="show_selpayDt == 'yes'">
                <div class="col-md-6">
                  <!-- <label for="Financial-Year">Select Payment Date </label> -->
                  <!-- <select class="form-control select-t" id="sel_payDt">
                  <option [value]='0'>Select Payment Date </option>
                </select> -->

                  <select (change)="set_sel_PayDt($event)" class="form-control select-t" id="Financial-Year">
                    <option *ngFor='let rec of filter_PayDt_List' [value]="rec.payDt | date:'dd/MM/yyyy'">
                      {{rec.payDt | date:'dd/MM/yyyy'}}</option>
                  </select>
                </div>
              </div>

              <!-- PFRegister -->

              <div class="row">
                <div class="col-md-6" *ngIf="show_yrsel == 'yes'">
                  <label for="Financial-Year">Year </label>
                  <!-- <input type="text" class="form-control" name="txtYr" id="txtYr" [(ngModel)]="txt_year"
                  value="{{txt_year}}" placeholder="" (keypress)="onlyNumberKey($event)" maxlength="4"> -->

                  <select (change)="Set_PFFayear($event)" class="form-control select-t" id="Financial-Year">
                    <option *ngIf="show_pfreg == 'yes'" [value]="0">
                      All</option>
                    <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== txt_year">
                      {{yr.displayYear}}</option>
                  </select>
                </div>

                <div class="col-md-6" *ngIf="show_pfreg == 'yes'">
                  <label for="status">Status </label>
                  <select (change)="Set_PFStatsus($event)" class="form-control select-t" id="status">
                    <option value="Active"> Active </option>
                    <option value="All"> All </option>
                    <option value="Cancellation"> Cancellation </option>
                    <option value="Cancellation Death"> Cancellation Death </option>
                    <option value="Cancellation Paid"> Cancellation Paid </option>
                    <option value="Cancellation UnPaid"> Cancellation UnPaid </option>
                    <option value="Death"> Death </option>
                    <option value="Resignation"> Resignation </option>

                  </select>
                </div>


                <div class="col-md-6" *ngIf="show_rpttype == 'yes'">
                  <label for="rpttype">Type </label>
                  <select (change)="Set_RptType($event)" class="form-control select-t" id="rpttype">
                    <option value="brief"> Summary</option>
                    <option value="detail"> Detail </option>


                  </select>
                </div>

              </div>



              <!-- Btn Genrate Pdf -->
              <div class="row" *ngIf="show_btngenratePdf == 'yes'">

                <div class="col-md-12" style="text-align: right;">
                  <button type="button" class="btn btn-primary" style="margin-top: 5px;"
                    (click)="get_Data(selected_rptID)">Genrate PDf</button>
                </div>



              </div>

              <!-- <div class="row" >

              <div class="col-md-12" style="text-align: right;">
                <button type="button" class="btn btn-primary" style="margin-top: 5px;"
                  (click)="test_rpt_pdf()">Test PDf</button>
              </div>

            </div> -->

            </div>
          </div>

        </div>

      </section>

      <!-- Unpaid Payment Details -->
      <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="background-color: #1A5276;color: white;">
              <h4 class="modal-title">Unpaid Payment Details</h4>
              <button type="button" class="close" data-dismiss="modal" (click)="hide_unpaidlist_modal()"
                style="color: white;">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

              <table class="table table-hover table-responsive-sm labour-table table-striped ">
                <thead>
                  <tr>

                    <th class="th-sm">Toli No.</th>
                    <th class="th-sm">Name</th>
                    <th class="th-sm">Wage Year</th>
                    <th class="th-sm">Wage Month</th>
                    <th class="th-sm">Pay Date</th>
                    <th class="th-sm">Gross Amt.</th>
                    <th class="th-sm">Paid</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  <tr class="sub-tr" *ngFor='let rec of toli_unpaidList;let i = index'>

                    <td>{{rec.toliNo}}</td>
                    <td style="text-align: left;">{{rec.toliName}}</td>
                    <td>{{rec.wageyr}}</td>
                    <td>{{rec.wageMon}}</td>
                    <td>{{rec.payDt | date: 'dd/MM/yyyy'}}</td>
                    <td>{{rec.grossAmt}}</td>
                    <td>{{rec.paid}}</td>

                    <td></td>
                  </tr>

                </tbody>

              </table>

            </div>
          </div>
        </div>
      </div>

    

    </body>
  </div>
</div>

  <!-- Bank Reconcilation Update Cheque Details -->
  <div class="modal" style="width: 1400px;" id="bankreconcModal" [style.display]="show_bankreconcModal ? 'block' : 'none'">
    <div class="modal-fullscreen" role="document" >
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header" style="background-color: #1A5276;color: white;">
          <h4 class="modal-title">Update Cheque Clearing Date</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="hide_bankreconc_modal()"
            style="color: white;">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">

          <!-- waiting modal -->

          <div class="container">

            <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
              <div class="modal-dialog" role="document">
                <div class="modal-content">

                  <!-- Modal body -->
                  <div class="modal-body">

                    <div style="text-align: center;">

                      Please wait ...
                      <div class="spinner-grow text-success"></div>
                      <div class="spinner-grow text-info"></div>
                      <div class="spinner-grow text-warning"></div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- waiting modal -->

          <div class="row">

            <div class="col-md-12">
              <div class="form-group has-search">
                <i class="fa fa-search form-control-feedback"></i>
                <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                  [(ngModel)]="searchToli" (input)="filter_toli_est_market()" placeholder="Search by number or name"
                  autofocus>

                <div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item nav-item" *ngFor="let rec of filter_toli_est_market_list"
                      appHighlight [searchedWord]="searchToli" [content]="rec.toliNo - rec.mukName"
                      [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                      (click)="set_searchToli(rec.toliID,rec.toliNo,rec.mukName);"
                      style="text-align: start;font-size: medium;font-weight: bold;">
                      {{rec.toliNo}} - {{rec.mukName}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="form-group col-md-8">
              <div class="input-group" style="color: blue;">
                {{toliInfo}}

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label for="Financial-Year">From Date </label>
              <input type="date" class="form-control" (change)="set_frmDate($event)" value="{{frm_Date}}" />
            </div>
            <div class="col-md-4" style="background-color: yello">
              <label for="Financial-Year">To Date </label>
              <input type="date" class="form-control" (change)="set_toDate($event)" value="{{to_Date}}" />
            </div>

            <div class="col-md-2" style="background-color: yello">
              <button type="button" class="button button4" style="margin-top: 26px;"
                (click)="show_cheque_clearing_List()">Show</button>
            </div>
            <div class="col-md-2" style="background-color: yello">
              <button type="button" class="button button4" style="margin-top: 26px;"
                (click)="update_cheque_clearing_Date()">Update</button>
            </div>
          </div>



          <br>


          <div class="scrollit" style="height: 400px;">
            <table class="table table-hover table-responsive-sm labour-table table-striped ">
              <thead>
                <tr>

                  <th class="th-sm">Rec No</th>
                  <th class="th-sm">Transaction Date</th>
                  <th class="th-sm">Transaction Type</th>
                  <th class="th-sm">Chq No</th>
                  <th class="th-sm">Chq Date</th>
                  <th class="th-sm">Chq Amount</th>
                  <th class="th-sm">Chq Clearing Date</th>
                  <th class="th-sm">Enter Clearing Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                <tr class="sub-tr" *ngFor='let rec of chq_ClearingList;let i = index'>

                  <td>{{rec.receiptNO}}</td>
                  <td>{{rec.docdt | date: 'dd/MM/yyyy'}}</td>
                  <td>{{rec.flag}}</td>
                  <td>{{rec.billNo}}</td>
                  <td>{{rec.billDt | date: 'dd/MM/yyyy'}}</td>
                  <td>{{rec.chqAmt}}</td>
                  <td>{{rec.chequeClearingDt | date: 'dd/MM/yyyy'}}</td>
                  <td><input type="date" class="form-control" (change)="set_chqClr_Date($event,i)" />
                    <span *ngIf="rec.validDt !=''" style="font-size: small;color:red">Invalid Date</span>
                  </td>

                  <td></td>
                </tr>

              </tbody>

            </table>
          </div>



        </div>
      </div>
    </div>
  </div>