import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';

@Component({
  selector: 'app-inscomp-dashboard',
  templateUrl: './inscomp-dashboard.component.html',
  styleUrls: ['./inscomp-dashboard.component.css']
})



export class InscompDashboardComponent implements OnInit {
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;


  active;
  disabled = true;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';

  public uploadYr = 0;
  public uploadMon = 0;
  public currDateTime = new Date();

  constructor(
    private http: HttpClient,
    config: NgbAccordionConfig
    , private _userService: UserService
    , private globals: Globals
    , public router: Router
    , private route: ActivatedRoute
    , private ngxService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    config.closeOthers = true;
    config.type = 'info';
  }
  // #region ngAfterViewChecked
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion
  public curDate: any = [];
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    this.get_lastfile_uploadMonth();
    this.fill_allLab();
    this.set_uploadFile_Yr_Month();
    this.fill_dedYear();
  }
  //#region get_lastupload_month
  lastUploadMonth = '';
  get_lastfile_uploadMonth() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.lastUploadMonth = '';
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getlastuploadmonth/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.lastUploadMonth = data;
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();

        //alert(err);
        return;
      });
  }
  //#endregion

  //#region All LabourList
  all_LabList = [];
  fill_allLab() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.all_LabList = [];
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getalllablist';
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.all_LabList = data;
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region fill dedList month/year
  public YrList: any = [];
  public MonthList: any = [];
  public filterFayear = 0;

  fill_dedYear() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.YrList = [];
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getdedyear/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.ngxService.stop();
      this.YrList = data;
      this.filterFayear = data[0]['dedYear'];
      this.filterYr = this.filterFayear
      this.fill_Month(this.filterFayear);
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  fill_Month(Yr) {

    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "April" },
      { mon: 5, yr: Yr, dispmon: "May" },
      { mon: 6, yr: Yr, dispmon: "June" },
      { mon: 7, yr: Yr, dispmon: "July" },
      { mon: 8, yr: Yr, dispmon: "August" },
      { mon: 9, yr: Yr, dispmon: "September" },
      { mon: 10, yr: Yr, dispmon: "October" },
      { mon: 11, yr: Yr, dispmon: "November" },
      { mon: 12, yr: Yr, dispmon: "December" },
      { mon: 1, yr: Yr, dispmon: "January" },
      { mon: 2, yr: Yr, dispmon: "February" },
      { mon: 3, yr: Yr, dispmon: "March" },
    ];

    this.filterMonth = 4;
  }
  get_monthName(intMonth) {
    if (intMonth == 1) {
      return "January";
    }
    else if (intMonth == 2) {
      return "February";
    }
    else if (intMonth == 3) {
      return "March";
    }
    else if (intMonth == 4) {
      return "April";
    }
    else if (intMonth == 5) {
      return "May";
    }
    else if (intMonth == 6) {
      return "June";
    }
    else if (intMonth == 7) {
      return "July";
    }
    else if (intMonth == 8) {
      return "August";
    }
    else if (intMonth == 9) {
      return "September";
    }
    else if (intMonth == 10) {
      return "October";
    }
    else if (intMonth == 11) {
      return "November";
    }
    else if (intMonth == 12) {
      return "December";
    }
    else {
      return "";
    }

  }
  Set_Fayear(event) {
    this.lic_DedList = [];
    this.filterFayear = event.target.value;
    this.filterYr = event.target.value;
    this.fill_Month(event.target.value);
  }
  public filterMonth = 0;
  public filterYr = 0;
  Set_FiterYrMon(event) {
    this.lic_DedList = [];
    this.filterMonth = Number(event.target.value);
    this.filterYr = this.filterFayear;
  }
  //#endregion

  //#region set Year/Month
  set_uploadFile_Yr_Month() {
    var date_to_parse = new Date();
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();
    this.show_loadingModal(true);
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getcurrendatefromweb';
    this._userService.get_Data(url).subscribe((data) => {
      this.show_loadingModal(false);
      this.uploadMon = Number(data.currMonth);
      this.uploadYr = Number(data.currYr);
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.uploadMon = Number(month);
        this.uploadYr = Number(year);
        this.show_loadingModal(false);
        return;
      });;
  }
  //#endregion

  //#region read Excel
  arrayBuffer: any;
  upload_fileName: File;
  sel_uploadfile(event) {
    this.Save_formData = new FormData();
    this.upload_fileName = event.target.files[0];
  }

  uploadExcel_Data = [];
  new_policyLabList = [];
  excle_policyLabList = [];

  //#endregion
  //#region get policyNumber list from db
  policyNo_List = [];
  fill_policyNumber_fromDb() {
    this.Save_formData = new FormData();

    if (Number(this.uploadYr.toString().length) != 4) {
      alert('Enter valid year.')
      return false;
    }
    else if (Number(this.uploadMon) <= 0 && Number(this.uploadMon) > 12) {
      alert('Enter valid month.')
      return false;
    }
    else if (this.upload_fileName == null) {
      alert('Select file.')
      return false;
    }
    else {
      this.policyNo_List = [];
      this.new_policyLabList = [];
      this.excle_policyLabList = [];
      this.uploadExcel_Data = [];

      this.ngxService.start();

      let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/inspolicynumberlist/' + this.Log_UserID
      this._userService.get_Data(url).subscribe((data) => {
        this.show_loadingModal(true);

        this.policyNo_List = data;

        //Get Data From Excel

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          //console.log('954352794',data);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];

           // console.log(worksheet['A1']['h']);

          // if (worksheet['A6']['h'] != 'Policy Number'){
          //   this.show_loadingModal(false)
          //   alert('Set file A6 column header Policy Number')
          //   return '';
          // }
          // else if (worksheet['B6']['h'] != 'Name of Policy Holder'){
          //   this.show_loadingModal(false)
          //   alert('Set file B6 column header Name of Policy Holder')
          //   return '';
          // }
          // else if (worksheet['C6']['h'] != 'Premium'){
          //   this.show_loadingModal(false)
          //   alert('Set file C6 column header Premium')
          //   return '';
          // }
          // else if (worksheet['G6']['h'] != 'Employee Code'){
          //   this.show_loadingModal(false)
          //   alert('Set file G6 column header Employee Code')
          //   return '';
          // }
          

          this.uploadExcel_Data = XLSX.utils.sheet_to_json(worksheet, { raw: true })
          //
          console.log('ins this.uploadExcel_Data', this.uploadExcel_Data);

          let filter_MonYr = this.uploadExcel_Data.filter(x => x.DedYear != this.uploadYr);
          // if (filter_MonYr.length > 0){
          //    alert("Please check upload file year and selected file DedYear not match.");
          //   return false;
          // }
          // else{
          //   filter_MonYr = [];
          //   filter_MonYr = this.uploadExcel_Data.filter(x => x.DedMonth != this.uploadMon);
          //  if (filter_MonYr.length > 0){
          //   alert("Please check upload file month and selected file DedMonth not match.");
          //   return false;
          //  }

          // }

          // 
          if (Number(this.uploadExcel_Data.length) > 0) {
            let File_Month, File_Year, CurrYr, CurrMonth;
            //alert(this.uploadExcel_Data[1]['Life Insurance Corporation of India'].toString().substring(30, [32]));
            //alert(this.uploadExcel_Data[1]['Life Insurance Corporation of India'].toString().substring(33, [37]));

            File_Month = this.uploadExcel_Data[1]['Life Insurance Corporation of India'].toString().substring(30, [32]);
            File_Year = this.uploadExcel_Data[1]['Life Insurance Corporation of India'].toString().substring(33, [37]);



            if (Number(File_Month) == 12) {
              CurrMonth = 1;
              CurrYr = Number(File_Year) + 1;
            }
            else {
              CurrMonth = Number(File_Month) + 1;
              CurrYr = Number(File_Year);
            }

            // console.log(File_Month);
            // console.log(File_Year);
            // console.log(this.uploadYr);
            // console.log(CurrYr);

            if (Number(CurrYr) != Number(this.uploadYr)) {
              this.show_loadingModal(false);
              this.loading = '';
              alert("Please check upload file year and selected file DedYear not match.");
              return false;
            }

            if (Number(CurrMonth) != Number(this.uploadMon)) {
              this.show_loadingModal(false);
              this.loading = '';
              alert("Please check upload file month and selected file DedMonth not match.");
              return false;
            }

//            console.log('this.all_LabList', this.all_LabList);

            for (let k = 4; k < this.uploadExcel_Data.length; k++) {
              let labourID, labName, labRegNo, exl_labName, exl_labRegNo;
              let isEdit = true;
              labourID = 0;
              labName = ''; //this.uploadExcel_Data[k]['LabName'];
              labRegNo = '';// this.uploadExcel_Data[k]['RegNo'];

              // console.log('policyno',this.uploadExcel_Data[k]['Life Insurance Corporation of India'].toString())

              exl_labName = this.uploadExcel_Data[k]['__EMPTY'];//LabName
              exl_labRegNo = this.uploadExcel_Data[k]['__EMPTY_5'];//RegNo

              let filter_ = [];
              filter_ = this.policyNo_List.filter(x => Number(x.policyNo) == Number(this.uploadExcel_Data[k]['Life Insurance Corporation of India'])) //PolicyNo
              // alert('1');
              //console.log('filter_.length',Number(filter_.length))
              if (Number(this.uploadExcel_Data[k]['Life Insurance Corporation of India']) > 0) {
                //alert('2');
                if (Number(filter_.length) == 0) {
                  // alert('3');
                  //console.log('policy no',this.uploadExcel_Data[k]['Life Insurance Corporation of India'])
                  //console.log('labRegNo',this.uploadExcel_Data[k]['__EMPTY_5'])
                  let filter_lab = [];
                  if (Number(this.uploadExcel_Data[k]['__EMPTY_5']) > 0) {
                    // alert('4');
                    filter_lab = this.all_LabList.filter(x => Number(x.labRegNo) == Number(this.uploadExcel_Data[k]['__EMPTY_5']))
                    if (Number(filter_lab.length) == 1) {
                      labourID = filter_lab[0]['labourID'];
                      labName = filter_lab[0]['labName'];
                      labRegNo = filter_lab[0]['labRegNo'];

                      exl_labName = filter_lab[0]['labName'];
                      exl_labRegNo = filter_lab[0]['labRegNo'];

                      isEdit = false;
                    }
                  }


                  if (Number(this.new_policyLabList.length) == 0) {
                    this.new_policyLabList = [{
                      "InsCompID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "PolicyNo": this.uploadExcel_Data[k]['Life Insurance Corporation of India'],
                      "PolicyAmt": this.uploadExcel_Data[k]['__EMPTY_1'],//DedAmt
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": isEdit,
                      "isNewPolicy": true,
                    },]
                  }
                  else {
                    this.new_policyLabList.push({
                      "InsCompID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "PolicyNo": this.uploadExcel_Data[k]['Life Insurance Corporation of India'],
                      "PolicyAmt": this.uploadExcel_Data[k]['__EMPTY_1'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": isEdit,
                      "isNewPolicy": true,
                    })
                  }
                }
                else {
                  //  alert('5');
                  let filter_lab = [];
                  if (Number(this.uploadExcel_Data[k]['__EMPTY_5']) > 0) {
                    filter_lab = this.all_LabList.filter(x => Number(x.labRegNo) == Number(this.uploadExcel_Data[k]['__EMPTY_5']))
                    if (Number(filter_lab.length) == 1) {
                      labourID = filter_lab[0]['labourID'];
                      labName = filter_lab[0]['labName'];
                      labRegNo = filter_lab[0]['labRegNo'];

                      exl_labName = filter_lab[0]['labName'];
                      exl_labRegNo = filter_lab[0]['labRegNo'];

                      isEdit = false;
                    }
                  }


                  if (Number(this.excle_policyLabList.length) == 0) {
                    this.excle_policyLabList = [{
                      "InsCompID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": exl_labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": exl_labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "PolicyNo": this.uploadExcel_Data[k]['Life Insurance Corporation of India'],
                      "PolicyAmt": this.uploadExcel_Data[k]['__EMPTY_1'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": false,
                      "isNewPolicy": false,
                    },]
                  }
                  else {
                    this.excle_policyLabList.push({
                      "InsCompID": this.Log_UserID,
                      "LabourID": labourID, //0,
                      "RegNo": exl_labRegNo, //this.uploadExcel_Data[k]['RegNo'],
                      "LabName": exl_labName,//this.uploadExcel_Data[k]['LabName'],
                      "exlLabName": exl_labName,
                      "PolicyNo": this.uploadExcel_Data[k]['Life Insurance Corporation of India'],
                      "PolicyAmt": this.uploadExcel_Data[k]['__EMPTY_1'],
                      "DedMonth": this.uploadMon,
                      "DedYear": this.uploadYr,
                      "bgcolor": 'None',
                      "imageFile": '',
                      "isSave": true,
                      "isEdit": false,
                      "isNewPolicy": false,
                    })
                  }

                }
              }

            }

            // 
            this.show_loadingModal(false);
            if (Number(this.new_policyLabList.length) == 0) {
              if (Number(this.excle_policyLabList.length) > 0) {
                this.upload_excel_file();
              }
              else {
                alert('Data not get.')
              }
            }
            else {
              alert('Upload faild.New labour found in excle file.')
            }


          }
        }
        fileReader.readAsArrayBuffer(this.upload_fileName);

        this.ngxService.stop();

      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          //alert(err);
          return;
        });
    }

  }
  cancle_update_AddNewLab() {
    this.Save_formData = new FormData();
    this.new_policyLabList = [];
    this.excle_policyLabList = [];
  }
  //#endregion

  //#region Add new lablist in InsPolicyMaster
  add_NewLab_InsPolicy() {
    let isValid = true;
    if (window.confirm('Are sure you to update new labour list.?')) {

      if (Number(this.new_policyLabList.length) > 0) {
        for (let k = 0; k < this.new_policyLabList.length; k++) {
          if (Number(this.new_policyLabList[k]['LabourID']) <= 0) {
            alert('Policy number ' + this.new_policyLabList[k]['PolicyNo'] + ' selected labour invalid.')
            this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {

        let saveData: any = {
          "InsPolicyAuthMaster": this.new_policyLabList,
          "ExlInsPolicyDt": this.excle_policyLabList,
        }

        // 
        // return;

        this.ngxService.start();
        let body = new FormData();
        body = saveData;
        let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/saveinspolicyauth';
        //alert(url);
        this._userService.post_data(url, body).subscribe(
          (data) => {
            if (data == 'Saved SuccessFully.') {

              alert('Record saved successfully.');
              this.new_policyLabList = [];
              this.excle_policyLabList = [];
              this.get_lastfile_uploadMonth();
            }
            else {
              alert(data);
            }
          });
        this.ngxService.stop();
      }

    }

  }
  //#endregion

  //#region set addnew lab
  set_addNewLab_Check(event: any, rindex) {
    this.new_policyLabList[rindex]['isSave'] = event.currentTarget.checked;
  }
  //#endregion

  //#region only numbers
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  //#endregion

  //#region filter upload labName and set
  updateLabName;
  set_upload_editcontrol(i) {
    this.new_policyLabList[i]['isEdit'] = true;
  }
  set_upload_editlabNamel(labName, labRegNo, labourID, i) {
    this.new_policyLabList[i]['LabName'] = labName;
    this.new_policyLabList[i]['RegNo'] = labRegNo;
    this.new_policyLabList[i]['LabourID'] = labourID;
    this.new_policyLabList[i]['isEdit'] = false;
    this.filter_uploadLabName = [];
  }
  set_approval_editlabNamel(labName, labRegNo, labourID, i) {
    this.lab_approval_pendList[i]['labName'] = labName;
    this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    this.lab_approval_pendList[i]['labourID'] = labourID;
    //this.lab_approval_pendList[i]['isEdit'] = false;
    this.filter_approval_uploadLabName = [];
  }
  //#endregion

  //#region Search filter labour by name or Regno
  filter_uploadLabName = [];
  searchuploadLabName;
  selcted_cntrIndex = -1;
  search_upload_labname(event, i) {
    this.selcted_cntrIndex = i;
    this.filter_uploadLabName = [];
    this.filter_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  filter_approval_uploadLabName = [];
  sear_approval_chuploadLabName;
  selcted_approval_cntrIndex = -1;
  search_approval_upload_labname(event, i) {
    this.selcted_approval_cntrIndex = i;
    this.filter_approval_uploadLabName = [];
    this.filter_approval_uploadLabName = this.all_LabList.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(event.target.value.toString().toLowerCase()) > -1);
    });
  }
  //#endregion

  //#region approval pending list\
  lab_approval_pendList = [];
  fill_approval_pendingList() {
    this.lab_approval_pendList = [];
    this.ngxService.start();
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.Log_UserID;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      this.lab_approval_pendList = data;
      if (this.lab_approval_pendList.length == 0) {
        alert('No record found.');
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  set_approval_pendList_editcontrol(i, isEdit: boolean) {
    this.Save_formData = new FormData();
    for (let k = 0; k < this.lab_approval_pendList.length; k++) {
      this.lab_approval_pendList[k]['isEdit'] = false;
    }
    this.lab_approval_pendList[i]['isEdit'] = isEdit;

  }
  //#endregion

  //#region test

  //url; //Angular 8
  url: any; //Angular 11, for stricter type
  msg = "";

  //selectFile(event) { //Angular 8
  uploadImg: any;
  selectFile(event: any) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    this.uploadImg = event.target.files[0];

    if (mimeType.match(/image\/*/) == null) {
      this.msg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.url = reader.result;
    }

    const formData: FormData = new FormData();
    formData.append('Image', event.target.files[0]);
    formData.append('ComponentId', '1');

    // return;


  }

  //#endregion

  //#region upload image
  upload_image(event: any) {
    const formData: FormData = new FormData();
    //    formData.append('Image', event.target.files[0]);
    formData.append('Image', this.uploadImg);

    formData.append('ComponentId', '1');

    // return;
    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/UploadImage';
    this._userService.post_data(url, formData).subscribe(
      (data) => {

        if (data == 'Saved SuccessFully.') {

          alert('Record saved successfully.');
          this.policyNo_List = [];
          this.new_policyLabList = [];
          this.excle_policyLabList = [];
          this.uploadExcel_Data = [];

        }
        else {
          alert(data);
        }
      });

    // return this.http.post('/api/dashboard/UploadImage', formData);

  }


  ///////////////////////
  imgPatha: any = "assets/mem_images/user.jpg";
  public isImageWithinLimit(event) {
    //let max_limit = 2097152;//2 mb
    let max_limit = 102400;//100 kb
    let file_size = event.target.files[0].size;
    return (file_size < max_limit);
  }
  public isChoosenFileValid = true;
  public filePosArray: Array<any> = [];
  public fileToUpload: any = [];
  public onChoosenFileTextChanged(event) {
    let text = event.target.value;
    if (text.length > 0) {
      if (this.isImageWithinLimit(event)) {
        this.isChoosenFileValid = true;

        let pos = this.filePosArray.length;
        this.addPositionToList(pos);
        this.fileToUpload[pos] = event.target.files[0];

        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imgPatha = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        event.target.value = '';
        alert('Maximum image size is 100 Kb');
      }

    }
  }
  uploadedImage: Blob;
  public addPositionToList(pos) {
    if (this.filePosArray.indexOf(pos) == -1) {
      this.filePosArray.push(pos);
    }
    if (this.fileToUpload == null || this.fileToUpload.length == 0) {
      this.fileToUpload = [];
    }
  }

  loading: string = '';
  remve_addImg = '';
  upload_excel_file() {

    let isValid = true;
    if (window.confirm('Are sure you to update new labour list.?')) {

      if (Number(this.new_policyLabList.length) > 0) {
        for (let k = 0; k < this.new_policyLabList.length; k++) {
          if (Number(this.new_policyLabList[k]['LabourID']) <= 0) {
            alert('Policy number ' + this.new_policyLabList[k]['PolicyNo'] + ' selected labour invalid.')
            //this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }
          if (this.new_policyLabList[k]['imageFile'] == null || this.new_policyLabList[k]['imageFile'] == '') {
            alert('Policy number ' + this.new_policyLabList[k]['PolicyNo'] + ' select document.')
            //this.new_policyLabList[k]['LabName'] = 'red';
            isValid = false
            return false;
          }

        }
      }

      if (isValid == true) {
        let url = this.globals.BaseURL + 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/saveinspolicyauth';

        this.ngxService.start();

        let isSaved: boolean = false;
        this.loading = 'y';
        this.Save_formData.append("InsPolicyAuthMaster", JSON.stringify(this.new_policyLabList));
        this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_policyLabList));
        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {
            $("#fileProgress").hide();
            if (fileName == 'Saved SuccessFully.') {

              alert('File uploaded successfully.')
              window.location.reload();
              // this.loading = '';
            }
            else {
              this.loading = '';
              alert('Something went wrong internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            //  this.ngxService.start();
            return fileXhr;
          }
        });

        this.Save_formData = new FormData();
        this.policyNo_List = [];
        this.new_policyLabList = [];
        this.excle_policyLabList = [];
        this.uploadExcel_Data = [];
        this.ngxService.stop();

      }
    }

  }

  public Save_formData = new FormData();
  set_uploadfile(event, i, PolicyNo) {
    this.new_policyLabList[i]['imageFile'] = 'yes';
    this.Save_formData.append(PolicyNo, event.target.files[0], event.target.files[0].fileName);

  }

  set_approval_updateData(event, colName, i, labID, labName, labRegNo) {
    if (colName == 'labname') {
      this.lab_approval_pendList[i]['labourID'] = labID;
      this.lab_approval_pendList[i]['labName'] = labName;
      this.lab_approval_pendList[i]['labRegNo'] = labRegNo;
    }
    else if (colName == 'policyno') {
      this.lab_approval_pendList[i]['policyNo'] = event.target.value;
    }
    else if (colName == 'policyamt') {
      this.lab_approval_pendList[i]['policyAmt'] = event.target.value;
    }
    else if (colName == 'doc') {
      this.Save_formData = new FormData();
      this.Save_formData.append(this.lab_approval_pendList[i]['policyNo'], event.target.files[0], event.target.files[0].fileName);
    }
  }
  update_approval_policyList = [];
  save_editIndex = -1;
  update_approval_pending_List(rindex: number) {
    this.update_approval_policyList = [];
    this.save_editIndex = rindex;

    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;
      this.update_approval_policyList = [{
        "PolicyAuthID": this.lab_approval_pendList[rindex]['policyAuthID'],
        "InsCompID": this.Log_UserID,
        "LabourID": this.lab_approval_pendList[rindex]['labourID'],
        "RegNo": this.lab_approval_pendList[rindex]['labRegNo'],
        "LabName": this.lab_approval_pendList[rindex]['labName'],
        "exlLabName": this.lab_approval_pendList[rindex]['labName'],
        "PolicyNo": this.lab_approval_pendList[rindex]['policyNo'],
        "PolicyAmt": this.lab_approval_pendList[rindex]['policyAmt'],
        "DedMonth": this.lab_approval_pendList[rindex]['dedMonth'],
        "DedYear": this.lab_approval_pendList[rindex]['dedYear'],
        "bgcolor": 'None',
        "imageFile": '',
        "isSave": true,
        "isEdit": false,
        "isNewPolicy": true,
        "Status": 'Pending',
      },]

      //

      if (Number(this.update_approval_policyList.length) > 0) {
        for (let k = 0; k < this.update_approval_policyList.length; k++) {
          if (Number(this.update_approval_policyList[k]['LabourID']) <= 0) {
            alert('Policy number ' + this.update_approval_policyList[k]['PolicyNo'] + ' selected labour invalid.')

            isValid = false
            return false;
          }

        }
      }
      if (isValid == true) {
        // this.show_loadingModal(true);
        let url = this.globals.BaseURL + 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/saveinspolicyauth';

        this.Save_formData.append("InsPolicyAuthMaster", JSON.stringify(this.update_approval_policyList));
        // this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_policyLabList));

        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {
            // this.show_loadingModal(false);
            $("#fileProgress").hide();
            if (fileName == 'Saved SuccessFully.') {
              alert('Updated successfully.')
              //this.lab_approval_pendList[this.save_editIndex]['isEdit'] = false;

            }
            else {
              // this.show_loadingModal(false);
              alert('Something went wrong internal server error.')
            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

      }
    }

    this.set_approval_pendList_editcontrol(this.save_editIndex, false);

  }

  //#endregion

  //#region view/download file

  view_uploadFile(fileName): any {

    window.open(this.globals.BaseURL + "/" + this._userService.get_insCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);

  }
  download_file_excelFormat() {

    window.open(this.globals.BaseURL + "/FileFormatToDownload/PimMatha Policy File Fromat.xlsx");

  }

  //#endregion

  //#region create excele downlod file

  lic_DedList = [];
  boardName = '';
  strMonthName = '';
  fill_licDedList() {
    this.show_loadingModal(true);
    this.ngxService.start();
    this.lic_DedList = [];
    this.strMonthName = '';
    let strFlag = "Ins";

    let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/checkdedpaid/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr + '/' + strFlag;
    this._userService.get_Data(url).subscribe((data) => {

      this.ngxService.stop();
      // this.lic_DedList = data;
      if (data == 'yes') {

        url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getinsdedlist/' + this.Log_UserID + '/' + this.filterMonth + '/' + this.filterYr;
        this._userService.get_Data(url).subscribe((data) => {

          this.ngxService.stop();
          this.lic_DedList = data;
          if (data.length > 0) {
            this.boardName = data[0]['boardName'];
            this.strMonthName = this.get_monthName(this.filterMonth);
          }
          this.show_loadingModal(false);
          //this.exportexcel('Lic Deduction List ' + this.filterYr + '-' + this.filterMonth + '.xlsx')
        },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            this.ngxService.stop();
            //alert(err);
            return;
          });

      }
      else {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert('Deduction list not authorized from board.');
      }
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });

  }

  //fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
   
    let fileName = 'Lic Deduction List ' + this.filterYr + '-' + this.filterMonth + '.xlsx'
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
  //#endregion

  //#region get Total
  getTotal(data, fldname) {
    if (fldname == 'policyAmt') {
      return data.reduce((sum, curr) => sum + curr.policyAmt, 0);
    }
  }
  //#endregion
  //#region loading modal
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

}
