<div  class="notranslate">
<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)"
                        routerLink="/dashboard/clerk-dashboard">Dashboard</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Upload Excel</li>
                <!-- <li>{{tempdate}}</li> -->
            </ol>
        </nav>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="{{activeTab}}">
        <ngb-panel *ngIf="uplodFile_for == 'bank'" 
            title="Upload Bank File &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{lastUploadMonth}}"
            id="config-panel-bank">
            <ng-template ngbPanelContent>

                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-10">
                        <h3 style="color: black;">Sample file format click here to download <span><a style="color: blue;text-decoration: underline;" (click)="download_file_excelFormat('bank')" > Download</a> columns in red are mandatory.</span> </h3>  
                    </div>
                    <div class="col-md-2"></div>
                </div>


                <div class="row" *ngIf="loading ==''">
                    <div class="col-md-4">
                        <label for="month-year">Browse excel file </label>
                        <input type="file" style="display: inline-block;" (change)="sel_uploadfile($event)"
                            (click)="$event.target.value=null" placeholder="Upload file" accept=".xlsx">

                            <!-- <input type="file" value="F:/KIT API/notice.txt"> -->

                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-2" style="margin-top: 18px;">
                        <button type="button" class="btn btn-info" (click)="upload_bank_Excel()">Upload</button>

                    </div>
                </div>
<br>
                <div class="row" *ngIf="Dt_RetVal.length > 0">
                    <!-- <h1 style="margin-left: 50px;margin-top: 20px; color: blue;">Bank Uplaod Excel Status List </h1> -->
                   <table style="margin-left: 15px;" class="table table-hover table-responsive-xl labour-table">
                       <thead>
                         <th > File Upload Status </th>
                        
                        </thead>
                       <tr *ngFor='let rec of Dt_RetVal;let i = index' class="sub-tr border" >
                           <td [style.color]="rec.retValType != 'Done' ? 'red' : 'green'" >{{rec.retVal}}  </td> 
                         </tr>
    
                   </table>
    
                  
               </div>

            </ng-template>
        </ngb-panel>

        <ngb-panel *ngIf="uplodFile_for == 'est'" 
            title="Upload Establishment File &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{lastUploadMonth}}"
            id="config-panel-est">
            <ng-template ngbPanelContent>

                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-10">
                        <h3 style="color: black;">Sample file format click here to download <span><a style="color: blue;text-decoration: underline;" (click)="download_file_excelFormat('est')" > Download</a> columns in red are mandatory.</span> </h3>  
                    </div>
                    <div class="col-md-2"></div>
                </div>

                <!-- Text Filter -->

                <div class="row">

                    <div class="col-md-6" >

                        <div class="form-group">
                            <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-toli"
                                    aria-describedby="search-toli" [(ngModel)]="searchToli" (input)="filter_toli()"
                                    placeholder="Search Toli by number or name" autofocus>

                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item nav-item" *ngFor="let rec of filter_toli_List"
                                            appHighlight [searchedWord]="searchToli"
                                            [content]="rec.toliNo - rec.mukName" [classToApply]="'font-weight-bold'"
                                            [setTitle]="'true'"
                                            (click)="set_searchToli(rec.toliID,rec.toliNo,rec.mukName);"
                                            style="text-align: start;font-size: medium;font-weight: bold;">
                                            {{rec.toliNo}} - {{rec.mukName}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class="input-group" style="color: blue;">
                              Toli-Info :  {{toliInfo}}

                            </div>


                        </div>



                    </div>

                   
                    <div class="col-md-6" >

                        <div class="form-group">
                            <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-est"
                                    aria-describedby="search-est" [(ngModel)]="searchEst" (input)="filter_est()"
                                    placeholder="Search Establishment by number or name" autofocus>

                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item nav-item" *ngFor="let rec of filter_Est_List"
                                            appHighlight [searchedWord]="searchEst"
                                            [content]="rec.toliNo - rec.mukName" [classToApply]="'font-weight-bold'"
                                            [setTitle]="'true'"
                                            (click)="set_searchEst(rec.toliID,rec.toliNo,rec.mukName);"
                                            style="text-align: start;font-size: medium;font-weight: bold;">
                                            {{rec.toliNo}} - {{rec.mukName}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class="input-group" style="color: blue;">
                              Est-Info :  {{estInfo}}

                            </div>
                        </div>
                   </div>
                   
                </div>

                <!--  -->

                <div class="row" *ngIf="filter_toliID > 0 && filter_estID > 0">
                    
                        <div class="col-md-6">
                            <label for="Financial-Year">Wage Year  <span style="font-size: small;color:red">*</span></label>
                            <input type="text" (keypress)="onlyNumberKey($event)" (input)="validateYear($event)" class="form-control"
                                [(ngModel)]="filterYr" value="{{filterYr}}" maxlength="4" />
                            <span *ngIf="invalidYear!=''" style="font-size: small;color:red">Invalid Year</span>
                        </div>
                    
                        <div class="col-md-6">
                            <label for="month-year">Wage Month  <span style="font-size: small;color:red">*</span></label>
                            <input type="text" (keypress)="onlyNumberKey($event)" (input)="validateMonth($event)" class="form-control"
                                [(ngModel)]="filterMonth" value="{{filterMonth}}" maxlength="2" />
                            <span *ngIf="invalidMonth!=''" style="font-size: small;color:red">Invalid Month</span>
                        </div>
                   
                   
                </div>

                <!--  -->
<br>
                <div class="row" *ngIf="filter_toliID > 0 && filter_estID > 0">
                    <div class="col-md-4">
                        <label for="month-year">Browse excel file </label>
                        <input type="file" style="display: inline-block;" (change)="sel_uploadfile($event)"
                            (click)="$event.target.value=null" placeholder="Upload file" accept=".xlsx">

                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-2" style="margin-top: 18px;" *ngIf="upload_fileName">
                        <button type="button" class="btn btn-info" (click)="upload_est_Excel()">Upload</button>
                    </div>

                    <div class="col-md-2" style="margin-top: 18px;" *ngIf="filterYr > 0 && filterMonth > 0">
                        <button type="button" class="btn btn-danger" (click)="del_upload_est_Data()">Delete</button>
                    </div>
                </div>

                <br>

                <div class="row" *ngIf="Dt_RetVal.length > 0">
                    <!-- <h1 style="margin-left: 50px;margin-top: 20px; color: blue;">Establishment Uplaod Excel Status List </h1> -->
                   <table style="margin-left: 15px;" class="table table-hover table-responsive-xl labour-table">
                       <thead>
                         <th > File Upload Status </th>
                        
                        </thead>
                       <tr *ngFor='let rec of Dt_RetVal;let i = index' class="sub-tr border" >
                           <td [style.color]="rec.retValType != 'Done' ? 'red' : 'green'" >{{rec.retVal}}  </td> 
                         </tr>
    
                   </table>
    
                  
               </div>
                
            </ng-template>
        </ngb-panel>

        <ngb-panel *ngIf="uplodFile_for == 'labpay'" 
        title="Upload Labour Payment File &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{lastUploadMonth}}"
        id="config-panel-est">
        <ng-template ngbPanelContent>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                    <h3 style="color: black;">Sample file format click here to download <span><a style="color: blue;text-decoration: underline;" (click)="download_file_excelFormat('labpay')" > Download</a> columns in red are mandatory.</span> </h3>  
                </div>
                <div class="col-md-2"></div>
            </div>

            <!-- Text Filter -->

            <div class="row">

                <div class="col-md-5" style="margin-top: 30px;" >

                    <div class="form-group">
                        <div class="form-group has-search">
                            <i class="fa fa-search form-control-feedback"></i>
                            <input type="text" class="form-control" id="search-toli"
                                aria-describedby="search-toli" [(ngModel)]="searchToli" (input)="filter_toli()"
                                placeholder="Search Toli by number or name" autofocus>

                            <div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item nav-item" *ngFor="let rec of filter_toli_List"
                                        appHighlight [searchedWord]="searchToli"
                                        [content]="rec.toliNo - rec.mukName" [classToApply]="'font-weight-bold'"
                                        [setTitle]="'true'"
                                        (click)="set_searchToli(rec.toliID,rec.toliNo,rec.mukName);"
                                        style="text-align: start;font-size: medium;font-weight: bold;">
                                        {{rec.toliNo}} - {{rec.mukName}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div class="input-group" style="color: blue;">
                          Toli-Info :  {{toliInfo}}

                        </div>


                    </div>



                </div>
               
                <div class="col-md-2">
                    <label for="Financial-Year">Wage Year  <span style="font-size: small;color:red">*</span> </label>
                    <input type="text" (keypress)="onlyNumberKey($event)" (input)="validateYear($event)" class="form-control"
                        [(ngModel)]="filterYr" value="{{filterYr}}" maxlength="4" />
                    <span *ngIf="invalidYear!=''" style="font-size: small;color:red">Invalid Year</span>
                </div>
            
                <div class="col-md-2">
                    <label for="month-year">Wage Month  <span style="font-size: small;color:red">*</span> </label>
                    <input type="text" (keypress)="onlyNumberKey($event)" (input)="validateMonth($event)" class="form-control"
                        [(ngModel)]="filterMonth" value="{{filterMonth}}" maxlength="2" />
                    <span *ngIf="invalidMonth!=''" style="font-size: small;color:red">Invalid Month</span>
                </div>

                <div class="col-md-3">
                    <label for="Financial-Year">Date <span style="font-size: small;">Formation (DD/MM/YYYY)</span>
                        <span style="font-size: small;color:red">*</span>
                      </label>
        
                      <input type="date" class="form-control" (input)="vlaidateDate($event)" (change)="setDate($event)"
                        value="{{payDate}}" />
                      <span *ngIf="invalidDate!=''" style="font-size: small;color:red">Invalid Date</span>
                </div>
               
            </div>

           

            <!--  -->
<br>
            <div class="row" *ngIf="loading =='' && filter_toliID > 0 && invalidYear == '' && invalidMonth == '' && invalidDate == '' ">
                <div class="col-md-4">
                    <label for="month-year">Browse excel file </label>
                    <input type="file" style="display: inline-block;" (change)="sel_uploadfile($event)"
                        (click)="$event.target.value=null" placeholder="Upload file" accept=".xlsx">

                </div>
                <div class="col-md-2"></div>
                <div class="col-md-2" style="margin-top: 18px;" *ngIf="upload_fileName && filter_toliID > 0 && invalidYear == '' && invalidMonth == '' && invalidDate == ''">
                    <button type="button" class="btn btn-info" (click)="upload_labPay_Excel()">Upload</button>
                </div>

                <div class="col-md-2" style="margin-top: 18px;" *ngIf="filter_lab_Pay_Exl_RetVal.length > 0 && filter_toliID > 0 && invalidYear == '' && invalidMonth == '' && invalidDate == '' ">
                    <button type="button" class="btn btn-success" (click)="go_to_labpay(payDate,filter_toliID,'',filterYr,filterMonth,'Manual')">Go To Payment</button>
                </div>
            </div>

            <div class="row" *ngIf="lab_Pay_Exl_RetVal.length > 0">
                <h1 style="margin-left: 50px;margin-top: 20px; color: blue;">Labour Uplaod Excel Payment Status List </h1>
               <table style="margin-left: 15px;" class="table table-hover table-responsive-xl labour-table">
                   <thead>
                     <!-- <th> </th> -->
                     <th >Reg.No. </th>
                     <!-- <th >Name </th> -->
                     <th >Remark</th>
                    
                    </thead>
                   <tr *ngFor='let rec of lab_Pay_Exl_RetVal;let i = index' class="sub-tr border" >
                      <td >{{rec.regNo}} </td>
                       <!-- <td >{{rec.name}}  </td>  -->
                       <td [style.color]="rec.retVal != '' ? 'red' : 'black'" >{{rec.remark}}  </td> 
                     </tr>

               </table>

              
           </div>
        </ng-template>
    </ngb-panel>

    </ngb-accordion>

</div>

<!-- Loading -->
<div class="container">

    <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">

                    <div style="text-align: center;">

                        Please wait ...{{progress_Value}}
                        <div class="spinner-grow text-success"></div>
                        <div class="spinner-grow text-info"></div>
                        <div class="spinner-grow text-warning"></div>
                    </div>

                    

                </div>
            </div>
        </div>
    </div>
</div>
</div>