
<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboard/clerk-dashboard">Clerk
                Dashboard</a></li>
            <li class="breadcrumb-item " aria-current="page">Set User Access Authority </li>
          </ol>
        </nav>
      
    </div>

    <div class="row">
      <select class="form-control" id="selauthfor" #selauthfor (change)="fill_userAuthDt($event)">
        <option value="">Select Authority For</option>
        <option value="Wages/Levy Rec">Wages/Levy Rec</option>
        <option value="Lab. Payment">Lab. Payment</option>
        <option value="Lab. Payment Authorization">Lab. Payment Authorization</option>
        <option value="Lab. PaidBank List">Lab. PaidBank List</option>
        <option value="InsurancePolicyAuth">Insurance Policy Authorization</option>
        <option value="NonRefApplAuth">Non.Ref. Application Authorization</option>
        <option value="NonRefPaidBankAuth">Non.Ref. Paid Bank Authorization</option>
        <option value="Iway Report View">Iway Report View</option>
        <option value="Iway Post">Iway Post</option>
        <option value="Upload Excel File">Upload Excel File</option>
        <option value="BankReconcilation">Bank Reconcilation</option>
        <option value="LabNotification">Labour Notification</option>
    
      </select>
    </div>
    
    <div class="row" style="margin-top: 10px;">
       
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sr.No.</th>
                    <th scope="col">User Type</th>
                    <th scope="col">Add</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor='let rec of auth_Dt;let i = index'>
                    <th scope="row">{{i + 1}}</th>
                    <td>{{rec.userType}}</td>
                    <td>
                      <label class="check-input">
                        <input type="checkbox" class="checkbox" (change)="set_checkStatus(i,'Add')" [checked]="rec.add" >
                        <span class="checkmark"></span>
                    </label>
                    </td>
                    <td>
                      <label class="check-input">
                        <input type="checkbox" class="checkbox" (change)="set_checkStatus(i,'Edit')" [checked]="rec.edit" >
                        <span class="checkmark"></span>
                    </label>
                    </td>
                    <td>
                      <label class="check-input">
                        <input type="checkbox" class="checkbox" (change)="set_checkStatus(i,'Delete')" [checked]="rec.delete" >
                        <span class="checkmark"></span>
                    </label>
                    </td>
                    
                  </tr>
                 
                </tbody>
              </table>
       
    </div>

    <div  style="margin-top: 10px;text-align: center;" *ngIf="auth_Dt.length > 0">
      <button class="btn btn-primary" (click)="submit()">Submit</button>
    </div>

</div>

 
