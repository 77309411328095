<div class="notranslate">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Login</li>
            </ol>
        </nav>
        <div class="col-sm-5 offset-sm-4 logindmain">
            <img src="assets/images/login-page-img.png" alt="login" title="login" class="img-fluid login-img">
            <h1 class="text-center">Login</h1>
            <p class="title-message">Sign in to your account</p>
            <form>
                <div class="form-group">
                    <label for="email-mobile">Login As </label>
                    <select #usertype id="usertype" (change)="showboarlist($event)" class="form-control">
                        <!-- <option value="HO">Head Office</option> -->
                        <option value="Chairman">Chairman</option>
                        <option value="Secretary">Secretary</option>
                        <option value="Inspector">Inspector</option>
                        <option value="Staff">Staff Member</option>
                        <option value="Est">Board's Establishments</option>
                        <option value="Patpedi">Patpedi</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Union">Union</option>
                        <option value="Auditor">Auditor</option>
                        <!-- <option value="Est">Board's Establishments</option>
                    <option value="Lab">Board's Labour</option>
                    <option value="Union">Union</option>
                     -->
                    </select>
                </div>
                <div [hidden]="!isshowboard" class="form-group">
                    <label for="email-mobile">Board Name </label>
                    <select #selboard id="selboard" class="form-control"
                        (change)="fill_ins_pat_union_CompListDt($event,0)">
                        <!-- <option value="0">HO</option> -->
                        <option *ngFor="let brd of boardList; let i=index" value="{{brd.boardID}}">{{brd.boardName}}
                        </option>

                    </select>
                </div>
                <div class="form-group">
                    <label for="email-mobile">Email / Mobile no </label>
                    <input type="text" #username id="email-mobile" class="form-control"
                        placeholder="Enter Email / Mobile no." required />

                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password"
                        (keydown.enter)="OnLogin(usertype.value,selboard.value,username.value,password.value)" #password
                        id="password" placeholder="Enter Password." class="form-control" />
                </div>
                <div class="form-group remember-forgot">
                    <label class="customcheck">Remember Me
                        <input type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                    <a (click)="forgotpassword(usertype.value,selboard.value,username.value,password.value)"
                        class="pull-right forgot-pass-message">Forgot Password ?</a>

                </div>
                <div class="clearfix"></div>
                <div class="login-bt-section"><button type="button"
                        (click)="OnLogin(usertype.value,selboard.value,username.value,password.value)"
                        class="btn btn-primary">Login</button></div>
                <div class="font-15 text-center">Don’t have an Account ? <a href="javascript:void(0)">Signup</a></div>
            </form>
        </div>
    </div>
    <div class="clearfix"></div>
</div>