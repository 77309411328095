<div  class="notranslate">

<div class="container dashboardmain">

    <body>
        <section id="company-dashboard">
            <div class="container">
                <div class="row">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a (click)="go_back()" href="javascript:void(0)">Dashboard</a></li>
                            <li class="breadcrumb-item " aria-current="page">Notification</li>
                        </ol>
                    </nav>
                </div>

                <div class="content-panel">

                    <form [formGroup]="sub_Form">

                        <div class="row">
                            <div class="form-group col-sm-12" style="text-align: center;">
                                <!-- <input type="radio" name="1" id="1" class="with-gap" [(ngModel)]="rdb_all" [value]="rdb_all">&nbsp;Send To All
                                &nbsp;&nbsp;&nbsp;&nbsp;  <input type="radio" name="2" id="2" class="with-gap" [(ngModel)]="rdb_all" [value]="rdb_all" >&nbsp;Send To Labour Wsie -->


                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="LabWise" id="LabWise1"
                                        [checked]="rdb == 'All'" (change)="set_rdb($event,'All')" value="All">
                                    <label class="form-check-label" for="exampleRadios1" >
                                        Send To All
                                    </label>
                                    
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="LabWise" id="LabWise2"
                                        [checked]="rdb == 'LabWise'" (change)="set_rdb($event,'LabWise')"
                                        value="LabWise">
                                    <label class="form-check-label" for="exampleRadios2" >
                                        Send To Labour Wsie
                                    </label>
                                    
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12">
                                <label>Subject <strong>*</strong></label>
    
                                <input type="text" class="form-control" formControlName="subject" placeholder="" required>
                                <span class="error ion-padding" style="color: red;"
                                *ngIf="isSubmitted && errorControl.subject.errors?.required">
                                Enter subject
                            </span>
                            </div>
                        </div>
    
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <label>Message <strong>*</strong></label>
                                <textarea class="form-control" formControlName="mesg" 
                                    placeholder="Type here..." rows="10"></textarea>
                                <span class="error ion-padding" style="color: red;"
                                *ngIf="isSubmitted && errorControl.mesg.errors?.required">
                                Enter Message
                            </span>
                            </div>
                            <div class="form-group col-sm-6">
                                <label class="form-label">Browse File 
                                    <strong> {{Notific_fileName}}</strong>
                                </label>

                                <label class="custom-file-input">
                                    <span><img src="../../../assets/images/upload-img.png" alt="">
                                    </span>
                                    <h5>Browse your file here</h5>
                                    <input type="file" accept="pdf/*" (change)="set_file($event)" placeholder="Upload file">
                                </label>
    
                            </div>
                        </div>

                        <div class="row" style="margin-top: 5px;" *ngIf="show_searchCnt == 'yes'">
                            <h4> + Add Labour</h4>
                            <div class="col-md-12">
                              <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-toli" formControlName="txtlabName"  aria-describedby="search-toli"
                                  [(ngModel)]="searchLab" (input)="filter_Lab()" placeholder="Search by number or name"
                                  autofocus>
              
                                <div>
                                  <ul class="list-group list-group-flush">
                                    <li class="list-group-item nav-item" *ngFor="let rec of filter_Lab_list" appHighlight
                                      [searchedWord]="searchLab" [content]="rec.toliNo - rec.mukName"
                                      [classToApply]="'font-weight-bold'" [setTitle]="'true'"
                                      (click)="set_searchLab(rec.toliID,rec.toliNo,rec.mukName);"
                                      style="text-align: start;font-size: medium;font-weight: bold;">
                                      {{rec.toliNo}} - {{rec.mukName}}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
              
                            <!-- <div class="form-group col-md-8">
                              <div class="input-group" style="color: blue;">
                                {{labInfo}}
              
                              </div>
                            </div> -->
              
              
                            <div class="table-responsive">

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><b>Labour Info</b></th>
                                            <th class="text-right"><b>Manage</b></th>
                                        </tr>
                                    </thead>
        
                                    <tr *ngFor='let rec of sendLabData_List;let i = index' >
                                        <td *ngIf="rec.AddLab == 'Yes'" class="title">{{rec.LabInfo}}</td>
                                       
                                        <td  *ngIf="rec.AddLab == 'Yes'" width="30%" class="text-right"> 
                                            <a (click)="remove_Lab_record(i)" class="btn-trash"><i style="color: red;" class="bi bi-trash"></i></a>
                                        </td>
                                    </tr>
                                    
                                </table>
        
        
                            </div>

              
                          </div>
    
                       
                    </form>
                    
                </div>

                <div class="content-panel">
                    <div class="row">
                            <div class="form-group col-sm-6">
                                <a class="btn-fullfil btn-comman mr-2 float-right" style="color: white;" (click)="submit()">Submit</a>
                            </div>
    
                            <!--  -->
                       </div>

                </div>

            </div>
        </section>



    </body>
</div>
</div>  