<div  class="notranslate">

<div class="container dashboardmain">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
        </nav>
    </div>

    <form class="search">

        <div class="row">
            <div class="col-md-6">
                <label for="Financial-Year">Financial Year</label>
                <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
                    <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== filterFayear">
                        {{yr.displayYear}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="month-year">Month - Year</label>
                <select (change)="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
                    <!-- <option [value]='0'>All</option> -->
                    <option *ngFor='let mon of MonthList' [value]='mon.mon' [selected]="mon.mon=== filterMonth">
                        {{mon.dispmon}}</option>
                </select>

            </div>
            <!-- <div class="col-md-1">
                <div class="search-bt" style="margin-top: 30px;" (click)="fill_allData()"><i class="fa fa-refresh"
                        aria-hidden="true"></i></div>
            </div> -->

            <!-- <div class="col-md-6" style="display: none;">
                <label for="month-year" class="board-name-label">Sub-Division</label>
                <select (change)="Set_MarketID($event)" class="form-control select-t board-name" id="sel_Market">
                    <option [value]='0'>All</option>
                    <option *ngFor='let m of market_List' [value]='m.marketID'
                        [selected]="m.marketID=== filterMarketID">{{m.marketName}}</option>
                </select>
                <div class="search-bt" (click)="fill_allData()"><i class="fa fa-search" aria-hidden="true"></i></div>
            </div> -->

        </div>
    </form>

    <div class="row">
        <div class="col-md-12">
            <div class="font-15 text-right" style="text-align: right;margin-left: 30px;margin-right: 30px;"> <a
                    href="javascript:void(0)" style="font-weight: bold;" (click)="goto_reports()">Go to Reports</a>
            </div>
        </div>

      
    </div>

    <!-- activeIds="config-panel-one" -->

    <ngb-accordion #acc="ngbAccordion">
        <!-- Wages Levy -->

        <ngb-panel title="Wages/Levy Receipt" id="config-panel-one">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-12">
                        <div class="font-15 text-center"
                            style="text-align: center;margin-left: 30px;margin-right: 30px;"> Do You Want To Make New
                            Receipt ? <a href="javascript:void(0)" style="font-weight: bold;"
                                (click)="go_to_receipt(0,0,'')">Go To Receipt</a></div>
                    </div>
                </div>

                <div class="row">
                    <ngb-tabset (tabChange)="tabClick($event)" [destroyOnHide]="false">
                        <ngb-tab title="Receipt Not Done">
                            <ng-template ngbTabContent>

                                <div class="row">
                                    <div class="col-md-12 font-15 text-center">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="fill_recNotDone_Toli()">Show</button>
                                    </div>

                                </div>

                                <div class="table-responsive" style="height: 400px;">
                                    <table id="dtBasicExample"
                                        class="table table-hover table-responsive-sm labour-table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="th-sm">Toli No.
                                                </th>
                                                <th class="th-sm">Name
                                                </th>
                                                <th class="th-sm">
                                                </th>
                                                <!-- <th class="th-sm">
                                                </th> -->

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="sub-tr">

                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_NoRec_toliNo"
                                                        (ngModelChange)="filter_No_recDone('toliNo',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_NoRec_mukName"
                                                        (ngModelChange)="filter_No_recDone('mukName',$event)" /></td>
                                                <td style="text-align: left;"></td>
                                                <!-- <td style="text-align: left;"></td> -->
                                            </tr>

                                            <tr class="sub-tr" *ngFor='let rec of filter_rec_notDoneToli;let i = index'>

                                                <td>{{rec.toliNo}}</td>
                                                <td style="text-align: left;">{{rec.mukName}}</td>
                                                <td><a href="javascript:void(0)" style="font-weight: bold;"
                                                        (click)="go_to_receipt(0,rec.toliId,'')">Go To Receipt</a></td>
                                                <!-- <td>
                                                    <a href="javascript:void(0)" style="font-weight: bold;"
                                                        (click)="go_to_labpay(0,rec.toliId,'',0,0,'Manual')">Go To
                                                        Payments</a>
                                                    </td> -->
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>

                                <!-- <pagination-controls (pageChange)="pageChanged($event,'recnotdone')"></pagination-controls> -->

                            </ng-template>
                        </ngb-tab>

                        <ngb-tab>
                            <ng-template ngbTabTitle>Receipt Done</ng-template>
                            <ng-template ngbTabContent>

                                <div class="row">
                                    <div class="col-md-12 font-15 text-center">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="fill_recDone_Toli()">Show</button>
                                    </div>

                                </div>

                                <div class="table-responsive" style="height: 400px;">
                                    <table class="table table-hover table-responsive-sm labour-table">
                                        <thead>
                                            <tr>
                                                <!-- <th>Sr.No</th> -->
                                                <th>Receipt No</th>
                                                <th>Date</th>
                                                <th>Toli No.</th>
                                                <th>Name</th>
                                                <th style="text-align: right;">Wage Year</th>
                                                <th style="text-align: right;">Wage Month</th>
                                                <th style="text-align: right;">Wages Amt.</th>
                                                <th style="text-align: right;">Levy Amt.</th>
                                                <th style="text-align: right;">Anamat Amt.</th>
                                                <th style="text-align: right;">Tot. Amt.</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container>
                                                <tr class="sub-tr">
                                                    <!-- <td scope="row"><i class="fa fa-search" aria-hidden="true"></i></td> -->
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_RecNo"
                                                            (ngModelChange)="filter_recDone('receiptNO',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_docdt"
                                                            (ngModelChange)="filter_recDone('docdt',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_toliNo"
                                                            (ngModelChange)="filter_recDone('toliNo',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_mukName"
                                                            (ngModelChange)="filter_recDone('mukName',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_wageyr"
                                                            (ngModelChange)="filter_recDone('wageyr',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_wageMon"
                                                            (ngModelChange)="filter_recDone('wageMon',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_wagesAmt"
                                                            (ngModelChange)="filter_recDone('wagesAmt',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_levyAmt"
                                                            (ngModelChange)="filter_recDone('levyAmt',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_levyAmt"
                                                            (ngModelChange)="filter_recDone('anamatAmt',$event)" /></td>
                                                    <td style="text-align: left;"><input class="form-control"
                                                            [ngModel]="filter_totAmt"
                                                            (ngModelChange)="filter_recDone('totAmt',$event)" /></td>
                                                    <td style="text-align: left;"></td>
                                                    <td style="text-align: left;"></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <!-- <tr class="sub-tr" style="font-weight:lighter;color: black;" *ngFor='let rec of rec_DoneToli | paginate: pg_RecDone_config;let i = index'> -->

                                                <tr class="sub-tr" style="font-weight:lighter;color: black;"
                                                    *ngFor='let rec of rec_DoneToli;let i = index'>

                                                    <!-- <td scope="row">{{i + 1}}</td> -->
                                                    <td style="text-align: left;">{{rec.receiptNO}}</td>
                                                    <td style="text-align: left;">{{rec.docdt}}</td>
                                                    <td style="text-align: left;">{{rec.toliNo}}</td>
                                                    <td style="text-align: left;width: 450px;">{{rec.mukName}}</td>
                                                    <td style="text-align: left;">{{rec.wageyr}}</td>
                                                    <td style="text-align: left;">{{rec.wageMon}}</td>
                                                    <td style="text-align: right;">{{rec.wagesAmt}}</td>
                                                    <td style="text-align: right;">{{rec.levyAmt}}</td>
                                                    <td style="text-align: right;">{{rec.anamatAmt}}</td>
                                                    <td style="text-align: right;">{{rec.totAmt}}</td>
                                                    <td style="text-align: left;"><a href="javascript:void(0)"><i
                                                                class="fa fa-print"
                                                                (click)="genrate_Wl_Rec_rpt(rec.docID)"
                                                                ngbTooltip="Print" aria-hidden="true"></i></a></td>
                                                    <td style="text-align: left;"><a href="javascript:void(0)"><i
                                                                class="fa fa-edit"
                                                                (click)="go_to_receipt(rec.docID,0,'no')"
                                                                ngbTooltip="Edit Receipt" aria-hidden="true"></i></a>
                                                    </td>

                                                    <td style="text-align: left;"><a href="javascript:void(0)"><i
                                                                class='fab fa-amazon-pay' placement="left"
                                                                ngbTooltip="Go To Payments"
                                                                (click)="go_to_labpay(0,rec.toliId,'',rec.wageyr,rec.wageMon,'Manual')"
                                                                style='font-size:18px'></i></a></td>

                                                    <td></td>
                                                </tr>


                                            </ng-container>
                                        </tbody>
                                        <tfoot>
                                            <tr Class="table-footer">
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td>Total </td>
                                                <td style="text-align: right;">{{getTotal(rec_DoneToli,'wagesAmt')}}
                                                </td>
                                                <td style="text-align: right;">{{getTotal(rec_DoneToli,'levyAmt')}}
                                                </td>
                                                <td style="text-align: right;">{{getTotal(rec_DoneToli,'anamatAmt')}}
                                                </td>
                                                <td style="text-align: right;">{{getTotal(rec_DoneToli,'totAmt')}}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle>Reports</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab> -->

                    </ngb-tabset>

                </div>
            </ng-template>
        </ngb-panel>

        <!-- Lab Payments -->
        <ngb-panel title="Labour Payments">
            <ng-template ngbPanelContent>
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Payment Not Done</a>
                        <ng-template ngbNavContent>

                            <div class="row">
                                <div class="col-md-12 font-15 text-center">
                                    <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                        (click)="fill_payNotDone_Toli()">Show</button>
                                </div>

                            </div>

                            <div class="table-responsive" style="height: 400px;">
                                <table
                                    class="table table-hover table-responsive-sm labour-table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <!-- <th class="th-sm">Sr.No
                                        </th> -->
                                            <th class="th-sm">Toli No.
                                            </th>
                                            <th class="th-sm">Name
                                            </th>
                                            <th style="text-align: right;">Wage Year</th>
                                            <th style="text-align: right;">Wage Month</th>
                                            <th class="th-sm">
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="sub-tr">
                                            <!-- <td scope="row"><i class="fa fa-search" aria-hidden="true"></i></td> -->
                                            <td style="text-align: left;"><input class="form-control"
                                                    [ngModel]="filter_Nopay_toliNo"
                                                    (ngModelChange)="filter_No_payDone('toliNo',$event)" /></td>
                                            <td style="text-align: left;"><input class="form-control"
                                                    [ngModel]="filter_Nopay_mukName"
                                                    (ngModelChange)="filter_No_payDone('mukName',$event)" /></td>
                                            <td style="text-align: left;"></td>
                                            <td style="text-align: left;"></td>
                                            <td style="text-align: left;"></td>
                                        </tr>
                                        <!-- | paginate: { itemsPerPage: display_recPerPage, currentPage: payNotDone_curPg } -->
                                        <tr class="sub-tr" *ngFor='let rec of filter_pay_notDoneToli'>
                                            <!-- <td>{{i + 1}}</td> -->
                                            <td>{{rec.toliNo}}</td>
                                            <td style="text-align: left;">{{rec.mukName}}</td>
                                            <td style="text-align: left;">{{rec.wageyr}}</td>
                                            <td style="text-align: left;">{{rec.wageMon}}</td>
                                            <td><a href="javascript:void(0)" style="font-weight: bold;"
                                                    (click)="go_to_labpay(0,rec.toliId,'',rec.wageyr,rec.wageMon,'Manual')">Go
                                                    To
                                                    Payment</a>
                                            </td>

                                        </tr>

                                    </tbody>

                                </table>
                            </div>

                            <!-- <div class="text-center" *ngIf=" filter_pay_notDoneToli.length > display_recPerPage">
                                <ul class="clearfix">
                                    <pagination-controls (pageChange)="payNotDone_curPg = $event"></pagination-controls>
                                </ul>
                            </div> -->

                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Payment Done</a>
                        <ng-template ngbNavContent>

                            <div class="row">
                                <div class="col-md-12 font-15 text-center">
                                    <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                        (click)="fill_payDone_Toli()">Show</button>
                                </div>

                            </div>

                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-sm labour-table">
                                    <thead>

                                        <tr>
                                            <!-- <th>Sr.No</th> -->
                                            <th>Bank List No</th>
                                            <th>Pay. Date</th>
                                            <th>Toli No.</th>
                                            <th>Name</th>
                                            <th style="text-align: right;">Wage Year</th>
                                            <th style="text-align: right;">Wage Month</th>
                                            <th style="text-align: right;">Wages Amt.</th>
                                            <th style="text-align: right;">Levy Amt.</th>

                                            <th style="text-align: right;">Netpay Amt.</th>
                                            <th>Finalize</th>
                                            <th>Authorize</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container>
                                            <tr class="sub-tr">
                                                <!-- <td scope="row"><i class="fa fa-search" aria-hidden="true"></i></td> -->
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_listno"
                                                        (ngModelChange)="filter_payDone('listNo',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_paydt"
                                                        (ngModelChange)="filter_payDone('payDt',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_toliNo"
                                                        (ngModelChange)="filter_payDone('toliNo',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_mukName"
                                                        (ngModelChange)="filter_payDone('mukName',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_wageyr"
                                                        (ngModelChange)="filter_payDone('wageyr',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_wageMon"
                                                        (ngModelChange)="filter_payDone('wageMon',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_wagesAmt"
                                                        (ngModelChange)="filter_payDone('wagesAmt',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_levyAmt"
                                                        (ngModelChange)="filter_payDone('levyAmt',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_netpayAmt"
                                                        (ngModelChange)="filter_payDone('netPay',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_finalized"
                                                        (ngModelChange)="filter_payDone('finalized',$event)" /></td>
                                                <td style="text-align: left;"><input class="form-control"
                                                        [ngModel]="filter_pay_authorized"
                                                        (ngModelChange)="filter_payDone('authorized',$event)" /></td>
                                                <td style="text-align: left;">
                                                    <!-- <a href="javascript:void(0)"
                                                    (click)="go_to_labpaidbankList('',0,'')">Got To Payment
                                                    Authorization</a> -->
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>


                                            <!-- | paginate: { itemsPerPage: display_recPerPage, currentPage: payDone_curPg } -->
                                            <tr class="sub-tr" style="font-weight:lighter;color: black;"
                                                *ngFor='let rec of filter_pay_DoneToli'>

                                                <!-- <td scope="row">{{i + 1}}</td> -->
                                                <td style="text-align: left;">{{rec.listNo}}</td>
                                                <td style="text-align: left;">{{rec.payDt}}</td>
                                                <td style="text-align: left;">{{rec.toliNo}}</td>
                                                <td style="text-align: left;width: 450px;">{{rec.mukName}}</td>
                                                <td style="text-align: left;">{{rec.wageyr}}</td>
                                                <td style="text-align: left;">{{rec.wageMon}}</td>
                                                <td style="text-align: right;">{{rec.wagesAmt}}</td>
                                                <td style="text-align: right;">{{rec.levyAmt}}</td>
                                                <td style="text-align: right;">{{rec.netPay}}</td>
                                                <td style="text-align: left;">{{rec.finalized}}</td>
                                                <td style="text-align: left;">
                                                    {{rec.authorized}}
                                                    <!-- <span *ngIf="rec.transBankListID == 0 && rec.finalized == 'Yes'">Yes</span>
                                            <span *ngIf="rec.transBankListID > 0">Yes</span>
                                            <span *ngIf="rec.transBankListID == 0 && rec.finalized != 'Yes'">No</span> -->
                                                </td>
                                                <td><a *ngIf="rec.transBankListID == 0 && rec.authorized != 'Yes'"
                                                        href="javascript:void(0)"><i
                                                            (click)="go_to_labpay(rec.dtdate,rec.toliId,'',rec.wageyr,rec.wageMon,rec.payMode)"
                                                            class="fa fa-edit" ngbTooltip="Edit Payments"
                                                            aria-hidden="true"></i></a>
                                                </td>
                                                <!-- <td><a *ngIf="rec.transBankListID > 0" href="javascript:void(0)"><i
                                                        class='fab fa-amazon-pay'
                                                        (click)="go_to_labpaidbankList(rec.payDt,rec.toliId,'')"
                                                        placement="left"
                                                        ngbTooltip="Go To Payment Authorization"
                                                        style='font-size:18px'></i></a></td> -->
                                                <td><a href="javascript:void(0)"><i class="fa fa-print"
                                                            ngbTooltip="Print" aria-hidden="true"></i></a></td>
                                                <td></td>
                                            </tr>

                                        </ng-container>
                                    </tbody>

                                    <!-- <tfoot>
                                        <tr Class="table-footer">
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td>Total </td>
                                            <td style="text-align: right;">
                                                {{getTotal(filter_pay_DoneToli,'wagesAmt')}} </td>
                                            <td style="text-align: right;">
                                                {{getTotal(filter_pay_DoneToli,'levyAmt')}} </td>
                                            <td style="text-align: right;">
                                                {{getTotal(filter_pay_DoneToli,'netPay')}}</td>
                                            <td style="text-align: right;"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot> -->

                                </table>
                            </div>

                            <!-- Styled Pagination -->
                            <!-- <div class="text-center" *ngIf=" filter_pay_DoneToli.length > display_recPerPage">
                                <ul class="clearfix">
                                    <pagination-controls (pageChange)="payDone_curPg = $event"></pagination-controls>
                                </ul>
                            </div> -->

                        </ng-template>
                    </li>

                    <!-- *ngIf="show_tab=='yes'" -->

                    <li [ngbNavItem]="3">
                        <a ngbNavLink>Payment Authorization</a>
                        <ng-template ngbNavContent>
                            <div style="padding-left: 1%; color: black;">
                                <div>

                                    <br>
                                    <div class="row">
                                        <table
                                            class="table table-hover table-responsive-sm labour-table table-striped ">

                                            <tbody>
                                                <tr class="sub-tr">
                                                    <td></td>

                                                    <td style="width: 150px;">
                                                        <div class="custom-control custom-radio">
                                                            <input id="Authorize" type="radio"
                                                                class="custom-control-input"
                                                                (click)="click_Auth_unAuth_Option(true)"
                                                                [checked]="isAuthSel" name="authorize">
                                                            <label class="custom-control-label"
                                                                for="Authorize">Authorize</label>
                                                        </div>
                                                    </td>
                                                    <td style="width: 180px;">
                                                        <div class="custom-control custom-radio">
                                                            <input id="unauthorize" type="radio"
                                                                class="custom-control-input"
                                                                (click)="click_Auth_unAuth_Option(false)"
                                                                [checked]="!isAuthSel" name="authorize">
                                                            <label class="custom-control-label"
                                                                for="unauthorize">Un-Authorize</label>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr class="sub-tr">
                                                    <td>From Date</td>
                                                    <td><input type="date" class="form-control"
                                                            [(ngModel)]="frm_authDate" value="{{frm_authDate}}"
                                                            (input)="validate_Date($event,'frmauthDate')"
                                                            (change)="set_Date($event,'frmauthDate')" />
                                                        <span *ngIf="invalid_frmAuthDate!=''"
                                                            style="font-size: small;color:red">Invalid Date</span>
                                                    </td>
                                                    <td>To Date </td>
                                                    <td><input type="date" class="form-control"
                                                            [(ngModel)]="to_authDate" value="{{to_authDate}}"
                                                            (input)="validate_Date($event,'toauthDate')"
                                                            (change)="set_Date($event,'toauthDate')" />
                                                        <span *ngIf="invalid_toAuthDate!=''"
                                                            style="font-size: small;color:red">Invalid Date</span>
                                                    </td>
                                                    <td><button type="button" class="btn btn-primary"
                                                            (click)="get_labPayAuthList()">Show</button></td>

                                                </tr>



                                            </tbody>

                                        </table>

                                    </div>
                                </div>

                                <div>
                                    <!-- *ngIf="lab_authList.length > 0" -->
                                    <div class="row">
                                        <!-- <a href="javascript:void(0)" style="color: blue;" (click)="set_selAll_AuthToli($event,-1)">{{bank_AuthSelunSel}}</a>
                            <br> -->


                                        <table
                                            class="table table-hover table-responsive-sm labour-table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th class="th-sm"><input type="checkbox"
                                                            (change)="set_selAll_AuthToli($event,-1)"
                                                            *ngIf="lab_authList.length > 0">
                                                        &nbsp;<span>{{bank_AuthSelunSel}}</span></th>
                                                    <th class="th-sm">Toli No.</th>
                                                    <th class="th-sm">Name</th>
                                                    <th class="th-sm">Wage Year/Month</th>
                                                    <th class="th-sm">Pay Date</th>
                                                    <th class="th-sm">Rec.Wage</th>
                                                    <th class="th-sm">Paid Wage</th>
                                                    <th class="th-sm">Netpay</th>
                                                    <th class="th-sm">IT Ded.</th>
                                                    <th class="th-sm">PAN Card</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="sub-tr" *ngFor='let rec of lab_authList;let i = index'>
                                                    <td><input type="checkbox" (change)="set_selAll_AuthToli($event,i)"
                                                            [checked]="rec.checkStatus"></td>
                                                    <td>{{rec.toliNo}}</td>
                                                    <td style="text-align: left;">{{rec.mukName}}</td>
                                                    <td>{{rec.wageYr}}/{{rec.wageMon}}</td>
                                                    <td>{{rec.payDt}}</td>
                                                    <td>{{rec.recWageAmt}}</td>
                                                    <td>{{rec.recPaidAmt}}</td>
                                                    <td>{{rec.netPay}}</td>
                                                    <td>{{rec.itDedSt}}</td>
                                                    <td>{{rec.panCardSt}}</td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr Class="table-footer">
                                                    <td></td>
                                                    <td></td>
                                                    <td style="text-align: left;"></td>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td>{{getTotal(lab_authList,'recWageAmt')}}</td>
                                                    <td>{{getTotal(lab_authList,'recPaidAmt')}}</td>
                                                    <td>{{getTotal(lab_authList,'netPay')}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                    <div class="text-center" *ngIf="filter_lab_noChqClearList.length == 0">
                                        <div class="row" *ngIf="isAuthSel">
                                            <div class="col-md-3" style="text-align: right;">
                                                <label for="Financial-Year">Authorize Date </label>
                                            </div>
                                            <div class="col-md-3" style="text-align: right;">

                                                <input type="date" class="form-control" [(ngModel)]="authDate"
                                                    value="{{authDate}}" (input)="validate_Date($event,'authDate')"
                                                    (change)="set_Date($event,'authDate')" />
                                                <span *ngIf="invalid_AuthDate!=''"
                                                    style="font-size: small;color:red">Invalid Date</span>
                                            </div>
                                            <div class="col-md-6" style="text-align: left;">

                                                <button type="button" class="btn btn-primary"
                                                    (click)="save_paidBank_Authorization()">Submit</button>
                                            </div>
                                        </div>

                                        <button *ngIf="!isAuthSel" type="button" class="btn btn-primary"
                                            (click)="save_paidBank_Authorization()">Submit</button>

                                    </div>

                                </div>

                                <!-- non chq clear list -->
                                <br>
                                <div *ngIf="filter_lab_noChqClearList.length > 0 && lab_authList.length > 0">
                                    <div style="text-align: center">
                                        <h2 style="color: red;">Payment Cheque Clearing Pending List</h2>
                                    </div>
                                    <div class="row">
                                        <table
                                            class="table table-hover table-responsive-sm labour-table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th class="th-sm">Bank Name</th>
                                                    <th class="th-sm">Rec.No.</th>
                                                    <th class="th-sm">Transaction Date</th>
                                                    <th class="th-sm">Transaction Type</th>
                                                    <th class="th-sm">Chq. No.</th>
                                                    <th class="th-sm">Chq. Date</th>
                                                    <th class="th-sm">Chq. Amount</th>
                                                    <th class="th-sm">Clear Date</th>
                                                    <th class="th-sm"></th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr class="sub-tr"
                                                    *ngFor='let rec of filter_lab_noChqClearList;let i = index'>

                                                    <td style="text-align: left;">{{rec.eAccName}}</td>
                                                    <td>{{rec.receiptNO}}</td>
                                                    <td>{{rec.docdt | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{rec.flag}}</td>
                                                    <td>{{rec.billNo}}</td>
                                                    <td>{{rec.billDt | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{rec.chqAmt}}</td>
                                                    <td> <input type="date" class="form-control"
                                                            value="{{rec.chequeClearingDt}}"
                                                            (change)="set_chq_clearDate($event,i)" />
                                                        <!-- (input)="validate_Date($event,'chqclrDate')" -->
                                                        <span *ngIf="rec.invalidchqDate!=''"
                                                            style="font-size: small;color:red">Invalid Date</span>
                                                    </td>
                                                    <td> </td>
                                                </tr>

                                            </tbody>


                                            <tfoot>
                                                <tr Class="table-footer">

                                                    <td style="text-align: left;"></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td> <button type="button" class="btn btn-primary"
                                                            (click)="save_labPay_chqClear()">Submit</button></td>
                                                    <td> </td>



                                                </tr>
                                            </tfoot>

                                        </table>



                                    </div>
                                </div>

                                <!-- bank ac no not get list -->

                                <br>
                                <div *ngIf="lab_noBankAcList.length > 0 && lab_authList.length > 0">
                                    <div style="text-align: center">
                                        <h2 style="color: red;">Labour Bank A/C No. Not Exist Details</h2>
                                    </div>
                                    <div class="row">
                                        <table
                                            class="table table-hover table-responsive-sm labour-table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th class="th-sm">Lab.RegNo.</th>
                                                    <th class="th-sm">Lab. Name</th>
                                                    <th class="th-sm">Wage Year/Month</th>
                                                    <th class="th-sm">Pay Date</th>
                                                    <th class="th-sm">Toli No.</th>
                                                    <th class="th-sm">Toli Name</th>

                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr class="sub-tr" *ngFor='let rec of lab_noBankAcList;let i = index'>

                                                    <td>{{rec.labRegNo}}</td>
                                                    <td style="text-align: left;">{{rec.labName}}</td>
                                                    <td>{{rec.wageYr}}/{{rec.wageMon}}</td>
                                                    <td>{{rec.payDt}}</td>
                                                    <td>{{rec.toliNo}}</td>
                                                    <td style="text-align: left;">{{rec.mukName}}</td>

                                                </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                </div>


                            </div>
                        </ng-template>
                    </li>

                    <!-- *ngIf="show_tab=='yes'" -->
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>Paid Bank List</a>
                        <ng-template ngbNavContent>

                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-3">
                                    <label for="month-year">List No.</label>
                                    <select class="form-control" (change)="Set_piadbankListNo($event)">
                                        <option value="-1">Select list no.</option>
                                        <option value="0">0</option>
                                        <option *ngFor='let rec of list_labpaidBankNo' value="{{rec.transBanklistID}}">
                                            {{rec.listNo}}</option>

                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="month-year">From Date</label>
                                    <input type="date" class="form-control" [(ngModel)]="frm_paidBankDate"
                                        value="{{frm_paidBankDate}}" [disabled]='PaidbankCnt_disabled'
                                        (input)="validate_Date($event,'frmpaidBankDate')"
                                        (change)="set_Date($event,'frmpaidBankDate')" />
                                    <span *ngIf="invalid_frmpaidBankDate!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>

                                <div class="col-md-3">
                                    <label for="month-year" class="board-name-label">To Date</label>
                                    <input type="date" class="form-control" [(ngModel)]="to_paidBankDate"
                                        value="{{to_paidBankDate}}" [disabled]='PaidbankCnt_disabled'
                                        (input)="validate_Date($event,'topaidBankDate')"
                                        (change)="set_Date($event,'topaidBankDate')" />
                                    <span *ngIf="invalid_topaidBankDate!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>


                                <div class="col-md-2" style="margin-top: 27px;">
                                    <button *ngIf="TransBanklistID != -1" type="button" class="form-control btn-primary"
                                        (click)="get_labPayAuthListPaidBank()">Show</button>
                                </div>


                            </div>
                            <br>
                            <div class="row">

                                <table class="table table-hover table-responsive-sm labour-table table-striped ">
                                    <thead>
                                        <tr>
                                            <th class="th-sm"><input type="checkbox"
                                                    (change)="set_selAll_paidbank($event,-1)"
                                                    *ngIf="lab_authListPaidBank.length > 0"
                                                    [disabled]='PaidbankCnt_disabled'>
                                                &nbsp;<span>{{paidbank_SelunSel}}</span></th>
                                            <th class="th-sm">Toli No.</th>
                                            <th class="th-sm">Name</th>
                                            <th class="th-sm">Amount</th>
                                            <th class="th-sm">No. Of Labour</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="sub-tr" *ngFor='let rec of lab_authListPaidBank;let i = index'>
                                            <td><input type="checkbox" (change)="set_selAll_paidbank($event,i)"
                                                    [checked]="rec.checkStatus" [disabled]='PaidbankCnt_disabled'></td>
                                            <td>{{rec.toliNo}}</td>
                                            <td style="text-align: left;">{{rec.mukName}}</td>
                                            <td>{{rec.netPay}}</td>
                                            <td>{{rec.labCnt}}</td>
                                            <td></td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr Class="table-footer">
                                            <td></td>
                                            <td></td>
                                            <td style="text-align: right;">Total</td>
                                            <td>{{getTotal(lab_authListPaidBank,'netPay')}}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div class="text-center">
                                <button
                                    *ngIf="lab_authListPaidBank.length > 0 && TransBanklistID != -1 && show_paidbanklisRpt != 'download'"
                                    type="button" class="btn btn-outline-danger"
                                    (click)="fill_labPaidBank_New('yes','chq')">Show Cheque Details</button>
                                &nbsp;&nbsp;
                                <button
                                    *ngIf="lab_authListPaidBank.length > 0 && TransBanklistID != -1 && TransBanklistID != 0"
                                    style="margin-left: 10px;" type="button" class="btn btn-primary"
                                    (click)="fill_rpt_paidLabbankList()">Show Report</button>
                                <!-- <button *ngIf="show_paidbanklisRpt === 'download'" type="button" class="btn btn-primary"
                                    style="margin-left: 10px;" (click)="generatePdf_paidLabbankList()">Download
                                    PDf</button> -->
                                &nbsp;&nbsp;
                                <button type="button" class="btn btn-primary"
                                    *ngIf="lab_authListPaidBank.length > 0 && TransBanklistID != -1 && TransBanklistID != 0"
                                    (click)="create_Lab_paid_bankList_excel_sendEmail()">Create File & Send
                                    Email</button>

                                <button
                                    *ngIf="lab_authListPaidBank.length > 0 && TransBanklistID != -1 && TransBanklistID != 0 "
                                    type="button" class="btn btn-primary" style="margin-left: 10px;"
                                    (click)="fill_labPaidBank_New('yes','chqprint')">Print Cheque</button>

                                <!-- <div style="text-align: center;margin-top: 5%;">
                                    <button
                                        *ngIf="lab_authListPaidBank.length > 0 && send_mailPaidBank == 'yes' && TransBanklistID != -1 && TransBanklistID != 0"
                                        type="button" class="btn btn-outline-primary btn-xs"
                                        (click)="paidBank_show_modal()">Send Email</button>
   
                                </div> -->


                            </div>
                            <br>

                            <!--Start Rpt NskMatha Paid BankList -->

                            <div class="container dashboardmain">
                                <div id="print-paidbanklist">
                                    <div *ngIf="show_paidbanklisRpt === 'download'" id="rpt_labpaidbank_pdf"
                                        style="text-align: center;">
                                        <div style="page-break-after: always"
                                            *ngFor='let brec of paid_bankIDList;let b = index'>
                                            {{set_srno_0()}}
                                            <table class="table table-bordered">
                                                <tbody>

                                                    <tr>
                                                        <td style="text-align: center;" colspan="7">
                                                            <h3 style="font-weight: bold;">Bank Payment Sheet List No. :
                                                                {{TransBanklistID}}</h3>
                                                            <h3 style="font-weight: bold;">पिंपरी चिंचवड माथाडी व
                                                                असंरक्षित कामगार मंडळ</h3>

                                                            <!-- <p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 ,
                                                                                PhNo.0253-2501350</p>
                                                                              -->
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style="text-align: center;" colspan="7">
                                                            Bank Payment Sheet for the month of
                                                            {{lab_authListPaidBank[0]['wageYr']}} -
                                                            {{lab_authListPaidBank[0]['wageMon']}}
                                                            And Period From
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'frmDate',brec) |
                                                            date: 'dd/MM/yyyy'}}
                                                            To
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'toDate',brec) |
                                                            date: 'dd/MM/yyyy'}}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style="text-align: center;" colspan="7">
                                                            बँकेचे नाव : -
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'bankName',brec)}}
                                                            <!-- बँकेची शाखा : -
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'branchName',brec)}} -->
                                                            &nbsp;&nbsp;
                                                            दिनांक : -
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'docdt',brec) |
                                                            date: 'dd/MM/yyyy'}}
                                                            &nbsp;&nbsp;
                                                            <!-- यादी प्रकार : -
                                                            {{get_paidBank_Details(rptLabPaiddBankList,'clrType',brec)}} -->
                                                        </td>

                                                    </tr>

                                                    <!-- <tr  *ngFor='let tl of paid_bank_toliIDList;let t = index'>
                                                        <td ></td>
                                                            <td >{{tl}}</td>
                                                            <td >test</td>
                                                            <td ></td>
                                                            <td ></td>
                                                            <td ></td>
                                                            
                                                            <td ></td>
                                                    </tr> -->

                                                    <tr>
                                                        <td style="text-align: center;font-weight: bold;">अ.न.</td>
                                                        <td style="text-align: center;font-weight: bold;">नोंदणी क्रमांक
                                                        </td>
                                                        <td style="text-align: center;font-weight: bold;">टोळी क्रमांक
                                                        </td>
                                                        <td style="text-align: left;font-weight: bold;">कामगाराचे नाव
                                                        </td>
                                                        <td style="text-align: center;font-weight: bold;">खाते क्रमांक
                                                        </td>
                                                        <td style="text-align: center;font-weight: bold;">IFSC Code
                                                        </td>
                                                        <td style="text-align: right;font-weight: bold;">वेतन रक्कम</td>
                                                    </tr>


                                                    <tr *ngFor='let rec of rptLabPaiddBankList;let i = index'>
                                                        <td *ngIf="brec == rec.bankID">{{add_srno()}}</td>
                                                        <td *ngIf="brec == rec.bankID">{{rec.labRegNo}}</td>
                                                        <td *ngIf="brec == rec.bankID">{{rec.toliNo}}</td>
                                                        <td *ngIf="brec == rec.bankID" style="text-align: left;">
                                                            {{rec.labName}}</td>
                                                        <td *ngIf="brec == rec.bankID">{{rec.accNo}}</td>
                                                        <td *ngIf="brec == rec.bankID">{{rec.ifscCode}}</td>

                                                        <td *ngIf="brec == rec.bankID" style="text-align: right;">
                                                            {{rec.netPay}}</td>
                                                    </tr>




                                                    <!-- <div  *ngFor='let tl of paid_bank_toliIDList;let t = index'>
                                                       
                                                        <tr *ngFor='let rec of rptLabPaiddBankList;let i = index'>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo">{{add_srno()}}</td>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo">{{rec.labRegNo}}</td>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo">{{rec.toliNo}}</td>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo" style="text-align: left;">
                                                                {{rec.labName}}</td>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo">{{rec.accNo}}</td>
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo">{{rec.ifscCode}}</td>
                                                            
                                                            <td *ngIf="brec == rec.bankID && tl == rec.toliNo" style="text-align: right;">
                                                                {{rec.netPay}}</td>
                                                        </tr>

                                                        <tr>
                                                            <td ></td>
                                                            <td ></td>
                                                            <td >{{tl}}</td>
                                                            <td ></td>
                                                            <td ></td>
                                                            <td ></td>
                                                            
                                                            <td ></td>
                                                        </tr>
                                                    </div> -->



                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style="text-align: right;font-weight: bold;">एकुण वेतन रक्कम
                                                        </td>
                                                        <td style="text-align: right;font-weight: bold;">
                                                            {{getTotal_bankwise(rptLabPaiddBankList,'netPay',brec)}}
                                                        </td>
                                                    </tr>


                                                    <tr>



                                                        <td colspan="7" style="text-align: left;font-weight: bold;">

                                                            <div class="row" style="margin-top: 5px;">
                                                                <div class="col"
                                                                    style="text-align: left;font-weight: bold;font-size:medium;">
                                                                    धनादेश अक्षरी रक्कम :-
                                                                    {{get_paidBank_Details(rptLabPaiddBankList,'chqAmt',brec)
                                                                    | amountInWords}}
                                                                </div>
                                                            </div>
                                                            <div class="row" style="margin-top: 5px;">
                                                                <div class="col"
                                                                    style="text-align: left;font-weight: bold;font-size:medium;">
                                                                    बँकेचे नाव :-
                                                                    {{get_paidBank_Details(rptLabPaiddBankList,'accName',brec)}}
                                                                </div>

                                                            </div>

                                                            <div class="row" style="margin-top: 5px;">
                                                                <div class="col"
                                                                    style="text-align: left;font-weight: bold;font-size:medium;">
                                                                    धनादेश क्रमांक :-
                                                                    {{get_paidBank_Details(rptLabPaiddBankList,'billNo',brec)}}
                                                                </div>

                                                                <div class="col"
                                                                    style="text-align: left;font-weight: bold;font-size:medium;">
                                                                    धनादेश दिनांक :-
                                                                    {{get_paidBank_Details(rptLabPaiddBankList,'chqDate',brec)
                                                                    | date: 'dd/MM/yyyy'}}
                                                                </div>

                                                            </div>

                                                            <div class="row" style="margin-top: 5px;">
                                                                <div class="col"
                                                                    style="text-align: left;font-weight: bold;font-size:medium;">
                                                                    This List Is mail to Your E-mail ID :-
                                                                    {{get_paidBank_Details(rptLabPaiddBankList,'emailID',brec)}}
                                                                </div>
                                                            </div>

                                                            <br>

                                                            <div>

                                                                <div style="text-align: right;font-weight:normal;">
                                                                    लिपिक &nbsp;&nbsp;रोखपाल &nbsp;&nbsp; मुख्य लेखापाल
                                                                    &nbsp;&nbsp;सचिव
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </div>
                                                                <div style="text-align: right;font-weight: bold;">
                                                                    नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक
                                                                </div>
                                                            </div>
                                                            <br>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <!--End Rpt NskMatha Paid BankList -->


                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                <!-- <pre>Active: {{ active }}</pre> -->
            </ng-template>
        </ngb-panel>

        <!--Lab Insurance -->
        
        <ngb-panel title="Labour Insurance &nbsp;">
            <ng-template ngbPanelTitle>
                <span *ngIf="cnt_Ins_auth_Pend > 0" class="badge"><b> {{cnt_Ins_auth_Pend}}</b></span>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>New Labour Policy Authorization</a>
                        <ng-template ngbNavContent>

                            <form class="search">
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <label for="insComp">Insurnace Company</label>
                                        <select (change)="set_policyApproval_insComapny($event)"
                                            class="form-control select-t" id="insComp">
                                            <option value="-1">All</option>
                                            <option *ngFor="let rec of ins_compListDt; let i=index"
                                                value="{{rec.insCompID}}">{{rec.compName}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="Policy-Approval">Approval Status</label>
                                        <select (change)="set_policyApproval_Status($event)"
                                            class="form-control select-t" id="Policy-Approval">
                                            <option value="Pending">Pending</option>
                                            <option value="Reject">Reject</option>
                                            <option value="All">All</option>
                                        </select>
                                    </div>


                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="fill_approval_pendingList()">Show</button>

                                    </div>

                                </div>
                            </form>

                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th style="text-align: center;">Comapny Name </th>
                                        <th style="text-align: center;">Reg.No. </th>
                                        <th style="text-align: center;">Name </th>
                                        <th style="text-align: center;">Policy No. </th>
                                        <th style="text-align: center;">Policy Amt.</th>
                                        <th style="text-align: center;">Status</th>
                                        <th style="text-align: center;">Remark</th>
                                        <th style="text-align: center;">Document</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </thead>
                                    <tr *ngFor='let rec of lab_approval_pendList;let i = index' class="sub-tr border">
                                        <td *ngIf="rec.policyAuthID > 0">{{rec.insCompName}} </td>
                                        <td *ngIf="rec.policyAuthID > 0">{{rec.labRegNo}} </td>
                                        <td *ngIf="rec.policyAuthID > 0">{{rec.labName}} </td>

                                        <td *ngIf="rec.policyAuthID > 0">{{rec.policyNo}} </td>

                                        <td *ngIf="rec.policyAuthID > 0">{{rec.policyAmt}} </td>

                                        <td *ngIf="rec.policyAuthID > 0">{{rec.status}}</td>
                                        <td *ngIf="rec.policyAuthID > 0">{{rec.remark}}</td>
                                        <td *ngIf="rec.policyAuthID > 0"><i class="fa fa-file" ngbTooltip="view file"
                                                (click)="view_uploadFile(rec.docName,'ins')" aria-hidden="true"
                                                style="color:black;"> </i></td>

                                        <td *ngIf="rec.policyAuthID > 0"> <a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="update_new_policy_Accept(i)">Accept</a> </td>
                                        <td *ngIf="rec.policyAuthID > 0"><a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="show_modal_policyRej(rec.policyAuthID,i)">Reject</a></td>
                                        <td *ngIf="rec.policyAuthID > 0"><i *ngIf="rec.isEdit == true"
                                                class="fas fa-times" ngbTooltip="Cancle edit" aria-hidden="true"
                                                style="color: red;"
                                                (click)="set_approval_pendList_editcontrol(i,false)"></i></td>
                                        <td *ngIf="rec.policyAuthID > 0"></td>
                                    </tr>

                                </table>
                            </div>

                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="2">
                        <a ngbNavLink>Labour Policy</a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive" style="height: 400px;">


                            </div>
                        </ng-template>
                    </li> -->
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                <!-- <pre>Active: {{ active }}</pre> -->
            </ng-template>
        </ngb-panel>
        

        <!-- Lab Patpedhi  -->
        <ngb-panel title="Labour Patpedhi &nbsp;">
            <ng-template ngbPanelTitle>
                <span *ngIf="cnt_Pat_auth_Pend > 0" class="badge"><b> {{cnt_Pat_auth_Pend}}</b></span>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>New Labour Patpedhi Authorization</a>
                        <ng-template ngbNavContent>

                            <form class="search">
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <label for="insComp">Patpedhi Name</label>
                                        <select (change)="set_patApproval_patComapny($event)"
                                            class="form-control select-t" id="patComp">
                                            <option value="-1">All</option>
                                            <option *ngFor="let rec of pat_compListDt; let i=index"
                                                value="{{rec.patPediID}}">{{rec.patPediName}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="Pat-Approval">Approval Status</label>
                                        <select (change)="set_patApproval_Status($event)" class="form-control select-t"
                                            id="Pat-Approval">
                                            <option value="Pending">Pending</option>
                                            <option value="Reject">Reject</option>
                                            <option value="All">All</option>
                                        </select>
                                    </div>


                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="fill_pat_approval_pendingList()">Show</button>

                                    </div>

                                </div>
                            </form>

                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th style="text-align: center;">Patepdhi Name </th>
                                        <th style="text-align: center;">Reg.No. </th>
                                        <th style="text-align: center;">Name </th>

                                        <th style="text-align: center;">Patpedhi Amt.</th>
                                        <th style="text-align: center;">Status</th>
                                        <th style="text-align: center;">Remark</th>
                                        <th style="text-align: center;">Document</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </thead>
                                    <tr *ngFor='let rec of patlab_approval_pendList;let i = index'
                                        class="sub-tr border">
                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.insCompName}} </td>
                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.labRegNo}} </td>
                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.labName}} </td>



                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.dedAmt}} </td>

                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.status}}</td>
                                        <td *ngIf="rec.patpediAuthID > 0">{{rec.remark}}</td>
                                        <td *ngIf="rec.patpediAuthID > 0"><i class="fa fa-file" ngbTooltip="view file"
                                                (click)="view_uploadFile(rec.docName,'pat')" aria-hidden="true"
                                                style="color:black;"> </i></td>

                                        <td *ngIf="rec.patpediAuthID > 0"> <a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="update_new_patpedi_Accept(i)">Accept</a> </td>
                                        <td *ngIf="rec.patpediAuthID > 0"><a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="show_modal_patpediRej(rec.patpediAuthID,i)">Reject</a></td>
                                        <td *ngIf="rec.patpediAuthID > 0"><i *ngIf="rec.isEdit == true"
                                                class="fas fa-times" ngbTooltip="Cancle edit" aria-hidden="true"
                                                style="color: red;"
                                                (click)="set_approval_pat_pendList_editcontrol(i,false)"></i></td>
                                        <td *ngIf="rec.patpediAuthID > 0"></td>
                                    </tr>

                                </table>
                            </div>

                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="2">
                        <a ngbNavLink>Labour Patpedhi</a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive" style="height: 400px;">


                            </div>
                        </ng-template>
                    </li> -->
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

            </ng-template>
        </ngb-panel>

        <!-- Lab Union -->
        <ngb-panel title="Labour Union &nbsp;" > 
            <ng-template ngbPanelTitle>
                <span *ngIf="cnt_Union_auth_Pend > 0" class="badge"><b>  {{cnt_Union_auth_Pend}}</b></span>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>New Labour Union Authorization</a>
                        <ng-template ngbNavContent>

                            <form class="search">
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <label for="unionComp">Union Name</label>
                                        <select (change)="set_unionApproval_unionComapny($event)"
                                            class="form-control select-t" id="unionComp">
                                            <option value="-1">All</option>
                                            <option *ngFor="let rec of union_compListDt; let i=index"
                                                value="{{rec.unionID}}">{{rec.compName}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="union-Approval">Approval Status</label>
                                        <select (change)="set_unionApproval_Status($event)"
                                            class="form-control select-t" id="union-Approval">
                                            <option value="Pending">Pending</option>
                                            <option value="Reject">Reject</option>
                                            <option value="All">All</option>
                                        </select>
                                    </div>


                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="fill_union_approval_pendingList()">Show</button>

                                    </div>

                                </div>
                            </form>

                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th style="text-align: center;">Union Name </th>
                                        <th style="text-align: center;">Reg.No. </th>
                                        <th style="text-align: center;">Name </th>

                                        <!-- <th style="text-align: center;">Union Amt.</th> -->
                                        <th style="text-align: center;">Status</th>
                                        <th style="text-align: center;">Remark</th>
                                        <th style="text-align: center;">Document</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </thead>
                                    <tr *ngFor='let rec of unionlab_approval_pendList;let i = index'
                                        class="sub-tr border">
                                        <td *ngIf="rec.unionAuthID > 0">{{rec.insCompName}} </td>
                                        <td *ngIf="rec.unionAuthID > 0">{{rec.labRegNo}} </td>
                                        <td *ngIf="rec.unionAuthID > 0">{{rec.labName}} </td>



                                        <!-- <td *ngIf="rec.unionAuthID > 0">{{rec.dedAmt}} </td> -->

                                        <td *ngIf="rec.unionAuthID > 0">{{rec.status}}</td>
                                        <td *ngIf="rec.unionAuthID > 0">{{rec.remark}}</td>
                                        <td *ngIf="rec.unionAuthID > 0"><i class="fa fa-file" ngbTooltip="view file"
                                                (click)="view_uploadFile(rec.docName,'union')" aria-hidden="true"
                                                style="color:black;"> </i></td>

                                        <td *ngIf="rec.unionAuthID > 0"> <a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="update_new_union_Accept(i)">Accept</a> </td>
                                        <td *ngIf="rec.unionAuthID > 0"><a
                                                style="color: blue;text-decoration: underline;"
                                                (click)="show_modal_unionRej(rec.unionAuthID,i)">Reject</a></td>
                                        <td *ngIf="rec.unionAuthID > 0"><i *ngIf="rec.isEdit == true"
                                                class="fas fa-times" ngbTooltip="Cancle edit" aria-hidden="true"
                                                style="color: red;"
                                                (click)="set_approval_union_pendList_editcontrol(i,false)"></i></td>
                                        <td *ngIf="rec.patpediAuthID > 0"></td>
                                    </tr>

                                </table>
                            </div>

                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="2">
                        <a ngbNavLink>Labour Patpedhi</a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive" style="height: 400px;">


                            </div>
                        </ng-template>
                    </li> -->
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                <!-- <pre>Active: {{ active }}</pre> -->
            </ng-template>
        </ngb-panel>

        <!-- Lab Non.Ref. Appll -->
        <ngb-panel title="Labour Non.Ref. Advance Appllication &nbsp; " > 
            <ng-template ngbPanelTitle>
                <span *ngIf="cnt_NonRef_auth_Pend > 0" class="badge"><b>  {{cnt_NonRef_auth_Pend}}</b></span>
            </ng-template>
            <ng-template ngbPanelContent >
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>New Non.Ref. Advance Appllication Authorization</a>
                        <ng-template ngbNavContent>

                            <form class="search">
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    
                                    <div class="col-md-3">
                                        <label for="nonref-Approval">Approval Status</label>
                                        <select (change)="set_nonRefApproval_Status($event)"
                                            class="form-control select-t" id="nonref-Approval">
                                            <option value="Pending">Pending</option>
                                            <option value="Reject">Reject</option>
                                            <option value="Clerk Approve">Approve</option>
                                        </select>
                                    </div>


                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                                            (click)="checkAuth_nonRef_approval()">Show</button>

                                    </div>

                                </div>
                            </form>

                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th style="text-align: center;">Application Date</th>
                                        <th style="text-align: center;">Reg.No. </th>
                                        <th style="text-align: center;">Name </th>
                                        <th style="text-align: center;">Application Loan Amt.</th>
                                        <th style="text-align: center;">Reason</th>
                                        <th style="text-align: center;">Status</th>
                                        <th style="text-align: center;">Remark</th>
                                        <th>View Case</th>
                                        <th style="text-align: center;">Document</th>
                                        <th *ngIf="sel_nonRefApp_Status == 'Pending'"></th>
                                        <th *ngIf="sel_nonRefApp_Status == 'Pending'"></th>
                                       
                                    </thead>
                                
                                    <tr *ngFor='let rec of nonReflab_approval_pendList;let i = index'
                                    class="sub-tr border">
                                    <td >{{rec.applDt | date: 'dd/MM/yyyy'}} </td>
                                    <td >{{rec.labRegNo}} </td>
                                    <td >{{rec.labName}} </td>
                                    <td >{{rec.advAmt}} </td>
                                    <td >{{rec.rdesc}} </td>
                                    <td >{{rec.status}}</td>
                                    <td >{{rec.remark}}</td>
                                    <td ><i class="fa fa-eye" ngbTooltip="view file"
                                        aria-hidden="true"
                                     (click)="fill_rpt_nonRef_LabPFcase(rec.pfAdvApplID,rec.pfMasterID,rec.labourID)" style="color:blue;"> </i></td>

                                    <td ><i class="fa fa-file" ngbTooltip="view document"
                                         aria-hidden="true"
                                        (click)="show_modal_nonRef_Doc(rec.pfAdvApplID)"    style="color:blue;"> </i></td>

                                    <td *ngIf="sel_nonRefApp_Status == 'Pending'"> <a
                                            style="color: blue;text-decoration: underline;"
                                            (click)="update_new_nonRef_Accept(rec.pfAdvApplID,i)">Accept</a> </td>
                                    <td *ngIf="sel_nonRefApp_Status == 'Pending'"><a
                                            style="color: blue;text-decoration: underline;"
                                            (click)="show_modal_nonRefRej(rec.pfAdvApplID,i)">Reject</a></td>
                                    
                                </tr>

                                </table>
                            </div>

                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Non.Ref. Advance Authorization</a>
                        <ng-template ngbNavContent>
                            <div>

                                <br>
                                <div class="row">
                                    <table
                                        class="table table-hover table-responsive-sm labour-table table-striped ">

                                        <tbody>
                                          
                                            <tr class="sub-tr">
                                                <td>From Date</td>
                                                <td><input type="date" class="form-control"
                                                        [(ngModel)]="frm_nonRefauthDate" value="{{frm_nonRefauthDate}}"
                                                        (input)="validate_Date($event,'frm_nonRefauthDate')"
                                                        (change)="set_Date($event,'frm_nonRefauthDate')" />
                                                    <span *ngIf="invalid_frm_nonRefauthDate!=''" style="font-size: small;color:red">Invalid
                                                        Date</span>
                                                    </td>
                                                <td>To Date </td>
                                                <td><input type="date" class="form-control"
                                                        [(ngModel)]="to_nonrefauthDate" value="{{to_nonrefauthDate}}"
                                                        (input)="validate_Date($event,'to_nonrefauthDate')"
                                                        (change)="set_Date($event,'to_nonrefauthDate')" />
                                                    <span *ngIf="invalid_to_nonRefauthDate!=''" style="font-size: small;color:red">Invalid
                                                        Date</span>
                                                    </td>
                                                <td><button type="button" class="btn btn-primary"
                                                        (click)="get_NonRef_AuthList()">Show</button></td>

                                            </tr>



                                        </tbody>

                                    </table>

                                    
                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th class="th-sm"><input type="checkbox"
                                            (change)="set_selAll_Auth_NonRef($event,-1)"
                                            *ngIf="lab_NonRef_authList.length > 0">
                                        &nbsp;<span>{{nonRef_AuthSelunSel}}</span></th>
                                        <th style="text-align: center;">Reg.No. </th>
                                        <th style="text-align: center;">Name </th>
                                        <th style="text-align: center;">Application Date</th>
                                        <th style="text-align: center;">Loan Amt.</th>
                                        <th style="text-align: center;">Reason</th>
                                        <th style="text-align: center;">A/C No.</th>
                                        <th style="text-align: center;">Bank Name</th>
                                        
                                    </thead>
                                
                                    <tr *ngFor='let rec of lab_NonRef_authList;let i = index'
                                    class="sub-tr border">
                                    <td><input type="checkbox" (change)="set_selAll_Auth_NonRef($event,i)"
                                        [checked]="rec.checkStatus"></td>
                                    <td >{{rec.labRegNo}} </td>
                                    <td >{{rec.labName}} </td>
                                    <td >{{rec.applDt | date: 'dd/MM/yyyy'}} </td>
                                    <td >{{rec.advAmt}} </td>
                                    <td >{{rec.rdesc}} </td>
                                    <td >{{rec.accNo}}</td>
                                    <td >{{rec.bankName}}</td>
                                   
                                    
                                </tr>

                                </table>
                              

                                </div>
                                <div class="text-center">
                                    <div class="row" >
                                        <div class="col-md-3" style="text-align: right;">
                                            <label for="Financial-Year">Authorize Date </label>
                                        </div>
                                        <div class="col-md-3" style="text-align: right;">

                                            <input type="date" class="form-control" (input)="vlaidate_nonRef_AuthDate($event)" [(ngModel)]="nonrefauthDate"
                                                value="{{nonrefauthDate}}" />
                                                 <span *ngIf="invalid_nonRef_AuthDate!=''" style="font-size: small;color:red">Invalid Date</span>
                                        </div>
                                        <div class="col-md-6" style="text-align: left;">

                                            <button type="button" class="btn btn-primary"
                                                (click)="save_nonRef_Authorization()">Submit</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>Non.Ref. Advance Paid Bank List</a>
                        <ng-template ngbNavContent>

                            <div class="row">
                                
                                <div class="col-md-2">
                                    <label for="month-year">List No.</label>
                                    <select class="form-control" (change)="Set_nonRef_piadbankListNo($event)">
                                        <option value="-1">Select list no.</option>
                                        <option value="0">0</option>
                                        <option *ngFor='let rec of nonRef_list_labpaidBankNo' value="{{rec.listNo}}">
                                            {{rec.listNo}}</option>

                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="month-year">From Date</label>
                                    <input type="date" class="form-control" [(ngModel)]="frm_nonRefpaidBankDate"
                                        value="{{frm_nonRefpaidBankDate}}" [disabled]='nonRef_PaidbankCnt_disabled' 

                                        (input)="validate_Date($event,'frm_nonRefpaidBankDate')"
                                        (change)="set_Date($event,'frm_nonRefpaidBankDate')" />
                                    <span *ngIf="invalid_frm_nonRefpaidBankDate!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>

                                <div class="col-md-2">
                                    <label for="month-year" class="board-name-label">To Date</label>
                                    <input type="date" class="form-control" [(ngModel)]="to_nonRefpaidBankDate"
                                        value="{{to_nonRefpaidBankDate}}" [disabled]='nonRef_PaidbankCnt_disabled' 
                                        (input)="validate_Date($event,'to_nonRefpaidBankDate')"
                                        (change)="set_Date($event,'to_nonRefpaidBankDate')" />
                                    <span *ngIf="invalid_to_nonRefpaidBankDate!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </div>

                                </div>

                                <div class="row">

                                <div class="col-md-2" style="margin-top: 27px;">
                                    <button *ngIf="nonRef_TransBanklistID != -1" type="button" class="form-control btn-primary"
                                        (click)="fill_nonRef_PaidBank_ChqDt('yes','chq')">Cheque Details</button>
                                </div>

                                <div class="col-md-2" style="margin-top: 27px;">
                                    <button *ngIf="nonRef_TransBanklistID > 0" type="button" class="form-control btn-primary"
                                        (click)="nonRef_labPayAuthListPaidBank()">Show Report</button>
                                </div>

                                <div class="col-md-4" style="margin-top: 27px;">
                                    <button *ngIf="nonRef_TransBanklistID > 0" type="button" class="form-control btn-primary"
                                        (click)="nonRef_Create_Excel_PaidBank()">Create Excel & Send Email</button>
                                </div>
                                <div class="col-md-4" style="margin-top: 27px;">
                                    <button *ngIf="nonRef_TransBanklistID != -1 && nonRef_TransBanklistID != 0 " type="button" class="form-control btn-primary" style="margin-left: 10px;"
                                (click)="fill_nonRef_PaidBank_ChqDt('yes','chqprint')">Print Cheque</button>
                                </div>

                            </div>
                            <br>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                <!-- <pre>Active: {{ active }}</pre> -->
            </ng-template>
        </ngb-panel>


        <!-- Upload Excel -->
        <ngb-panel title="Upload Excel File">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-4">
                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                            (click)="go_to_upload_excel('bank')">Upload Bank Excel File</button>

                    </div>
                    <div class="col-md-4">
                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                            (click)="go_to_upload_excel('est')">Upload Establishment Excel File</button>

                    </div>
                    <div class="col-md-4">
                        <button type="button" class="btn btn-primary" style="margin-top: 28px;"
                            (click)="go_to_upload_excel('labpay')">Upload Labour Payment Excel File</button>

                    </div>
                </div>

            </ng-template>
        </ngb-panel>

        <!-- Send Notification -->
        
        <ngb-panel title="Labour Notification &nbsp; "  > 
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-12">
                        <div class="font-15 text-right" style="text-align: right;margin-left: 30px;margin-right: 30px;"> <a
                                href="javascript:void(0)" style="font-weight: bold;" (click)="goto_lab_Notification(0)">Go to Notfication</a>
                        </div>
                    </div>
                </div>
                
                <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Notification List</a>
                        <ng-template ngbNavContent>
                            <form class="search">
                                <div class="row">
                                    <div class="col-md-1"></div>
                                </div>
                            </form>

                            <div class="row">
                                <table class="table table-hover table-responsive-xl labour-table" style="table-layout:fixed">
                                  <thead>
                                    <th class="col-md-2">Date</th>
                                    <th>Subject</th>
                                    <th class="col-md-2" style="word-wrap: break-word;">Message</th>
                                    <th class="col-md-2" style="word-wrap: break-word;">Send To</th>
                                    <th class="col-md-2" style="word-wrap: break-word;">Status</th>
                                    <th class="col-md-2" style="word-wrap: break-word;"></th>
                                    
                                  </thead>
                                  <tbody>
                                    <ng-container *ngIf="notfication_MsgList || notfication_MsgList.length>0">
                                        <tr *ngFor="let rec of notfication_MsgList">
                                          <td class="col-md-2">{{rec.inputDt| date:'dd/MM/yyyy'}} </td>
                                          <td class="col-md-2" style="word-wrap: break-word;">{{rec.subject}}</td>
                                          <td class="col-md-2" style="word-wrap: break-word;">{{rec.message}}</td>
                                          <td class="col-md-2" style="word-wrap: break-word;">{{rec.sendTo}}</td>
                                          <td class="col-md-2" style="word-wrap: break-word;">{{rec.status}}</td>
                                          <td>
                                            <a *ngIf="rec.status == 'Active'" href="javascript:void(0)" (click)="Deactive_lab_notificationList(rec.messageID)" >DeActive</a>
                                          </td>
                                        </tr>
                                      </ng-container>
                                  </tbody>
                                </table>
                              </div>

                        </ng-template>
                    </li>
                 
                </ul>

                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                
            </ng-template>
        </ngb-panel>

        

    </ngb-accordion>

    <!-- <pagination-controls  (pageChange)="pageChanged($event,'recdone')"></pagination-controls> -->
</div>



<!-- Paid Bank List ChqDetails Modal -->
<div class="container">

    <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Payment Cheque Details</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-3" style="text-align: right;">
                            <label for="Financial-Year">Paid Date </label>
                        </div>
                        <div class="col-md-3" style="text-align: right;">

                            <input type="date" class="form-control" [(ngModel)]="labPaidBankDate"
                                value="{{labPaidBankDate}}" (input)="validate_Date($event,'labPaidBankDate')"
                                (change)="set_Date($event,'labPaidBankDate')" />
                            <span *ngIf="invalid_labPaidBankDate!=''" style="font-size: small;color:red">Invalid
                                Date</span>
                        </div>
                    </div>
                    <br>
                    <table class="table table-hover table-responsive-sm labour-table table-striped ">
                        <thead>
                            <tr>
                                <th class="th-sm">Bank Group Name</th>
                                <th class="th-sm">Paid Bank Name</th>
                                <th class="th-sm">Paid Amt.</th>
                                <th class="th-sm">Cheuqe No.</th>
                                <th class="th-sm">Cheuqe Date</th>
                                <!-- <th class="th-sm">Clearing Type</th> -->

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-tr" *ngFor='let rec of new_labpaidBankChq;let i = index'>
                                <td>{{rec.bankGroupName}}</td>
                                <td>{{rec.accName}}</td>
                                <td>{{rec.netPay}}</td>
                                <td><input type="text" class="form-control" placeholder="Enter cheque no."
                                        id="paidChqno" value="{{rec.chqNo}}" (change)="set_labPaidBankChqNo($event,i)"
                                        required></td>
                                <td>
                                    <!-- [(ngModel)]="rec.chqDate" -->
                                    <input type="date" class="form-control" value="{{rec.chqDate}}"
                                        (change)="set_labPaidBankChqDate($event,i)" />
                                    <!-- (input)="validate_Date($event,'labPaidBankChqDate')"  -->
                                    <span *ngIf="rec.invalidchqDate!=''" style="font-size: small;color:red">Invalid
                                        Date</span>
                                </td>
                                <!-- | date: 'dd/MM/yyyy' -->
                                <!-- <td>{{rec.clrType}}</td> -->
                            </tr>

                        </tbody>
                        <tfoot>
                            <tr Class="table-footer">
                                <td></td>
                                <td style="text-align: right;">Total</td>
                                <td>{{getTotal(new_labpaidBankChq,'netPay')}}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>


                </div>

                <div class="modal-footer">
                    <!--  -->
                    <button type="button" *ngIf="ishow_save_paidbankList_btn == 'yes'" class="btn btn-outline-primary"
                        (click)="save_labPaidBankList()">Update</button>
                    <button *ngIf="TransBanklistID != -1 && TransBanklistID != 0" type="button"
                        class="btn btn-outline-danger" (click)="del_labPaidBankList()">Delete</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Paid Bank List Print ChqDetails Modal -->
<div class="container">
    <div class="modal" id="myModal_chqprint" [style.display]="showModalChqPrint ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Print Payment Cheque Details</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_chqprint()"
                        style="color: white;">&times;</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <table class="table table-hover table-responsive-sm labour-table table-striped ">
                        <thead>
                            <tr>
                                <th class="th-sm">Bank Name</th>
                                <th class="th-sm">Cheuqe No.</th>
                                <th class="th-sm">Cheuqe Date</th>
                                <th class="th-sm">Paid Amt.</th>
                                <th class="th-sm">Clearing Type</th>
                                <th class="th-sm"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-tr" *ngFor='let rec of chqPrint_Data;let i = index'>
                                <td>{{rec.accName}}</td>
                                <td>{{rec.chqNo}}</td>
                                <td>{{rec.chqDate | date: 'dd/MM/yyyy'}}</td>
                                <td>{{rec.netPay}}</td>
                                <td>{{rec.clrType}}</td>
                                <td><a href="javascript:void(0)"><i class="fa fa-print"
                                            (click)="print_chq(chqPrint_Data,i)" ngbTooltip="Print"
                                            aria-hidden="true"></i></a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Paid Bank List Send Mail Modal -->
<div class="container">

    <div class="modal" id="paidbanksendMail" [style.display]="paidBank_showModal ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Bank Email Details</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="paidBank_hide_modal()"
                        style="color: white;">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row" *ngIf="PiadBank_EmialList.length > 0">

                        <table class="table table-hover table-responsive-xl labour-table">
                            <thead>
                                <!-- <th> </th> -->
                                <th style="text-align: center;">Bank Name </th>
                                <th style="text-align: center;">Branch Name </th>
                                <th style="text-align: center;">EmailID </th>
                                <th></th>
                            </thead>
                            <tr *ngFor='let rec of PiadBank_EmialList;let i = index' class="sub-tr border">

                                <td>{{rec.bankName}} </td>
                                <td>{{rec.branchName}} </td>
                                <td>{{rec.emailID}} </td>

                                <td><input type="file" style="display: inline-block;"
                                        (change)="set_uploadfile($event,i,rec.bankID)"
                                        (click)="$event.target.value=null" placeholder="Upload file"></td>
                                <!--  -->
                            </tr>


                        </table>


                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="send_paidbankListMail()">Send</button>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- New Policy Reject -->
<div class="container">

    <div class="modal" id="myModal" [style.display]="showModal_policyRej ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Reject New Policy</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_policyRej()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <label for="remark">Remark </label>
                            <input type="text" id="txtpolicyrejremark" [(ngModel)]="Str_policyrejremark"
                                value="{{Str_policyrejremark}}" class="form-control" placeholder="Enter remark" />

                        </div>


                    </div>



                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="update_new_policyReject()">Update</button>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- New Patpedhi Reject -->
<div class="container">

    <div class="modal" id="patRejModal" [style.display]="showModal_patpediRej ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Reject New Patpedhi</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_patpediRej()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <label for="remark">Remark </label>
                            <input type="text" id="txtpatpedhirejremark" [(ngModel)]="Str_patpedirejremark"
                                value="{{Str_patpedirejremark}}" class="form-control" placeholder="Enter remark" />

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="update_new_patpedhiReject()">Update</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- New Union Reject -->
<div class="container">

    <div class="modal" id="unionRejModal" [style.display]="showModal_unionRej ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Reject New Union</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_unionRej()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <label for="remark">Remark </label>
                            <input type="text" id="txunionrejremark" [(ngModel)]="Str_unionrejremark"
                                value="{{Str_unionrejremark}}" class="form-control" placeholder="Enter remark" />

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="update_new_unionReject()">Update</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Nonref Adv DocList -->
<div class="container">

    <div class="modal" id="nonRefDocModal" [style.display]="showModal_nonRefDocModal ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Document Details</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_nonRef_Doc()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                           
                            <div class="table-responsive" style="height: 400px;">

                                <table class="table table-hover table-responsive-xl labour-table">
                                    <thead>
                                        <th style="text-align: center;">Document Name</th>
                                       
                                        <th>View</th>
                                     
                                       
                                    </thead>
                                
                                    <tr *ngFor='let rec of filter_nonReflab_approvalDoc_List;let i = index'
                                    class="sub-tr border">
                                    
                                    <td >{{rec.docExample}} </td>
                                  
                                    <td ><i class="fa fa-eye" ngbTooltip="view file"
                                             aria-hidden="true"
                                        (click)="view_uploadFile(rec.docName,'nonref')"    style="color:blue;"> </i></td>

                                   
                                    
                                </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

              

            </div>
        </div>
    </div>
</div>

<!-- Non RefAdv Application Reject -->
<div class="container">

    <div class="modal" id="myModal" [style.display]="showModal_nonRefRej ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Reject Non Ref.Adv. Application</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_nonRefRej()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <label for="remark">Remark </label>
                            <input type="text" id="txtnonrefrejremark" [(ngModel)]="Str_nonRef_Rej_remark"
                                value="{{Str_nonRef_Rej_remark}}" class="form-control" placeholder="Enter remark" />

                        </div>


                    </div>



                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary"
                        (click)="update_nonRef_Reject()">Update</button>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- Non Ref. Paid Bank List ChqDetails Modal -->
<div class="container">

    <div class="modal" id="modalnonRefChq" [style.display]="showModal_nonRefChq ? 'block' : 'none'">
        <div class="modal-dialog modal-xl" role="dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background-color: #1A5276;color: white;">
                    <h4 class="modal-title">Payment Cheque Details</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide_nonRef_ChqModal()"
                        style="color: white;">&times;</button>
                </div>



                <!-- Modal body -->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-3" style="text-align: right;">
                            <label for="Financial-Year">Paid Date </label>
                        </div>
                        <div class="col-md-3" style="text-align: right;">

                            <input type="date" class="form-control" (input)="vlaidate_nonRefPaidBankDate($event)" [(ngModel)]="nonRefPaidBankDate"
                                value="{{nonRefPaidBankDate}}" />
                                <span *ngIf="invalid_nonRefPaidBankDate!=''" style="font-size: small;color:red">Invalid Date</span>
                        </div>
                    </div>
                    <br>
                    <table class="table table-hover table-responsive-sm labour-table table-striped ">
                        <thead>
                            <tr>
                                <th class="th-sm">A/C No.</th>
                                <th class="th-sm">A/C Name</th>
                                <th class="th-sm">Paid Amt.</th>
                                <th class="th-sm">Cheuqe No.</th>
                                <th class="th-sm">Cheuqe Date</th>
                               <th class="th-sm">Clearing Type</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-tr" *ngFor='let rec of nonRef_PaidBank_ChqDt;let i = index'>
                                <td>{{rec.accID}}</td>
                                <td>{{rec.eAccName}}</td>
                                <td>{{rec.amount}}</td>
                                <td><input type="text" class="form-control" placeholder="Enter cheque no."
                                        id="nonrefpaidChqno" value="{{rec.chqNo}}" (change)="set_nonRef_PaidBankChqNo($event,i)"
                                        required></td>
                                <td>
                                    <!-- [(ngModel)]="rec.chqDate" -->
                                    <input type="date" class="form-control" value="{{rec.chqDt}}"
                                    (input)="vlaidate_nonRef_Chq_PaidBankDate($event,i)"  (change)="set__nonRef_PaidBankChqDate($event,i)" />
                                        <span *ngIf="rec.invalidchqDate!=''" style="font-size: small;color:red">Invalid Date</span>
                                </td>
                               
                                <td>{{rec.clrType}}</td>
                            </tr>

                        </tbody>

                    </table>


                </div>

                <div class="modal-footer">
                    <!--  -->
                    <button *ngIf="ishow_save_nonref_paidbankList_btn == 'yes'" type="button" class="btn btn-outline-primary"
                        (click)="save_nonRef_PaidBankList()">Update</button>
                    <button *ngIf="nonRef_TransBanklistID != -1 && nonRef_TransBanklistID != 0" type="button"
                        class="btn btn-outline-danger" (click)="del__nonRef_PaidBankList()">Delete</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Loading -->
<div class="container">

    <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">

                    <div style="text-align: center;">

                        Please wait ...
                        <div class="spinner-grow text-success"></div>
                        <div class="spinner-grow text-info"></div>
                        <div class="spinner-grow text-warning"></div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

</div>